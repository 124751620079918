import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const FileFragmentFragmentDoc = gql `
  fragment FileFragment on File {
    id
    fileId
    filename
    downloadUrl
  }
`;
export const CheckinAttendeeInfoFragmentDoc = gql `
  fragment CheckinAttendeeInfo on Attendee {
    id
    checkIn
    user {
      id
      firstName
      lastName
      gender
      birthDate
      avatar {
        ...FileFragment
      }
    }
    teamMembership {
      id
      role
      team {
        id
        name
      }
    }
    bibAssignment {
      id
      bibNumber
    }
  }
  ${FileFragmentFragmentDoc}
`;
export const CheckinCampaignFragmentFragmentDoc = gql `
  fragment CheckinCampaignFragment on Campaign {
    id
    name
    timeZone
    startDate
    endDate
    campaignStatus
    donorGoal
    vizslaOrganization {
      id
      name
      timeZone
    }
    logo {
      ...FileFragment
    }
  }
  ${FileFragmentFragmentDoc}
`;
export const CheckinExperienceInfoFragmentDoc = gql `
  fragment CheckinExperienceInfo on Experience {
    id
    name
    experienceType
    attendees {
      count
      items {
        checkIn
      }
    }
    donations {
      items {
        id
        amount
      }
    }
    dateRangeType
    timeZone
    startDate
    endDate
    isActive
    hideFromPublic
  }
`;
export const OrderFragmentCheckinFragmentDoc = gql `
  fragment OrderFragmentCheckin on Order {
    id
    status
    reasonStatus
    totalAmount
    user {
      id
      firstName
      lastName
    }
  }
`;
export const UploadCredentialsFragmentDoc = gql `
  fragment UploadCredentials on SystemFileUploadSignInfo {
    type: __typename
    ... on FileStackSignInfoResponse {
      apiKey
      path
      signature
      policy
    }
    ... on AwsSignInfoResponse {
      url
      path
      fields
    }
    ... on UploadcareSignInfoResponse {
      publicKey
      expire
      signature
    }
  }
`;
export const AsyncTaskFragmentFragmentDoc = gql `
  fragment AsyncTaskFragment on AsyncTask {
    id
    name
    args
    status
  }
`;
export const Attendee_User_FragmentFragmentDoc = gql `
  fragment ATTENDEE_USER_FRAGMENT on User {
    id
    firstName
    lastName
    email
    language
    phone {
      code
      number
    }
    createdAt
    address {
      city
      state
      country
      zip
      street1
      street2
    }
    birthDate
    gender
    avatar {
      downloadUrl
      id
    }
  }
`;
export const Client_Attendee_FragmentFragmentDoc = gql `
  fragment CLIENT_ATTENDEE_FRAGMENT on Attendee {
    id
    totalRaised
    createdAt
    user {
      ...ATTENDEE_USER_FRAGMENT
    }
    experience {
      id
      name
    }
    registrationOption {
      id
      name
      fundraisingMinimum
    }
    ticketingOption {
      id
      ticketName
    }
    teamMembership {
      role
      team {
        id
        name
      }
    }
    fundraising {
      id
      goal
      raised
      createdAt
    }
  }
  ${Attendee_User_FragmentFragmentDoc}
`;
export const AttendeeCrmFragmentDoc = gql `
  fragment AttendeeCRM on Attendee {
    id
    totalRaised
    teamMembership {
      team {
        name
      }
    }
    fundraising {
      raised
    }
    user {
      id
      firstName
      lastName
      email
      avatar {
        downloadUrl
      }
      allocatedDonations(first: 1, orderBy: createdAt_DESC) {
        items {
          id
          createdAt
        }
      }
      campaigns: attendees(
        groupBy: {
          query: { experience: { campaign: { id: { as: "count", fn: { aggregate: COUNT } } } } }
        }
      ) {
        groups {
          count: Int
        }
      }
    }
  }
`;
export const CampaignsPageCampaignFragmentFragmentDoc = gql `
  fragment CampaignsPageCampaignFragment on Campaign {
    id
    name
    code
    contactEmail
    contactPhone {
      code
      number
    }
    logo {
      id
      fileId
      filename
      public
      downloadUrl
    }
    image {
      id
      fileId
      filename
      public
      downloadUrl
    }
    city
    state
    campaignType
    fundraisingGoal
    fundraiserGoal
    attendanceFundraisersCountGoal
    teamGoal
    teams {
      count
      items {
        id
        createdAt
      }
    }
    donorGoal
    donors {
      count
    }
    attendees: experiences(
      groupBy: { query: { attendees: { id: { fn: { aggregate: COUNT }, as: "count" } } } }
    ) {
      groups {
        count: Int
      }
    }
    fundraisers: experiences(
      groupBy: {
        query: { attendees: { fundraisingPageLink: { fn: { aggregate: COUNT }, as: "count" } } }
      }
    ) {
      groups {
        count: Int
      }
    }
    totalRaised
    startDate
    endDate
    campaignStatus
    webLink
    program {
      id
      name
    }
    createdAt
    updatedAt
    createdBy {
      id
      firstName
      lastName
    }
    experiences {
      count
      items {
        id
        name
        startDate
        endDate
        experienceLogoImage {
          id
          downloadUrl
        }
        image {
          id
          downloadUrl
        }
        experienceHeaderImage {
          id
          downloadUrl
        }
      }
    }
    dnsRecord {
      id
      slug
    }
    campaignTemplates {
      id
      templates {
        items {
          id
          structure
          templateType {
            type
            title
          }
        }
      }
    }
    campaignStatus
    teams {
      count
    }
    vizslaOrganization {
      id
    }
    fundraisingSettings {
      donationSettings {
        isEnabled
        allowAnonymousDonations
        allowSelfDonations
        allowDonorsToOptIn
        allowDonorsToDedicateDonations
        coveredFees
        percentageAmount
        feesEnabled
        feesMessage
        roundupEnabled
        roundupMessage
      }
    }
  }
`;
export const User_Recent_FragmentFragmentDoc = gql `
  fragment USER_RECENT_FRAGMENT on User {
    id
    firstName
    lastName
    email
    birthDate
    gender
    createdAt
    phone {
      code
      number
    }
    address {
      city
      state
      country
      zip
      street1
      street2
    }
    avatar {
      downloadUrl
    }
  }
`;
export const CampaignDiscountsSettingsFragmentFragmentDoc = gql `
  fragment CampaignDiscountsSettingsFragment on DiscountCodeSetting {
    id
    name
    category
    amount
    amountType
    datePickerType
    startDate
    startTime
    endDate
    endTime
    allocationType
    experienceType {
      id
      name
    }
    limitToOneUsePerAttendee
    totalUses
    usesCount
    isEnabled
  }
`;
export const CampaignFaqExperienceFragmentFragmentDoc = gql `
  fragment CampaignFAQExperienceFragment on Experience {
    id
    name
  }
`;
export const CampaignFaqFragmentFragmentDoc = gql `
  fragment CampaignFaqFragment on CampaignFAQ {
    id
    question
    answer
    allocation {
      items {
        ...CampaignFAQExperienceFragment
      }
    }
    userGroup
    campaign {
      id
      name
    }
  }
  ${CampaignFaqExperienceFragmentFragmentDoc}
`;
export const CampaignQuestionExperienceFragmentFragmentDoc = gql `
  fragment CampaignQuestionExperienceFragment on Experience {
    id
    name
  }
`;
export const DefaultCampaignQuestionFragmentFragmentDoc = gql `
  fragment DefaultCampaignQuestionFragment on DefaultCampaignQuestion {
    id
    isRequiredAttendee
    isRequiredDonor
    isRequiredTeam
    isEnabledAttendee
    isEnabledDonor
    isEnabledTeam
    shouldDisplay
    order
    experience {
      items {
        ...CampaignQuestionExperienceFragment
      }
    }
    question {
      id
      title
      name
      category
      type
      format
      options
      userGroup
      isMandatory
    }
  }
  ${CampaignQuestionExperienceFragmentFragmentDoc}
`;
export const CustomCampaignQuestionFragmentFragmentDoc = gql `
  fragment CustomCampaignQuestionFragment on CustomCampaignQuestion {
    id
    title
    isRequiredAttendee
    isRequiredDonor
    isRequiredTeam
    isEnabledAttendee
    isEnabledDonor
    isEnabledTeam
    userGroup
    type
    format
    options
    order
    experience {
      items {
        ...CampaignQuestionExperienceFragment
      }
    }
  }
  ${CampaignQuestionExperienceFragmentFragmentDoc}
`;
export const CampaignStaffFragmentFragmentDoc = gql `
  fragment CampaignStaffFragment on CampaignStaff {
    id
    user {
      id
      firstName
      lastName
      email
      avatar {
        id
        downloadUrl
      }
    }
    campaign {
      id
    }
    emailForwarding
  }
`;
export const CloneExperienceFragmentFragmentDoc = gql `
  fragment CloneExperienceFragment on Experience {
    name
    experienceType
    experienceCategory
    startDate
    endDate
    timeZone
    waiverAllowMultipleAttendeeConsent
    waiverTextBody
    qualifyingFundraisingAmount
    hideFromPublic
    virtualExperience
    fundraisingQualifierAmount
    externalRaceURL
    externalResultsURL
    waiverEnabled
    isActive
    raised
    privacy
    dateRangeType
    transactionFees
    allowIndividualBibAssignment
    allowExcludedBibNumbers
    bibAutoAssignStartDate
    bibAutoAssignEndDate
    bibAutoAssignEnabled
    experienceLogoImage {
      fileId
    }
    experienceHeaderImage {
      fileId
    }
    registrationOptions {
      items {
        name
        distance
        unitOfMeasure
        description
        ageRequirement
        ageRequirementAsOfDate
        fundraisingMinimum
        startWaveOrder
        maximumAttendees
        attendeesPerRegistration
        maximumRegistrationPerOrder
        groupRegistrationMinimum
        groupRegistrationMaximum
        pricingSettings {
          pricingEnabled
          pricingType
          allowCostToBeFundraisingCredit
          pricingTiers {
            items {
              price
              taxDeductibleType
              taxDeductiblePercentsAmount
              taxDeductibleCurrencyAmount
              startDate
              endDate
            }
          }
        }
      }
    }
    ticketingOptions {
      items {
        ticketName
        tableNumber
        description
        ageRequirement
        ageRequirementAsOfDate
        maximumAttendees
        attendeesPerTicket
        maximumPerOrder
        minimumGroupTickets
        maximumGroupTickets
        ticketingAvailabilityStartDateTime
        ticketingAvailabilityEndDateTime
        pricingSettings {
          pricingEnabled
          pricingType
          allowCostToBeFundraisingCredit
          pricingTiers {
            items {
              price
              taxDeductibleType
              taxDeductiblePercentsAmount
              taxDeductibleCurrencyAmount
              startDate
              endDate
            }
          }
        }
      }
    }
    venues {
      items {
        name
        notes
        address
      }
    }
    donationOptions {
      items {
        price
        description
        questions
        image {
          fileId
        }
      }
    }
    fundraisingSettings {
      donationSettings {
        isEnabled
        allowAnonymousDonations
        allowSelfDonations
        allowDonorsToOptIn
        allowDonorsToDedicateDonations
        coveredFees
        percentageAmount
        feesEnabled
        feesMessage
        roundupEnabled
        roundupMessage
        benefittingCharities
        benefittingCharitiesOrganizationName
      }
      individualSettings {
        isEnabled
        appealMessage
        thankYouMessage
      }
      teamSettings {
        isEnabled
        teamCapabilities
        joiningTeamEnabled
        allowTeamsMakeTheirTeamInvite
        allowAttendeeRequestAdmissionOntoTeam
        maximumTeams
        maximumTeamMembers
        teamSharingEnabled
        appealMessage
        thankYouMessage
      }
    }
    customExperienceQuestions {
      items {
        title
        type
        options
        userGroup
        format
        category
        isRequiredAttendee
        isRequiredTeam
        isRequiredDonor
        isEnabledTeam
        isEnabledDonor
        isEnabledAttendee
      }
    }
    campaignFAQs {
      items {
        answer
        question
        userGroup
      }
    }
    bibAssignments {
      items {
        type
        bibNumber
      }
    }
    bibPools {
      items {
        allocationType
        bibRanges
        name
        registrationOption {
          name
        }
        experience {
          name
        }
      }
    }
    fundraisingExperience {
      name
    }
    whiteListRegistrationExperience {
      name
    }
  }
`;
export const DonorFragmentFragmentDoc = gql `
  fragment DonorFragment on Donor {
    id
    createdAt
    user {
      id
      firstName
      lastName
      email
      avatar {
        id
        downloadUrl
      }
    }
    campaign {
      id
    }
    donations {
      count
      items {
        id
        amount
      }
    }
  }
`;
export const EarlyBirdRegistrationOptionFragmentFragmentDoc = gql `
  fragment EarlyBirdRegistrationOptionFragment on EarlyBirdRegistrationOption {
    id
    startDate
    endDate
    registrationOption {
      id
      name
      registrationStartDate
      registrationEndDate
    }
  }
`;
export const EarlyBirdRegistrationInvitationFragmentFragmentDoc = gql `
  fragment EarlyBirdRegistrationInvitationFragment on EarlyBirdRegistrationInvitation {
    id
    invitationType
    sentDate
    attendee {
      id
      user {
        id
        avatar {
          id
          downloadUrl
        }
        firstName
        lastName
      }
    }
    team {
      id
      avatar {
        id
        downloadUrl
      }
      name
    }
  }
`;
export const ExperienceFragmentFragmentDoc = gql `
  fragment ExperienceFragment on Experience {
    id
    name
    experienceType
    experienceCategory
    startDate
    earlyBirdUrlCode
    endDate
    waiverAllowMultipleAttendeeConsent
    waiverTextBody
    qualifyingFundraisingAmount
    timeZone
    bibAssignSort
    bibAssignSortDirection
    campaign {
      name
      startDate
      endDate
      campaignType
      vizslaOrganization {
        id
        name
        timeZone
      }
    }
    attendees {
      count
      items {
        id
      }
    }
    registrationOptions {
      items {
        id
        name
        registrationStartDate
        registrationEndDate
        attendees {
          items {
            id
          }
        }
      }
    }
    ticketingOptions {
      count
      items {
        id
        ticketingAvailabilityStartDateTime
        ticketingAvailabilityEndDateTime
        attendees {
          items {
            id
          }
        }
      }
    }
  }
`;
export const ExperienceVenueFragmentFragmentDoc = gql `
  fragment ExperienceVenueFragment on ExperienceVenue {
    id
    address
    name
    notes
  }
`;
export const ExperienceGeneralFragmentFragmentDoc = gql `
  fragment ExperienceGeneralFragment on Experience {
    hideFromPublic
    timeZone
    privacy
    whiteListTicketingExperience {
      items {
        id
        name
      }
    }
    whiteListRegistrationExperience {
      id
      name
    }
    fundraisingExperience {
      id
      name
    }
    fundraisingTicketingExperience {
      items {
        id
        name
      }
    }
    dateRangeType
    venues {
      items {
        ...ExperienceVenueFragment
      }
    }
  }
  ${ExperienceVenueFragmentFragmentDoc}
`;
export const BibAssignmentFragmentFragmentDoc = gql `
  fragment BibAssignmentFragment on ExperienceBibAssignment {
    id
    type
    bibNumber
    bibPool {
      id
    }
    attendee {
      id
      user {
        id
        firstName
        lastName
      }
    }
    experience {
      id
    }
  }
`;
export const BibPoolFragmentFragmentDoc = gql `
  fragment BibPoolFragment on ExperienceBibPool {
    id
    name
    bibRanges
    registrationOption {
      id
      name
    }
    allocationType
    teamAllocation {
      items {
        id
        name
        avatar {
          id
          downloadUrl
        }
      }
    }
    bibAssignments {
      items {
        ...BibAssignmentFragment
      }
      count
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
export const ExperienceBibAssignmentSettingsFragmentFragmentDoc = gql `
  fragment ExperienceBibAssignmentSettingsFragment on Experience {
    allowIndividualBibAssignment
    allowExcludedBibNumbers
    bibAutoAssignEnabled
    bibAutoAssignStartDate
    bibAutoAssignEndDate
    bibPools {
      items {
        ...BibPoolFragment
      }
    }
    bibAssignments {
      items {
        ...BibAssignmentFragment
      }
    }
  }
  ${BibPoolFragmentFragmentDoc}
  ${BibAssignmentFragmentFragmentDoc}
`;
export const FundraiserCrmFragmentDoc = gql `
  fragment FundraiserCRM on IndividualFundraising {
    id
    raised
    attendee {
      totalRaised
      user {
        id
        firstName
        lastName
        avatar {
          downloadUrl
        }
      }
      teamMembership {
        team {
          name
        }
      }
    }
    allocatedDonations(first: 1, orderBy: createdAt_DESC) {
      items {
        createdAt
      }
    }
  }
`;
export const UserBasicInfoFragmentFragmentDoc = gql `
  fragment UserBasicInfoFragment on User {
    id
    firstName
    lastName
    fullName
    email
    phone {
      code
      number
    }
    birthDate
  }
`;
export const BaseUserFragmentFragmentDoc = gql `
  fragment BaseUserFragment on User {
    id
    firstName
    lastName
    avatar {
      id
      downloadUrl
    }
    attendees {
      items {
        id
        teamMembership {
          team {
            id
            name
          }
        }
        totalRaised
        experience {
          campaign {
            id
          }
        }
      }
    }
  }
`;
export const RegistrationPricingTierFragmentFragmentDoc = gql `
  fragment RegistrationPricingTierFragment on RegistrationPricingTier {
    id
    price
    startDate
    endDate
    taxDeductibleCurrencyAmount
    taxDeductibleType
    taxDeductiblePercentsAmount
    pricingSettings {
      id
      registrationOption {
        id
      }
    }
  }
`;
export const TicketingPricingTierFragmentFragmentDoc = gql `
  fragment TicketingPricingTierFragment on TicketingPricingTier {
    id
    price
    startDate
    endDate
    taxDeductibleCurrencyAmount
    taxDeductibleType
    taxDeductiblePercentsAmount
    pricingSettings {
      id
      ticketingOption {
        id
      }
    }
  }
`;
export const ProgramFragmentFragmentDoc = gql `
  fragment ProgramFragment on Program {
    id
    name
  }
`;
export const RegistrationOptionFragmentFragmentDoc = gql `
  fragment RegistrationOptionFragment on RegistrationOption {
    id
    name
    distance
    unitOfMeasure
    startWaveOrder
    description
    fundraisingMinimum
    ageRequirement
    ageRequirementAsOfDate
    attendeesPerRegistration
    maximumRegistrationPerOrder
    groupRegistrationMinimum
    groupRegistrationMaximum
    maximumAttendees
    fundraisingMinimum
    checkpointAmount
    checkpointDate
    checkpointNotification
    registrationStartDate
    registrationEndDate
    attendees {
      count
    }
    pricingSettings {
      id
      allowCostToBeFundraisingCredit
      pricingEnabled
      pricingType
      pricingTiers {
        items {
          id
          price
          taxDeductibleCurrencyAmount
          taxDeductiblePercentsAmount
          taxDeductibleType
          startDate
          endDate
        }
        count
      }
    }
  }
`;
export const StoreAssetFragmentFragmentDoc = gql `
  fragment StoreAssetFragment on StoreAsset {
    id
    name
    description
    image {
      ...FileFragment
    }
    storeAvailability
    swagbagAvailability
    price
    unitCost
    continueSalesWhenOutOfStock
    notifyWhenStockMinimumIsAchieved
    stockMinimumBoundary
    note
    variantsPossible
  }
  ${FileFragmentFragmentDoc}
`;
export const UserFragmentFragmentDoc = gql `
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
    fullName
    status
    customer
    birthDate
    minor
    gender
    vizslaOrganizationStaff {
      count
      items {
        id
        roles
      }
    }
    phone {
      code
      number
    }
    address {
      street1
      street2
      state
      city
      zip
    }
    avatar {
      id
      fileId
      downloadUrl
    }
  }
`;
export const StoreHistoryEventFragmentFragmentDoc = gql `
  fragment StoreHistoryEventFragment on StoreHistoryEvent {
    id
    quantity
    type
    dateReceived
    variantsValues
    orderCost
    createdAt
    createdBy {
      ...UserFragment
    }
    note
  }
  ${UserFragmentFragmentDoc}
`;
export const StoreAssetSimpleFragmentFragmentDoc = gql `
  fragment StoreAssetSimpleFragment on StoreAsset {
    id
    name
    description
    continueSalesWhenOutOfStock
    price
    image {
      ...FileFragment
    }
    vizslaOrganization {
      id
    }
  }
  ${FileFragmentFragmentDoc}
`;
export const ExperienceStoreAssetFragmentFragmentDoc = gql `
  fragment ExperienceStoreAssetFragment on ExperienceStoreAsset {
    id
    price
    experience {
      id
    }
    storeAsset {
      ...StoreAssetSimpleFragment
    }
  }
  ${StoreAssetSimpleFragmentFragmentDoc}
`;
export const ExperienceStoreSettingsFragmentFragmentDoc = gql `
  fragment ExperienceStoreSettingsFragment on Experience {
    id
    isStoreEnabled
    storeAssets {
      count
      items {
        id
      }
    }
  }
`;
export const TeamRtFragmentFragmentDoc = gql `
  fragment TeamRTFragment on TeamFundraising {
    id
    name
    createdAt
    totalRaised
    fundraiserGoal
    avatar {
      id
      downloadUrl
    }
    teamCaptain: members(filter: { role: { equals: "Team Captain" } }) {
      items {
        id
        role
        attendee {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
    members {
      count
      items {
        id
      }
    }
  }
`;
export const TicketingOptionFragmentFragmentDoc = gql `
  fragment TicketingOptionFragment on TicketingOption {
    id
    ticketName
    name: ticketName
    tableNumber
    description
    ageRequirement
    ageRequirementAsOfDate
    maximumAttendees
    attendeesPerTicket
    maximumPerOrder
    minimumGroupTickets
    maximumGroupTickets
    ticketingAvailabilityStartDateTime
    ticketingAvailabilityEndDateTime
    pricingSettings {
      id
      allowCostToBeFundraisingCredit
      pricingEnabled
      pricingType
      pricingTiers {
        items {
          id
          price
          taxDeductibleCurrencyAmount
          taxDeductiblePercentsAmount
          taxDeductibleType
          startDate
          endDate
        }
        count
      }
    }
  }
`;
export const UserFragmentListFragmentDoc = gql `
  fragment UserFragmentList on User {
    id
    email
    firstName
    lastName
    fullName
    status
    customer
    roles {
      items {
        id
        name
      }
    }
    avatar {
      downloadUrl
    }
  }
`;
export const InboxUserFragmentFragmentDoc = gql `
  fragment InboxUserFragment on User {
    id
    firstName
    lastName
    fullName
    email
    avatar {
      downloadUrl
    }
  }
`;
export const InboxFragmentFragmentDoc = gql `
  fragment InboxFragment on Inbox {
    id
    type
    subject
    createdAt
    recipients {
      items {
        ...InboxUserFragment
      }
    }
  }
  ${InboxUserFragmentFragmentDoc}
`;
export const InboxMessageFragmentFragmentDoc = gql `
  fragment InboxMessageFragment on InboxMessage {
    id
    createdAt
    createdBy {
      ...InboxUserFragment
    }
    body
    readBy {
      items {
        id
      }
    }
  }
  ${InboxUserFragmentFragmentDoc}
`;
export const InboxFragmentWithLastMessageFragmentDoc = gql `
  fragment InboxFragmentWithLastMessage on Inbox {
    ...InboxFragment
    inboxMessages(first: 1, sort: [{ createdAt: DESC }]) {
      items {
        ...InboxMessageFragment
      }
    }
  }
  ${InboxFragmentFragmentDoc}
  ${InboxMessageFragmentFragmentDoc}
`;
export const AssetMerchandiseFragmentDoc = gql `
  fragment AssetMerchandise on GetAssetByExperience {
    available
    id
    continueSalesWhenOutOfStock
    assetId
    inventoryId
    name
    picture
    price
    storeAvailability
    total
    itemToSellWhenOutOfStock
    variantsValues
    experienceStoreAssetId
  }
`;
export const FileUrlFragmentFragmentDoc = gql `
  fragment FileURLFragment on File {
    id
    fileId
    filename
    downloadUrl
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
  }
`;
export const Team_Fundraising_FragmentFragmentDoc = gql `
  fragment TEAM_FUNDRAISING_FRAGMENT on TeamFundraising {
    id
    name
    teamMissionTitle
    avatar {
      ...FileURLFragment
    }
    teamPhoto {
      ...FileURLFragment
    }
    members {
      items {
        role
        attendee {
          user {
            ...UserFragment
          }
        }
      }
    }
  }
  ${FileUrlFragmentFragmentDoc}
  ${UserFragmentFragmentDoc}
`;
export const Team_Membership_FragmentFragmentDoc = gql `
  fragment TEAM_MEMBERSHIP_FRAGMENT on CampaignTeamMember {
    id
    role
    team {
      ...TEAM_FUNDRAISING_FRAGMENT
    }
  }
  ${Team_Fundraising_FragmentFragmentDoc}
`;
export const Attendee_FragmentFragmentDoc = gql `
  fragment ATTENDEE_FRAGMENT on Attendee {
    id
    waiverSignature
    user {
      ...UserFragment
      avatar {
        ...FileURLFragment
      }
    }
    registrationOption {
      id
      name
      description
      fundraisingMinimum
    }
    teamMembership {
      ...TEAM_MEMBERSHIP_FRAGMENT
    }
    waiverSignedBy {
      id
      email
      firstName
      lastName
    }
    experience {
      id
      name
      startDate
      endDate
      waiverEnabled
      waiverAllowMultipleAttendeeConsent
      waiverTextBody
      timeZone
      venues {
        items {
          id
          name
          address
          notes
        }
      }
      experienceLogoImage {
        ...FileURLFragment
      }
      experienceHeaderImage {
        ...FileURLFragment
      }
      fundraisingSettings {
        id
        generalSettings {
          id
          facebookPage
          isFacebookEnabled
        }
      }
      campaign {
        id
        name
        contactEmail
        city
        state
        vizslaOrganization {
          name
          id
          timeZone
        }
        contactPhone {
          code
          number
        }
        logo {
          ...FileURLFragment
        }
        dnsRecord {
          slug
        }
        campaignTemplates {
          templates(filter: { templateType: { type: { equals: "" } } }) {
            items {
              structure
            }
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
  ${FileUrlFragmentFragmentDoc}
  ${Team_Membership_FragmentFragmentDoc}
`;
export const ClientCampaignFragmentFragmentDoc = gql `
  fragment ClientCampaignFragment on Campaign {
    id
    name
    code
    contactEmail
    contactPhone {
      code
      number
    }
    logo {
      id
      downloadUrl
    }
    image {
      id
      downloadUrl
    }
    city
    state
    campaignType
    fundraisingGoal
    fundraiserGoal
    attendanceFundraisersCountGoal
    teamGoal
    teams {
      count
      items {
        id
        createdAt
      }
    }
    donorGoal
    donors {
      count
    }
    totalRaised
    startDate
    endDate
    campaignStatus
    webLink
    program {
      id
      name
    }
    createdAt
    updatedAt
    createdBy {
      id
      firstName
      lastName
    }
    experiences {
      items {
        id
        name
        waiverEnabled
        waiverTextBody
        experienceType
        startDate
        endDate
        ticketingOptions {
          items {
            id
            ticketName
          }
        }
        registrationOptions {
          items {
            id
            name
          }
        }
        experienceLogoImage {
          id
          downloadUrl
        }
        image {
          id
          downloadUrl
        }
        experienceHeaderImage {
          id
          downloadUrl
        }
      }
    }
    dnsRecord {
      id
      slug
    }
    campaignTemplates {
      id
      templates {
        items {
          id
          structure
          templateType {
            type
            title
          }
        }
      }
    }
  }
`;
export const CampaignHeaderImageFragmentFragmentDoc = gql `
  fragment CampaignHeaderImageFragment on Campaign {
    campaignType
    experiences {
      items {
        id
      }
    }
    image {
      id
      filename
      downloadUrl
      fileId
      public
    }
  }
`;
export const CampaignHeaderLogoFragmentFragmentDoc = gql `
  fragment CampaignHeaderLogoFragment on Campaign {
    campaignType
    experiences {
      items {
        id
      }
    }
    logo {
      id
      filename
      downloadUrl
      fileId
      public
    }
  }
`;
export const PublicClientCampaignFragmentFragmentDoc = gql `
  fragment PublicClientCampaignFragment on Campaign {
    id
    name
    code
    contactEmail
    contactPhone {
      code
      number
    }
    logo {
      id
      downloadUrl
    }
    image {
      id
      downloadUrl
    }
    city
    state
    campaignType
    fundraisingGoal
    fundraiserGoal
    attendanceFundraisersCountGoal
    teamGoal
    donorGoal
    totalRaised
    startDate
    endDate
    campaignStatus
    createdAt
    updatedAt
    createdBy {
      id
      firstName
      lastName
    }
    vizslaOrganization {
      id
      name
    }
  }
`;
export const DnsRecordFragmentFragmentDoc = gql `
  fragment DnsRecordFragment on DnsRecord {
    id
    slug
    campaign {
      ...PublicClientCampaignFragment
    }
  }
  ${PublicClientCampaignFragmentFragmentDoc}
`;
export const DonationOptionFragmentFragmentDoc = gql `
  fragment DonationOptionFragment on DonationOption {
    id
    price
    description
    image {
      id
      downloadUrl
    }
  }
`;
export const DonorCommitmentFragmentFragmentDoc = gql `
  fragment DonorCommitmentFragment on DonorCommitment {
    id
    createdAt
    status
    type
    amount
    company
    fundraiser {
      id
      title
      attendee {
        user {
          firstName
          lastName
        }
      }
    }
    team {
      id
      name
    }
    donor {
      id
      fullName
      email
    }
    experience {
      id
      name
    }
    campaign {
      id
      name
      vizslaOrganization {
        id
      }
    }
  }
`;
export const ExperienceHeaderImageFragmentFragmentDoc = gql `
  fragment ExperienceHeaderImageFragment on Experience {
    experienceHeaderImage {
      id
      filename
      downloadUrl
      fileId
      public
    }
  }
`;
export const ExperienceHeaderLogoFragmentFragmentDoc = gql `
  fragment ExperienceHeaderLogoFragment on Experience {
    experienceLogoImage {
      id
      filename
      downloadUrl
      fileId
      public
    }
  }
`;
export const ExperienceGalleryImagesFragmentFragmentDoc = gql `
  fragment ExperienceGalleryImagesFragment on Experience {
    experienceGalleryImages {
      items {
        id
        filename
        downloadUrl
        fileId
        public
      }
    }
  }
`;
export const FileFragmentDoc = gql `
  fragment File on File {
    id
    fileId
    filename
    downloadUrl
    createdAt
    meta
  }
`;
export const GlobalOrganizationFragmentDoc = gql `
  fragment GlobalOrganization on GlobalOrganization {
    id
    name
  }
`;
export const Individual_Fundraising_Content_FragmentFragmentDoc = gql `
  fragment INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT on IndividualFundraising {
    id
    title
    description
    goal
    webLink
  }
`;
export const Individual_Fundraising_Header_FragmentFragmentDoc = gql `
  fragment INDIVIDUAL_FUNDRAISING_HEADER_FRAGMENT on IndividualFundraising {
    logo {
      ...FileURLFragment
    }
    hero {
      ...FileURLFragment
    }
  }
  ${FileUrlFragmentFragmentDoc}
`;
export const Individual_Fundraising_Gallery_FragmentFragmentDoc = gql `
  fragment INDIVIDUAL_FUNDRAISING_GALLERY_FRAGMENT on IndividualFundraising {
    gallery {
      items {
        ...FileURLFragment
      }
    }
  }
  ${FileUrlFragmentFragmentDoc}
`;
export const Individual_Fundraising_Management_FragmentFragmentDoc = gql `
  fragment INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT on IndividualFundraising {
    id
    title
    description
    goal
    raised
    webLink
    logo {
      ...FileURLFragment
    }
    hero {
      ...FileURLFragment
    }
    allocatedDonations {
      items {
        id
        amount
      }
    }
    gallery {
      items {
        ...FileURLFragment
      }
    }
    attendee {
      id
      user {
        ...UserFragment
      }
    }
  }
  ${FileUrlFragmentFragmentDoc}
  ${UserFragmentFragmentDoc}
`;
export const IntegrationFragmentFragmentDoc = gql `
  fragment IntegrationFragment on Integration {
    id
    name
    description
    status
    type
    image {
      downloadUrl
    }
  }
`;
export const IntegrationDataFragmentFragmentDoc = gql `
  fragment IntegrationDataFragment on Integration {
    ...IntegrationFragment
  }
  ${IntegrationFragmentFragmentDoc}
`;
export const IntegrationConfigDataFragmentFragmentDoc = gql `
  fragment IntegrationConfigDataFragment on IntegrationConfig {
    id
    isEnabled
    token: credentials
    credentials
    integration {
      items {
        ...IntegrationFragment
      }
    }
  }
  ${IntegrationFragmentFragmentDoc}
`;
export const Organization_Logo_FragmentFragmentDoc = gql `
  fragment ORGANIZATION_LOGO_FRAGMENT on VizslaOrganization {
    logo {
      ...FileURLFragment
    }
    negativeLogo {
      ...FileURLFragment
    }
  }
  ${FileUrlFragmentFragmentDoc}
`;
export const DefaultQuestionFragmentDoc = gql `
  fragment DefaultQuestion on DefaultQuestion {
    id
    name
    title
    category
    section: category
    format
    type
    options
    isMandatory
  }
`;
export const CustomQuestionFragmentDoc = gql `
  fragment CustomQuestion on CustomCampaignQuestion {
    id
    title
    category
    section: category
    format
    type
    options
    userGroup
    isRequiredTeam
    isRequiredDonor
    isRequiredAttendee
  }
`;
export const DefaultCampaignQuestionFragmentDoc = gql `
  fragment DefaultCampaignQuestion on DefaultCampaignQuestion {
    id
    order
    shouldDisplay
    isEnabledTeam
    isEnabledDonor
    isEnabledAttendee
    isRequiredTeam
    isRequiredDonor
    isRequiredAttendee
    question {
      id
    }
  }
`;
export const ReportDataSourceFragmentDoc = gql `
  fragment ReportDataSource on ReportDataSource {
    id
    format
    createdAt
    updatedAt
    customConfiguration
    metadata: customConfiguration
    source {
      fileId
      downloadUrl
    }
  }
`;
export const ReportFolderFragmentDoc = gql `
  fragment ReportFolder on ReportFolder {
    id
    name
  }
`;
export const ReportFragmentDoc = gql `
  fragment Report on Report {
    id
    status
    description
    createdAt
    updatedAt
    customConfiguration
    createdBySystem
    folder {
      id
      name
    }
    organization {
      id
      name
    }
    template {
      id
      name
    }
    author: createdBy {
      id
      fullName
    }
  }
`;
export const ActiveCampaignsAndExperiencesReportFragmentDoc = gql `
  fragment ActiveCampaignsAndExperiencesReport on ActiveCampaignsAndExperiencesReport {
    numberOfCampaigns
    numberOfExperiences
    month
  }
`;
export const ActiveUsersByTypeReportFragmentDoc = gql `
  fragment ActiveUsersByTypeReport on ActiveUsersByTypeReport {
    month
    numberOfTeams
    numberOfDonors
    numberOfAttendees
    numberOfFundraisers
  }
`;
export const AdoptionByOrganizationReportFragmentDoc = gql `
  fragment AdoptionByOrganizationReport on AdoptionByOrganizationReport {
    month
    organizationsActive
    usersActive
  }
`;
export const AttendeesReportFragmentDoc = gql `
  fragment AttendeesReport on AttendeesReport {
    id
    registrationStatus
    checkedIn
    firstName
    lastName
    registrationDate
    experience
    campaign
    registrationOption
    registrationAmount
    discountCodeOpt
    checkInDateAndTime
    bibOpt
    fundraiser
    totalDonationsRaised
    donationCommitment
    totalDonors
    donationGoalMet
    team
    teamCaptain
    emergencyContactRelationship
    emergencyContactName
    emergencyContactPhone
    swagBagItemOne
    swagBagItemTwo
    swagBagItems
    signUpQuestionOne
    signUpQuestionTwo
    restOfSignUpQuestions
    birthDate
    gender
    email
    address
    city
    state
    zip
    country
    phone
    registrationTicketID
    transactionID
  }
`;
export const AttendeesReportMetadataFragmentDoc = gql `
  fragment AttendeesReportMetadata on AttendeesReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
    registrationOption
    registrationStatus
    state
  }
`;
export const CampaignsReportFragmentDoc = gql `
  fragment CampaignsReport on CampaignsReport {
    id
    name
    startDate
    endDate
    donorCount
    attendeeCount
    experiencesCount
    fundraiserCount
    oneTimeDonationsAmount
    recurringDonationsAmount
    teamCount
    totalDonationsAmount
  }
`;
export const CampaignsReportMetadataFragmentDoc = gql `
  fragment CampaignsReportMetadata on CampaignsReportMetadata {
    months
    programs {
      id
      name
    }
  }
`;
export const ComparisonExperiencesReportFragmentDoc = gql `
  fragment ComparisonExperiencesReport on ComparisonExperiencesReport {
    campaignName
    primaryExperience
    comparisonExperienceFinal
    comparisonExperienceT
    overallComparison
    tComparison
  }
`;
export const ComparisonExperiencesReportMetadataFragmentDoc = gql `
  fragment ComparisonExperiencesReportMetadata on ComparisonExperiencesReportMetadata {
    programsComparison {
      value
      label
      campaigns {
        value
        label
        experiences {
          value
          label
        }
      }
    }
  }
`;
export const CumulativeDonationGrowthReportFragmentDoc = gql `
  fragment CumulativeDonationGrowthReport on CumulativeDonationGrowthReport {
    id
    numberOfDonors
    recurringDonationsAmount
    oneTimeDonationsAmount
    cumulativeDonationsAmount
    monthYear
  }
`;
export const CumulativeDonationGrowthReportMetadataFragmentDoc = gql `
  fragment CumulativeDonationGrowthReportMetadata on CumulativeDonationGrowthReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
    monthYears
  }
`;
export const ReadDonationImpactByYearReportFragmentDoc = gql `
  fragment ReadDonationImpactByYearReport on ReadDonationImpactByYearReport {
    amountOneTime
    amountRecurring
    donorsOneTime
    year
    donorsRecurring
  }
`;
export const ReadDonationImpactByYearReportMetadataFragmentDoc = gql `
  fragment ReadDonationImpactByYearReportMetadata on ReadDonationImpactByYearReportMetadata {
    yearsArray
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
  }
`;
export const DonationsImpactByMonthReportFragmentDoc = gql `
  fragment DonationsImpactByMonthReport on DonationsImpactByMonthReport {
    id
    oneTimeDonors
    recurringDonors
    recurringDonations
    oneTimeDonations
    monthAndYear
  }
`;
export const DonationsImpactByMonthReportMetadataFragmentDoc = gql `
  fragment DonationsImpactByMonthReportMetadata on DonationsImpactByMonthReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
    months
  }
`;
export const DonationsReportFragmentDoc = gql `
  fragment DonationsReport on DonationsReport {
    id
    annualizedRecurringDonationAmount
    anonymousDonor
    campaign
    creationDate
    donationType
    donorAddress
    donorCity
    donorCountry
    donorEmail
    donorFirstName
    donorPhone
    donorState
    donorZip
    donortLastName
    experience
    fundraiserName
    fundraiserTeam
    id
    month
    oneTimeDonationAmount
    oneTimeDonationPledgeAmount
    oneTimeDonationPledgeDate
    recurringDonationAmountCommitment
    recurringDonationAmountFrequency
    recurringDonationAmountPaid
    recurringDonationPledgeDate
    source
    transactionType
    year
  }
`;
export const DonationsReportMetadataFragmentDoc = gql `
  fragment DonationsReportMetadata on DonationsReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
    months
    years
    states
  }
`;
export const DonationsSourcesReportFragmentDoc = gql `
  fragment DonationsSourcesReport on DonationsSourcesReport {
    month
    startDate
    endDate
    online
    offline
    matching
    facebook
  }
`;
export const DonationsSourcesReportMetadataFragmentDoc = gql `
  fragment DonationsSourcesReportMetadata on DonationsSourcesReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
    months
  }
`;
export const DonorsPerFundraiserReportFragmentDoc = gql `
  fragment DonorsPerFundraiserReport on DonorsPerFundraiserReport {
    donors
    month
    donorIds
  }
`;
export const ExperiencesReportFragmentDoc = gql `
  fragment ExperiencesReport on ExperiencesReport {
    id
    experienceName
    experienceStartDate
    experienceEndDate
    programName
    campaignName
    experienceType
    attendeeCount
    fundraiserCount
    donorCount
    teamCount
    totalRevenue
    registrationRevenue
    totalDonationAmount
    oneTimeDonationAmount
    recurringDonationAmount
    recurringDonationAmountAnnualized
    matchingDonations
    merchandisingRevenue
    partnerships
  }
`;
export const ExperiencesReportMetadataFragmentDoc = gql `
  fragment ExperiencesReportMetadata on ExperiencesReportMetadata {
    programs {
      id
      name
    }
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
    months
  }
`;
export const FailingRecurringGivingPlansReportFragmentDoc = gql `
  fragment FailingRecurringGivingPlansReport on FailingRecurringGivingPlansReport {
    id
    firstName
    lastName
    totalDonationsMade
    totalOneTimeDonationsMade
    totalRecurringDonationsMade
    countOneTimeDonationsMade
    recurringDonationAmount
    recurringDonationFrecuency
    annualizedRecurringDonationAmount
    recurringDonorSince
    birthday
    gender
    email
    address
    city
    state
    zip
    country
    phone
  }
`;
export const FailingRecurringGivingPlansReportMetadataFragmentDoc = gql `
  fragment FailingRecurringGivingPlansReportMetadata on FailingRecurringGivingPlansReportMetadata {
    items {
      id
      name
    }
  }
`;
export const MedianDonationsMonthReportFragmentDoc = gql `
  fragment MedianDonationsMonthReport on MedianDonationsMonthReport {
    month
    startDate
    endDate
    medianAmount
    totalDonations
    totalOneTime
    totalRecurring
    totalTransfer
    totalMatching
  }
`;
export const MedianDonationsMonthReportMetadataFragmentDoc = gql `
  fragment MedianDonationsMonthReportMetadata on MedianDonationsMonthReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
    months
  }
`;
export const MerchandiseReportFragmentDoc = gql `
  fragment MerchandiseReport on MerchandiseReport {
    id
    assetName
    description
    attributeOne
    attributeTwo
    attributeThree
    inventoryOnHand
    availableInventory
    allocatedInventory
    totalFulfilled
    totalPurchased
    totalItemsForSwagBag
    totalReceived
    totalSales
    totalProfit
    inventoryValue
    recommendedPrice
    unitCost
    unitProfit
    availabilityStore
    availabilitySwagbag
    offeredWhenOutOfStock
    stockMinimumAlert
    lastTransactionDate
  }
`;
export const MerchandiseReportMetadataFragmentDoc = gql `
  fragment MerchandiseReportMetadata on MerchandiseReportMetadata {
    items {
      id
      name
    }
  }
`;
export const NonProfitRevenueSourcesReportFragmentDoc = gql `
  fragment NonProfitRevenueSourcesReport on NonProfitRevenueSourcesReport {
    month
    taxes
    collieRevenue
    donations
    merchandise
    registration
    sponsorship
  }
`;
export const PartnershipsReportFragmentDoc = gql `
  fragment PartnershipsReport on PartnershipsReport {
    id
    dateEntered
    name
    partnerType
    email
    commitmentType
    cashAmount
    cashDecisionDate
    cashReceived
    inKindAmount
    inKindGood
    inKindReceived
    matchLimit
    matchTotal
    matchingSingleDate
    matchingStartDate
    matchingStartTime
    matchingEndDate
    matchingEndTime
    finalMatchAmountPromised
    finalMatchAmountReceived
    notes
    contactFirstName
    contactLastName
    contactTitle
    contactEmail
    contactPhone
    contactNotes
    campaignName
    experienceName
  }
`;
export const PartnershipsReportMetadataFragmentDoc = gql `
  fragment PartnershipsReportMetadata on PartnershipsReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
  }
`;
export const PeopleReportFragmentDoc = gql `
  fragment PeopleReport on PeopleReport {
    id
    type
    firstName
    lastName
    email
    birthDate
    phoneNumber
    address
    city
    state
    country
    gender
    joinedAt
    lastActivityAt
    origin
    campaignsCount
    experiencesCount
    activeExperiencesCount
    experiencesAttendeeCount
    activeExperiencesAttendeeCount
    experiencesFundraiserCount
    activeExperiencesFundraiserCount
    experiencesDonorCount
    activeExperiencesDonorCount
    donorTotalDonationsCount
    donorTotalDonationsAmount
    donorOneTimeDonationsTotalCount
    donorOneTimeDonationsTotalAmount
    donorRecurringDonationsCount
    donorRecurringDonationsSince
    donorRecurringDonationsFrequency
    donorRecurringDonationsAverageAmount
    donorRecurringDonationsTotalAmount
    isRecurringDonor
    fundraiserTotalRaisedAmount
    fundraiserTotalDonorsCount
    teamsCount
    teamsCaptainCount
  }
`;
export const PeopleReportMetadataFragmentDoc = gql `
  fragment PeopleReportMetadata on PeopleReportMetadata {
    types {
      id
      name
    }
    states
    experiences {
      id
      name
    }
    campaigns {
      id
      name
    }
  }
`;
export const RevenueCategoriesByMonthReportFragmentDoc = gql `
  fragment RevenueCategoriesByMonthReport on RevenueCategoriesByMonthReport {
    id
    month
    totalDonations
    totalMerchandise
    totalRegistrations
    totalPartnerships
  }
`;
export const RevenueCategoriesByMonthReportMetadataFragmentDoc = gql `
  fragment RevenueCategoriesByMonthReportMetadata on RevenueCategoriesByMonthReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
    months
  }
`;
export const RevenueCategoriesByYearReportFragmentDoc = gql `
  fragment RevenueCategoriesByYearReport on RevenueCategoriesByYearReport {
    id
    totalRegistrations
    totalDonations
    totalMerchandise
    totalPartnerships
    year
  }
`;
export const RevenueCategoriesByYearReportMetadataFragmentDoc = gql `
  fragment RevenueCategoriesByYearReportMetadata on RevenueCategoriesByYearReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
    years
  }
`;
export const TeamsReportFragmentDoc = gql `
  fragment TeamsReport on TeamsReport {
    id
    teamName
    creationDate
    captainFirstName
    captainLastName
    captainEmail
    activeCampaigns
    lastActivityDate
    members
    totalCampaigns
    totalDonors
    totalRaised
    campaignName
    experienceName
  }
`;
export const TeamsReportMetadataFragmentDoc = gql `
  fragment TeamsReportMetadata on TeamsReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
  }
`;
export const TransactionsReportFragmentDoc = gql `
  fragment TransactionsReport on TransactionsReport {
    id
    date
    status
    type
    subtype
    campaign
    experience
    amount
    payeeFirstName
    payeeLastName
    campaignFundFirstName
    campaignFundLastName
    campaignTeam
  }
`;
export const TransactionsReportMetadataFragmentDoc = gql `
  fragment TransactionsReportMetadata on TransactionsReportMetadata {
    campaigns {
      id
      name
    }
    experiences {
      id
      name
    }
    status
    subtypes
  }
`;
export const WeeksComparisonAttendeesRevenueAndTeamsReportFragmentDoc = gql `
  fragment WeeksComparisonAttendeesRevenueAndTeamsReport on WeeksComparisonAttendeesRevenueAndTeamsReport {
    weekLabel
    attendeesPrimary
    attendeesComparison
    attendeesDifference
    revenuePrimary
    revenueComparison
    revenueDifference
    teamsPrimary
    teamsComparison
    teamsDifference
  }
`;
export const WeeksComparisonAttendeesRevenueAndTeamsReportMetadataFragmentDoc = gql `
  fragment WeeksComparisonAttendeesRevenueAndTeamsReportMetadata on WeeksComparisonAttendeesRevenueAndTeamsReportMetadata {
    programs {
      value
      label
      campaigns {
        value
        label
        experiences {
          value
          label
        }
      }
    }
    initialValues {
      program
      campaigns
      experiencePrimary
      experienceComparison
    }
  }
`;
export const AssetSwagbagFragmentDoc = gql `
  fragment AssetSwagbag on AssetByRegistrationOptionView {
    id
    name
    picture
    inventoryId
    assetId
    registrationOptionId
    available
    price
    continueSalesWhenOutOfStock
    storeAvailability
    itemToSellWhenOutOfStock
    variantsValues
  }
`;
export const AssetSwagbagByRegistrationOptionFragmentDoc = gql `
  fragment AssetSwagbagByRegistrationOption on AssetSwagbagList {
    id
    name
    picture
    inventoryId
    assetId
    registrationOptionId
    available
    price
    continueSalesWhenOutOfStock
    storeAvailability
    itemToSellWhenOutOfStock
    variantsValues
    registrationName
    total
  }
`;
export const TeamFundraisingFileFragmentDoc = gql `
  fragment TeamFundraisingFile on File {
    id
    fileId
    filename
    downloadUrl
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
  }
`;
export const TeamFundraisingCampaignFragmentDoc = gql `
  fragment TeamFundraisingCampaign on Campaign {
    id
    name
    description
    contactEmail
    city
    state
    contactPhone {
      code
      number
    }
    logo {
      fileId
      downloadUrl
    }
    image {
      fileId
      downloadUrl
    }
    dnsRecord {
      slug
    }
    campaignTemplates {
      templates(filter: { templateType: { type: { equals: "TEAM_FUNDRAISER" } } }) {
        items {
          structure
        }
      }
    }
  }
`;
export const TeamFundraisingManagementFragmentFragmentDoc = gql `
  fragment TeamFundraisingManagementFragment on TeamFundraising {
    id
    createdAt
    name
    teamMissionTitle
    teamMissionDescription
    totalRaised
    fundraiserGoal
    webLink
    experience {
      id
      name
      experienceLogoImage {
        downloadUrl
      }
      experienceHeaderImage {
        downloadUrl
      }
    }
    avatar {
      ...TeamFundraisingFile
    }
    teamPhoto {
      ...TeamFundraisingFile
    }
    donations {
      items {
        amount
      }
    }
    gallery {
      items {
        ...TeamFundraisingFile
      }
    }
    campaign {
      ...TeamFundraisingCampaign
    }
    captains: members(filter: { role: { equals: "Team Captain" } }) {
      items {
        id
        role
        attendee {
          id
          totalRaised
          fundraising {
            id
            goal
            raised
          }
          user {
            ...UserFragment
            avatar {
              ...TeamFundraisingFile
            }
            phone {
              code
              number
            }
            address {
              street1
              street2
              city
              state
              country
              zip
            }
          }
        }
      }
    }
    members(sort: { attendee: { fundraising: { raised: DESC } } }) {
      count
      items {
        id
        role
        attendee {
          id
          totalRaised
          createdAt
          user {
            ...UserFragment
            avatar {
              downloadUrl
            }
            phone {
              code
              number
            }
            address {
              city
              state
            }
          }
          fundraising {
            id
            goal
            raised
            webLink
            allocatedDonations(
              groupBy: {
                query: {
                  donor: { id: { as: "donors", fn: { aggregate: COUNT, distinct: true } } }
                  amount: { as: "raised", fn: { aggregate: SUM } }
                }
              }
            ) {
              groups {
                donors: Int
                raised: Float
              }
            }
            hero {
              fileId
              downloadUrl
            }
            logo {
              fileId
              downloadUrl
            }
          }
        }
      }
    }
  }
  ${TeamFundraisingFileFragmentDoc}
  ${TeamFundraisingCampaignFragmentDoc}
  ${UserFragmentFragmentDoc}
`;
export const TeamFundraisingGalleryFragmentFragmentDoc = gql `
  fragment TeamFundraisingGalleryFragment on TeamFundraising {
    gallery {
      items {
        ...TeamFundraisingFile
      }
    }
  }
  ${TeamFundraisingFileFragmentDoc}
`;
export const TeamFundraisingContentFragmentFragmentDoc = gql `
  fragment TeamFundraisingContentFragment on TeamFundraising {
    name
    teamMissionTitle
    teamMissionDescription
    totalRaised
    fundraiserGoal
    webLink
  }
`;
export const TeamFundraisingHeaderFragmentFragmentDoc = gql `
  fragment TeamFundraisingHeaderFragment on TeamFundraising {
    avatar {
      ...TeamFundraisingFile
    }
    teamPhoto {
      ...TeamFundraisingFile
    }
  }
  ${TeamFundraisingFileFragmentDoc}
`;
export const UserFragmentOrganizationFragmentDoc = gql `
  fragment UserFragmentOrganization on User {
    id
    email
    firstName
    lastName
    fullName
    status
    customer
    birthDate
    minor
    gender
    phone {
      code
      number
    }
    address {
      street1
      street2
      state
      city
      zip
    }
    avatar {
      id
      fileId
      downloadUrl
    }
    vizslaOrganizationStaff {
      items {
        id
        vizslaOrganization {
          id
        }
      }
    }
  }
`;
export const PublicClientDnsRecordFragmentFragmentDoc = gql `
  fragment PublicClientDnsRecordFragment on DnsRecord {
    id
    campaign {
      id
      vizslaOrganization {
        id
        name
      }
    }
  }
`;
export const ExperienceLandingFragmentFragmentDoc = gql `
  fragment ExperienceLandingFragment on Experience {
    id
    name
    experienceType
    experienceCategory
    startDate
    endDate
    waiverAllowMultipleAttendeeConsent
    waiverTextBody
    earlyBirdUrlCode
    earlyBirdRegistrationAvailable
    timeZone
    experienceLogoImage {
      id
      downloadUrl
    }
    image {
      id
      downloadUrl
    }
    experienceHeaderImage {
      id
      downloadUrl
      fileId
    }
    registrationOptions {
      items {
        id
        name
        ageRequirement
        ageRequirementAsOfDate
        attendeesPerRegistration
        maximumRegistrationPerOrder
        registrationStartDate
        registrationEndDate
        description
        fundraisingMinimum
        pricingSettings {
          id
          pricingType
          pricingTiers {
            items {
              id
              price
              startDate
              endDate
            }
          }
          pricingEnabled
        }
        attendees {
          count
        }
      }
    }
    ticketingOptions {
      items {
        id
        ticketName
        ticketingAvailabilityStartDateTime
        ticketingAvailabilityEndDateTime
        tableNumber
        description
        minimumGroupTickets
        maximumGroupTickets
        maximumPerOrder
        maximumAttendees
        pricingSettings {
          id
          pricingType
          pricingTiers {
            items {
              id
              price
              startDate
              endDate
            }
          }
          pricingEnabled
        }
        attendees {
          count
        }
      }
    }
    campaign {
      id
      name
      campaignType
      fundraisingGoal
      totalRaised
      vizslaOrganization {
        id
        fee
        stripeFee
        taxRate
        timeZone
        name
      }
      contactEmail
      contactPhone {
        code
        number
      }
    }
    donations {
      items {
        amount
        order {
          number
        }
      }
    }
    venues {
      items {
        id
        name
        notes
        address
      }
    }
    fundraisingSettings {
      donationSettings {
        feesMessage
        feesEnabled
        roundupEnabled
        roundupMessage
        benefittingCharities
        benefittingCharitiesOrganizationName
      }
    }
    isStoreEnabled
  }
`;
export const ExperienceRegistrationOptionFragmentFragmentDoc = gql `
  fragment ExperienceRegistrationOptionFragment on RegistrationOption {
    id
    name
    distance
    unitOfMeasure
    startWaveOrder
    description
    fundraisingMinimum
    ageRequirement
    ageRequirementAsOfDate
    attendeesPerRegistration
    maximumRegistrationPerOrder
    groupRegistrationMinimum
    groupRegistrationMaximum
    maximumAttendees
    fundraisingMinimum
    checkpointAmount
    checkpointDate
    checkpointNotification
    registrationStartDate
    registrationEndDate
    attendees {
      count
    }
    pricingSettings {
      id
      allowCostToBeFundraisingCredit
      pricingEnabled
      pricingType
      pricingTiers {
        items {
          id
          price
          taxDeductibleCurrencyAmount
          taxDeductiblePercentsAmount
          taxDeductibleType
          startDate
          endDate
        }
        count
      }
    }
  }
`;
export const ExperienceDefaultCampaignQuestionFragmentFragmentDoc = gql `
  fragment ExperienceDefaultCampaignQuestionFragment on DefaultCampaignQuestion {
    id
    isEnabledAttendee
    isRequiredAttendee
    shouldDisplay
    order
    question {
      id
      title
      name
      category
      type
      format
      options
      userGroup
      isMandatory
    }
  }
`;
export const ExperienceCustomCampaignQuestionFragmentFragmentDoc = gql `
  fragment ExperienceCustomCampaignQuestionFragment on CustomCampaignQuestion {
    id
    title
    isEnabledAttendee
    isRequiredAttendee
    userGroup
    type
    format
    options
    order
  }
`;
export const ExperienceRegistrationFragmentDoc = gql `
  fragment ExperienceRegistration on Experience {
    id
    name
    experienceType
    waiverEnabled
    waiverAllowMultipleAttendeeConsent
    waiverTextBody
    isStoreEnabled
    startDate
    endDate
    campaign {
      id
      vizslaOrganization {
        id
        name
      }
    }
    fundraisingSettings {
      id
      generalSettings {
        isObligation
      }
    }
  }
`;
export const OrderFragmentFragmentDoc = gql `
  fragment OrderFragment on Order {
    id
    status
    reasonStatus
    totalAmount
    user {
      id
      firstName
      lastName
    }
  }
`;
export const PublicClientExperienceStoreAssetFragmentFragmentDoc = gql `
  fragment PublicClientExperienceStoreAssetFragment on ExperienceStoreAsset {
    id
    price
    experience {
      id
    }
    storeAsset {
      id
      name
      description
      image {
        id
        fileId
        filename
        downloadUrl
      }
      inventoryItem {
        items {
          id
        }
      }
    }
  }
`;
export const User_Data_FragmentFragmentDoc = gql `
  fragment USER_DATA_FRAGMENT on User {
    id
    firstName
    lastName
    fullName
    email
    language
    phone {
      code
      number
    }
    createdAt
    address {
      city
      state
      country
      zip
      street1
      street2
    }
    birthDate
    gender
    avatar {
      downloadUrl
      id
    }
  }
`;
export const CheckinAttendeesDocument = gql `
  query CheckinAttendees($filter: AttendeeFilter, $skip: Int, $first: Int) {
    attendeesList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...CheckinAttendeeInfo
      }
    }
  }
  ${CheckinAttendeeInfoFragmentDoc}
`;
/**
 * __useCheckinAttendeesQuery__
 *
 * To run a query within a React component, call `useCheckinAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckinAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckinAttendeesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCheckinAttendeesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CheckinAttendeesDocument, options);
}
export function useCheckinAttendeesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CheckinAttendeesDocument, options);
}
export const UserAttendeesDocument = gql `
  query UserAttendees($id: ID) {
    attendee(id: $id) {
      user {
        id
        email
        avatar {
          id
          downloadUrl
          previewUrl
        }
        firstName
        lastName
        phone {
          code
          number
        }
        gender
        birthDate
        address {
          city
          state
          street1
          street2
          zip
          country
        }
        status
        createdAt
      }
      registrationOption {
        id
        name
        fundraisingMinimum
      }
      donations {
        items {
          amount
        }
      }
      fundraisingPageLink
      fundraiserGoal
      totalRaised
      bibAssignment {
        bibNumber
      }
      experience {
        id
        name
        waiverEnabled
        waiverTextBody
        store
        storeAssets {
          count
          items {
            price
          }
        }
      }
    }
  }
`;
/**
 * __useUserAttendeesQuery__
 *
 * To run a query within a React component, call `useUserAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAttendeesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAttendeesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserAttendeesDocument, options);
}
export function useUserAttendeesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserAttendeesDocument, options);
}
export const AttendeesRegistrationOptionsDocument = gql `
  query AttendeesRegistrationOptions($id: ID) {
    attendee(id: $id) {
      id
      user {
        id
        email
        firstName
        lastName
        phone {
          number
        }
      }
      registrationOptionFulfillment {
        items {
          order {
            id
          }
        }
      }
      registrationOption {
        id
        name
        pricingSettings {
          pricingType
          pricingTiers {
            items {
              price
              taxDeductibleCurrencyAmount
              taxDeductiblePercentsAmount
              taxDeductibleType
            }
          }
        }
      }
      experience {
        id
        name
        timeZone
        venues {
          items {
            id
            name
            address
          }
        }
        campaign {
          id
          name
          vizslaOrganization {
            id
            name
            timeZone
          }
        }
        registrationOptions {
          items {
            id
            name
            fundraisingMinimum
            pricingSettings {
              pricingType
              pricingTiers {
                items {
                  price
                  taxDeductibleCurrencyAmount
                  taxDeductiblePercentsAmount
                  taxDeductibleType
                  startDate
                  endDate
                }
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useAttendeesRegistrationOptionsQuery__
 *
 * To run a query within a React component, call `useAttendeesRegistrationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesRegistrationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeesRegistrationOptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendeesRegistrationOptionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeesRegistrationOptionsDocument, options);
}
export function useAttendeesRegistrationOptionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeesRegistrationOptionsDocument, options);
}
export const UpdateRegistrationOptionDocument = gql `
  mutation updateRegistrationOption($attenddeId: ID, $optionId: ID) {
    attendeeUpdate(
      filter: { id: $attenddeId }
      data: { registrationOption: { reconnect: { id: $optionId } } }
    ) {
      id
      registrationOption {
        id
        name
      }
    }
  }
`;
/**
 * __useUpdateRegistrationOptionMutation__
 *
 * To run a mutation, you first call `useUpdateRegistrationOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegistrationOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegistrationOptionMutation, { data, loading, error }] = useUpdateRegistrationOptionMutation({
 *   variables: {
 *      attenddeId: // value for 'attenddeId'
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useUpdateRegistrationOptionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateRegistrationOptionDocument, options);
}
export const BibAssignmentByAttendeeDocument = gql `
  mutation BibAssignmentByAttendee($experienceId: ID!, $attendeeId: ID!) {
    bibAssignByAttendee(experienceId: $experienceId, attendeeId: $attendeeId) {
      bibNumber
    }
  }
`;
/**
 * __useBibAssignmentByAttendeeMutation__
 *
 * To run a mutation, you first call `useBibAssignmentByAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBibAssignmentByAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bibAssignmentByAttendeeMutation, { data, loading, error }] = useBibAssignmentByAttendeeMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useBibAssignmentByAttendeeMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(BibAssignmentByAttendeeDocument, options);
}
export const AttendeeCheckedDocument = gql `
  mutation AttendeeChecked($attendeeId: ID!, $checked: Boolean!, $checkInDate: DateTime!) {
    attendeeUpdate(
      filter: { id: $attendeeId }
      data: { checkIn: $checked, checkInDate: $checkInDate }
    ) {
      id
      checkIn
      checkInDate
      user {
        id
        firstName
      }
    }
  }
`;
/**
 * __useAttendeeCheckedMutation__
 *
 * To run a mutation, you first call `useAttendeeCheckedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendeeCheckedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendeeCheckedMutation, { data, loading, error }] = useAttendeeCheckedMutation({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *      checked: // value for 'checked'
 *      checkInDate: // value for 'checkInDate'
 *   },
 * });
 */
export function useAttendeeCheckedMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AttendeeCheckedDocument, options);
}
export const AttendeeIndividualFundraisingDocument = gql `
  query AttendeeIndividualFundraising($filter: AttendeeFilter!) {
    attendeesList(filter: $filter) {
      items {
        id
        fundraising {
          id
          title
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
/**
 * __useAttendeeIndividualFundraisingQuery__
 *
 * To run a query within a React component, call `useAttendeeIndividualFundraisingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeIndividualFundraisingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeIndividualFundraisingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAttendeeIndividualFundraisingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeIndividualFundraisingDocument, options);
}
export function useAttendeeIndividualFundraisingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeIndividualFundraisingDocument, options);
}
export const AttendeSendEmailDocument = gql `
  mutation attendeSendEmail($notificationData: JSON, $notificationMethods: [String!]) {
    notifications {
      notificationSend(
        notificationData: $notificationData
        notificationMethods: $notificationMethods
      ) {
        success
      }
    }
  }
`;
/**
 * __useAttendeSendEmailMutation__
 *
 * To run a mutation, you first call `useAttendeSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendeSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendeSendEmailMutation, { data, loading, error }] = useAttendeSendEmailMutation({
 *   variables: {
 *      notificationData: // value for 'notificationData'
 *      notificationMethods: // value for 'notificationMethods'
 *   },
 * });
 */
export function useAttendeSendEmailMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AttendeSendEmailDocument, options);
}
export const UpdateAttendeeWaiverSignatureDocument = gql `
  mutation updateAttendeeWaiverSignature($data: AttendeeUpdateInput!) {
    attendeeUpdate(data: $data) {
      id
      waiverSignedAt
      waiverSignature
    }
  }
`;
/**
 * __useUpdateAttendeeWaiverSignatureMutation__
 *
 * To run a mutation, you first call `useUpdateAttendeeWaiverSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendeeWaiverSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendeeWaiverSignatureMutation, { data, loading, error }] = useUpdateAttendeeWaiverSignatureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAttendeeWaiverSignatureMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateAttendeeWaiverSignatureDocument, options);
}
export const BibAssignManualDocument = gql `
  query bibAssignManual($attendeeId: ID!, $experienceId: ID!, $bibNumber: Int) {
    bibAssignManual(attendeeId: $attendeeId, experienceId: $experienceId, bibNumber: $bibNumber) {
      bibNumber
      isAllowed
    }
  }
`;
/**
 * __useBibAssignManualQuery__
 *
 * To run a query within a React component, call `useBibAssignManualQuery` and pass it any options that fit your needs.
 * When your component renders, `useBibAssignManualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBibAssignManualQuery({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *      experienceId: // value for 'experienceId'
 *      bibNumber: // value for 'bibNumber'
 *   },
 * });
 */
export function useBibAssignManualQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(BibAssignManualDocument, options);
}
export function useBibAssignManualLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(BibAssignManualDocument, options);
}
export const CheckinCampaignsDocument = gql `
  query CheckinCampaigns($filter: CampaignFilter, $skip: Int, $first: Int) {
    campaignsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      items {
        ...CheckinCampaignFragment
      }
    }
  }
  ${CheckinCampaignFragmentFragmentDoc}
`;
/**
 * __useCheckinCampaignsQuery__
 *
 * To run a query within a React component, call `useCheckinCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckinCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckinCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCheckinCampaignsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CheckinCampaignsDocument, options);
}
export function useCheckinCampaignsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CheckinCampaignsDocument, options);
}
export const CheckinExperiencesDocument = gql `
  query CheckinExperiences($filter: ExperienceFilter, $skip: Int, $first: Int) {
    experiencesList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...CheckinExperienceInfo
      }
    }
  }
  ${CheckinExperienceInfoFragmentDoc}
`;
/**
 * __useCheckinExperiencesQuery__
 *
 * To run a query within a React component, call `useCheckinExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckinExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckinExperiencesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCheckinExperiencesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CheckinExperiencesDocument, options);
}
export function useCheckinExperiencesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CheckinExperiencesDocument, options);
}
export const CreateFundraisingForAttendeeDocument = gql `
  mutation CreateFundraisingForAttendee($data: IndividualFundraisingCreateInput!) {
    __typename
    individualFundraisingCreate(data: $data) {
      id
      goal
      raised
      webLink
    }
  }
`;
/**
 * __useCreateFundraisingForAttendeeMutation__
 *
 * To run a mutation, you first call `useCreateFundraisingForAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFundraisingForAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFundraisingForAttendeeMutation, { data, loading, error }] = useCreateFundraisingForAttendeeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFundraisingForAttendeeMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateFundraisingForAttendeeDocument, options);
}
export const NewOrderShoppingCartCheckinDocument = gql `
  mutation NewOrderShoppingCartCheckin($data: OrderCreateInput!) {
    orderCreate(data: $data) {
      ...OrderFragmentCheckin
    }
  }
  ${OrderFragmentCheckinFragmentDoc}
`;
/**
 * __useNewOrderShoppingCartCheckinMutation__
 *
 * To run a mutation, you first call `useNewOrderShoppingCartCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderShoppingCartCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderShoppingCartCheckinMutation, { data, loading, error }] = useNewOrderShoppingCartCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewOrderShoppingCartCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewOrderShoppingCartCheckinDocument, options);
}
export const NewOrderItemCheckinDocument = gql `
  mutation NewOrderItemCheckin($data: OrderItemCreateInput!) {
    orderItemCreate(data: $data) {
      id
      description
      category
      transactionType
      amount
      order {
        id
      }
    }
  }
`;
/**
 * __useNewOrderItemCheckinMutation__
 *
 * To run a mutation, you first call `useNewOrderItemCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderItemCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderItemCheckinMutation, { data, loading, error }] = useNewOrderItemCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewOrderItemCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewOrderItemCheckinDocument, options);
}
export const NewStatusOrderCheckinDocument = gql `
  mutation NewStatusOrderCheckin($data: OrderHistoryCreateInput!) {
    orderHistoryCreate(data: $data) {
      id
      status
      reason
      order {
        id
      }
    }
  }
`;
/**
 * __useNewStatusOrderCheckinMutation__
 *
 * To run a mutation, you first call `useNewStatusOrderCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewStatusOrderCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newStatusOrderCheckinMutation, { data, loading, error }] = useNewStatusOrderCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewStatusOrderCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewStatusOrderCheckinDocument, options);
}
export const NewRegistratioOptionFullfimentCheckinDocument = gql `
  mutation NewRegistratioOptionFullfimentCheckin($data: RegistrationOptionFulfillmentCreateInput!) {
    registrationOptionFulfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewRegistratioOptionFullfimentCheckinMutation__
 *
 * To run a mutation, you first call `useNewRegistratioOptionFullfimentCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewRegistratioOptionFullfimentCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newRegistratioOptionFullfimentCheckinMutation, { data, loading, error }] = useNewRegistratioOptionFullfimentCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewRegistratioOptionFullfimentCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewRegistratioOptionFullfimentCheckinDocument, options);
}
export const NewAssetFullfimentCheckinDocument = gql `
  mutation NewAssetFullfimentCheckin($data: AssetFullfillmentCreateInput!) {
    assetFullfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewAssetFullfimentCheckinMutation__
 *
 * To run a mutation, you first call `useNewAssetFullfimentCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewAssetFullfimentCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newAssetFullfimentCheckinMutation, { data, loading, error }] = useNewAssetFullfimentCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewAssetFullfimentCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewAssetFullfimentCheckinDocument, options);
}
export const UpdateAssetFullfimentCheckinDocument = gql `
  mutation UpdateAssetFullfimentCheckin(
    $data: AssetFullfillmentUpdateByFilterInput!
    $ids: [ID!]!
  ) {
    assetFullfillmentUpdateByFilter(data: $data, filter: { id: { in: $ids } }) {
      items {
        id
        storeInventoryItem {
          id
          storeAsset {
            name
          }
        }
      }
    }
  }
`;
/**
 * __useUpdateAssetFullfimentCheckinMutation__
 *
 * To run a mutation, you first call `useUpdateAssetFullfimentCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetFullfimentCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetFullfimentCheckinMutation, { data, loading, error }] = useUpdateAssetFullfimentCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUpdateAssetFullfimentCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateAssetFullfimentCheckinDocument, options);
}
export const NewSwagbagFullfimentCheckinDocument = gql `
  mutation NewSwagbagFullfimentCheckin($data: AssetSwagbagFulfillmentCreateInput!) {
    assetSwagbagFulfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewSwagbagFullfimentCheckinMutation__
 *
 * To run a mutation, you first call `useNewSwagbagFullfimentCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewSwagbagFullfimentCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newSwagbagFullfimentCheckinMutation, { data, loading, error }] = useNewSwagbagFullfimentCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewSwagbagFullfimentCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewSwagbagFullfimentCheckinDocument, options);
}
export const UpdateSwagbagFullfimentCheckinDocument = gql `
  mutation UpdateSwagbagFullfimentCheckin(
    $data: AssetSwagbagFulfillmentUpdateByFilterInput!
    $ids: [ID!]!
  ) {
    assetSwagbagFulfillmentUpdateByFilter(data: $data, filter: { id: { in: $ids } }) {
      items {
        id
        storeInventoryItem {
          id
          storeAsset {
            name
          }
        }
      }
    }
  }
`;
/**
 * __useUpdateSwagbagFullfimentCheckinMutation__
 *
 * To run a mutation, you first call `useUpdateSwagbagFullfimentCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSwagbagFullfimentCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSwagbagFullfimentCheckinMutation, { data, loading, error }] = useUpdateSwagbagFullfimentCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUpdateSwagbagFullfimentCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateSwagbagFullfimentCheckinDocument, options);
}
export const NewPaymentOrderCheckinDocument = gql `
  mutation NewPaymentOrderCheckin($data: PaymentCreateInput!) {
    paymentCreate(data: $data) {
      id
      status
      gateway
      method
      category
      reasonStatus
      totalAmount
      order {
        id
      }
    }
  }
`;
/**
 * __useNewPaymentOrderCheckinMutation__
 *
 * To run a mutation, you first call `useNewPaymentOrderCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewPaymentOrderCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newPaymentOrderCheckinMutation, { data, loading, error }] = useNewPaymentOrderCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewPaymentOrderCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewPaymentOrderCheckinDocument, options);
}
export const NewDonationOrderCheckinDocument = gql `
  mutation NewDonationOrderCheckin($data: ExperienceDonationCreateInput!) {
    experienceDonationCreate(data: $data) {
      id
      amount
    }
  }
`;
/**
 * __useNewDonationOrderCheckinMutation__
 *
 * To run a mutation, you first call `useNewDonationOrderCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDonationOrderCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDonationOrderCheckinMutation, { data, loading, error }] = useNewDonationOrderCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewDonationOrderCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewDonationOrderCheckinDocument, options);
}
export const NewDonationOrderIndividualCheckinDocument = gql `
  mutation NewDonationOrderIndividualCheckin($data: IndividualFundraisingDonationCreateInput!) {
    individualFundraisingDonationCreate(data: $data) {
      id
      amount
    }
  }
`;
/**
 * __useNewDonationOrderIndividualCheckinMutation__
 *
 * To run a mutation, you first call `useNewDonationOrderIndividualCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDonationOrderIndividualCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDonationOrderIndividualCheckinMutation, { data, loading, error }] = useNewDonationOrderIndividualCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewDonationOrderIndividualCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewDonationOrderIndividualCheckinDocument, options);
}
export const NewOrderIntentDocument = gql `
  mutation NewOrderIntent($data: OrderIntentCreateInput!) {
    orderIntentCreate(data: $data) {
      id
      data
      parentOrderId
    }
  }
`;
/**
 * __useNewOrderIntentMutation__
 *
 * To run a mutation, you first call `useNewOrderIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderIntentMutation, { data, loading, error }] = useNewOrderIntentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewOrderIntentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewOrderIntentDocument, options);
}
export const OrderIntentToOrderDocument = gql `
  mutation OrderIntentToOrder($data: OrderIntentToOrderInput!, $typePayment: MethodPaymentType!) {
    orderIntentToOrder(data: $data, typePayment: $typePayment) {
      success
    }
  }
`;
/**
 * __useOrderIntentToOrderMutation__
 *
 * To run a mutation, you first call `useOrderIntentToOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderIntentToOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderIntentToOrderMutation, { data, loading, error }] = useOrderIntentToOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *      typePayment: // value for 'typePayment'
 *   },
 * });
 */
export function useOrderIntentToOrderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(OrderIntentToOrderDocument, options);
}
export const SwagBagReplaceDocument = gql `
  mutation swagBagReplace($data: SwagBagReplaceInput!) {
    swagBagReplace(data: $data) {
      success
      message
    }
  }
`;
/**
 * __useSwagBagReplaceMutation__
 *
 * To run a mutation, you first call `useSwagBagReplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwagBagReplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swagBagReplaceMutation, { data, loading, error }] = useSwagBagReplaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSwagBagReplaceMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SwagBagReplaceDocument, options);
}
export const ChangeRegistrationOptionDocument = gql `
  mutation ChangeRegistrationOption($orderId: ID!, $data: JSON!) {
    registrationOptionReplace(data: { orderId: $orderId, data: $data }) {
      success
      message
    }
  }
`;
/**
 * __useChangeRegistrationOptionMutation__
 *
 * To run a mutation, you first call `useChangeRegistrationOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRegistrationOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRegistrationOptionMutation, { data, loading, error }] = useChangeRegistrationOptionMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeRegistrationOptionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChangeRegistrationOptionDocument, options);
}
export const User_Info_UpdateDocument = gql `
  mutation User_Info_update($userid: ID, $data: UserUpdateInput!) {
    userUpdate(filter: { id: $userid }, data: $data) {
      firstName
      lastName
      gender
      birthDate
      phone {
        code
        number
      }
      address {
        city
        state
        country
        zip
      }
    }
  }
`;
/**
 * __useUser_Info_UpdateMutation__
 *
 * To run a mutation, you first call `useUser_Info_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUser_Info_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userInfoUpdateMutation, { data, loading, error }] = useUser_Info_UpdateMutation({
 *   variables: {
 *      userid: // value for 'userid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUser_Info_UpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(User_Info_UpdateDocument, options);
}
export const AttendeeregistrationordersDocument = gql `
  query attendeeregistrationorders($attendeeId: ID) {
    attendee(id: $attendeeId) {
      bibAssignment {
        bibNumber
      }
      registrationOptionFulfillment {
        items {
          amount
          attendee {
            user {
              firstName
              lastName
            }
          }
          order {
            number
            createdAt
            payment {
              status
              category
              metadata
              method
              gateway
              billingInfo
            }
          }
          registrationOption {
            id
            name
            experience {
              id
              name
              startDate
              venues {
                items {
                  address
                }
              }
              campaign {
                name
              }
            }
          }
        }
      }
      ticketingOptionFulfillment {
        items {
          amount
          attendee {
            user {
              firstName
              lastName
            }
          }
          ticketingOption {
            id
            ticketName
            experience {
              id
              name
              startDate
              venues {
                items {
                  address
                }
              }
              campaign {
                name
              }
            }
          }
          order {
            number
            createdAt
            payment {
              status
              category
              metadata
              method
              gateway
              billingInfo
              id
            }
          }
        }
      }
    }
  }
`;
/**
 * __useAttendeeregistrationordersQuery__
 *
 * To run a query within a React component, call `useAttendeeregistrationordersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeregistrationordersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeregistrationordersQuery({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useAttendeeregistrationordersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeregistrationordersDocument, options);
}
export function useAttendeeregistrationordersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeregistrationordersDocument, options);
}
export const GetDonationScheduleDocument = gql `
  query getDonationSchedule($id: ID) {
    orderItem(id: $id) {
      schedule
      description
      amount
    }
  }
`;
/**
 * __useGetDonationScheduleQuery__
 *
 * To run a query within a React component, call `useGetDonationScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonationScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonationScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDonationScheduleQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetDonationScheduleDocument, options);
}
export function useGetDonationScheduleLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetDonationScheduleDocument, options);
}
export const GetDonationScheduleByDonorDocument = gql `
  query getDonationScheduleByDonor($email: String) {
    donationSchedulesList(filter: { user: { some: { email: { equals: $email } } } }) {
      items {
        id
        amount
        frecuency
        endDate
        user {
          items {
            id
            email
          }
        }
      }
    }
  }
`;
/**
 * __useGetDonationScheduleByDonorQuery__
 *
 * To run a query within a React component, call `useGetDonationScheduleByDonorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonationScheduleByDonorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonationScheduleByDonorQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetDonationScheduleByDonorQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetDonationScheduleByDonorDocument, options);
}
export function useGetDonationScheduleByDonorLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetDonationScheduleByDonorDocument, options);
}
export const AllocateTeammatesDonationsDocument = gql `
  mutation AllocateTeammatesDonations($data: [DonationAllocationCreateManyInput!]!) {
    donations: allocateManyDonations(data: $data) {
      count
      items {
        id
      }
    }
  }
`;
/**
 * __useAllocateTeammatesDonationsMutation__
 *
 * To run a mutation, you first call `useAllocateTeammatesDonationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateTeammatesDonationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateTeammatesDonationsMutation, { data, loading, error }] = useAllocateTeammatesDonationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAllocateTeammatesDonationsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AllocateTeammatesDonationsDocument, options);
}
export const GetDonationsDocument = gql `
  query GetDonations($filter: DonationAllocationFilter) {
    donationAllocationsList(filter: $filter) {
      count
      items {
        id
        amount
        teamFundraising {
          id
          name
        }
        individualFundraising {
          id
          attendee {
            id
            user {
              id
            }
          }
        }
        donor {
          id
          email
          fullName
        }
      }
    }
  }
`;
/**
 * __useGetDonationsQuery__
 *
 * To run a query within a React component, call `useGetDonationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDonationsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetDonationsDocument, options);
}
export function useGetDonationsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetDonationsDocument, options);
}
export const UpdateTeamFundraisingDocument = gql `
  mutation UpdateTeamFundraising(
    $filter: TeamFundraisingKeyFilter!
    $data: TeamFundraisingUpdateInput!
  ) {
    teamFundraisingUpdate(filter: $filter, data: $data) {
      id
    }
  }
`;
/**
 * __useUpdateTeamFundraisingMutation__
 *
 * To run a mutation, you first call `useUpdateTeamFundraisingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamFundraisingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamFundraisingMutation, { data, loading, error }] = useUpdateTeamFundraisingMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTeamFundraisingMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateTeamFundraisingDocument, options);
}
export const UpdateDonationDocument = gql `
  mutation UpdateDonation($donationid: ID, $data: DonationUpdateInput!) {
    donationUpdate(filter: { id: $donationid }, data: $data) {
      id
      message
    }
  }
`;
/**
 * __useUpdateDonationMutation__
 *
 * To run a mutation, you first call `useUpdateDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonationMutation, { data, loading, error }] = useUpdateDonationMutation({
 *   variables: {
 *      donationid: // value for 'donationid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDonationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateDonationDocument, options);
}
export const EmergencyContactDocument = gql `
  query EmergencyContact($userId: ID!) {
    emergencyContactsList(filter: { user: { some: { id: { equals: $userId } } } }) {
      items {
        id
        name
        email
        phoneNumber
        relationship
        user {
          items {
            id
          }
        }
      }
    }
  }
`;
/**
 * __useEmergencyContactQuery__
 *
 * To run a query within a React component, call `useEmergencyContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmergencyContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmergencyContactQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEmergencyContactQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(EmergencyContactDocument, options);
}
export function useEmergencyContactLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(EmergencyContactDocument, options);
}
export const CreateEmergencyContactDocument = gql `
  mutation CreateEmergencyContact($data: EmergencyContactCreateInput!) {
    emergencyContactCreate(data: $data) {
      id
      name
      email
      phoneNumber
      relationship
      user {
        items {
          id
        }
      }
    }
  }
`;
/**
 * __useCreateEmergencyContactMutation__
 *
 * To run a mutation, you first call `useCreateEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmergencyContactMutation, { data, loading, error }] = useCreateEmergencyContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEmergencyContactMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateEmergencyContactDocument, options);
}
export const UpdateEmergencyContactDocument = gql `
  mutation UpdateEmergencyContact($id: ID!, $data: EmergencyContactUpdateInput!) {
    emergencyContactUpdate(filter: { id: $id }, data: $data) {
      id
      name
      email
      phoneNumber
      relationship
      user {
        items {
          id
        }
      }
    }
  }
`;
/**
 * __useUpdateEmergencyContactMutation__
 *
 * To run a mutation, you first call `useUpdateEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmergencyContactMutation, { data, loading, error }] = useUpdateEmergencyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEmergencyContactMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateEmergencyContactDocument, options);
}
export const ExperiencesByOrganizationIdDocument = gql `
  query ExperiencesByOrganizationID($userID: ID!, $filters: [ExperienceFilter!]) {
    experiences: experiencesList(filter: { AND: $filters }) {
      items {
        id
        name
        startDate
        endDate
        experienceLogoImage {
          fileId
          downloadUrl
        }
        venues {
          items {
            id
            address
          }
        }
        attendees(filter: { user: { id: { equals: $userID } } }) {
          items {
            id
          }
        }
      }
    }
  }
`;
/**
 * __useExperiencesByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useExperiencesByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperiencesByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperiencesByOrganizationIdQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExperiencesByOrganizationIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperiencesByOrganizationIdDocument, options);
}
export function useExperiencesByOrganizationIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperiencesByOrganizationIdDocument, options);
}
export const HealthInfoDocument = gql `
  query healthInfo($userId: ID!) {
    healthInfosList(filter: { user: { some: { id: { equals: $userId } } } }) {
      items {
        id
        dietaryPreferences
        allergies
        medicalConditions
        user {
          items {
            id
          }
        }
      }
    }
  }
`;
/**
 * __useHealthInfoQuery__
 *
 * To run a query within a React component, call `useHealthInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useHealthInfoQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(HealthInfoDocument, options);
}
export function useHealthInfoLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(HealthInfoDocument, options);
}
export const CreateHealthInfoDocument = gql `
  mutation CreateHealthInfo($data: HealthInfoCreateInput!) {
    healthInfoCreate(data: $data) {
      id
      dietaryPreferences
      allergies
      medicalConditions
      user {
        items {
          id
        }
      }
    }
  }
`;
/**
 * __useCreateHealthInfoMutation__
 *
 * To run a mutation, you first call `useCreateHealthInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHealthInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHealthInfoMutation, { data, loading, error }] = useCreateHealthInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateHealthInfoMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateHealthInfoDocument, options);
}
export const UpdateHealthInfoDocument = gql `
  mutation UpdateHealthInfo($id: ID!, $data: HealthInfoUpdateInput!) {
    healthInfoUpdate(filter: { id: $id }, data: $data) {
      id
      dietaryPreferences
      allergies
      medicalConditions
      user {
        items {
          id
        }
      }
    }
  }
`;
/**
 * __useUpdateHealthInfoMutation__
 *
 * To run a mutation, you first call `useUpdateHealthInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthInfoMutation, { data, loading, error }] = useUpdateHealthInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateHealthInfoMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateHealthInfoDocument, options);
}
export const SentRecurringDonationChangeDocument = gql `
  mutation sentRecurringDonationChange($donationScheduleId: ID!, $organizationId: ID!) {
    __typename
    sentRecurringDonationChangeNotification(
      data: { donationScheduleId: $donationScheduleId, organizationId: $organizationId }
    ) {
      success
    }
  }
`;
/**
 * __useSentRecurringDonationChangeMutation__
 *
 * To run a mutation, you first call `useSentRecurringDonationChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSentRecurringDonationChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sentRecurringDonationChangeMutation, { data, loading, error }] = useSentRecurringDonationChangeMutation({
 *   variables: {
 *      donationScheduleId: // value for 'donationScheduleId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSentRecurringDonationChangeMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SentRecurringDonationChangeDocument, options);
}
export const SentRecurringDonationCancelledDocument = gql `
  mutation sentRecurringDonationCancelled($donorName: String!, $organizationId: ID!) {
    __typename
    sentRecurringDonationCancelledNotification(
      data: { donorName: $donorName, organizationId: $organizationId }
    ) {
      success
    }
  }
`;
/**
 * __useSentRecurringDonationCancelledMutation__
 *
 * To run a mutation, you first call `useSentRecurringDonationCancelledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSentRecurringDonationCancelledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sentRecurringDonationCancelledMutation, { data, loading, error }] = useSentRecurringDonationCancelledMutation({
 *   variables: {
 *      donorName: // value for 'donorName'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSentRecurringDonationCancelledMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SentRecurringDonationCancelledDocument, options);
}
export const NotifyDocument = gql `
  mutation Notify($data: NotifyInput!) {
    notify(data: $data) {
      success
    }
  }
`;
/**
 * __useNotifyMutation__
 *
 * To run a mutation, you first call `useNotifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyMutation, { data, loading, error }] = useNotifyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNotifyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NotifyDocument, options);
}
export const OrdersbydonorDocument = gql `
  query ordersbydonor($orderId: ID) {
    order(id: $orderId) {
      donations {
        items {
          amount
          donor {
            createdAt
            firstName
            lastName
            email
            gender
            companyName
          }
          donationAllocations {
            items {
              teamFundraising {
                name
              }
              individualFundraising {
                attendee {
                  user {
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
      registrationOptionFulfillment {
        items {
          attendee {
            id
            user {
              id
              firstName
              lastName
              gender
              companyName
              email
            }
          }
        }
      }
    }
  }
`;
/**
 * __useOrdersbydonorQuery__
 *
 * To run a query within a React component, call `useOrdersbydonorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersbydonorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersbydonorQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrdersbydonorQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrdersbydonorDocument, options);
}
export function useOrdersbydonorLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrdersbydonorDocument, options);
}
export const GetAssetsByOrderIdDocument = gql `
  query getAssetsByOrderId($id: ID) {
    order(id: $id) {
      assetsFulfillment {
        items {
          storeInventoryItem {
            storeAsset {
              name
              image {
                downloadUrl
              }
            }
            itemToSellWhenOutOfStock
            variantsValues
          }
        }
      }
    }
  }
`;
/**
 * __useGetAssetsByOrderIdQuery__
 *
 * To run a query within a React component, call `useGetAssetsByOrderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsByOrderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsByOrderIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetsByOrderIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAssetsByOrderIdDocument, options);
}
export function useGetAssetsByOrderIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAssetsByOrderIdDocument, options);
}
export const GetDonationByOrderDocument = gql `
  query getDonationByOrder($id: ID) {
    order(id: $id) {
      donation {
        items {
          id
          message
        }
      }
    }
  }
`;
/**
 * __useGetDonationByOrderQuery__
 *
 * To run a query within a React component, call `useGetDonationByOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonationByOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonationByOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDonationByOrderQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetDonationByOrderDocument, options);
}
export function useGetDonationByOrderLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetDonationByOrderDocument, options);
}
export const GetOrderItemDocument = gql `
  query getOrderItem($id: ID!) {
    orderItem(id: $id) {
      id
      description
      amount
      donationSchedule {
        id
        startDate
        endDate
        nextPayment
        frecuency
        amount
        user {
          items {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;
/**
 * __useGetOrderItemQuery__
 *
 * To run a query within a React component, call `useGetOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderItemQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetOrderItemDocument, options);
}
export function useGetOrderItemLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetOrderItemDocument, options);
}
export const UpdateDonationScheduleDocument = gql `
  mutation UpdateDonationSchedule(
    $filter: DonationScheduleKeyFilter!
    $data: DonationScheduleUpdateInput!
  ) {
    donationScheduleUpdate(filter: $filter, data: $data) {
      id
      startDate
      endDate
      nextPayment
      frecuency
    }
  }
`;
/**
 * __useUpdateDonationScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateDonationScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonationScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonationScheduleMutation, { data, loading, error }] = useUpdateDonationScheduleMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDonationScheduleMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateDonationScheduleDocument, options);
}
export const ImpactedOrganizationsDocument = gql `
  query ImpactedOrganizations($filters: [VizslaOrganizationFilter!]!) {
    organizations: vizslaOrganizationsList(filter: { OR: $filters }) {
      count
      items {
        id
        name
        logo {
          downloadUrl
        }
      }
    }
  }
`;
/**
 * __useImpactedOrganizationsQuery__
 *
 * To run a query within a React component, call `useImpactedOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpactedOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpactedOrganizationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useImpactedOrganizationsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ImpactedOrganizationsDocument, options);
}
export function useImpactedOrganizationsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ImpactedOrganizationsDocument, options);
}
export const SendEmailDocument = gql `
  mutation SendEmail($data: JSON, $methods: [String!]) {
    notifications {
      notificationSend(notificationData: $data, notificationMethods: $methods) {
        success
      }
    }
  }
`;
/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *      methods: // value for 'methods'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SendEmailDocument, options);
}
export const StripePaymentMethodsUserDocument = gql `
  query StripePaymentMethodsUser($userId: String!) {
    stripePaymentMethods(userId: $userId, onlyUser: true) {
      items {
        __typename
        ... on CardPaymentMethod {
          id
          type
          primary
          reference
          brand
          exp
        }
        ... on BankPaymentMethod {
          id
          type
          primary
          reference
          name
          exp
        }
        ... on UnknownPaymentMethod {
          id
          type
          primary
          exp
        }
      }
    }
  }
`;
/**
 * __useStripePaymentMethodsUserQuery__
 *
 * To run a query within a React component, call `useStripePaymentMethodsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePaymentMethodsUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStripePaymentMethodsUserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StripePaymentMethodsUserDocument, options);
}
export function useStripePaymentMethodsUserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StripePaymentMethodsUserDocument, options);
}
export const StripePaymentMethodDefaultUserDocument = gql `
  mutation StripePaymentMethodDefaultUser($userId: String!, $paymentMethodId: String!) {
    stripePaymentMethodDefault(userId: $userId, paymentMethodId: $paymentMethodId) {
      __typename
      ... on CardPaymentMethod {
        id
        type
        primary
        reference
        brand
      }
      ... on BankPaymentMethod {
        id
        type
        primary
        reference
        name
      }
      ... on UnknownPaymentMethod {
        id
        type
        primary
      }
    }
  }
`;
/**
 * __useStripePaymentMethodDefaultUserMutation__
 *
 * To run a mutation, you first call `useStripePaymentMethodDefaultUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodDefaultUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMethodDefaultUserMutation, { data, loading, error }] = useStripePaymentMethodDefaultUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useStripePaymentMethodDefaultUserMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripePaymentMethodDefaultUserDocument, options);
}
export const StripePaymentMethodInactiveUserDocument = gql `
  mutation StripePaymentMethodInactiveUser($userId: String!, $paymentMethodId: String!) {
    stripePaymentMethodInactive(
      userId: $userId
      paymentMethodId: $paymentMethodId
      onlyUser: true
    ) {
      success
    }
  }
`;
/**
 * __useStripePaymentMethodInactiveUserMutation__
 *
 * To run a mutation, you first call `useStripePaymentMethodInactiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodInactiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMethodInactiveUserMutation, { data, loading, error }] = useStripePaymentMethodInactiveUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useStripePaymentMethodInactiveUserMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripePaymentMethodInactiveUserDocument, options);
}
export const GetTeamsDocument = gql `
  query getTeams($filter: CampaignTeamMemberFilter) {
    campaignTeamMembersList(filter: $filter) {
      count
      items {
        id
        attendee {
          id
          user {
            id
            fullName
          }
        }
        team {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTeamsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTeamsDocument, options);
}
export function useGetTeamsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTeamsDocument, options);
}
export const GetTransactionsDocument = gql `
  query GetTransactions($filter: TransactionVizslaViewExpFilter, $skip: Int, $first: Int) {
    transactionVizslaViewExpsList(
      filter: $filter
      sort: { OrderDate: DESC }
      skip: $skip
      first: $first
    ) {
      count
      items {
        id
        OrderDate
        OrderStatus
        Ordersubtype
        OrderItemTransType
        UsersFirsName
        UsersLastName
        CampaignName
        ExperienceName
        ExperienceId
        OrderTotalAmount
        OrderItemsAmount
        OrderPaymentMethod
        OrderitemCommentDetail
        Userid
        OrderItemsId
        TransactionCategory
        Name
        OrderNumber
        OrderBillingInfo
      }
    }
  }
`;
/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTransactionsDocument, options);
}
export function useGetTransactionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTransactionsDocument, options);
}
export const EmailUserDocument = gql `
  query emailUser($id: ID!) {
    transactionVizslaViewExp(id: $id) {
      Campaignid
      UsersEmail
    }
  }
`;
/**
 * __useEmailUserQuery__
 *
 * To run a query within a React component, call `useEmailUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailUserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(EmailUserDocument, options);
}
export function useEmailUserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(EmailUserDocument, options);
}
export const UploadCredentialsDocument = gql `
  query UploadCredentials {
    system {
      credentials: fileUploadSignInfo {
        ...UploadCredentials
      }
    }
  }
  ${UploadCredentialsFragmentDoc}
`;
/**
 * __useUploadCredentialsQuery__
 *
 * To run a query within a React component, call `useUploadCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadCredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUploadCredentialsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UploadCredentialsDocument, options);
}
export function useUploadCredentialsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UploadCredentialsDocument, options);
}
export const CurrentUserConsumerDocument = gql `
  query CurrentUserConsumer {
    user {
      id
      email
      firstName
      lastName
      createdAt
      gender
      notifications
      roles {
        items {
          id
          name
        }
      }
      phone {
        code
        number
      }
      birthDate
      address {
        city
        country
        state
        street1
        street2
        zip
      }
      avatar {
        id
        downloadUrl
      }
      companyName
      about
    }
  }
`;
/**
 * __useCurrentUserConsumerQuery__
 *
 * To run a query within a React component, call `useCurrentUserConsumerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserConsumerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserConsumerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserConsumerQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserConsumerDocument, options);
}
export function useCurrentUserConsumerLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserConsumerDocument, options);
}
export const UserSignupDocument = gql `
  mutation UserSignup($user: UserCreateInput!) {
    userSignUpWithToken(user: $user) {
      id
      email
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
  }
`;
/**
 * __useUserSignupMutation__
 *
 * To run a mutation, you first call `useUserSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSignupMutation, { data, loading, error }] = useUserSignupMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUserSignupMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UserSignupDocument, options);
}
export const User_Consumer_Info_UpdateDocument = gql `
  mutation User_consumer_Info_update($userid: ID, $data: UserUpdateInput!) {
    userUpdate(filter: { id: $userid }, data: $data) {
      firstName
      lastName
      gender
      birthDate
      language
      notifications
      phone {
        code
        number
      }
      address {
        city
        state
        country
        street1
        zip
      }
    }
  }
`;
/**
 * __useUser_Consumer_Info_UpdateMutation__
 *
 * To run a mutation, you first call `useUser_Consumer_Info_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUser_Consumer_Info_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userConsumerInfoUpdateMutation, { data, loading, error }] = useUser_Consumer_Info_UpdateMutation({
 *   variables: {
 *      userid: // value for 'userid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUser_Consumer_Info_UpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(User_Consumer_Info_UpdateDocument, options);
}
export const UserConsumerProfileDocument = gql `
  query UserConsumerProfile {
    user {
      id
      avatar {
        id
        downloadUrl
      }
      firstName
      lastName
      createdAt
      email
      phone {
        code
        number
      }
      birthDate
      address {
        street1
        street2
        country
        city
        zip
        state
      }
      language
      gender
      companyName
      about
    }
  }
`;
/**
 * __useUserConsumerProfileQuery__
 *
 * To run a query within a React component, call `useUserConsumerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserConsumerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserConsumerProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserConsumerProfileQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserConsumerProfileDocument, options);
}
export function useUserConsumerProfileLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserConsumerProfileDocument, options);
}
export const SendEmailLoginDocument = gql `
  mutation SendEmailLogin($data: JSON, $methods: [String!]) {
    notifications {
      notificationSend(notificationData: $data, notificationMethods: $methods) {
        success
      }
    }
  }
`;
/**
 * __useSendEmailLoginMutation__
 *
 * To run a mutation, you first call `useSendEmailLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailLoginMutation, { data, loading, error }] = useSendEmailLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *      methods: // value for 'methods'
 *   },
 * });
 */
export function useSendEmailLoginMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SendEmailLoginDocument, options);
}
export const CurrentUserloginDocument = gql `
  query CurrentUserlogin {
    user {
      id
      email
      firstName
      lastName
      createdAt
      gender
      notifications
      currentApplication
      roles {
        items {
          id
          name
        }
      }
      phone {
        code
        number
      }
      birthDate
      address {
        city
        country
        state
        street1
        street2
        zip
      }
      avatar {
        id
        downloadUrl
      }
      companyName
      about
    }
  }
`;
/**
 * __useCurrentUserloginQuery__
 *
 * To run a query within a React component, call `useCurrentUserloginQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserloginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserloginQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserloginQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserloginDocument, options);
}
export function useCurrentUserloginLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserloginDocument, options);
}
export const UserSignupLoginDocument = gql `
  mutation UserSignupLogin($user: UserCreateInput!) {
    userSignUpWithToken(user: $user) {
      id
      email
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
  }
`;
/**
 * __useUserSignupLoginMutation__
 *
 * To run a mutation, you first call `useUserSignupLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSignupLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSignupLoginMutation, { data, loading, error }] = useUserSignupLoginMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUserSignupLoginMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UserSignupLoginDocument, options);
}
export const GetCustomQuestionAnswerByOrderItemIdDocument = gql `
  query GetCustomQuestionAnswerByOrderItemId($orderItemId: ID!) {
    orderItem(id: $orderItemId) {
      registrationOptionFulfillments {
        attendee {
          id
          customQuestionAnswers {
            items {
              id
              question {
                id
                title
                options
                type
                format
                category
                order
                isRequiredAttendee
                isRequiredDonor
                isRequiredTeam
              }
              text
            }
          }
        }
      }
    }
  }
`;
/**
 * __useGetCustomQuestionAnswerByOrderItemIdQuery__
 *
 * To run a query within a React component, call `useGetCustomQuestionAnswerByOrderItemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomQuestionAnswerByOrderItemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomQuestionAnswerByOrderItemIdQuery({
 *   variables: {
 *      orderItemId: // value for 'orderItemId'
 *   },
 * });
 */
export function useGetCustomQuestionAnswerByOrderItemIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCustomQuestionAnswerByOrderItemIdDocument, options);
}
export function useGetCustomQuestionAnswerByOrderItemIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCustomQuestionAnswerByOrderItemIdDocument, options);
}
export const UpdateCustomQuestionAnswerDocument = gql `
  mutation UpdateCustomQuestionAnswer($data: CustomQuestionAnswerUpdateInput!) {
    customQuestionAnswerUpdate(data: $data) {
      id
    }
  }
`;
/**
 * __useUpdateCustomQuestionAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomQuestionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomQuestionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomQuestionAnswerMutation, { data, loading, error }] = useUpdateCustomQuestionAnswerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomQuestionAnswerMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateCustomQuestionAnswerDocument, options);
}
export const VizslaOrganizationUpdateDocument = gql `
  mutation VizslaOrganizationUpdate($data: VizslaOrganizationUpdateInput!) {
    vizslaOrganizationUpdate(data: $data) {
      id
    }
  }
`;
/**
 * __useVizslaOrganizationUpdateMutation__
 *
 * To run a mutation, you first call `useVizslaOrganizationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vizslaOrganizationUpdateMutation, { data, loading, error }] = useVizslaOrganizationUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVizslaOrganizationUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(VizslaOrganizationUpdateDocument, options);
}
export const OrganizationsListDocument = gql `
  query OrganizationsList($filter: VizslaOrganizationFilter, $skip: Int, $first: Int) {
    vizslaOrganizationsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        name
        fee
        createdAt
        updatedAt
        createdBy {
          id
          lastName
          firstName
        }
        vizslaOrganizationStaff {
          count
          items {
            id
            roles
            user {
              firstName
              lastName
              fullName
            }
          }
        }
        isActive
        campaigns {
          count
          items {
            id
            campaignStaff {
              count
            }
            donors(filter: { user: { status: { equals: "active" } } }) {
              count
              items {
                id
                user {
                  id
                }
              }
            }
            experiences {
              count
              items {
                id
                attendees {
                  count
                  items {
                    id
                    user {
                      id
                    }
                  }
                }
                fundraisers: attendees(filter: { fundraisingPageLink: { is_not_empty: true } }) {
                  count
                  items {
                    id
                    user {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useOrganizationsListQuery__
 *
 * To run a query within a React component, call `useOrganizationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationsListDocument, options);
}
export function useOrganizationsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationsListDocument, options);
}
export const VizslaTotalMetricsDocument = gql `
  query VizslaTotalMetrics {
    vizslaOrganizationsList {
      count
    }
    campaignsList(filter: { vizslaOrganization: { id: { is_not_empty: true } } }) {
      count
    }
    usersList(
      filter: {
        AND: [
          {
            OR: [
              {
                attendees: {
                  some: { id: { is_not_empty: true }, experience: { id: { is_not_empty: true } } }
                }
              }
            ]
          }
        ]
      }
    ) {
      count
      items {
        attendees {
          count
        }
        donors {
          count
        }
      }
    }
    experiencesList {
      count
    }
    donationFulfillmentsList(
      groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
    ) {
      groups {
        total: Float
      }
    }
  }
`;
/**
 * __useVizslaTotalMetricsQuery__
 *
 * To run a query within a React component, call `useVizslaTotalMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVizslaTotalMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVizslaTotalMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVizslaTotalMetricsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(VizslaTotalMetricsDocument, options);
}
export function useVizslaTotalMetricsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(VizslaTotalMetricsDocument, options);
}
export const AsyncTaskSubscriptionDocument = gql `
  subscription AsyncTaskSubscription($filter: AsyncTaskSubscriptionFilter!) {
    AsyncTasks(filter: $filter) {
      mutation
      node {
        ...AsyncTaskFragment
      }
    }
  }
  ${AsyncTaskFragmentFragmentDoc}
`;
/**
 * __useAsyncTaskSubscriptionSubscription__
 *
 * To run a query within a React component, call `useAsyncTaskSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAsyncTaskSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAsyncTaskSubscriptionSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAsyncTaskSubscriptionSubscription(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSubscription(AsyncTaskSubscriptionDocument, options);
}
export const AsyncTaskDispatchDocument = gql `
  mutation AsyncTaskDispatch($name: String!, $args: JSON) {
    asyncTaskDispatch(name: $name, args: $args) {
      taskId
    }
  }
`;
/**
 * __useAsyncTaskDispatchMutation__
 *
 * To run a mutation, you first call `useAsyncTaskDispatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAsyncTaskDispatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [asyncTaskDispatchMutation, { data, loading, error }] = useAsyncTaskDispatchMutation({
 *   variables: {
 *      name: // value for 'name'
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAsyncTaskDispatchMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AsyncTaskDispatchDocument, options);
}
export const AttendeesListDocument = gql `
  query AttendeesList($filter: AttendeeFilter, $skip: Int, $first: Int) {
    attendees: attendeesList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...CLIENT_ATTENDEE_FRAGMENT
      }
    }
  }
  ${Client_Attendee_FragmentFragmentDoc}
`;
/**
 * __useAttendeesListQuery__
 *
 * To run a query within a React component, call `useAttendeesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAttendeesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeesListDocument, options);
}
export function useAttendeesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeesListDocument, options);
}
export const IndividualFundraiserDocument = gql `
  query IndividualFundraiser($filter: IndividualFundraisingFilter, $skip: Int, $first: Int) {
    fundraisers: individualFundraisingsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        goal
        raised
        createdAt
        attendee {
          id
          experience {
            name
          }
          registrationOption {
            name
            fundraisingMinimum
          }
          teamMembership {
            team {
              name
            }
          }
          user {
            id
            firstName
            lastName
            email
            birthDate
            gender
            createdAt
            phone {
              code
              number
            }
            address {
              city
              state
              country
              zip
              street1
              street2
            }
            avatar {
              downloadUrl
            }
          }
        }
      }
    }
  }
`;
/**
 * __useIndividualFundraiserQuery__
 *
 * To run a query within a React component, call `useIndividualFundraiserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraiserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualFundraiserQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useIndividualFundraiserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(IndividualFundraiserDocument, options);
}
export function useIndividualFundraiserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(IndividualFundraiserDocument, options);
}
export const AttendeesNamesListDocument = gql `
  query AttendeesNamesList($filter: AttendeeFilter) {
    attendeesList(filter: $filter, first: 10) {
      items {
        id
        user {
          id
          lastName
          firstName
          email
        }
        experience {
          id
        }
      }
    }
  }
`;
/**
 * __useAttendeesNamesListQuery__
 *
 * To run a query within a React component, call `useAttendeesNamesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesNamesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeesNamesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAttendeesNamesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeesNamesListDocument, options);
}
export function useAttendeesNamesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeesNamesListDocument, options);
}
export const AttendeesCrmDocument = gql `
  query AttendeesCRM($first: Int, $skip: Int, $filter: AttendeeFilter) {
    attendees: attendeesList(first: $first, skip: $skip, filter: $filter) {
      count
      items {
        ...AttendeeCRM
      }
    }
  }
  ${AttendeeCrmFragmentDoc}
`;
/**
 * __useAttendeesCrmQuery__
 *
 * To run a query within a React component, call `useAttendeesCrmQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesCrmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeesCrmQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAttendeesCrmQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeesCrmDocument, options);
}
export function useAttendeesCrmLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeesCrmDocument, options);
}
export const AttendeeRegistrationDataDocument = gql `
  query AttendeeRegistrationData($id: ID!) {
    experience(id: $id) {
      id
      name
      waiverEnabled
      waiverTextBody
      waiverAllowMultipleAttendeeConsent
      campaign {
        id
        defaultCampaignQuestions(
          filter: { shouldDisplay: { equals: true }, isEnabledAttendee: { equals: true } }
        ) {
          items {
            ...DefaultCampaignQuestionFragment
          }
        }
        customCampaignQuestions(filter: { isEnabledAttendee: { equals: true } }) {
          items {
            ...CustomCampaignQuestionFragment
          }
        }
      }
      registrationOptions {
        items {
          id
          name
          ageRequirement
          ageRequirementAsOfDate
          description
          maximumAttendees
          attendeesPerRegistration
          maximumRegistrationPerOrder
          groupRegistrationMinimum
          groupRegistrationMaximum
          attendees {
            count
          }
          pricingSettings {
            pricingEnabled
            pricingType
            pricingTiers {
              items {
                id
                price
                startDate
                endDate
              }
            }
          }
        }
      }
    }
  }
  ${DefaultCampaignQuestionFragmentFragmentDoc}
  ${CustomCampaignQuestionFragmentFragmentDoc}
`;
/**
 * __useAttendeeRegistrationDataQuery__
 *
 * To run a query within a React component, call `useAttendeeRegistrationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeRegistrationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeRegistrationDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendeeRegistrationDataQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeRegistrationDataDocument, options);
}
export function useAttendeeRegistrationDataLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeRegistrationDataDocument, options);
}
export const AuthProfileDocument = gql `
  query AuthProfile {
    authProfile {
      id
    }
  }
`;
/**
 * __useAuthProfileQuery__
 *
 * To run a query within a React component, call `useAuthProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthProfileQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AuthProfileDocument, options);
}
export function useAuthProfileLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AuthProfileDocument, options);
}
export const BibNumberAssignBatchDocument = gql `
  mutation BibNumberAssignBatch($data: BibAssignmentBatchInput!) {
    bibAssignmentBatch(data: $data) {
      success
      message
    }
  }
`;
/**
 * __useBibNumberAssignBatchMutation__
 *
 * To run a mutation, you first call `useBibNumberAssignBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBibNumberAssignBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bibNumberAssignBatchMutation, { data, loading, error }] = useBibNumberAssignBatchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBibNumberAssignBatchMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(BibNumberAssignBatchDocument, options);
}
export const BibAssignedsQuantityDocument = gql `
  query bibAssignedsQuantity($filter: ExperienceBibAssignmentFilter) {
    experienceBibAssignmentsList(filter: $filter) {
      count
    }
  }
`;
/**
 * __useBibAssignedsQuantityQuery__
 *
 * To run a query within a React component, call `useBibAssignedsQuantityQuery` and pass it any options that fit your needs.
 * When your component renders, `useBibAssignedsQuantityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBibAssignedsQuantityQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBibAssignedsQuantityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(BibAssignedsQuantityDocument, options);
}
export function useBibAssignedsQuantityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(BibAssignedsQuantityDocument, options);
}
export const CampaignCreateDocument = gql `
  mutation CampaignCreate($data: CampaignCreateInput!) {
    campaignCreate(data: $data) {
      ...CampaignsPageCampaignFragment
    }
  }
  ${CampaignsPageCampaignFragmentFragmentDoc}
`;
/**
 * __useCampaignCreateMutation__
 *
 * To run a mutation, you first call `useCampaignCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignCreateMutation, { data, loading, error }] = useCampaignCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignCreateDocument, options);
}
export const CampaignUpdateDocument = gql `
  mutation CampaignUpdate($data: CampaignUpdateInput!) {
    campaign: campaignUpdate(data: $data) {
      ...CampaignsPageCampaignFragment
    }
  }
  ${CampaignsPageCampaignFragmentFragmentDoc}
`;
/**
 * __useCampaignUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignUpdateMutation, { data, loading, error }] = useCampaignUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignUpdateDocument, options);
}
export const CampaignDeleteDocument = gql `
  mutation CampaignDelete($data: CampaignDeleteInput!) {
    campaignDelete(data: $data, force: true) {
      success
    }
  }
`;
/**
 * __useCampaignDeleteMutation__
 *
 * To run a mutation, you first call `useCampaignDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDeleteMutation, { data, loading, error }] = useCampaignDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignDeleteDocument, options);
}
export const CampaignsPageDocument = gql `
  query CampaignsPage($filter: CampaignFilter, $skip: Int, $first: Int) {
    campaignsList(filter: $filter, skip: $skip, first: $first) {
      items {
        ...CampaignsPageCampaignFragment
      }
      count
    }
  }
  ${CampaignsPageCampaignFragmentFragmentDoc}
`;
/**
 * __useCampaignsPageQuery__
 *
 * To run a query within a React component, call `useCampaignsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCampaignsPageQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignsPageDocument, options);
}
export function useCampaignsPageLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignsPageDocument, options);
}
export const CampaignListDocument = gql `
  query CampaignList {
    campaignsList {
      items {
        id
        name
      }
    }
  }
`;
/**
 * __useCampaignListQuery__
 *
 * To run a query within a React component, call `useCampaignListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCampaignListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignListDocument, options);
}
export function useCampaignListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignListDocument, options);
}
export const CampaignWithExperienceListDocument = gql `
  query CampaignWithExperienceList($filter: CampaignFilter) {
    campaignsList(filter: $filter) {
      items {
        ...CampaignsPageCampaignFragment
      }
    }
  }
  ${CampaignsPageCampaignFragmentFragmentDoc}
`;
/**
 * __useCampaignWithExperienceListQuery__
 *
 * To run a query within a React component, call `useCampaignWithExperienceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignWithExperienceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignWithExperienceListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignWithExperienceListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignWithExperienceListDocument, options);
}
export function useCampaignWithExperienceListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignWithExperienceListDocument, options);
}
export const CampaignListByOrganizationDocument = gql `
  query CampaignListByOrganization($orgId: ID!) {
    campaignsList(filter: { vizslaOrganization: { id: { equals: $orgId } } }) {
      items {
        id
        name
      }
    }
  }
`;
/**
 * __useCampaignListByOrganizationQuery__
 *
 * To run a query within a React component, call `useCampaignListByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignListByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignListByOrganizationQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useCampaignListByOrganizationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignListByOrganizationDocument, options);
}
export function useCampaignListByOrganizationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignListByOrganizationDocument, options);
}
export const CampaignListByOrganizationAndTypesDocument = gql `
  query CampaignListByOrganizationAndTypes($orgId: ID!, $campaignType: String!) {
    campaignsList(
      filter: {
        vizslaOrganization: { id: { equals: $orgId } }
        campaignType: { equals: $campaignType }
      }
    ) {
      items {
        id
        name
        campaignType
        campaignStatus
      }
    }
  }
`;
/**
 * __useCampaignListByOrganizationAndTypesQuery__
 *
 * To run a query within a React component, call `useCampaignListByOrganizationAndTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignListByOrganizationAndTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignListByOrganizationAndTypesQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      campaignType: // value for 'campaignType'
 *   },
 * });
 */
export function useCampaignListByOrganizationAndTypesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignListByOrganizationAndTypesDocument, options);
}
export function useCampaignListByOrganizationAndTypesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignListByOrganizationAndTypesDocument, options);
}
export const CampaignStaffingDocument = gql `
  query CampaignStaffing($id: ID) {
    campaign(id: $id) {
      id
      campaignStaff {
        items {
          id
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;
/**
 * __useCampaignStaffingQuery__
 *
 * To run a query within a React component, call `useCampaignStaffingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignStaffingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignStaffingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignStaffingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignStaffingDocument, options);
}
export function useCampaignStaffingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignStaffingDocument, options);
}
export const CampaignsStatisticsDocument = gql `
  query CampaignsStatistics($organizationId: ID!) {
    totalCount: campaignsList(filter: { vizslaOrganization: { id: { equals: $organizationId } } }) {
      count
    }
    totalActive: campaignsList(
      filter: {
        vizslaOrganization: { id: { equals: $organizationId } }
        campaignStatus: { equals: "" }
      }
    ) {
      count
    }
    totalCompleted: campaignsList(
      filter: {
        campaignStatus: { equals: "" }
        vizslaOrganization: { id: { equals: $organizationId } }
      }
    ) {
      count
    }
    totalAttendees: vizslaOrganization(id: $organizationId) {
      campaigns(
        groupBy: {
          query: { experiences: { attendees: { id: { as: "count", fn: { aggregate: COUNT } } } } }
        }
      ) {
        groups {
          count: Int
        }
      }
    }
    totalRaised: campaignsList(
      filter: { vizslaOrganization: { id: { equals: $organizationId } } }
      groupBy: { query: { totalRaised: { as: "totalRaised", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalRaised: Int
      }
    }
  }
`;
/**
 * __useCampaignsStatisticsQuery__
 *
 * To run a query within a React component, call `useCampaignsStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsStatisticsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCampaignsStatisticsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignsStatisticsDocument, options);
}
export function useCampaignsStatisticsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignsStatisticsDocument, options);
}
export const FundraisingSettingsByCampaignIdDocument = gql `
  query FundraisingSettingsByCampaignId($filter: CampaignFundraisingSettingFilter) {
    campaignFundraisingSettingsList(filter: $filter) {
      items {
        id
        generalSettings {
          id
          isEnabled
          isObligation
          isWithoutPenalty
          pageSetUp
          date
          chargeDate
          isFacebookEnabled
          isInstagramEnabled
        }
        donationSettings {
          id
          isEnabled
          allowAnonymousDonations
          allowSelfDonations
          allowDonorsToOptIn
          allowDonorsToDedicateDonations
          coveredFees
          percentageAmount
          feesEnabled
          feesMessage
          roundupEnabled
          roundupMessage
        }
        teamSettings {
          id
          isEnabled
          teamCapabilities
          joiningTeamEnabled
          allowTeamsMakeTheirTeamInvite
          allowAttendeeRequestAdmissionOntoTeam
          maximumTeams
          maximumTeamMembers
          teamSharingEnabled
          appealMessage
          thankYouMessage
        }
        individualSettings {
          id
          isEnabled
          appealMessage
          thankYouMessage
        }
      }
    }
  }
`;
/**
 * __useFundraisingSettingsByCampaignIdQuery__
 *
 * To run a query within a React component, call `useFundraisingSettingsByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundraisingSettingsByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundraisingSettingsByCampaignIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFundraisingSettingsByCampaignIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FundraisingSettingsByCampaignIdDocument, options);
}
export function useFundraisingSettingsByCampaignIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FundraisingSettingsByCampaignIdDocument, options);
}
export const CampaignFundraisingSettingsCreateDocument = gql `
  mutation CampaignFundraisingSettingsCreate($createData: CampaignFundraisingSettingCreateInput!) {
    campaignFundraisingSettingCreate(data: $createData) {
      id
    }
  }
`;
/**
 * __useCampaignFundraisingSettingsCreateMutation__
 *
 * To run a mutation, you first call `useCampaignFundraisingSettingsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignFundraisingSettingsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignFundraisingSettingsCreateMutation, { data, loading, error }] = useCampaignFundraisingSettingsCreateMutation({
 *   variables: {
 *      createData: // value for 'createData'
 *   },
 * });
 */
export function useCampaignFundraisingSettingsCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignFundraisingSettingsCreateDocument, options);
}
export const CampaignFundraisingSettingsUpdateDocument = gql `
  mutation CampaignFundraisingSettingsUpdate(
    $updateData: CampaignFundraisingSettingUpdateInput!
    $settingsId: ID!
  ) {
    campaignFundraisingSettingUpdate(data: $updateData, filter: { id: $settingsId }) {
      id
    }
  }
`;
/**
 * __useCampaignFundraisingSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignFundraisingSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignFundraisingSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignFundraisingSettingsUpdateMutation, { data, loading, error }] = useCampaignFundraisingSettingsUpdateMutation({
 *   variables: {
 *      updateData: // value for 'updateData'
 *      settingsId: // value for 'settingsId'
 *   },
 * });
 */
export function useCampaignFundraisingSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignFundraisingSettingsUpdateDocument, options);
}
export const FundraisingSettingsByExperienceIdDocument = gql `
  query FundraisingSettingsByExperienceId($filter: ExperienceFundraisingFilter) {
    experienceFundraisingsList(filter: $filter) {
      items {
        id
        generalSettings {
          id
          isEnabled
          isObligation
          isWithoutPenalty
          pageSetUp
          facebookPage
          date
          chargeDate
          isFacebookEnabled
          isInstagramEnabled
        }
        donationSettings {
          id
          isEnabled
          allowAnonymousDonations
          allowSelfDonations
          allowDonorsToOptIn
          allowDonorsToDedicateDonations
          coveredFees
          percentageAmount
          feesEnabled
          feesMessage
          roundupEnabled
          roundupMessage
          benefittingCharities
          benefittingCharitiesOrganizationName
        }
        teamSettings {
          id
          isEnabled
          teamCapabilities
          joiningTeamEnabled
          allowTeamsMakeTheirTeamInvite
          allowAttendeeRequestAdmissionOntoTeam
          maximumTeams
          maximumTeamMembers
          teamSharingEnabled
          appealMessage
          thankYouMessage
        }
        individualSettings {
          id
          isEnabled
          appealMessage
          thankYouMessage
        }
      }
    }
  }
`;
/**
 * __useFundraisingSettingsByExperienceIdQuery__
 *
 * To run a query within a React component, call `useFundraisingSettingsByExperienceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundraisingSettingsByExperienceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundraisingSettingsByExperienceIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFundraisingSettingsByExperienceIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FundraisingSettingsByExperienceIdDocument, options);
}
export function useFundraisingSettingsByExperienceIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FundraisingSettingsByExperienceIdDocument, options);
}
export const ExperienceFundraisingSettingsCreateDocument = gql `
  mutation ExperienceFundraisingSettingsCreate($createData: ExperienceFundraisingCreateInput!) {
    experienceFundraisingCreate(data: $createData) {
      id
    }
  }
`;
/**
 * __useExperienceFundraisingSettingsCreateMutation__
 *
 * To run a mutation, you first call `useExperienceFundraisingSettingsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceFundraisingSettingsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceFundraisingSettingsCreateMutation, { data, loading, error }] = useExperienceFundraisingSettingsCreateMutation({
 *   variables: {
 *      createData: // value for 'createData'
 *   },
 * });
 */
export function useExperienceFundraisingSettingsCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceFundraisingSettingsCreateDocument, options);
}
export const ExperienceFundraisingSettingsUpdateDocument = gql `
  mutation ExperienceFundraisingSettingsUpdate(
    $updateData: ExperienceFundraisingUpdateInput!
    $settingsId: ID!
  ) {
    experienceFundraisingUpdate(data: $updateData, filter: { id: $settingsId }) {
      id
    }
  }
`;
/**
 * __useExperienceFundraisingSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceFundraisingSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceFundraisingSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceFundraisingSettingsUpdateMutation, { data, loading, error }] = useExperienceFundraisingSettingsUpdateMutation({
 *   variables: {
 *      updateData: // value for 'updateData'
 *      settingsId: // value for 'settingsId'
 *   },
 * });
 */
export function useExperienceFundraisingSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceFundraisingSettingsUpdateDocument, options);
}
export const RegistrationAndTicketingRecentActivityDocument = gql `
  query RegistrationAndTicketingRecentActivity(
    $id: ID!
    $skip: Int
    $first: Int
    $filter: DonationAllocationFilter
  ) {
    campaign(id: $id) {
      allocatedDonations(first: $first, skip: $skip, sort: { createdAt: DESC }, filter: $filter) {
        count
        items {
          id
          amount
          createdAt
          donor {
            ...USER_RECENT_FRAGMENT
          }
          individualFundraising {
            id
            attendee {
              user {
                ...USER_RECENT_FRAGMENT
              }
            }
          }
          teamFundraising {
            name
          }
        }
      }
      teams(sort: { createdAt: DESC }, first: $first) {
        items {
          id
          name
          createdAt
          totalRaised
          fundraiserGoal
          avatar {
            id
            downloadUrl
          }
          members {
            items {
              id
              role
              attendee {
                user {
                  ...USER_RECENT_FRAGMENT
                }
              }
            }
            count
          }
          campaign {
            fundraisingSettings {
              teamSettings {
                maximumTeamMembers
              }
            }
          }
        }
        count
      }
    }
  }
  ${User_Recent_FragmentFragmentDoc}
`;
/**
 * __useRegistrationAndTicketingRecentActivityQuery__
 *
 * To run a query within a React component, call `useRegistrationAndTicketingRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationAndTicketingRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationAndTicketingRecentActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationAndTicketingRecentActivityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationAndTicketingRecentActivityDocument, options);
}
export function useRegistrationAndTicketingRecentActivityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationAndTicketingRecentActivityDocument, options);
}
export const PersonalMissionRecentActivityDocument = gql `
  query PersonalMissionRecentActivity($id: ID!, $first: Int) {
    campaign(id: $id) {
      donations(sort: { createdAt: DESC }, first: $first) {
        items {
          id
          amount
        }
        count
      }
    }
  }
`;
/**
 * __usePersonalMissionRecentActivityQuery__
 *
 * To run a query within a React component, call `usePersonalMissionRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalMissionRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalMissionRecentActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePersonalMissionRecentActivityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PersonalMissionRecentActivityDocument, options);
}
export function usePersonalMissionRecentActivityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PersonalMissionRecentActivityDocument, options);
}
export const ImpactGivingRecentActivityDocument = gql `
  query ImpactGivingRecentActivity($id: ID!, $skip: Int, $first: Int) {
    campaign(id: $id) {
      allocatedDonations(
        first: $first
        skip: $skip
        sort: { createdAt: DESC }
        filter: { parents: { every: { id: { is_empty: true } } } }
      ) {
        count
        items {
          id
          amount
          createdAt
          donor {
            ...USER_RECENT_FRAGMENT
          }
          individualFundraising {
            id
            attendee {
              user {
                ...USER_RECENT_FRAGMENT
              }
            }
          }
          teamFundraising {
            name
          }
        }
      }
      teams(sort: { createdAt: DESC }, first: $first) {
        items {
          id
          name
          createdAt
          totalRaised
          fundraiserGoal
          avatar {
            id
            downloadUrl
          }
          members {
            items {
              id
              role
              attendee {
                user {
                  ...USER_RECENT_FRAGMENT
                }
              }
            }
            count
          }
          campaign {
            fundraisingSettings {
              teamSettings {
                maximumTeamMembers
              }
            }
          }
        }
        count
      }
    }
  }
  ${User_Recent_FragmentFragmentDoc}
`;
/**
 * __useImpactGivingRecentActivityQuery__
 *
 * To run a query within a React component, call `useImpactGivingRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpactGivingRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpactGivingRecentActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useImpactGivingRecentActivityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ImpactGivingRecentActivityDocument, options);
}
export function useImpactGivingRecentActivityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ImpactGivingRecentActivityDocument, options);
}
export const GetCampaignsIdOptionsDocument = gql `
  query getCampaignsIdOptions($filter: CampaignFilter, $skip: Int, $first: Int) {
    campaignsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        id
        name
      }
    }
  }
`;
/**
 * __useGetCampaignsIdOptionsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsIdOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsIdOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsIdOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetCampaignsIdOptionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCampaignsIdOptionsDocument, options);
}
export function useGetCampaignsIdOptionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCampaignsIdOptionsDocument, options);
}
export const CampaignDiscountsSettingsListDocument = gql `
  query CampaignDiscountsSettingsList($filter: DiscountCodeSettingFilter!) {
    discountCodeSettingsList(filter: $filter) {
      items {
        ...CampaignDiscountsSettingsFragment
      }
    }
  }
  ${CampaignDiscountsSettingsFragmentFragmentDoc}
`;
/**
 * __useCampaignDiscountsSettingsListQuery__
 *
 * To run a query within a React component, call `useCampaignDiscountsSettingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountsSettingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDiscountsSettingsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignDiscountsSettingsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignDiscountsSettingsListDocument, options);
}
export function useCampaignDiscountsSettingsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignDiscountsSettingsListDocument, options);
}
export const CampaignDiscountSettingsCreateDocument = gql `
  mutation CampaignDiscountSettingsCreate($createData: DiscountCodeSettingCreateInput!) {
    discountCodeSettingCreate(data: $createData) {
      ...CampaignDiscountsSettingsFragment
    }
  }
  ${CampaignDiscountsSettingsFragmentFragmentDoc}
`;
/**
 * __useCampaignDiscountSettingsCreateMutation__
 *
 * To run a mutation, you first call `useCampaignDiscountSettingsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountSettingsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDiscountSettingsCreateMutation, { data, loading, error }] = useCampaignDiscountSettingsCreateMutation({
 *   variables: {
 *      createData: // value for 'createData'
 *   },
 * });
 */
export function useCampaignDiscountSettingsCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignDiscountSettingsCreateDocument, options);
}
export const CampaignDiscountSettingsUpdateDocument = gql `
  mutation CampaignDiscountSettingsUpdate(
    $data: DiscountCodeSettingUpdateInput!
    $filter: DiscountCodeSettingKeyFilter!
  ) {
    discountCodeSettingUpdate(data: $data, filter: $filter) {
      ...CampaignDiscountsSettingsFragment
    }
  }
  ${CampaignDiscountsSettingsFragmentFragmentDoc}
`;
/**
 * __useCampaignDiscountSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignDiscountSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDiscountSettingsUpdateMutation, { data, loading, error }] = useCampaignDiscountSettingsUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignDiscountSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignDiscountSettingsUpdateDocument, options);
}
export const CampaignDiscountSettingsDeleteDocument = gql `
  mutation CampaignDiscountSettingsDelete($id: ID!) {
    discountCodeSettingDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useCampaignDiscountSettingsDeleteMutation__
 *
 * To run a mutation, you first call `useCampaignDiscountSettingsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountSettingsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDiscountSettingsDeleteMutation, { data, loading, error }] = useCampaignDiscountSettingsDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignDiscountSettingsDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignDiscountSettingsDeleteDocument, options);
}
export const CampaignDiscountCounterByNameDocument = gql `
  query CampaignDiscountCounterByName($campaignId: ID!, $discountName: String!) {
    campaign(id: $campaignId) {
      discountCodeSettings(filter: { name: { equals: $discountName } }) {
        count
      }
    }
  }
`;
/**
 * __useCampaignDiscountCounterByNameQuery__
 *
 * To run a query within a React component, call `useCampaignDiscountCounterByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountCounterByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDiscountCounterByNameQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      discountName: // value for 'discountName'
 *   },
 * });
 */
export function useCampaignDiscountCounterByNameQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignDiscountCounterByNameDocument, options);
}
export function useCampaignDiscountCounterByNameLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignDiscountCounterByNameDocument, options);
}
export const CampaignDiscountSettingsDocument = gql `
  query CampaignDiscountSettings($campaignId: ID!, $filter: DiscountCodeSettingFilter!) {
    campaign(id: $campaignId) {
      discountCodeSettings(filter: $filter) {
        items {
          ...CampaignDiscountsSettingsFragment
        }
      }
    }
  }
  ${CampaignDiscountsSettingsFragmentFragmentDoc}
`;
/**
 * __useCampaignDiscountSettingsQuery__
 *
 * To run a query within a React component, call `useCampaignDiscountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDiscountSettingsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignDiscountSettingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignDiscountSettingsDocument, options);
}
export function useCampaignDiscountSettingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignDiscountSettingsDocument, options);
}
export const CampaignFaQsListDocument = gql `
  query CampaignFAQsList($filter: CampaignFAQFilter) {
    campaignFAQSList(filter: $filter) {
      items {
        ...CampaignFaqFragment
      }
    }
  }
  ${CampaignFaqFragmentFragmentDoc}
`;
/**
 * __useCampaignFaQsListQuery__
 *
 * To run a query within a React component, call `useCampaignFaQsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignFaQsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignFaQsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignFaQsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignFaQsListDocument, options);
}
export function useCampaignFaQsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignFaQsListDocument, options);
}
export const CampaignFaqCreateDocument = gql `
  mutation campaignFAQCreate($data: CampaignFAQCreateInput!) {
    campaignFAQCreate(data: $data) {
      ...CampaignFaqFragment
    }
  }
  ${CampaignFaqFragmentFragmentDoc}
`;
/**
 * __useCampaignFaqCreateMutation__
 *
 * To run a mutation, you first call `useCampaignFaqCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignFaqCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignFaqCreateMutation, { data, loading, error }] = useCampaignFaqCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignFaqCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignFaqCreateDocument, options);
}
export const CampaignFaqUpdateDocument = gql `
  mutation campaignFAQUpdate($data: CampaignFAQUpdateInput!, $filter: CampaignFAQKeyFilter!) {
    campaignFAQUpdate(data: $data, filter: $filter) {
      ...CampaignFaqFragment
    }
  }
  ${CampaignFaqFragmentFragmentDoc}
`;
/**
 * __useCampaignFaqUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignFaqUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignFaqUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignFaqUpdateMutation, { data, loading, error }] = useCampaignFaqUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignFaqUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignFaqUpdateDocument, options);
}
export const CampaignFaqDeleteDocument = gql `
  mutation CampaignFaqDelete($id: ID!) {
    campaignFAQDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useCampaignFaqDeleteMutation__
 *
 * To run a mutation, you first call `useCampaignFaqDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignFaqDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignFaqDeleteMutation, { data, loading, error }] = useCampaignFaqDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignFaqDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignFaqDeleteDocument, options);
}
export const CampaignDonationsMetricsDocument = gql `
  query CampaignDonationsMetrics(
    $campaignId: ID!
    $todayDate: DateTime!
    $lastSevenDaysDate: DateTime!
  ) {
    campaign(id: $campaignId) {
      totalGoal: fundraisingGoal
      totalRaised
      average: allocatedDonations(
        filter: { parents: { every: { id: { is_empty: true } } } }
        groupBy: { query: { amount: { as: "total", fn: { aggregate: AVG } } } }
      ) {
        groups {
          total: Float
        }
      }
      lastSevenDays: allocatedDonations(
        filter: {
          AND: [
            { parents: { every: { id: { is_empty: true } } } }
            { createdAt: { gte: $lastSevenDaysDate } }
          ]
        }
        groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
      ) {
        groups {
          total: Float
        }
      }
      today: allocatedDonations(
        filter: {
          AND: [
            { parents: { every: { id: { is_empty: true } } } }
            { createdAt: { gte: $todayDate } }
          ]
        }
        groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
      ) {
        groups {
          total: Float
        }
      }
    }
  }
`;
/**
 * __useCampaignDonationsMetricsQuery__
 *
 * To run a query within a React component, call `useCampaignDonationsMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDonationsMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDonationsMetricsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      todayDate: // value for 'todayDate'
 *      lastSevenDaysDate: // value for 'lastSevenDaysDate'
 *   },
 * });
 */
export function useCampaignDonationsMetricsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignDonationsMetricsDocument, options);
}
export function useCampaignDonationsMetricsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignDonationsMetricsDocument, options);
}
export const CampaignTemplatesListDocument = gql `
  query CampaignTemplatesList($id: ID!) {
    campaignTemplatesList(filter: { campaign: { id: { equals: $id } } }) {
      items {
        id
        templates {
          items {
            id
            templateType {
              type
              title
            }
          }
        }
        campaign {
          id
        }
      }
    }
  }
`;
/**
 * __useCampaignTemplatesListQuery__
 *
 * To run a query within a React component, call `useCampaignTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignTemplatesListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignTemplatesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignTemplatesListDocument, options);
}
export function useCampaignTemplatesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignTemplatesListDocument, options);
}
export const CampaignTemplateCreateDocument = gql `
  mutation CampaignTemplateCreate($data: CampaignTemplateCreateInput!) {
    campaignTemplateCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useCampaignTemplateCreateMutation__
 *
 * To run a mutation, you first call `useCampaignTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignTemplateCreateMutation, { data, loading, error }] = useCampaignTemplateCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignTemplateCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignTemplateCreateDocument, options);
}
export const TemplateStructureListDocument = gql `
  query TemplateStructureList {
    templateStructuresList {
      items {
        id
      }
      count
    }
  }
`;
/**
 * __useTemplateStructureListQuery__
 *
 * To run a query within a React component, call `useTemplateStructureListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateStructureListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateStructureListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateStructureListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TemplateStructureListDocument, options);
}
export function useTemplateStructureListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TemplateStructureListDocument, options);
}
export const TemplateTypeListDocument = gql `
  query TemplateTypeList {
    templateTypesList {
      items {
        id
        type
      }
    }
  }
`;
/**
 * __useTemplateTypeListQuery__
 *
 * To run a query within a React component, call `useTemplateTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateTypeListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TemplateTypeListDocument, options);
}
export function useTemplateTypeListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TemplateTypeListDocument, options);
}
export const TemplateStructureDocument = gql `
  query TemplateStructure($campaignId: ID!, $templateType: String!) {
    templateStructuresList(
      filter: {
        campaignTemplates: { campaign: { id: { equals: $campaignId } } }
        templateType: { type: { equals: $templateType } }
      }
    ) {
      items {
        id
        structure
        templateType {
          id
          title
          type
        }
      }
    }
  }
`;
/**
 * __useTemplateStructureQuery__
 *
 * To run a query within a React component, call `useTemplateStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateStructureQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useTemplateStructureQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TemplateStructureDocument, options);
}
export function useTemplateStructureLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TemplateStructureDocument, options);
}
export const CampaignPageTemplateUpdateDocument = gql `
  mutation CampaignPageTemplateUpdate($data: TemplateStructureUpdateInput!) {
    templateStructureUpdate(data: $data) {
      id
      structure
      templateType {
        id
        title
        type
      }
    }
  }
`;
/**
 * __useCampaignPageTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignPageTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignPageTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignPageTemplateUpdateMutation, { data, loading, error }] = useCampaignPageTemplateUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignPageTemplateUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignPageTemplateUpdateDocument, options);
}
export const DefaultQuestionsByCategoryDocument = gql `
  query DefaultQuestionsByCategory {
    defaultQuestionsList {
      items {
        id
        type
        title
        category
        isMandatory
      }
    }
  }
`;
/**
 * __useDefaultQuestionsByCategoryQuery__
 *
 * To run a query within a React component, call `useDefaultQuestionsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultQuestionsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultQuestionsByCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultQuestionsByCategoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DefaultQuestionsByCategoryDocument, options);
}
export function useDefaultQuestionsByCategoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DefaultQuestionsByCategoryDocument, options);
}
export const CampaignCustomQuestionListDocument = gql `
  query CampaignCustomQuestionList($filter: CustomCampaignQuestionFilter) {
    customCampaignQuestionsList(filter: $filter) {
      items {
        id
        type
        options
        experience {
          items {
            ...CampaignQuestionExperienceFragment
          }
        }
      }
    }
  }
  ${CampaignQuestionExperienceFragmentFragmentDoc}
`;
/**
 * __useCampaignCustomQuestionListQuery__
 *
 * To run a query within a React component, call `useCampaignCustomQuestionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignCustomQuestionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignCustomQuestionListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignCustomQuestionListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignCustomQuestionListDocument, options);
}
export function useCampaignCustomQuestionListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignCustomQuestionListDocument, options);
}
export const CampaignQuestionsDocument = gql `
  query CampaignQuestions(
    $id: ID!
    $defaultQuestionsFilter: DefaultCampaignQuestionFilter
    $customQuestionsFilter: CustomCampaignQuestionFilter
  ) {
    campaign(id: $id) {
      id
      defaultCampaignQuestions(filter: $defaultQuestionsFilter) {
        items {
          ...DefaultCampaignQuestionFragment
        }
      }
      customCampaignQuestions(filter: $customQuestionsFilter) {
        items {
          ...CustomCampaignQuestionFragment
        }
      }
    }
  }
  ${DefaultCampaignQuestionFragmentFragmentDoc}
  ${CustomCampaignQuestionFragmentFragmentDoc}
`;
/**
 * __useCampaignQuestionsQuery__
 *
 * To run a query within a React component, call `useCampaignQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignQuestionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      defaultQuestionsFilter: // value for 'defaultQuestionsFilter'
 *      customQuestionsFilter: // value for 'customQuestionsFilter'
 *   },
 * });
 */
export function useCampaignQuestionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignQuestionsDocument, options);
}
export function useCampaignQuestionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignQuestionsDocument, options);
}
export const CustomCampaignQuestionCreateDocument = gql `
  mutation customCampaignQuestionCreate($data: CustomCampaignQuestionCreateInput!) {
    customCampaignQuestionCreate(data: $data) {
      ...CustomCampaignQuestionFragment
    }
  }
  ${CustomCampaignQuestionFragmentFragmentDoc}
`;
/**
 * __useCustomCampaignQuestionCreateMutation__
 *
 * To run a mutation, you first call `useCustomCampaignQuestionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomCampaignQuestionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customCampaignQuestionCreateMutation, { data, loading, error }] = useCustomCampaignQuestionCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCustomCampaignQuestionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CustomCampaignQuestionCreateDocument, options);
}
export const CampaignDefaultQuestionsCreateManyDocument = gql `
  mutation CampaignDefaultQuestionsCreateMany($data: [DefaultCampaignQuestionCreateManyInput]!) {
    defaultCampaignQuestionCreateMany(data: $data) {
      items {
        id
      }
      count
    }
  }
`;
/**
 * __useCampaignDefaultQuestionsCreateManyMutation__
 *
 * To run a mutation, you first call `useCampaignDefaultQuestionsCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDefaultQuestionsCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDefaultQuestionsCreateManyMutation, { data, loading, error }] = useCampaignDefaultQuestionsCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignDefaultQuestionsCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignDefaultQuestionsCreateManyDocument, options);
}
export const UpdateDefaultCampaignQuestionDocument = gql `
  mutation UpdateDefaultCampaignQuestion($id: ID!, $data: DefaultCampaignQuestionUpdateInput!) {
    defaultCampaignQuestionUpdate(data: $data, filter: { id: $id }) {
      ...DefaultCampaignQuestionFragment
      shouldDisplay
      experience {
        items {
          ...CampaignQuestionExperienceFragment
        }
      }
    }
  }
  ${DefaultCampaignQuestionFragmentFragmentDoc}
  ${CampaignQuestionExperienceFragmentFragmentDoc}
`;
/**
 * __useUpdateDefaultCampaignQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultCampaignQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultCampaignQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultCampaignQuestionMutation, { data, loading, error }] = useUpdateDefaultCampaignQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDefaultCampaignQuestionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateDefaultCampaignQuestionDocument, options);
}
export const UpdateCustomCampaignQuestionDocument = gql `
  mutation UpdateCustomCampaignQuestion($id: ID!, $data: CustomCampaignQuestionUpdateInput!) {
    customCampaignQuestionUpdate(data: $data, filter: { id: $id }) {
      ...CustomCampaignQuestionFragment
      experience {
        items {
          ...CampaignQuestionExperienceFragment
        }
      }
    }
  }
  ${CustomCampaignQuestionFragmentFragmentDoc}
  ${CampaignQuestionExperienceFragmentFragmentDoc}
`;
/**
 * __useUpdateCustomCampaignQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateCustomCampaignQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomCampaignQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomCampaignQuestionMutation, { data, loading, error }] = useUpdateCustomCampaignQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomCampaignQuestionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateCustomCampaignQuestionDocument, options);
}
export const UpdateCustomQuestionsByFilterDocument = gql `
  mutation UpdateCustomQuestionsByFilter(
    $data: CustomCampaignQuestionUpdateByFilterInput!
    $filter: CustomCampaignQuestionFilter
  ) {
    customCampaignQuestionUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
        isEnabledAttendee
        isEnabledDonor
        isEnabledTeam
      }
    }
  }
`;
/**
 * __useUpdateCustomQuestionsByFilterMutation__
 *
 * To run a mutation, you first call `useUpdateCustomQuestionsByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomQuestionsByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomQuestionsByFilterMutation, { data, loading, error }] = useUpdateCustomQuestionsByFilterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateCustomQuestionsByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateCustomQuestionsByFilterDocument, options);
}
export const UpdateDefaultQuestionsByFilterDocument = gql `
  mutation UpdateDefaultQuestionsByFilter(
    $data: DefaultCampaignQuestionUpdateByFilterInput!
    $filter: DefaultCampaignQuestionFilter
  ) {
    defaultCampaignQuestionUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
        isEnabledAttendee
        isEnabledDonor
        isEnabledTeam
      }
    }
  }
`;
/**
 * __useUpdateDefaultQuestionsByFilterMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultQuestionsByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultQuestionsByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultQuestionsByFilterMutation, { data, loading, error }] = useUpdateDefaultQuestionsByFilterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateDefaultQuestionsByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateDefaultQuestionsByFilterDocument, options);
}
export const CustomCampaignQuestionDeleteDocument = gql `
  mutation CustomCampaignQuestionDelete($data: CustomCampaignQuestionDeleteInput!) {
    customCampaignQuestionDelete(data: $data) {
      success
    }
  }
`;
/**
 * __useCustomCampaignQuestionDeleteMutation__
 *
 * To run a mutation, you first call `useCustomCampaignQuestionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomCampaignQuestionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customCampaignQuestionDeleteMutation, { data, loading, error }] = useCustomCampaignQuestionDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCustomCampaignQuestionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CustomCampaignQuestionDeleteDocument, options);
}
export const CustomQuestionAnswerCreateManyDocument = gql `
  mutation CustomQuestionAnswerCreateMany($data: [CustomQuestionAnswerCreateManyInput]!) {
    customQuestionAnswerCreateMany(data: $data) {
      items {
        id
        text
        question {
          id
          title
        }
        attendee {
          id
        }
        user {
          id
        }
      }
    }
  }
`;
/**
 * __useCustomQuestionAnswerCreateManyMutation__
 *
 * To run a mutation, you first call `useCustomQuestionAnswerCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomQuestionAnswerCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customQuestionAnswerCreateManyMutation, { data, loading, error }] = useCustomQuestionAnswerCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCustomQuestionAnswerCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CustomQuestionAnswerCreateManyDocument, options);
}
export const UpdateCampaignQuestionsManyDocument = gql `
  mutation UpdateCampaignQuestionsMany($data: UpdateCampaignQuestionsManyData!) {
    updateCampaignQuestionsMany(data: $data) {
      success
    }
  }
`;
/**
 * __useUpdateCampaignQuestionsManyMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignQuestionsManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignQuestionsManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignQuestionsManyMutation, { data, loading, error }] = useUpdateCampaignQuestionsManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCampaignQuestionsManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateCampaignQuestionsManyDocument, options);
}
export const CampaignStaffCreateManyDocument = gql `
  mutation CampaignStaffCreateMany($dataToCreate: [CampaignStaffCreateManyInput]!) {
    createdStaffs: campaignStaffCreateMany(data: $dataToCreate) {
      count
      items {
        ...CampaignStaffFragment
      }
    }
  }
  ${CampaignStaffFragmentFragmentDoc}
`;
/**
 * __useCampaignStaffCreateManyMutation__
 *
 * To run a mutation, you first call `useCampaignStaffCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignStaffCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignStaffCreateManyMutation, { data, loading, error }] = useCampaignStaffCreateManyMutation({
 *   variables: {
 *      dataToCreate: // value for 'dataToCreate'
 *   },
 * });
 */
export function useCampaignStaffCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignStaffCreateManyDocument, options);
}
export const CampaignStaffUpdateByFilterDocument = gql `
  mutation CampaignStaffUpdateByFilter(
    $data: CampaignStaffUpdateByFilterInput!
    $filter: CampaignStaffFilter
  ) {
    updatedCampaignStaffs: campaignStaffUpdateByFilter(data: $data, filter: $filter) {
      items {
        ...CampaignStaffFragment
      }
    }
  }
  ${CampaignStaffFragmentFragmentDoc}
`;
/**
 * __useCampaignStaffUpdateByFilterMutation__
 *
 * To run a mutation, you first call `useCampaignStaffUpdateByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignStaffUpdateByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignStaffUpdateByFilterMutation, { data, loading, error }] = useCampaignStaffUpdateByFilterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignStaffUpdateByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignStaffUpdateByFilterDocument, options);
}
export const CampaignStaffListDocument = gql `
  query CampaignStaffList($filter: CampaignStaffFilter) {
    campaignStaffsList(filter: $filter) {
      items {
        ...CampaignStaffFragment
      }
    }
  }
  ${CampaignStaffFragmentFragmentDoc}
`;
/**
 * __useCampaignStaffListQuery__
 *
 * To run a query within a React component, call `useCampaignStaffListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignStaffListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignStaffListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignStaffListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignStaffListDocument, options);
}
export function useCampaignStaffListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignStaffListDocument, options);
}
export const CampaignStaffDeleteByFilterDocument = gql `
  mutation CampaignStaffDeleteByFilter($filter: CampaignStaffFilter!) {
    campaignStaffDeleteByFilter(filter: $filter) {
      success
    }
  }
`;
/**
 * __useCampaignStaffDeleteByFilterMutation__
 *
 * To run a mutation, you first call `useCampaignStaffDeleteByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignStaffDeleteByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignStaffDeleteByFilterMutation, { data, loading, error }] = useCampaignStaffDeleteByFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignStaffDeleteByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignStaffDeleteByFilterDocument, options);
}
export const CloneExperienceListDocument = gql `
  query CloneExperienceList($filter: ExperienceFilter, $skip: Int, $first: Int) {
    experiencesList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...CloneExperienceFragment
      }
    }
  }
  ${CloneExperienceFragmentFragmentDoc}
`;
/**
 * __useCloneExperienceListQuery__
 *
 * To run a query within a React component, call `useCloneExperienceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloneExperienceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloneExperienceListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCloneExperienceListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CloneExperienceListDocument, options);
}
export function useCloneExperienceListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CloneExperienceListDocument, options);
}
export const GetCommitmentsDocument = gql `
  query GetCommitments($filter: DonorCommitmentViewFilter, $skip: Int, $first: Int) {
    donorCommitmentViewsList(
      filter: $filter
      sort: { CommitmentDate: DESC }
      skip: $skip
      first: $first
    ) {
      count
      items {
        id
        CommitmentDate
        CommitmentStatus
        CommitmentType
        CommitmentAmount
        CommitmentCompany
        FundraiserId
        FundraiserTitle
        FundraiserFirstName
        FundraiserLastName
        TeamId
        TeamName
        DonorId
        DonorFullName
        DonorEmail
        CampaignName
        ExperienceName
        VizslaOrganizationId
      }
    }
  }
`;
/**
 * __useGetCommitmentsQuery__
 *
 * To run a query within a React component, call `useGetCommitmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommitmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommitmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetCommitmentsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCommitmentsDocument, options);
}
export function useGetCommitmentsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCommitmentsDocument, options);
}
export const DonationsToMyFundraisingsDocument = gql `
  query DonationsToMyFundraisings($filter: DonationAllocationFilter, $skip: Int, $first: Int) {
    donationAllocationsList(filter: $filter, skip: $skip, first: $first) {
      items {
        createdAt
        amount
        experience {
          name
        }
        campaign {
          name
        }
        donor {
          fullName
        }
        teamFundraising {
          name
        }
      }
    }
  }
`;
/**
 * __useDonationsToMyFundraisingsQuery__
 *
 * To run a query within a React component, call `useDonationsToMyFundraisingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationsToMyFundraisingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationsToMyFundraisingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useDonationsToMyFundraisingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DonationsToMyFundraisingsDocument, options);
}
export function useDonationsToMyFundraisingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DonationsToMyFundraisingsDocument, options);
}
export const DonationsToOthersDocument = gql `
  query DonationsToOthers($filter: DonationAllocationFilter, $skip: Int, $first: Int) {
    donationAllocationsList(filter: $filter, skip: $skip, first: $first) {
      items {
        createdAt
        amount
        id
        experience {
          name
        }
        campaign {
          name
        }
        donor {
          fullName
        }
        teamFundraising {
          name
        }
        individualFundraising {
          attendee {
            user {
              fullName
            }
          }
        }
      }
    }
  }
`;
/**
 * __useDonationsToOthersQuery__
 *
 * To run a query within a React component, call `useDonationsToOthersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationsToOthersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationsToOthersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useDonationsToOthersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DonationsToOthersDocument, options);
}
export function useDonationsToOthersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DonationsToOthersDocument, options);
}
export const DonorsListDocument = gql `
  query DonorsList($filter: DonorFilter, $skip: Int, $first: Int) {
    donorsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...DonorFragment
      }
    }
  }
  ${DonorFragmentFragmentDoc}
`;
/**
 * __useDonorsListQuery__
 *
 * To run a query within a React component, call `useDonorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useDonorsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DonorsListDocument, options);
}
export function useDonorsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DonorsListDocument, options);
}
export const UsersDonorsListDocument = gql `
  query UsersDonorsList($organizationId: ID!, $skip: Int, $first: Int) {
    usersList(
      filter: {
        donors: { some: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } } }
      }
      skip: $skip
      first: $first
    ) {
      count
      items {
        id
        firstName
        lastName
        avatar {
          id
          downloadUrl
        }
        donors(filter: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }) {
          count
          items {
            id
            donations {
              items {
                id
                amount
              }
            }
            campaign {
              id
              name
            }
          }
        }
      }
    }
  }
`;
/**
 * __useUsersDonorsListQuery__
 *
 * To run a query within a React component, call `useUsersDonorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersDonorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersDonorsListQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUsersDonorsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsersDonorsListDocument, options);
}
export function useUsersDonorsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsersDonorsListDocument, options);
}
export const GetRecentDonationsByCampaignDocument = gql `
  query GetRecentDonationsByCampaign(
    $campaignId: ID!
    $filter: DonationAllocationFilter!
    $first: Int
    $skip: Int
  ) {
    donationAllocationsList(
      filter: { AND: [{ campaign: { id: { equals: $campaignId } } }, $filter] }
      groupBy: {
        query: {
          donor: { id: { as: "donorId" }, _group: { as: "donor" } }
          amount: { as: "amount", fn: { aggregate: SUM } }
          createdAt: { as: "date", fn: { aggregate: MAX } }
        }
        sort: { alias: "amount", direction: DESC }
        having: { alias: "donorId", id: { not_equals: null } }
        first: $first
        skip: $skip
      }
    ) {
      count
      groups {
        donorId: ID
        date: DateTime
        amount: Float
        donor: UserGroup {
          items {
            ...USER_RECENT_FRAGMENT
          }
        }
      }
    }
  }
  ${User_Recent_FragmentFragmentDoc}
`;
/**
 * __useGetRecentDonationsByCampaignQuery__
 *
 * To run a query within a React component, call `useGetRecentDonationsByCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentDonationsByCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentDonationsByCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetRecentDonationsByCampaignQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRecentDonationsByCampaignDocument, options);
}
export function useGetRecentDonationsByCampaignLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRecentDonationsByCampaignDocument, options);
}
export const EarlyBirdRegistrationSettingsDocument = gql `
  query EarlyBirdRegistrationSettings($id: ID!) {
    experience(id: $id) {
      id
      earlyBirdUrlCode
      earlyBirdRegistrationAvailable
      earlyBirdRegistrationsApplyTo
      earlyBirdRegistrationsMode
      earlyBirdRegistrationOptions {
        items {
          ...EarlyBirdRegistrationOptionFragment
        }
        count
      }
    }
  }
  ${EarlyBirdRegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useEarlyBirdRegistrationSettingsQuery__
 *
 * To run a query within a React component, call `useEarlyBirdRegistrationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarlyBirdRegistrationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarlyBirdRegistrationSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEarlyBirdRegistrationSettingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(EarlyBirdRegistrationSettingsDocument, options);
}
export function useEarlyBirdRegistrationSettingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(EarlyBirdRegistrationSettingsDocument, options);
}
export const EarlyBirdRegistrationSettingsUpdateDocument = gql `
  mutation earlyBirdRegistrationSettingsUpdate(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experienceUpdate(data: $data, filter: $filter) {
      id
      earlyBirdRegistrationAvailable
      earlyBirdRegistrationsApplyTo
      earlyBirdRegistrationsMode
      earlyBirdRegistrationOptions {
        items {
          ...EarlyBirdRegistrationOptionFragment
        }
        count
      }
    }
  }
  ${EarlyBirdRegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useEarlyBirdRegistrationSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useEarlyBirdRegistrationSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEarlyBirdRegistrationSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [earlyBirdRegistrationSettingsUpdateMutation, { data, loading, error }] = useEarlyBirdRegistrationSettingsUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEarlyBirdRegistrationSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EarlyBirdRegistrationSettingsUpdateDocument, options);
}
export const EarlyBirdRegistrationInvitationsDocument = gql `
  query earlyBirdRegistrationInvitations($filter: EarlyBirdRegistrationInvitationFilter!) {
    earlyBirdRegistrationInvitationsList(filter: $filter) {
      items {
        ...EarlyBirdRegistrationInvitationFragment
      }
    }
  }
  ${EarlyBirdRegistrationInvitationFragmentFragmentDoc}
`;
/**
 * __useEarlyBirdRegistrationInvitationsQuery__
 *
 * To run a query within a React component, call `useEarlyBirdRegistrationInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarlyBirdRegistrationInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarlyBirdRegistrationInvitationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEarlyBirdRegistrationInvitationsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(EarlyBirdRegistrationInvitationsDocument, options);
}
export function useEarlyBirdRegistrationInvitationsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(EarlyBirdRegistrationInvitationsDocument, options);
}
export const EarlyBirdRegistrationInvitationCreateDocument = gql `
  mutation earlyBirdRegistrationInvitationCreate(
    $data: EarlyBirdRegistrationInvitationCreateInput!
  ) {
    earlyBirdRegistrationInvitationCreate(data: $data) {
      ...EarlyBirdRegistrationInvitationFragment
    }
  }
  ${EarlyBirdRegistrationInvitationFragmentFragmentDoc}
`;
/**
 * __useEarlyBirdRegistrationInvitationCreateMutation__
 *
 * To run a mutation, you first call `useEarlyBirdRegistrationInvitationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEarlyBirdRegistrationInvitationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [earlyBirdRegistrationInvitationCreateMutation, { data, loading, error }] = useEarlyBirdRegistrationInvitationCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEarlyBirdRegistrationInvitationCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EarlyBirdRegistrationInvitationCreateDocument, options);
}
export const EarlyBirdRegistrationInvitationDeleteDocument = gql `
  mutation earlyBirdRegistrationInvitationDelete($id: ID!) {
    earlyBirdRegistrationInvitationDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useEarlyBirdRegistrationInvitationDeleteMutation__
 *
 * To run a mutation, you first call `useEarlyBirdRegistrationInvitationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEarlyBirdRegistrationInvitationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [earlyBirdRegistrationInvitationDeleteMutation, { data, loading, error }] = useEarlyBirdRegistrationInvitationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEarlyBirdRegistrationInvitationDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EarlyBirdRegistrationInvitationDeleteDocument, options);
}
export const ExperienceperCampaignDocument = gql `
  query ExperienceperCampaign($campaignId: ID!) {
    experiencesList(filter: { campaign: { id: { equals: $campaignId } } }) {
      count
      items {
        ...ExperienceFragment
      }
    }
  }
  ${ExperienceFragmentFragmentDoc}
`;
/**
 * __useExperienceperCampaignQuery__
 *
 * To run a query within a React component, call `useExperienceperCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceperCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceperCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useExperienceperCampaignQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceperCampaignDocument, options);
}
export function useExperienceperCampaignLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceperCampaignDocument, options);
}
export const TeamsPerExperienceDocument = gql `
  query TeamsPerExperience($experienceId: ID!, $teamName: String) {
    experience(id: $experienceId) {
      teams(filter: { name: { equals: $teamName } }) {
        count
        items {
          name
        }
      }
    }
  }
`;
/**
 * __useTeamsPerExperienceQuery__
 *
 * To run a query within a React component, call `useTeamsPerExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsPerExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsPerExperienceQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useTeamsPerExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamsPerExperienceDocument, options);
}
export function useTeamsPerExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamsPerExperienceDocument, options);
}
export const ExperienceListDocument = gql `
  query ExperienceList($filter: ExperienceFilter, $skip: Int, $first: Int) {
    experiencesList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...ExperienceFragment
      }
    }
  }
  ${ExperienceFragmentFragmentDoc}
`;
/**
 * __useExperienceListQuery__
 *
 * To run a query within a React component, call `useExperienceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useExperienceListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceListDocument, options);
}
export function useExperienceListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceListDocument, options);
}
export const ExperienceDocument = gql `
  query Experience($id: ID!) {
    experience(id: $id) {
      ...ExperienceFragment
      campaign {
        id
        organization: vizslaOrganization {
          id
          platformFee: fee
          processingFee: stripeFee
        }
      }
    }
  }
  ${ExperienceFragmentFragmentDoc}
`;
/**
 * __useExperienceQuery__
 *
 * To run a query within a React component, call `useExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceDocument, options);
}
export function useExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceDocument, options);
}
export const ExperienceCreateManyDocument = gql `
  mutation ExperienceCreateMany($data: [ExperienceCreateManyInput]!) {
    experienceCreateMany(data: $data) {
      count
      items {
        id
        name
      }
    }
  }
`;
/**
 * __useExperienceCreateManyMutation__
 *
 * To run a mutation, you first call `useExperienceCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceCreateManyMutation, { data, loading, error }] = useExperienceCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceCreateManyDocument, options);
}
export const ExperienceCreateDocument = gql `
  mutation ExperienceCreate($data: ExperienceCreateInput!) {
    experienceCreate(data: $data) {
      ...ExperienceFragment
    }
  }
  ${ExperienceFragmentFragmentDoc}
`;
/**
 * __useExperienceCreateMutation__
 *
 * To run a mutation, you first call `useExperienceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceCreateMutation, { data, loading, error }] = useExperienceCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceCreateDocument, options);
}
export const ExperienceUpdateDocument = gql `
  mutation ExperienceUpdate($data: ExperienceUpdateInput!, $filter: ExperienceKeyFilter!) {
    experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceFragment
    }
  }
  ${ExperienceFragmentFragmentDoc}
`;
/**
 * __useExperienceUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceUpdateMutation, { data, loading, error }] = useExperienceUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceUpdateDocument, options);
}
export const ExperienceDeleteDocument = gql `
  mutation ExperienceDelete($id: ID!) {
    experienceDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;
/**
 * __useExperienceDeleteMutation__
 *
 * To run a mutation, you first call `useExperienceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceDeleteMutation, { data, loading, error }] = useExperienceDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceDeleteDocument, options);
}
export const ExperienceGeneralSettingsListDocument = gql `
  query ExperienceGeneralSettingsList($id: ID!) {
    experience(id: $id) {
      ...ExperienceFragment
      ...ExperienceGeneralFragment
    }
  }
  ${ExperienceFragmentFragmentDoc}
  ${ExperienceGeneralFragmentFragmentDoc}
`;
/**
 * __useExperienceGeneralSettingsListQuery__
 *
 * To run a query within a React component, call `useExperienceGeneralSettingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceGeneralSettingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceGeneralSettingsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceGeneralSettingsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceGeneralSettingsListDocument, options);
}
export function useExperienceGeneralSettingsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceGeneralSettingsListDocument, options);
}
export const ExperienceGeneralUpdateDocument = gql `
  mutation ExperienceGeneralUpdate($data: ExperienceUpdateInput!, $filter: ExperienceKeyFilter!) {
    experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceFragment
      ...ExperienceGeneralFragment
    }
  }
  ${ExperienceFragmentFragmentDoc}
  ${ExperienceGeneralFragmentFragmentDoc}
`;
/**
 * __useExperienceGeneralUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceGeneralUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceGeneralUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceGeneralUpdateMutation, { data, loading, error }] = useExperienceGeneralUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceGeneralUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceGeneralUpdateDocument, options);
}
export const ExperienceVenueCreateMutationDocument = gql `
  mutation ExperienceVenueCreateMutation($data: ExperienceVenueCreateInput!) {
    experienceVenueCreate(data: $data) {
      ...ExperienceVenueFragment
    }
  }
  ${ExperienceVenueFragmentFragmentDoc}
`;
/**
 * __useExperienceVenueCreateMutationMutation__
 *
 * To run a mutation, you first call `useExperienceVenueCreateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceVenueCreateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceVenueCreateMutationMutation, { data, loading, error }] = useExperienceVenueCreateMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceVenueCreateMutationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceVenueCreateMutationDocument, options);
}
export const ExperienceVenueCreateManyMutationDocument = gql `
  mutation ExperienceVenueCreateManyMutation($data: [ExperienceVenueCreateManyInput]!) {
    experienceVenueCreateMany(data: $data) {
      items {
        ...ExperienceVenueFragment
      }
    }
  }
  ${ExperienceVenueFragmentFragmentDoc}
`;
/**
 * __useExperienceVenueCreateManyMutationMutation__
 *
 * To run a mutation, you first call `useExperienceVenueCreateManyMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceVenueCreateManyMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceVenueCreateManyMutationMutation, { data, loading, error }] = useExperienceVenueCreateManyMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceVenueCreateManyMutationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceVenueCreateManyMutationDocument, options);
}
export const ExperienceVenueUpdateMutationDocument = gql `
  mutation ExperienceVenueUpdateMutation(
    $data: ExperienceVenueUpdateInput!
    $filter: ExperienceVenueKeyFilter!
  ) {
    experienceVenueUpdate(data: $data, filter: $filter) {
      ...ExperienceVenueFragment
    }
  }
  ${ExperienceVenueFragmentFragmentDoc}
`;
/**
 * __useExperienceVenueUpdateMutationMutation__
 *
 * To run a mutation, you first call `useExperienceVenueUpdateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceVenueUpdateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceVenueUpdateMutationMutation, { data, loading, error }] = useExperienceVenueUpdateMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceVenueUpdateMutationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceVenueUpdateMutationDocument, options);
}
export const ExperienceVenueDeleteMutationDocument = gql `
  mutation ExperienceVenueDeleteMutation($id: ID!) {
    experienceVenueDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useExperienceVenueDeleteMutationMutation__
 *
 * To run a mutation, you first call `useExperienceVenueDeleteMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceVenueDeleteMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceVenueDeleteMutationMutation, { data, loading, error }] = useExperienceVenueDeleteMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceVenueDeleteMutationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceVenueDeleteMutationDocument, options);
}
export const ExperienceTransactionFeesDocument = gql `
  query ExperienceTransactionFees($id: ID) {
    experience(id: $id) {
      id
      transactionFees
    }
  }
`;
/**
 * __useExperienceTransactionFeesQuery__
 *
 * To run a query within a React component, call `useExperienceTransactionFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceTransactionFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceTransactionFeesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceTransactionFeesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceTransactionFeesDocument, options);
}
export function useExperienceTransactionFeesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceTransactionFeesDocument, options);
}
export const ExperienceListAvailableRegistrationDocument = gql `
  query ExperienceListAvailableRegistration(
    $filter: ExperienceFilter
    $filterRegistrationOption: RegistrationOptionFilter
    $filterTicketingOption: TicketingOptionFilter
    $skip: Int
    $first: Int
  ) {
    experiencesList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        id
        name
        experienceType
        experienceCategory
        startDate
        earlyBirdUrlCode
        endDate
        waiverAllowMultipleAttendeeConsent
        waiverTextBody
        qualifyingFundraisingAmount
        campaign {
          name
          startDate
          endDate
          campaignType
        }
        attendees {
          count
        }
        registrationOptions(filter: $filterRegistrationOption) {
          count
          items {
            id
            registrationStartDate
            registrationEndDate
            name
            attendees {
              items {
                id
              }
            }
          }
        }
        ticketingOptions(filter: $filterTicketingOption) {
          count
          items {
            id
            ticketingAvailabilityStartDateTime
            ticketingAvailabilityEndDateTime
            ticketName
            attendees {
              items {
                id
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useExperienceListAvailableRegistrationQuery__
 *
 * To run a query within a React component, call `useExperienceListAvailableRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceListAvailableRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceListAvailableRegistrationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      filterRegistrationOption: // value for 'filterRegistrationOption'
 *      filterTicketingOption: // value for 'filterTicketingOption'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useExperienceListAvailableRegistrationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceListAvailableRegistrationDocument, options);
}
export function useExperienceListAvailableRegistrationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceListAvailableRegistrationDocument, options);
}
export const GetExperiencesIdOptionsDocument = gql `
  query getExperiencesIdOptions($filter: ExperienceFilter, $skip: Int, $first: Int) {
    experiencesList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        id
        name
      }
    }
  }
`;
/**
 * __useGetExperiencesIdOptionsQuery__
 *
 * To run a query within a React component, call `useGetExperiencesIdOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperiencesIdOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperiencesIdOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetExperiencesIdOptionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetExperiencesIdOptionsDocument, options);
}
export function useGetExperiencesIdOptionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetExperiencesIdOptionsDocument, options);
}
export const CampaignTeamsListDocument = gql `
  query CampaignTeamsList($filter: TeamFundraisingFilter!) {
    teamFundraisingsList(filter: $filter) {
      items {
        avatar {
          id
          downloadUrl
        }
        id
        name
      }
    }
  }
`;
/**
 * __useCampaignTeamsListQuery__
 *
 * To run a query within a React component, call `useCampaignTeamsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignTeamsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignTeamsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignTeamsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignTeamsListDocument, options);
}
export function useCampaignTeamsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignTeamsListDocument, options);
}
export const ExperienceBibAssignmentSettingsDocument = gql `
  query ExperienceBibAssignmentSettings($id: ID!) {
    experience(id: $id) {
      ...ExperienceBibAssignmentSettingsFragment
    }
  }
  ${ExperienceBibAssignmentSettingsFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignmentSettingsQuery__
 *
 * To run a query within a React component, call `useExperienceBibAssignmentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceBibAssignmentSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceBibAssignmentSettingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceBibAssignmentSettingsDocument, options);
}
export function useExperienceBibAssignmentSettingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceBibAssignmentSettingsDocument, options);
}
export const ExperienceBibAssignmentSettingsUpdateDocument = gql `
  mutation ExperienceBibAssignmentSettingsUpdate(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter
  ) {
    experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceBibAssignmentSettingsFragment
    }
  }
  ${ExperienceBibAssignmentSettingsFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignmentSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignmentSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignmentSettingsUpdateMutation, { data, loading, error }] = useExperienceBibAssignmentSettingsUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceBibAssignmentSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignmentSettingsUpdateDocument, options);
}
export const ExperienceBibPoolCreateDocument = gql `
  mutation ExperienceBibPoolCreate($validatedData: JSON!, $additionalData: JSON!) {
    bibPoolCreate(validatedData: $validatedData, additionalData: $additionalData) {
      experienceBibPoolCreate
    }
  }
`;
/**
 * __useExperienceBibPoolCreateMutation__
 *
 * To run a mutation, you first call `useExperienceBibPoolCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibPoolCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibPoolCreateMutation, { data, loading, error }] = useExperienceBibPoolCreateMutation({
 *   variables: {
 *      validatedData: // value for 'validatedData'
 *      additionalData: // value for 'additionalData'
 *   },
 * });
 */
export function useExperienceBibPoolCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibPoolCreateDocument, options);
}
export const ExperienceBibPoolUpdateDocument = gql `
  mutation ExperienceBibPoolUpdate($validatedData: JSON!, $additionalData: JSON!) {
    bibPoolUpdate(validatedData: $validatedData, additionalData: $additionalData) {
      experienceBibPoolUpdate
    }
  }
`;
/**
 * __useExperienceBibPoolUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceBibPoolUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibPoolUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibPoolUpdateMutation, { data, loading, error }] = useExperienceBibPoolUpdateMutation({
 *   variables: {
 *      validatedData: // value for 'validatedData'
 *      additionalData: // value for 'additionalData'
 *   },
 * });
 */
export function useExperienceBibPoolUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibPoolUpdateDocument, options);
}
export const ExperienceBibPoolDeleteDocument = gql `
  mutation ExperienceBibPoolDelete($filter: ExperienceBibPoolKeyFilter!) {
    experienceBibPoolDelete(filter: $filter) {
      success
    }
  }
`;
/**
 * __useExperienceBibPoolDeleteMutation__
 *
 * To run a mutation, you first call `useExperienceBibPoolDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibPoolDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibPoolDeleteMutation, { data, loading, error }] = useExperienceBibPoolDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceBibPoolDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibPoolDeleteDocument, options);
}
export const ExperienceBibAssignmentsListDocument = gql `
  query ExperienceBibAssignmentsList($filter: ExperienceBibAssignmentFilter) {
    experienceBibAssignmentsList(filter: $filter) {
      items {
        ...BibAssignmentFragment
      }
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignmentsListQuery__
 *
 * To run a query within a React component, call `useExperienceBibAssignmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceBibAssignmentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceBibAssignmentsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceBibAssignmentsListDocument, options);
}
export function useExperienceBibAssignmentsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceBibAssignmentsListDocument, options);
}
export const ExperienceBibAssignmentCreateDocument = gql `
  mutation ExperienceBibAssignmentCreate($data: ExperienceBibAssignmentCreateInput!) {
    experienceBibAssignmentCreate(data: $data) {
      ...BibAssignmentFragment
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignmentCreateMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignmentCreateMutation, { data, loading, error }] = useExperienceBibAssignmentCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceBibAssignmentCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignmentCreateDocument, options);
}
export const ExperienceBibExcludeNumberDocument = gql `
  mutation ExperienceBibExcludeNumber($experienceId: ID!, $bibNumber: Int!) {
    bibExcludeNumber(experienceId: $experienceId, bibNumber: $bibNumber) {
      deleted
      bibAssignment {
        ...BibAssignmentFragment
      }
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
/**
 * __useExperienceBibExcludeNumberMutation__
 *
 * To run a mutation, you first call `useExperienceBibExcludeNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibExcludeNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibExcludeNumberMutation, { data, loading, error }] = useExperienceBibExcludeNumberMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      bibNumber: // value for 'bibNumber'
 *   },
 * });
 */
export function useExperienceBibExcludeNumberMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibExcludeNumberDocument, options);
}
export const ExperienceBibAssignIndividualNumberDocument = gql `
  mutation ExperienceBibAssignIndividualNumber(
    $experienceId: ID!
    $attendeeId: ID!
    $bibNumber: Int!
  ) {
    bibAssignIndividualNumber(
      experienceId: $experienceId
      attendeeId: $attendeeId
      bibNumber: $bibNumber
    ) {
      deleted
      bibAssignment {
        ...BibAssignmentFragment
      }
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignIndividualNumberMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignIndividualNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignIndividualNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignIndividualNumberMutation, { data, loading, error }] = useExperienceBibAssignIndividualNumberMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      attendeeId: // value for 'attendeeId'
 *      bibNumber: // value for 'bibNumber'
 *   },
 * });
 */
export function useExperienceBibAssignIndividualNumberMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignIndividualNumberDocument, options);
}
export const ExperienceBibAssignBatchDocument = gql `
  mutation ExperienceBibAssignBatch($experienceId: ID!, $sortBy: String!, $order: String!) {
    bibAssignBatch(experienceId: $experienceId, sortBy: $sortBy, order: $order) {
      success
    }
  }
`;
/**
 * __useExperienceBibAssignBatchMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignBatchMutation, { data, loading, error }] = useExperienceBibAssignBatchMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      sortBy: // value for 'sortBy'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExperienceBibAssignBatchMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignBatchDocument, options);
}
export const ExperienceBibAssignmentCreateManyDocument = gql `
  mutation ExperienceBibAssignmentCreateMany($data: [ExperienceBibAssignmentCreateManyInput!]!) {
    experienceBibAssignmentCreateMany(data: $data) {
      items {
        ...BibAssignmentFragment
      }
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignmentCreateManyMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignmentCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignmentCreateManyMutation, { data, loading, error }] = useExperienceBibAssignmentCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceBibAssignmentCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignmentCreateManyDocument, options);
}
export const ExperienceBibAssignmentDeleteManyDocument = gql `
  mutation ExperienceBibAssignmentDeleteMany($filter: ExperienceBibAssignmentFilter!) {
    experienceBibAssignmentDeleteByFilter(filter: $filter) {
      success
    }
  }
`;
/**
 * __useExperienceBibAssignmentDeleteManyMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignmentDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignmentDeleteManyMutation, { data, loading, error }] = useExperienceBibAssignmentDeleteManyMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceBibAssignmentDeleteManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignmentDeleteManyDocument, options);
}
export const ExperienceBibAssignmentDeleteDocument = gql `
  mutation ExperienceBibAssignmentDelete($filter: ExperienceBibAssignmentKeyFilter!) {
    experienceBibAssignmentDelete(filter: $filter) {
      success
    }
  }
`;
/**
 * __useExperienceBibAssignmentDeleteMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignmentDeleteMutation, { data, loading, error }] = useExperienceBibAssignmentDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceBibAssignmentDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignmentDeleteDocument, options);
}
export const FundraisersCrmDocument = gql `
  query FundraisersCRM($first: Int, $skip: Int, $filter: UserFilter!, $organizationId: ID) {
    fundraisers: usersList(first: $first, skip: $skip, filter: $filter) {
      count
      items {
        id
        firstName
        lastName
        birthDate
        email
        gender
        createdAt
        phone {
          code
          number
        }
        address {
          city
          state
          country
          zip
          street1
          street2
        }
        avatar {
          downloadUrl
        }
        totalRaisedFundraiser: attendees(
          filter: {
            experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
          }
          groupBy: {
            query: {
              fundraising: {
                allocatedDonations: {
                  amount: { as: "count", fn: { aggregate: SUM, distinct: true } }
                }
              }
            }
          }
        ) {
          groups {
            count: Int
          }
        }
        teams: attendees(
          sort: { teamMembership: { team: { createdAt: DESC } } }
          filter: {
            teamMembership: {
              team: {
                id: { is_not_empty: true }
                campaign: { vizslaOrganization: { id: { equals: $organizationId } } }
              }
            }
          }
        ) {
          items {
            teamMembership {
              team {
                name
                createdAt
              }
            }
          }
        }
        allocatedDonations(first: 1, orderBy: createdAt_DESC) {
          items {
            createdAt
          }
        }
      }
    }
  }
`;
/**
 * __useFundraisersCrmQuery__
 *
 * To run a query within a React component, call `useFundraisersCrmQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundraisersCrmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundraisersCrmQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useFundraisersCrmQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FundraisersCrmDocument, options);
}
export function useFundraisersCrmLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FundraisersCrmDocument, options);
}
export const GetNotificationSettingsDocument = gql `
  query GetNotificationSettings($organizationId: ID!) {
    notificationSettingsList {
      items {
        id
        key
        label
        type
        category
        organization(filter: { id: { equals: $organizationId } }) {
          enabled: count
        }
      }
      count
    }
  }
`;
/**
 * __useGetNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSettingsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetNotificationSettingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetNotificationSettingsDocument, options);
}
export function useGetNotificationSettingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetNotificationSettingsDocument, options);
}
export const SendNotificationOrganizationMemberAlreadyRegisteredDocument = gql `
  mutation SendNotificationOrganizationMemberAlreadyRegistered(
    $data: sendNotificationOrganizationMemberAlreadyRegisteredInput
  ) {
    __typename
    sendNotificationOrganizationMemberAlreadyRegistered(data: $data) {
      success
    }
  }
`;
/**
 * __useSendNotificationOrganizationMemberAlreadyRegisteredMutation__
 *
 * To run a mutation, you first call `useSendNotificationOrganizationMemberAlreadyRegisteredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationOrganizationMemberAlreadyRegisteredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNotificationOrganizationMemberAlreadyRegisteredMutation, { data, loading, error }] = useSendNotificationOrganizationMemberAlreadyRegisteredMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendNotificationOrganizationMemberAlreadyRegisteredMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SendNotificationOrganizationMemberAlreadyRegisteredDocument, options);
}
export const GetTeamFundraisingByIdDocument = gql `
  query GetTeamFundraisingById($id: ID!) {
    teamFundraising(id: $id) {
      id
      name
      experience {
        id
        name
      }
    }
  }
`;
/**
 * __useGetTeamFundraisingByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamFundraisingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamFundraisingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamFundraisingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamFundraisingByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTeamFundraisingByIdDocument, options);
}
export function useGetTeamFundraisingByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTeamFundraisingByIdDocument, options);
}
export const GetExperienceByIdDocument = gql `
  query GetExperienceById($id: ID!) {
    experience(id: $id) {
      id
      name
      isStoreEnabled
      isActive
      store
      raised
      createdAt
      endDate
      startDate
    }
  }
`;
/**
 * __useGetExperienceByIdQuery__
 *
 * To run a query within a React component, call `useGetExperienceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperienceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperienceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExperienceByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetExperienceByIdDocument, options);
}
export function useGetExperienceByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetExperienceByIdDocument, options);
}
export const GetIndividualFundraisingByIdDocument = gql `
  query GetIndividualFundraisingById($id: ID!) {
    individualFundraising(id: $id) {
      id
      attendee {
        id
        experience {
          id
          name
        }
        user {
          ...UserBasicInfoFragment
        }
      }
    }
  }
  ${UserBasicInfoFragmentFragmentDoc}
`;
/**
 * __useGetIndividualFundraisingByIdQuery__
 *
 * To run a query within a React component, call `useGetIndividualFundraisingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndividualFundraisingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndividualFundraisingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIndividualFundraisingByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetIndividualFundraisingByIdDocument, options);
}
export function useGetIndividualFundraisingByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetIndividualFundraisingByIdDocument, options);
}
export const GetOrderIntentsDocument = gql `
  query GetOrderIntents($filter: OrderIntentFilter!, $first: Int, $skip: Int) {
    orderIntentsList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        createdAt
        createdBy {
          ...UserBasicInfoFragment
        }
        data
        parentOrderId
        status
        trace
      }
    }
  }
  ${UserBasicInfoFragmentFragmentDoc}
`;
/**
 * __useGetOrderIntentsQuery__
 *
 * To run a query within a React component, call `useGetOrderIntentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderIntentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderIntentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetOrderIntentsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetOrderIntentsDocument, options);
}
export function useGetOrderIntentsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetOrderIntentsDocument, options);
}
export const GetOrderIntentDocument = gql `
  query GetOrderIntent($id: ID!) {
    orderIntent(id: $id) {
      id
      createdAt
      createdBy {
        ...UserBasicInfoFragment
      }
      data
      parentOrderId
      status
      trace
    }
  }
  ${UserBasicInfoFragmentFragmentDoc}
`;
/**
 * __useGetOrderIntentQuery__
 *
 * To run a query within a React component, call `useGetOrderIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderIntentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderIntentQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetOrderIntentDocument, options);
}
export function useGetOrderIntentLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetOrderIntentDocument, options);
}
export const OrdersbydonorsDocument = gql `
  query ordersbydonors($orderId: ID) {
    order(id: $orderId) {
      donations {
        items {
          amount
          donor {
            createdAt
            firstName
            lastName
            email
            gender
            companyName
            id
          }
          donationAllocations {
            items {
              id
              transferedFrom {
                id
              }
              receivedBy {
                id
              }
              teamFundraising {
                name
                campaign {
                  id
                  name
                  vizslaOrganization {
                    id
                  }
                }
              }
              individualFundraising {
                attendee {
                  user {
                    firstName
                    lastName
                    createdAt
                  }
                  experience {
                    id
                    name
                    campaign {
                      id
                      name
                      vizslaOrganization {
                        id
                      }
                    }
                  }
                }
              }
              experience {
                id
                name
                campaign {
                  id
                  name
                  vizslaOrganization {
                    id
                  }
                }
              }
            }
          }
        }
      }
      registrationOptionFulfillment {
        items {
          attendee {
            id
            createdAt
            user {
              id
              firstName
              lastName
              gender
              companyName
              email
              createdAt
            }
          }
        }
      }
      user {
        firstName
        lastName
        email
        createdAt
      }
    }
  }
`;
/**
 * __useOrdersbydonorsQuery__
 *
 * To run a query within a React component, call `useOrdersbydonorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersbydonorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersbydonorsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrdersbydonorsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrdersbydonorsDocument, options);
}
export function useOrdersbydonorsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrdersbydonorsDocument, options);
}
export const GetAssetsByOrderDocument = gql `
  query getAssetsByOrder($id: ID) {
    order(id: $id) {
      assetsFulfillment {
        items {
          id
          amount
          storeInventoryItem {
            id
            storeAsset {
              name
              image {
                downloadUrl
              }
            }
            itemToSellWhenOutOfStock
            variantsValues
          }
        }
      }
      orderItems(filter: { description: { equals: "Asset" } }) {
        count
        items {
          id
          amount
          description
          comment
          orderItemOriginal {
            id
          }
          orderItemRefunded {
            id
          }
        }
      }
    }
  }
`;
/**
 * __useGetAssetsByOrderQuery__
 *
 * To run a query within a React component, call `useGetAssetsByOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsByOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsByOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetsByOrderQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAssetsByOrderDocument, options);
}
export function useGetAssetsByOrderLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAssetsByOrderDocument, options);
}
export const OrganizationCreateDocument = gql `
  mutation OrganizationCreate($data: JSON!) {
    organizationCreate(data: $data) {
      success
    }
  }
`;
/**
 * __useOrganizationCreateMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateMutation, { data, loading, error }] = useOrganizationCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrganizationCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(OrganizationCreateDocument, options);
}
export const VizslaOrganizationStaffDocument = gql `
  query VizslaOrganizationStaff(
    $id: ID!
    $filter: VizslaOrganizationStaffFilter
    $skip: Int
    $first: Int
  ) {
    vizslaOrganization(id: $id) {
      id
      vizslaOrganizationStaff(filter: $filter, skip: $skip, first: $first) {
        count
        items {
          id
          roles
          user {
            ...UserFragment
            createdAt
            updatedAt
            roles {
              items {
                id
                name
              }
            }
            avatar {
              id
              downloadUrl
            }
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
/**
 * __useVizslaOrganizationStaffQuery__
 *
 * To run a query within a React component, call `useVizslaOrganizationStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVizslaOrganizationStaffQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useVizslaOrganizationStaffQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(VizslaOrganizationStaffDocument, options);
}
export function useVizslaOrganizationStaffLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(VizslaOrganizationStaffDocument, options);
}
export const VizslaOrganizationUpdateNotificationSettingsDocument = gql `
  mutation VizslaOrganizationUpdateNotificationSettings(
    $id: ID!
    $connect: [NotificationSettingKeyFilter!]
    $disconnect: [NotificationSettingKeyFilter!]
  ) {
    vizslaOrganizationUpdate(
      data: { id: $id, notificationItems: { connect: $connect, disconnect: $disconnect } }
    ) {
      id
      name
      notificationItems {
        items {
          key
          id
        }
      }
    }
  }
`;
/**
 * __useVizslaOrganizationUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useVizslaOrganizationUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vizslaOrganizationUpdateNotificationSettingsMutation, { data, loading, error }] = useVizslaOrganizationUpdateNotificationSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      connect: // value for 'connect'
 *      disconnect: // value for 'disconnect'
 *   },
 * });
 */
export function useVizslaOrganizationUpdateNotificationSettingsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(VizslaOrganizationUpdateNotificationSettingsDocument, options);
}
export const VizslaOrganizationStaffListDocument = gql `
  query VizslaOrganizationStaffList($filter: VizslaOrganizationStaffFilter) {
    vizslaOrganizationStaffsList(filter: $filter) {
      count
      items {
        id
        user {
          id
          firstName
          lastName
          fullName
          email
          phone {
            number
          }
          hasAlreadyLoggedIn
        }
        vizslaOrganization {
          id
          name
        }
        roles
      }
    }
  }
`;
/**
 * __useVizslaOrganizationStaffListQuery__
 *
 * To run a query within a React component, call `useVizslaOrganizationStaffListQuery` and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationStaffListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVizslaOrganizationStaffListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVizslaOrganizationStaffListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(VizslaOrganizationStaffListDocument, options);
}
export function useVizslaOrganizationStaffListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(VizslaOrganizationStaffListDocument, options);
}
export const VizslaOrganizationCampaignsDocument = gql `
  query VizslaOrganizationCampaigns($id: ID!) {
    vizslaOrganization(id: $id) {
      id
      name
      campaigns {
        count
        items {
          id
          name
          experiences {
            count
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`;
/**
 * __useVizslaOrganizationCampaignsQuery__
 *
 * To run a query within a React component, call `useVizslaOrganizationCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVizslaOrganizationCampaignsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVizslaOrganizationCampaignsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(VizslaOrganizationCampaignsDocument, options);
}
export function useVizslaOrganizationCampaignsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(VizslaOrganizationCampaignsDocument, options);
}
export const CampaignExperienceDataActiveDocument = gql `
  query CampaignExperienceDataActive(
    $campaingFilter: CampaignFilter!
    $experienceFilter: ExperienceFilter!
  ) {
    campaignsList(filter: $campaingFilter) {
      count
      items {
        id
        name
        campaignStatus
        startDate
        endDate
        experiences(filter: $experienceFilter) {
          count
          items {
            id
            name
            startDate
            endDate
            campaign {
              id
              name
            }
            teams {
              count
              items {
                id
                name
                experience {
                  id
                  name
                  campaign {
                    id
                    name
                  }
                }
              }
            }
            attendees(filter: { fundraising: { id: { is_empty: false } } }) {
              count
              items {
                id
                fundraising {
                  id
                  title
                  attendee {
                    user {
                      id
                      email
                      fullName
                      firstName
                      lastName
                    }
                    experience {
                      id
                      name
                      campaign {
                        id
                        name
                      }
                    }
                  }
                }
                user {
                  id
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useCampaignExperienceDataActiveQuery__
 *
 * To run a query within a React component, call `useCampaignExperienceDataActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignExperienceDataActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignExperienceDataActiveQuery({
 *   variables: {
 *      campaingFilter: // value for 'campaingFilter'
 *      experienceFilter: // value for 'experienceFilter'
 *   },
 * });
 */
export function useCampaignExperienceDataActiveQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignExperienceDataActiveDocument, options);
}
export function useCampaignExperienceDataActiveLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignExperienceDataActiveDocument, options);
}
export const GetOrganizationIdOptionsDocument = gql `
  query getOrganizationIdOptions($filter: VizslaOrganizationFilter, $skip: Int, $first: Int) {
    vizslaOrganizationsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        name
      }
    }
  }
`;
/**
 * __useGetOrganizationIdOptionsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationIdOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationIdOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationIdOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetOrganizationIdOptionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetOrganizationIdOptionsDocument, options);
}
export function useGetOrganizationIdOptionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetOrganizationIdOptionsDocument, options);
}
export const VizslaOrganizationStatusDocument = gql `
  mutation VizslaOrganizationStatus($data: OrganizationActiveInput) {
    organizationActive(data: $data) {
      success
      message
    }
  }
`;
/**
 * __useVizslaOrganizationStatusMutation__
 *
 * To run a mutation, you first call `useVizslaOrganizationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vizslaOrganizationStatusMutation, { data, loading, error }] = useVizslaOrganizationStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVizslaOrganizationStatusMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(VizslaOrganizationStatusDocument, options);
}
export const StripeIntegrationUpdateByFilterDocument = gql `
  mutation StripeIntegrationUpdateByFilter(
    $filter: StripeIntegrationFilter
    $data: StripeIntegrationUpdateByFilterInput!
  ) {
    stripeIntegrationUpdateByFilter(filter: $filter, data: $data) {
      items {
        id
        status
        stripeAccountID
        organization {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useStripeIntegrationUpdateByFilterMutation__
 *
 * To run a mutation, you first call `useStripeIntegrationUpdateByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeIntegrationUpdateByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeIntegrationUpdateByFilterMutation, { data, loading, error }] = useStripeIntegrationUpdateByFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStripeIntegrationUpdateByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripeIntegrationUpdateByFilterDocument, options);
}
export const OrganizationMembersDocument = gql `
  query organizationMembers($filter: VizslaOrganizationStaffFilter!, $skip: Int, $first: Int) {
    vizslaOrganizationStaffsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        roles
        user {
          email
          status
          avatar {
            downloadUrl
          }
          firstName
          lastName
          phone {
            number
            code
          }
          hasAlreadyLoggedIn
          updatedAt
        }
      }
    }
  }
`;
/**
 * __useOrganizationMembersQuery__
 *
 * To run a query within a React component, call `useOrganizationMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMembersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationMembersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationMembersDocument, options);
}
export function useOrganizationMembersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationMembersDocument, options);
}
export const AddOrganizationMemberDocument = gql `
  mutation addOrganizationMember($data: JSON!) {
    addOrganizationMember(data: $data) {
      success
      message
    }
  }
`;
/**
 * __useAddOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useAddOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationMemberMutation, { data, loading, error }] = useAddOrganizationMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddOrganizationMemberMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AddOrganizationMemberDocument, options);
}
export const ResendTemporaryPasswordDocument = gql `
  mutation resendTemporaryPassword($data: JSON!) {
    resendTemporaryPassword(data: $data) {
      success
    }
  }
`;
/**
 * __useResendTemporaryPasswordMutation__
 *
 * To run a mutation, you first call `useResendTemporaryPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendTemporaryPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendTemporaryPasswordMutation, { data, loading, error }] = useResendTemporaryPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResendTemporaryPasswordMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ResendTemporaryPasswordDocument, options);
}
export const VizslaOrganizationStaffUpdateDocument = gql `
  mutation vizslaOrganizationStaffUpdate(
    $data: VizslaOrganizationStaffUpdateInput!
    $filter: VizslaOrganizationStaffKeyFilter
  ) {
    vizslaOrganizationStaffUpdate(data: $data, filter: $filter) {
      id
    }
  }
`;
/**
 * __useVizslaOrganizationStaffUpdateMutation__
 *
 * To run a mutation, you first call `useVizslaOrganizationStaffUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationStaffUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vizslaOrganizationStaffUpdateMutation, { data, loading, error }] = useVizslaOrganizationStaffUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVizslaOrganizationStaffUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(VizslaOrganizationStaffUpdateDocument, options);
}
export const VizslaOrganizationStaffDeleteDocument = gql `
  mutation vizslaOrganizationStaffDelete(
    $data: VizslaOrganizationStaffDeleteInput!
    $filter: VizslaOrganizationStaffKeyFilter
  ) {
    vizslaOrganizationStaffDelete(data: $data, filter: $filter) {
      success
    }
  }
`;
/**
 * __useVizslaOrganizationStaffDeleteMutation__
 *
 * To run a mutation, you first call `useVizslaOrganizationStaffDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationStaffDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vizslaOrganizationStaffDeleteMutation, { data, loading, error }] = useVizslaOrganizationStaffDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVizslaOrganizationStaffDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(VizslaOrganizationStaffDeleteDocument, options);
}
export const OrganizationMembersStatisticsQueryDocument = gql `
  query organizationMembersStatisticsQuery($organizationId: ID!, $lastMonth: DateTime) {
    totalUsers: vizslaOrganizationStaffsList(
      filter: { vizslaOrganization: { id: { equals: $organizationId } } }
    ) {
      count
    }
    totalNewUsers: vizslaOrganizationStaffsList(
      filter: {
        createdAt: { gte: $lastMonth }
        vizslaOrganization: { id: { equals: $organizationId } }
      }
    ) {
      count
    }
    totalPendingInvitations: vizslaOrganizationStaffsList(
      filter: {
        user: { hasAlreadyLoggedIn: { equals: false } }
        vizslaOrganization: { id: { equals: $organizationId } }
      }
    ) {
      count
    }
  }
`;
/**
 * __useOrganizationMembersStatisticsQueryQuery__
 *
 * To run a query within a React component, call `useOrganizationMembersStatisticsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMembersStatisticsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMembersStatisticsQueryQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      lastMonth: // value for 'lastMonth'
 *   },
 * });
 */
export function useOrganizationMembersStatisticsQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationMembersStatisticsQueryDocument, options);
}
export function useOrganizationMembersStatisticsQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationMembersStatisticsQueryDocument, options);
}
export const CreatePartnershipCommitmentDocument = gql `
  mutation CreatePartnershipCommitment($data: PartnershipCommitmentCreateInput!) {
    partnershipCommitmentCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useCreatePartnershipCommitmentMutation__
 *
 * To run a mutation, you first call `useCreatePartnershipCommitmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnershipCommitmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnershipCommitmentMutation, { data, loading, error }] = useCreatePartnershipCommitmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePartnershipCommitmentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreatePartnershipCommitmentDocument, options);
}
export const UpdatePartnershipCommitmentDocument = gql `
  mutation UpdatePartnershipCommitment(
    $data: PartnershipCommitmentUpdateInput!
    $filter: PartnershipCommitmentKeyFilter!
  ) {
    partnershipCommitmentUpdate(data: $data, filter: $filter) {
      id
    }
  }
`;
/**
 * __useUpdatePartnershipCommitmentMutation__
 *
 * To run a mutation, you first call `useUpdatePartnershipCommitmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnershipCommitmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnershipCommitmentMutation, { data, loading, error }] = useUpdatePartnershipCommitmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdatePartnershipCommitmentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdatePartnershipCommitmentDocument, options);
}
export const ListPartnershipCommitmentsDocument = gql `
  query ListPartnershipCommitments($partnershipOrganizationId: String!) {
    partnershipCommitmentsList(
      filter: { partnershipOrganizationId: { equals: $partnershipOrganizationId } }
    ) {
      items {
        id
        amount
        receivedAmount
        usedAmount
        type
        status
        startDate
        endDate
        decisionDate
        serviceProvided
        notes
        matchLimit
        agreement {
          downloadUrl
          downloadStorageUrl
          previewUrl
          filename
        }
        campaign {
          name
          id
        }
        experience {
          name
          id
          campaign {
            id
          }
        }
        partnershipOrganization {
          id
          name
          organization {
            id
          }
        }
        description
      }
      count
    }
  }
`;
/**
 * __useListPartnershipCommitmentsQuery__
 *
 * To run a query within a React component, call `useListPartnershipCommitmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPartnershipCommitmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPartnershipCommitmentsQuery({
 *   variables: {
 *      partnershipOrganizationId: // value for 'partnershipOrganizationId'
 *   },
 * });
 */
export function useListPartnershipCommitmentsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ListPartnershipCommitmentsDocument, options);
}
export function useListPartnershipCommitmentsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ListPartnershipCommitmentsDocument, options);
}
export const DeletePartnershipCommitmentDocument = gql `
  mutation DeletePartnershipCommitment($data: PartnershipCommitmentDeleteInput!) {
    partnershipCommitmentDelete(data: $data) {
      success
    }
  }
`;
/**
 * __useDeletePartnershipCommitmentMutation__
 *
 * To run a mutation, you first call `useDeletePartnershipCommitmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnershipCommitmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnershipCommitmentMutation, { data, loading, error }] = useDeletePartnershipCommitmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeletePartnershipCommitmentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeletePartnershipCommitmentDocument, options);
}
export const CreatePartnershipContactDocument = gql `
  mutation CreatePartnershipContact($data: PartnershipContactCreateInput!) {
    partnershipContactCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useCreatePartnershipContactMutation__
 *
 * To run a mutation, you first call `useCreatePartnershipContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnershipContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnershipContactMutation, { data, loading, error }] = useCreatePartnershipContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePartnershipContactMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreatePartnershipContactDocument, options);
}
export const UpdatePartnershipContactDocument = gql `
  mutation UpdatePartnershipContact($data: PartnershipContactUpdateInput!) {
    partnershipContactUpdate(data: $data) {
      id
    }
  }
`;
/**
 * __useUpdatePartnershipContactMutation__
 *
 * To run a mutation, you first call `useUpdatePartnershipContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnershipContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnershipContactMutation, { data, loading, error }] = useUpdatePartnershipContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePartnershipContactMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdatePartnershipContactDocument, options);
}
export const ListPartnershipContactsDocument = gql `
  query ListPartnershipContacts($partnershipOrganizationId: String!) {
    partnershipContactsList(
      filter: { partnershipOrganizationId: { equals: $partnershipOrganizationId } }
    ) {
      items {
        id
        title
        lastName
        firstName
        email
        phone
        notes
        partnershipOrganizationId
      }
      count
    }
  }
`;
/**
 * __useListPartnershipContactsQuery__
 *
 * To run a query within a React component, call `useListPartnershipContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPartnershipContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPartnershipContactsQuery({
 *   variables: {
 *      partnershipOrganizationId: // value for 'partnershipOrganizationId'
 *   },
 * });
 */
export function useListPartnershipContactsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ListPartnershipContactsDocument, options);
}
export function useListPartnershipContactsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ListPartnershipContactsDocument, options);
}
export const DeletePartnershipContactDocument = gql `
  mutation DeletePartnershipContact($data: PartnershipContactDeleteInput!) {
    partnershipContactDelete(data: $data) {
      success
    }
  }
`;
/**
 * __useDeletePartnershipContactMutation__
 *
 * To run a mutation, you first call `useDeletePartnershipContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnershipContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnershipContactMutation, { data, loading, error }] = useDeletePartnershipContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeletePartnershipContactMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeletePartnershipContactDocument, options);
}
export const PartnershipOrganizationsListDocument = gql `
  query PartnershipOrganizationsList($filter: PartnershipOrganizationFilter) {
    partnershipOrganizationsList(filter: $filter) {
      items {
        id
        name
        email
        type
        avatar {
          id
          downloadUrl
        }
        organization {
          id
        }
        donations {
          items {
            id
            amount
          }
        }
        partnershipCommitments {
          count
          items {
            id
            amount
          }
        }
      }
    }
  }
`;
/**
 * __usePartnershipOrganizationsListQuery__
 *
 * To run a query within a React component, call `usePartnershipOrganizationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnershipOrganizationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnershipOrganizationsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePartnershipOrganizationsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PartnershipOrganizationsListDocument, options);
}
export function usePartnershipOrganizationsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PartnershipOrganizationsListDocument, options);
}
export const PartnershipDashboardDataDocument = gql `
  query PartnershipDashboardData($id: ID!) {
    inKind: partnershipCommitmentsList(
      filter: { type: { equals: "inKind" }, partnershipOrganization: { id: { equals: $id } } }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    matching: partnershipCommitmentsList(
      filter: { type: { equals: "matching" }, partnershipOrganization: { id: { equals: $id } } }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    cash: partnershipCommitmentsList(
      filter: { type: { equals: "cash" }, partnershipOrganization: { id: { equals: $id } } }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    total: partnershipCommitmentsList(
      filter: { partnershipOrganization: { id: { equals: $id } } }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
      count
    }
  }
`;
/**
 * __usePartnershipDashboardDataQuery__
 *
 * To run a query within a React component, call `usePartnershipDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnershipDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnershipDashboardDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePartnershipDashboardDataQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PartnershipDashboardDataDocument, options);
}
export function usePartnershipDashboardDataLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PartnershipDashboardDataDocument, options);
}
export const OrganizationPartnershipsDashboardDataDocument = gql `
  query OrganizationPartnershipsDashboardData($id: ID!) {
    inKind: partnershipCommitmentsList(
      filter: {
        type: { equals: "inKind" }
        partnershipOrganization: { organization: { id: { equals: $id } } }
      }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    matching: partnershipCommitmentsList(
      filter: {
        type: { equals: "matching" }
        partnershipOrganization: { organization: { id: { equals: $id } } }
      }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    cash: partnershipCommitmentsList(
      filter: {
        type: { equals: "cash" }
        partnershipOrganization: { organization: { id: { equals: $id } } }
      }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    total: partnershipCommitmentsList(
      filter: { partnershipOrganization: { organization: { id: { equals: $id } } } }
      groupBy: { query: { amount: { as: "sum", fn: { aggregate: SUM } } } }
    ) {
      groups {
        sum: Float
      }
    }
    partnershipsCount: partnershipOrganizationsList(
      filter: { organization: { id: { equals: $id } } }
    ) {
      count
    }
  }
`;
/**
 * __useOrganizationPartnershipsDashboardDataQuery__
 *
 * To run a query within a React component, call `useOrganizationPartnershipsDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPartnershipsDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPartnershipsDashboardDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationPartnershipsDashboardDataQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationPartnershipsDashboardDataDocument, options);
}
export function useOrganizationPartnershipsDashboardDataLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationPartnershipsDashboardDataDocument, options);
}
export const CreatePartnershipDocument = gql `
  mutation CreatePartnership($data: PartnershipOrganizationCreateInput!) {
    partnershipOrganizationCreate(data: $data) {
      id
      name
      email
    }
  }
`;
/**
 * __useCreatePartnershipMutation__
 *
 * To run a mutation, you first call `useCreatePartnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnershipMutation, { data, loading, error }] = useCreatePartnershipMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePartnershipMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreatePartnershipDocument, options);
}
export const PartnershipsQueryDocument = gql `
  query PartnershipsQuery($first: Int, $skip: Int, $filter: PartnershipOrganizationFilter) {
    partnershipOrganizationsList(first: $first, skip: $skip, filter: $filter) {
      count
      items {
        id
        name
        type
        email
        organization {
          name
          id
        }
        avatar {
          id
          downloadUrl
        }
        donations {
          items {
            amount
          }
        }
      }
    }
  }
`;
/**
 * __usePartnershipsQueryQuery__
 *
 * To run a query within a React component, call `usePartnershipsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnershipsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnershipsQueryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePartnershipsQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PartnershipsQueryDocument, options);
}
export function usePartnershipsQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PartnershipsQueryDocument, options);
}
export const OrganizationStatisticsDocument = gql `
  query OrganizationStatistics($organizationId: ID!) {
    attendeesTotal: usersList(
      filter: {
        attendees: {
          some: {
            experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
          }
        }
      }
    ) {
      count
    }
    donorsCount: usersList(
      filter: {
        allocatedDonations: {
          some: {
            experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
          }
        }
      }
    ) {
      count
    }
    fundraisersCount: usersList(
      filter: {
        attendees: {
          some: {
            AND: [
              {
                experience: {
                  campaign: { vizslaOrganization: { id: { equals: $organizationId } } }
                }
              }
              { fundraising: { id: { is_not_empty: true } } }
            ]
          }
        }
      }
    ) {
      count
    }
    teamsCount: teamFundraisingsList(
      filter: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
    ) {
      count
    }
    totalRecurringDonorsCount: donationSchedulesList(
      groupBy: {
        query: { user: { id: { as: "count", fn: { aggregate: COUNT, distinct: true } } } }
      }
      filter: {
        experience: {
          some: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
        }
      }
    ) {
      groups {
        count: Int
      }
    }
  }
`;
/**
 * __useOrganizationStatisticsQuery__
 *
 * To run a query within a React component, call `useOrganizationStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStatisticsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationStatisticsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationStatisticsDocument, options);
}
export function useOrganizationStatisticsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationStatisticsDocument, options);
}
export const OrganizationFilteredUsersListDocument = gql `
  query OrganizationFilteredUsersList($filter: UserFilter, $skip: Int, $first: Int) {
    usersList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...BaseUserFragment
      }
    }
  }
  ${BaseUserFragmentFragmentDoc}
`;
/**
 * __useOrganizationFilteredUsersListQuery__
 *
 * To run a query within a React component, call `useOrganizationFilteredUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationFilteredUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationFilteredUsersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationFilteredUsersListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationFilteredUsersListDocument, options);
}
export function useOrganizationFilteredUsersListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationFilteredUsersListDocument, options);
}
export const OrganizationFilteredDonorsListDocument = gql `
  query OrganizationFilteredDonorsList($filter: UserFilter, $skip: Int, $first: Int) {
    usersList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        firstName
        lastName
        avatar {
          id
          downloadUrl
        }
        attendees {
          items {
            id
            teamMembership {
              team {
                id
                name
              }
            }
            totalRaised
          }
        }
        donors {
          items {
            id
            campaign {
              id
            }
          }
        }
      }
    }
  }
`;
/**
 * __useOrganizationFilteredDonorsListQuery__
 *
 * To run a query within a React component, call `useOrganizationFilteredDonorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationFilteredDonorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationFilteredDonorsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationFilteredDonorsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationFilteredDonorsListDocument, options);
}
export function useOrganizationFilteredDonorsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationFilteredDonorsListDocument, options);
}
export const OrganizationTeamsListDocument = gql `
  query OrganizationTeamsList($filter: TeamFundraisingFilter, $skip: Int, $first: Int) {
    teamFundraisingsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        name
        avatar {
          id
          downloadUrl
        }
        captain: members(filter: { role: { equals: "Team Captain" } }, first: 1) {
          items {
            id
            attendee {
              id
              user {
                id
                firstName
                lastName
                email
                birthDate
                gender
                createdAt
                phone {
                  code
                  number
                }
                address {
                  city
                  state
                  country
                  zip
                  street1
                  street2
                }
                avatar {
                  downloadUrl
                }
              }
            }
          }
          count
        }
        membersCount: members {
          count
        }
        totalDonations: allocatedDonations(
          groupBy: { query: { amount: { as: "count", fn: { aggregate: SUM, distinct: true } } } }
        ) {
          groups {
            count: Int
          }
        }
        totalRaised
        createdAt
        webLink
      }
    }
  }
`;
/**
 * __useOrganizationTeamsListQuery__
 *
 * To run a query within a React component, call `useOrganizationTeamsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTeamsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTeamsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationTeamsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationTeamsListDocument, options);
}
export function useOrganizationTeamsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationTeamsListDocument, options);
}
export const UserContactInfoDocument = gql `
  query UserContactInfo($userId: ID!, $organizationId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      createdAt
      status
      vizslaOrganizationStaff {
        items {
          id
          roles
        }
      }
      allocatedDonations(
        filter: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
      ) {
        count
        items {
          amount
          campaign {
            name
            vizslaOrganization {
              id
            }
          }
        }
      }
      avatar {
        id
        downloadUrl
      }
      campaignStaff {
        items {
          campaign {
            id
            campaignStatus
          }
        }
      }
      attendees(
        filter: {
          experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
        }
        groupBy: {
          query: {
            fundraising: {
              allocatedDonations: {
                amount: { as: "totalRaised", fn: { aggregate: SUM, distinct: true } }
              }
            }
          }
        }
      ) {
        groups {
          totalRaised: Float
        }
        items {
          id
          fundraising {
            id
            title
          }
          experience {
            campaign {
              id
              campaignStatus
              allocatedDonations {
                count
              }
            }
          }
        }
      }
      roles {
        items {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useUserContactInfoQuery__
 *
 * To run a query within a React component, call `useUserContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContactInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUserContactInfoQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserContactInfoDocument, options);
}
export function useUserContactInfoLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserContactInfoDocument, options);
}
export const UserTeamsInfoDocument = gql `
  query UserTeamsInfo(
    $first: Int
    $skip: Int
    $search: String
    $userId: ID!
    $organizationId: ID!
  ) {
    teamFundraisingsList(
      filter: {
        OR: [
          { campaign: { name: { contains: $search } } }
          { experience: { name: { contains: $search } } }
          { members: { some: { attendee: { user: { fullName: { contains: $search } } } } } }
          { name: { contains: $search } }
        ]
        members: { some: { attendee: { user: { id: { equals: $userId } } } } }
        campaign: { vizslaOrganization: { id: { equals: $organizationId } } }
      }
      orderBy: createdAt_DESC
      first: $first
      skip: $skip
    ) {
      items {
        id
        avatar {
          downloadUrl
          fileId
          __typename
        }
        name
        avatarName: members(
          filter: { attendee: { user: { id: { equals: $userId } } } }
          groupBy: {
            query: {
              team: {
                name: { as: "teamName" }
                avatar: { downloadUrl: { as: "teamAvatarDownloadUrl" } }
              }
            }
          }
        ) {
          groups {
            teamName: String
            teamAvatarDownloadUrl: String
            __typename
          }
        }
        selfMember: members(filter: { attendee: { user: { id: { equals: $userId } } } }) {
          count
          items {
            id
            createdAt
            role
            __typename
          }
          __typename
        }
        members {
          count
          __typename
        }
        captain: members(
          groupBy: {
            query: { attendee: { user: { id: { as: "captainId" }, fullName: { as: "fullName" } } } }
          }
          filter: { role: { equals: "Team Captain" } }
        ) {
          groups {
            captainId: String
            fullName: String
            __typename
          }
          __typename
        }
        campaign {
          name
          __typename
        }
        experience {
          name
          __typename
        }
        fundraiserGoal
        __typename
      }
      count
      __typename
    }
  }
`;
/**
 * __useUserTeamsInfoQuery__
 *
 * To run a query within a React component, call `useUserTeamsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTeamsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTeamsInfoQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      search: // value for 'search'
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUserTeamsInfoQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserTeamsInfoDocument, options);
}
export function useUserTeamsInfoLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserTeamsInfoDocument, options);
}
export const UserContactProfileInfoDocument = gql `
  query UserContactProfileInfo($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      email
      gender
      phone {
        code
        number
      }
      birthDate
      address {
        city
        country
        state
        street1
        street2
        zip
      }
    }
  }
`;
/**
 * __useUserContactProfileInfoQuery__
 *
 * To run a query within a React component, call `useUserContactProfileInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContactProfileInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContactProfileInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserContactProfileInfoQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserContactProfileInfoDocument, options);
}
export function useUserContactProfileInfoLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserContactProfileInfoDocument, options);
}
export const CrmContactUpdateDocument = gql `
  mutation CRMContactUpdate($data: CRMContactUpdateInput!) {
    cRMContactUpdate(data: $data) {
      id
    }
  }
`;
/**
 * __useCrmContactUpdateMutation__
 *
 * To run a mutation, you first call `useCrmContactUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmContactUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmContactUpdateMutation, { data, loading, error }] = useCrmContactUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCrmContactUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CrmContactUpdateDocument, options);
}
export const CrmContactCreateDocument = gql `
  mutation CRMContactCreate($data: CRMContactCreateInput!) {
    cRMContactCreate(data: $data) {
      createdAt
    }
  }
`;
/**
 * __useCrmContactCreateMutation__
 *
 * To run a mutation, you first call `useCrmContactCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmContactCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmContactCreateMutation, { data, loading, error }] = useCrmContactCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCrmContactCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CrmContactCreateDocument, options);
}
export const CrmContactsListDocument = gql `
  query CRMContactsList($skip: Int, $first: Int, $filter: CRMContactFilter) {
    cRMContactsList(skip: $skip, first: $first, filter: $filter) {
      items {
        id
        firstName
        lastName
        middleName
        email
        phone1 {
          code
          number
        }
        phone2 {
          code
          number
        }
        note
        address {
          street1
          street2
          city
          state
          country
          zip
        }
      }
      count
    }
  }
`;
/**
 * __useCrmContactsListQuery__
 *
 * To run a query within a React component, call `useCrmContactsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmContactsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmContactsListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCrmContactsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CrmContactsListDocument, options);
}
export function useCrmContactsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CrmContactsListDocument, options);
}
export const CrmContactCreateManyDocument = gql `
  mutation CRMContactCreateMany($data: [CRMContactCreateManyInput]!) {
    cRMContactCreateMany(data: $data) {
      items {
        id
      }
    }
  }
`;
/**
 * __useCrmContactCreateManyMutation__
 *
 * To run a mutation, you first call `useCrmContactCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmContactCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmContactCreateManyMutation, { data, loading, error }] = useCrmContactCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCrmContactCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CrmContactCreateManyDocument, options);
}
export const ImportUsersFromCsvDocument = gql `
  mutation ImportUsersFromCSV($data: [JSON!]!) {
    importUsersFromCSV(data: $data) {
      success
      data {
        row
        message
      }
    }
  }
`;
/**
 * __useImportUsersFromCsvMutation__
 *
 * To run a mutation, you first call `useImportUsersFromCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUsersFromCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUsersFromCsvMutation, { data, loading, error }] = useImportUsersFromCsvMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportUsersFromCsvMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ImportUsersFromCsvDocument, options);
}
export const RegistrationPricingTierCreateManyDocument = gql `
  mutation RegistrationPricingTierCreateMany($data: [RegistrationPricingTierCreateManyInput]!) {
    registrationPricingTierCreateMany(data: $data) {
      count
    }
  }
`;
/**
 * __useRegistrationPricingTierCreateManyMutation__
 *
 * To run a mutation, you first call `useRegistrationPricingTierCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationPricingTierCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationPricingTierCreateManyMutation, { data, loading, error }] = useRegistrationPricingTierCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationPricingTierCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationPricingTierCreateManyDocument, options);
}
export const RegistrationPricingTierDeleteByFilterDocument = gql `
  mutation RegistrationPricingTierDeleteByFilter($filter: RegistrationPricingTierFilter!) {
    registrationPricingTierDeleteByFilter(filter: $filter) {
      success
    }
  }
`;
/**
 * __useRegistrationPricingTierDeleteByFilterMutation__
 *
 * To run a mutation, you first call `useRegistrationPricingTierDeleteByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationPricingTierDeleteByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationPricingTierDeleteByFilterMutation, { data, loading, error }] = useRegistrationPricingTierDeleteByFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationPricingTierDeleteByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationPricingTierDeleteByFilterDocument, options);
}
export const TicketingPricingTierCreateManyDocument = gql `
  mutation TicketingPricingTierCreateMany($data: [TicketingPricingTierCreateManyInput]!) {
    ticketingPricingTierCreateMany(data: $data) {
      count
    }
  }
`;
/**
 * __useTicketingPricingTierCreateManyMutation__
 *
 * To run a mutation, you first call `useTicketingPricingTierCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingPricingTierCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingPricingTierCreateManyMutation, { data, loading, error }] = useTicketingPricingTierCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTicketingPricingTierCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TicketingPricingTierCreateManyDocument, options);
}
export const TicketingPricingTierDeleteByFilterDocument = gql `
  mutation TicketingPricingTierDeleteByFilter($filter: TicketingPricingTierFilter!) {
    ticketingPricingTierDeleteByFilter(filter: $filter) {
      success
    }
  }
`;
/**
 * __useTicketingPricingTierDeleteByFilterMutation__
 *
 * To run a mutation, you first call `useTicketingPricingTierDeleteByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingPricingTierDeleteByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingPricingTierDeleteByFilterMutation, { data, loading, error }] = useTicketingPricingTierDeleteByFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTicketingPricingTierDeleteByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TicketingPricingTierDeleteByFilterDocument, options);
}
export const ProgramsListDocument = gql `
  query ProgramsList($filter: ProgramFilter) {
    programsList(filter: $filter) {
      items {
        ...ProgramFragment
      }
    }
  }
  ${ProgramFragmentFragmentDoc}
`;
/**
 * __useProgramsListQuery__
 *
 * To run a query within a React component, call `useProgramsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProgramsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProgramsListDocument, options);
}
export function useProgramsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProgramsListDocument, options);
}
export const ProgramsListQueryDocument = gql `
  query ProgramsListQuery($filter: ProgramFilter, $CampaignFilter: CampaignFilter) {
    programsList(filter: $filter) {
      items {
        ...ProgramFragment
        campaigns(filter: $CampaignFilter) {
          items {
            ...CampaignsPageCampaignFragment
          }
        }
      }
    }
  }
  ${ProgramFragmentFragmentDoc}
  ${CampaignsPageCampaignFragmentFragmentDoc}
`;
/**
 * __useProgramsListQueryQuery__
 *
 * To run a query within a React component, call `useProgramsListQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsListQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsListQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      CampaignFilter: // value for 'CampaignFilter'
 *   },
 * });
 */
export function useProgramsListQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProgramsListQueryDocument, options);
}
export function useProgramsListQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProgramsListQueryDocument, options);
}
export const ProgramCreateDocument = gql `
  mutation ProgramCreate($data: ProgramCreateInput!) {
    programCreate(data: $data) {
      ...ProgramFragment
    }
  }
  ${ProgramFragmentFragmentDoc}
`;
/**
 * __useProgramCreateMutation__
 *
 * To run a mutation, you first call `useProgramCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProgramCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [programCreateMutation, { data, loading, error }] = useProgramCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProgramCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProgramCreateDocument, options);
}
export const UpdateProgramNameDescriptionDocument = gql `
  mutation UpdateProgramNameDescription($data: ProgramUpdateInput!, $settingsId: ID!) {
    programUpdate(data: $data, filter: { id: $settingsId }) {
      id
    }
  }
`;
/**
 * __useUpdateProgramNameDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateProgramNameDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramNameDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramNameDescriptionMutation, { data, loading, error }] = useUpdateProgramNameDescriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      settingsId: // value for 'settingsId'
 *   },
 * });
 */
export function useUpdateProgramNameDescriptionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateProgramNameDescriptionDocument, options);
}
export const RegistrationOptionDocument = gql `
  query RegistrationOption($id: ID!) {
    registrationOption(id: $id) {
      ...RegistrationOptionFragment
    }
  }
  ${RegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegistrationOptionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionDocument, options);
}
export function useRegistrationOptionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionDocument, options);
}
export const RegistrationOptionUpdateDocument = gql `
  mutation RegistrationOptionUpdate($data: RegistrationOptionUpdateInput!) {
    registrationOptionUpdate(data: $data) {
      ...RegistrationOptionFragment
    }
  }
  ${RegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionUpdateMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionUpdateMutation, { data, loading, error }] = useRegistrationOptionUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationOptionUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionUpdateDocument, options);
}
export const RegistrationOptionsListDocument = gql `
  query RegistrationOptionsList($filter: RegistrationOptionFilter) {
    registrationOptionsList(filter: $filter) {
      count
      items {
        ...RegistrationOptionFragment
      }
    }
  }
  ${RegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionsListQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationOptionsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionsListDocument, options);
}
export function useRegistrationOptionsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionsListDocument, options);
}
export const RegistrationOptionDeleteDocument = gql `
  mutation RegistrationOptionDelete($id: ID!) {
    registrationOptionDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;
/**
 * __useRegistrationOptionDeleteMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionDeleteMutation, { data, loading, error }] = useRegistrationOptionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegistrationOptionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionDeleteDocument, options);
}
export const RegistrationOptionCreateDocument = gql `
  mutation RegistrationOptionCreate($data: RegistrationOptionCreateInput!) {
    registrationOptionCreate(data: $data) {
      ...RegistrationOptionFragment
    }
  }
  ${RegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionCreateMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionCreateMutation, { data, loading, error }] = useRegistrationOptionCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationOptionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionCreateDocument, options);
}
export const RegistrationOptionCreateManyDocument = gql `
  mutation RegistrationOptionCreateMany($data: [RegistrationOptionCreateManyInput]!) {
    registrationOptionCreateMany(data: $data) {
      items {
        ...RegistrationOptionFragment
      }
    }
  }
  ${RegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionCreateManyMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionCreateManyMutation, { data, loading, error }] = useRegistrationOptionCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationOptionCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionCreateManyDocument, options);
}
export const RegistrationOptionSwagbagCreateDocument = gql `
  mutation registrationOptionSwagbagCreate($data: RegistrationOptionSwagbagCreateInput!) {
    registrationOptionSwagbagCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useRegistrationOptionSwagbagCreateMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionSwagbagCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionSwagbagCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionSwagbagCreateMutation, { data, loading, error }] = useRegistrationOptionSwagbagCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationOptionSwagbagCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionSwagbagCreateDocument, options);
}
export const RegistrationOptionSwagbagCreateManyDocument = gql `
  mutation registrationOptionSwagbagCreateMany(
    $data: [RegistrationOptionSwagbagCreateManyInput!]!
  ) {
    registrationOptionSwagbagCreateMany(data: $data) {
      items {
        id
      }
      count
    }
  }
`;
/**
 * __useRegistrationOptionSwagbagCreateManyMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionSwagbagCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionSwagbagCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionSwagbagCreateManyMutation, { data, loading, error }] = useRegistrationOptionSwagbagCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationOptionSwagbagCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionSwagbagCreateManyDocument, options);
}
export const RegistrationOptionSwagbagListDocument = gql `
  query registrationOptionSwagbagList($filter: RegistrationOptionSwagbagFilter!) {
    registrationOptionSwagbagsList(filter: $filter) {
      items {
        id
        storeAsset {
          ...StoreAssetFragment
        }
      }
    }
  }
  ${StoreAssetFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionSwagbagListQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionSwagbagListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionSwagbagListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionSwagbagListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationOptionSwagbagListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionSwagbagListDocument, options);
}
export function useRegistrationOptionSwagbagListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionSwagbagListDocument, options);
}
export const RegistrationOptionSwagbagDeleteDocument = gql `
  mutation registrationOptionSwagbagDelete($filter: RegistrationOptionSwagbagKeyFilter!) {
    registrationOptionSwagbagDelete(filter: $filter, force: true) {
      success
    }
  }
`;
/**
 * __useRegistrationOptionSwagbagDeleteMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionSwagbagDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionSwagbagDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionSwagbagDeleteMutation, { data, loading, error }] = useRegistrationOptionSwagbagDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationOptionSwagbagDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionSwagbagDeleteDocument, options);
}
export const RegistrationOptionSwagbagListInventoryDocument = gql `
  query registrationOptionSwagbagListInventory($filter: GetAllAssetInventoryFilter!) {
    getAllAssetInventoriesList(filter: $filter) {
      items {
        id
        aviable
        swagbagAvailability
      }
    }
  }
`;
/**
 * __useRegistrationOptionSwagbagListInventoryQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionSwagbagListInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionSwagbagListInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionSwagbagListInventoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationOptionSwagbagListInventoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionSwagbagListInventoryDocument, options);
}
export function useRegistrationOptionSwagbagListInventoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionSwagbagListInventoryDocument, options);
}
export const StoreAssetDocument = gql `
  query StoreAsset($id: ID!) {
    storeAsset(id: $id) {
      ...StoreAssetFragment
    }
  }
  ${StoreAssetFragmentFragmentDoc}
`;
/**
 * __useStoreAssetQuery__
 *
 * To run a query within a React component, call `useStoreAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreAssetQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StoreAssetDocument, options);
}
export function useStoreAssetLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StoreAssetDocument, options);
}
export const StoreAssetsDocument = gql `
  query StoreAssets($filter: StoreAssetFilter, $skip: Int, $first: Int) {
    storeAssetsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...StoreAssetSimpleFragment
      }
    }
  }
  ${StoreAssetSimpleFragmentFragmentDoc}
`;
/**
 * __useStoreAssetsQuery__
 *
 * To run a query within a React component, call `useStoreAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreAssetsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useStoreAssetsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StoreAssetsDocument, options);
}
export function useStoreAssetsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StoreAssetsDocument, options);
}
export const StoreAssetCreateDocument = gql `
  mutation StoreAssetCreate($data: StoreAssetCreateInput!) {
    storeAssetCreate(data: $data) {
      ...StoreAssetSimpleFragment
    }
  }
  ${StoreAssetSimpleFragmentFragmentDoc}
`;
/**
 * __useStoreAssetCreateMutation__
 *
 * To run a mutation, you first call `useStoreAssetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreAssetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeAssetCreateMutation, { data, loading, error }] = useStoreAssetCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreAssetCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StoreAssetCreateDocument, options);
}
export const StoreAssetUpdateDocument = gql `
  mutation StoreAssetUpdate($id: ID!, $data: StoreAssetUpdateInput!) {
    storeAssetUpdate(data: $data, filter: { id: $id }) {
      ...StoreAssetFragment
    }
  }
  ${StoreAssetFragmentFragmentDoc}
`;
/**
 * __useStoreAssetUpdateMutation__
 *
 * To run a mutation, you first call `useStoreAssetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreAssetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeAssetUpdateMutation, { data, loading, error }] = useStoreAssetUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreAssetUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StoreAssetUpdateDocument, options);
}
export const StoreHistoryEventDocument = gql `
  query StoreHistoryEvent($id: ID!) {
    storeHistoryEvent(id: $id) {
      ...StoreHistoryEventFragment
    }
  }
  ${StoreHistoryEventFragmentFragmentDoc}
`;
/**
 * __useStoreHistoryEventQuery__
 *
 * To run a query within a React component, call `useStoreHistoryEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreHistoryEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreHistoryEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreHistoryEventQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StoreHistoryEventDocument, options);
}
export function useStoreHistoryEventLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StoreHistoryEventDocument, options);
}
export const StoreHistoryEventsListDocument = gql `
  query StoreHistoryEventsList($filter: StoreHistoryEventFilter, $skip: Int, $first: Int) {
    storeHistoryEventsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...StoreHistoryEventFragment
      }
    }
  }
  ${StoreHistoryEventFragmentFragmentDoc}
`;
/**
 * __useStoreHistoryEventsListQuery__
 *
 * To run a query within a React component, call `useStoreHistoryEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreHistoryEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreHistoryEventsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useStoreHistoryEventsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StoreHistoryEventsListDocument, options);
}
export function useStoreHistoryEventsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StoreHistoryEventsListDocument, options);
}
export const StoreHistoryEventCreateWithRecalcDocument = gql `
  mutation StoreHistoryEventCreateWithRecalc($data: StoreHistoryEventCreateWithRecalcInput!) {
    storeHistoryEventCreateWithRecalc(input: $data) {
      storeHistoryEvent {
        id
      }
    }
  }
`;
/**
 * __useStoreHistoryEventCreateWithRecalcMutation__
 *
 * To run a mutation, you first call `useStoreHistoryEventCreateWithRecalcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreHistoryEventCreateWithRecalcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeHistoryEventCreateWithRecalcMutation, { data, loading, error }] = useStoreHistoryEventCreateWithRecalcMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreHistoryEventCreateWithRecalcMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StoreHistoryEventCreateWithRecalcDocument, options);
}
export const ExperienceStoreAssetsListDocument = gql `
  query ExperienceStoreAssetsList($filter: ExperienceStoreAssetFilter, $skip: Int, $first: Int) {
    experienceStoreAssetsList(
      filter: $filter
      sort: { createdAt: DESC }
      skip: $skip
      first: $first
    ) {
      count
      items {
        ...ExperienceStoreAssetFragment
      }
    }
  }
  ${ExperienceStoreAssetFragmentFragmentDoc}
`;
/**
 * __useExperienceStoreAssetsListQuery__
 *
 * To run a query within a React component, call `useExperienceStoreAssetsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreAssetsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceStoreAssetsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useExperienceStoreAssetsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceStoreAssetsListDocument, options);
}
export function useExperienceStoreAssetsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceStoreAssetsListDocument, options);
}
export const ExperienceStoreSettingsDocument = gql `
  query ExperienceStoreSettings($id: ID!) {
    experience(id: $id) {
      ...ExperienceStoreSettingsFragment
    }
  }
  ${ExperienceStoreSettingsFragmentFragmentDoc}
`;
/**
 * __useExperienceStoreSettingsQuery__
 *
 * To run a query within a React component, call `useExperienceStoreSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceStoreSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceStoreSettingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceStoreSettingsDocument, options);
}
export function useExperienceStoreSettingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceStoreSettingsDocument, options);
}
export const ExperienceStoreAssetCreateDocument = gql `
  mutation ExperienceStoreAssetCreate($data: ExperienceStoreAssetCreateInput!) {
    experienceStoreAssetCreate(data: $data) {
      ...ExperienceStoreAssetFragment
    }
  }
  ${ExperienceStoreAssetFragmentFragmentDoc}
`;
/**
 * __useExperienceStoreAssetCreateMutation__
 *
 * To run a mutation, you first call `useExperienceStoreAssetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreAssetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceStoreAssetCreateMutation, { data, loading, error }] = useExperienceStoreAssetCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceStoreAssetCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceStoreAssetCreateDocument, options);
}
export const ExperienceStoreAssetUpdateDocument = gql `
  mutation ExperienceStoreAssetUpdate($id: ID!, $data: ExperienceStoreAssetUpdateInput!) {
    experienceStoreAssetUpdate(filter: { id: $id }, data: $data) {
      ...ExperienceStoreAssetFragment
    }
  }
  ${ExperienceStoreAssetFragmentFragmentDoc}
`;
/**
 * __useExperienceStoreAssetUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceStoreAssetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreAssetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceStoreAssetUpdateMutation, { data, loading, error }] = useExperienceStoreAssetUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceStoreAssetUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceStoreAssetUpdateDocument, options);
}
export const ExperienceStoreAssetDeleteDocument = gql `
  mutation ExperienceStoreAssetDelete($filter: ExperienceStoreAssetKeyFilter) {
    experienceStoreAssetDelete(filter: $filter) {
      success
    }
  }
`;
/**
 * __useExperienceStoreAssetDeleteMutation__
 *
 * To run a mutation, you first call `useExperienceStoreAssetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreAssetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceStoreAssetDeleteMutation, { data, loading, error }] = useExperienceStoreAssetDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceStoreAssetDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceStoreAssetDeleteDocument, options);
}
export const ExperienceStoreSettingsUpdateDocument = gql `
  mutation ExperienceStoreSettingsUpdate($id: ID!, $data: ExperienceUpdateInput!) {
    experienceUpdate(filter: { id: $id }, data: $data) {
      ...ExperienceStoreSettingsFragment
    }
  }
  ${ExperienceStoreSettingsFragmentFragmentDoc}
`;
/**
 * __useExperienceStoreSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceStoreSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceStoreSettingsUpdateMutation, { data, loading, error }] = useExperienceStoreSettingsUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceStoreSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceStoreSettingsUpdateDocument, options);
}
export const GetAllAssetsByOrganizationListDocument = gql `
  query GetAllAssetsByOrganizationList(
    $filter: GetAllAssetInventoryFilter
    $skip: Int
    $first: Int
  ) {
    getAllAssetInventoriesList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        organizationId
        aviable
        storeAvailability
        swagbagAvailability
        name
        picture
        unitPrice
        total
        profit
        sales
        price
        priceSales
      }
    }
  }
`;
/**
 * __useGetAllAssetsByOrganizationListQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsByOrganizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsByOrganizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsByOrganizationListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetAllAssetsByOrganizationListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAllAssetsByOrganizationListDocument, options);
}
export function useGetAllAssetsByOrganizationListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAllAssetsByOrganizationListDocument, options);
}
export const GetAllFileListDocument = gql `
  query GetAllFileList($filter: FileFilter) {
    filesList(filter: $filter) {
      items {
        id
        downloadUrl
      }
    }
  }
`;
/**
 * __useGetAllFileListQuery__
 *
 * To run a query within a React component, call `useGetAllFileListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFileListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFileListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllFileListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAllFileListDocument, options);
}
export function useGetAllFileListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAllFileListDocument, options);
}
export const GetAllAssetInventoryDocument = gql `
  query getAllAssetInventory($id: ID!) {
    getAllAssetInventory(id: $id) {
      name
      variantsPossible
      unitPrice
      total
      swagbagAvailability
      storeAvailability
      sales
      profit
      picture
      organizationId
      id
      aviable
      description
      continueSalesWhenOutOfStock
      note
      notifyWhenStockMinimumIsAchieved
      price
      stockMinimumBoundary
      priceSales
      inventoryOnHand
    }
  }
`;
/**
 * __useGetAllAssetInventoryQuery__
 *
 * To run a query within a React component, call `useGetAllAssetInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetInventoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllAssetInventoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAllAssetInventoryDocument, options);
}
export function useGetAllAssetInventoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAllAssetInventoryDocument, options);
}
export const GetAssetPictureDocument = gql `
  query getAssetPicture($id: ID!) {
    file(id: $id) {
      downloadUrl
      fileId
      filename
      id
    }
  }
`;
/**
 * __useGetAssetPictureQuery__
 *
 * To run a query within a React component, call `useGetAssetPictureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetPictureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetPictureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetPictureQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAssetPictureDocument, options);
}
export function useGetAssetPictureLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAssetPictureDocument, options);
}
export const GetAssetByExperiencieViewsListDocument = gql `
  query getAssetByExperiencieViewsList($filter: GetAssetByExperiencieViewFilter!) {
    getAssetByExperiencieViewsList(filter: $filter) {
      items {
        available
        continueSalesWhenOutOfStock
        id
        assetId
        name
        picture
        price
        storeAvailability
        total
        assetPrice
        experienceStoreAssetId
      }
    }
  }
`;
/**
 * __useGetAssetByExperiencieViewsListQuery__
 *
 * To run a query within a React component, call `useGetAssetByExperiencieViewsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByExperiencieViewsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByExperiencieViewsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAssetByExperiencieViewsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAssetByExperiencieViewsListDocument, options);
}
export function useGetAssetByExperiencieViewsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAssetByExperiencieViewsListDocument, options);
}
export const StoreGhostItemCreateDocument = gql `
  mutation StoreGhostItemCreate($data: StoreInventoryItemCreateInput!) {
    storeInventoryItemCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useStoreGhostItemCreateMutation__
 *
 * To run a mutation, you first call `useStoreGhostItemCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreGhostItemCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeGhostItemCreateMutation, { data, loading, error }] = useStoreGhostItemCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreGhostItemCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StoreGhostItemCreateDocument, options);
}
export const StoreGhostItemUpdateByFilterDocument = gql `
  mutation storeGhostItemUpdateByFilter(
    $data: StoreInventoryItemUpdateByFilterInput!
    $filter: StoreInventoryItemFilter
  ) {
    storeInventoryItemUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
      }
    }
  }
`;
/**
 * __useStoreGhostItemUpdateByFilterMutation__
 *
 * To run a mutation, you first call `useStoreGhostItemUpdateByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreGhostItemUpdateByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeGhostItemUpdateByFilterMutation, { data, loading, error }] = useStoreGhostItemUpdateByFilterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStoreGhostItemUpdateByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StoreGhostItemUpdateByFilterDocument, options);
}
export const InventoryByOrganizationDocument = gql `
  query InventoryByOrganization($filter: GetAssetByOrganizationFilter, $skip: Int, $first: Int) {
    __typename
    getAssetByOrganizationsList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        OrgID
        _description
        id
        profit
        sales
        storeAssetId
        storeAssetImageId
        storeAssetName
        storeStock
        storeVariants
        unitPrice
      }
    }
  }
`;
/**
 * __useInventoryByOrganizationQuery__
 *
 * To run a query within a React component, call `useInventoryByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryByOrganizationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useInventoryByOrganizationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(InventoryByOrganizationDocument, options);
}
export function useInventoryByOrganizationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(InventoryByOrganizationDocument, options);
}
export const CreateStoreAssetVariantsDocument = gql `
  mutation createStoreAssetVariants($data: StoreAssetCreateInput!) {
    __typename
    createStoreAssetVariants(data: $data) {
      success
    }
  }
`;
/**
 * __useCreateStoreAssetVariantsMutation__
 *
 * To run a mutation, you first call `useCreateStoreAssetVariantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreAssetVariantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreAssetVariantsMutation, { data, loading, error }] = useCreateStoreAssetVariantsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStoreAssetVariantsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateStoreAssetVariantsDocument, options);
}
export const StripePlansDocument = gql `
  query StripePlans {
    stripePlans {
      id
      object
      name
      active
      description
      prices {
        id
        active
        nickname
        unit_amount
        recurring
      }
    }
  }
`;
/**
 * __useStripePlansQuery__
 *
 * To run a query within a React component, call `useStripePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripePlansQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StripePlansDocument, options);
}
export function useStripePlansLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StripePlansDocument, options);
}
export const CheckoutSubscriptionDocument = gql `
  mutation CheckoutSubscription($data: CheckoutSubscriptionInput!) {
    checkoutSubscription(data: $data) {
      id
      metadata
      url
    }
  }
`;
/**
 * __useCheckoutSubscriptionMutation__
 *
 * To run a mutation, you first call `useCheckoutSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutSubscriptionMutation, { data, loading, error }] = useCheckoutSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckoutSubscriptionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CheckoutSubscriptionDocument, options);
}
export const UpdateStripePlanDocument = gql `
  mutation UpdateStripePlan($data: CheckoutUpdateSubscriptionInput!) {
    checkoutUpdateSubscription(data: $data) {
      success
    }
  }
`;
/**
 * __useUpdateStripePlanMutation__
 *
 * To run a mutation, you first call `useUpdateStripePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripePlanMutation, { data, loading, error }] = useUpdateStripePlanMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStripePlanMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateStripePlanDocument, options);
}
export const UsersStripePlansDocument = gql `
  query UsersStripePlans($filter: VizslaStripePlanFilter) {
    vizslaStripePlansList(filter: $filter) {
      items {
        id
        priceId
        userId
        productId
        customerId
        subscriptionId
        organizationId
        status
      }
    }
  }
`;
/**
 * __useUsersStripePlansQuery__
 *
 * To run a query within a React component, call `useUsersStripePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersStripePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersStripePlansQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersStripePlansQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsersStripePlansDocument, options);
}
export function useUsersStripePlansLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsersStripePlansDocument, options);
}
export const StripeInvoicesDocument = gql `
  query StripeInvoices($customerId: String!) {
    stripeInvoices(customerId: $customerId) {
      id
      customer
      created
      description
      status
      amount_due
      amount_paid
      payment_settings
      hosted_invoice_url
      lines
    }
  }
`;
/**
 * __useStripeInvoicesQuery__
 *
 * To run a query within a React component, call `useStripeInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeInvoicesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useStripeInvoicesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StripeInvoicesDocument, options);
}
export function useStripeInvoicesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StripeInvoicesDocument, options);
}
export const StripePaymentMethodsDocument = gql `
  query StripePaymentMethods($userId: String!) {
    stripePaymentMethods(userId: $userId) {
      items {
        __typename
        ... on CardPaymentMethod {
          id
          type
          primary
          reference
          brand
          exp
        }
        ... on BankPaymentMethod {
          id
          type
          primary
          reference
          name
          exp
        }
        ... on UnknownPaymentMethod {
          id
          type
          primary
        }
      }
    }
  }
`;
/**
 * __useStripePaymentMethodsQuery__
 *
 * To run a query within a React component, call `useStripePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePaymentMethodsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStripePaymentMethodsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StripePaymentMethodsDocument, options);
}
export function useStripePaymentMethodsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StripePaymentMethodsDocument, options);
}
export const StripePaymentMethodDefaultDocument = gql `
  mutation StripePaymentMethodDefault(
    $userId: String!
    $paymentMethodId: String!
    $onlyUser: Boolean
  ) {
    stripePaymentMethodDefault(
      userId: $userId
      paymentMethodId: $paymentMethodId
      onlyUser: $onlyUser
    ) {
      __typename
      ... on CardPaymentMethod {
        id
        type
        primary
        reference
        brand
      }
      ... on BankPaymentMethod {
        id
        type
        primary
        reference
        name
      }
      ... on UnknownPaymentMethod {
        id
        type
        primary
      }
    }
  }
`;
/**
 * __useStripePaymentMethodDefaultMutation__
 *
 * To run a mutation, you first call `useStripePaymentMethodDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMethodDefaultMutation, { data, loading, error }] = useStripePaymentMethodDefaultMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      onlyUser: // value for 'onlyUser'
 *   },
 * });
 */
export function useStripePaymentMethodDefaultMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripePaymentMethodDefaultDocument, options);
}
export const StripePaymentMethodInactiveDocument = gql `
  mutation StripePaymentMethodInactive($userId: String!, $paymentMethodId: String!) {
    stripePaymentMethodInactive(userId: $userId, paymentMethodId: $paymentMethodId) {
      success
    }
  }
`;
/**
 * __useStripePaymentMethodInactiveMutation__
 *
 * To run a mutation, you first call `useStripePaymentMethodInactiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodInactiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMethodInactiveMutation, { data, loading, error }] = useStripePaymentMethodInactiveMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useStripePaymentMethodInactiveMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripePaymentMethodInactiveDocument, options);
}
export const StripeCreateAccountSessionDocument = gql `
  mutation StripeCreateAccountSession($data: StripeCreateConnectAccountSessionInput) {
    stripeCreateConnectAccountSession(data: $data) {
      success
      message
      secret
      accountId
    }
  }
`;
/**
 * __useStripeCreateAccountSessionMutation__
 *
 * To run a mutation, you first call `useStripeCreateAccountSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreateAccountSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreateAccountSessionMutation, { data, loading, error }] = useStripeCreateAccountSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStripeCreateAccountSessionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripeCreateAccountSessionDocument, options);
}
export const TeamRTsListDocument = gql `
  query TeamRTsList($filter: TeamFundraisingFilter, $skip: Int, $first: Int) {
    teamFundraisingsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...TeamRTFragment
      }
    }
  }
  ${TeamRtFragmentFragmentDoc}
`;
/**
 * __useTeamRTsListQuery__
 *
 * To run a query within a React component, call `useTeamRTsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamRTsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamRTsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTeamRTsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamRTsListDocument, options);
}
export function useTeamRTsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamRTsListDocument, options);
}
export const TeamRtCreateDocument = gql `
  mutation TeamRTCreate($createData: TeamFundraisingCreateInput!) {
    teamFundraisingCreate(data: $createData) {
      id
      name
    }
  }
`;
/**
 * __useTeamRtCreateMutation__
 *
 * To run a mutation, you first call `useTeamRtCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamRtCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamRtCreateMutation, { data, loading, error }] = useTeamRtCreateMutation({
 *   variables: {
 *      createData: // value for 'createData'
 *   },
 * });
 */
export function useTeamRtCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TeamRtCreateDocument, options);
}
export const TeamWebLinkCounterByNameDocument = gql `
  query TeamWebLinkCounterByName($campaignId: ID!, $webLink: String!) {
    teamFundraisingsList(
      filter: { campaign: { id: { equals: $campaignId } }, webLink: { equals: $webLink } }
    ) {
      count
    }
  }
`;
/**
 * __useTeamWebLinkCounterByNameQuery__
 *
 * To run a query within a React component, call `useTeamWebLinkCounterByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamWebLinkCounterByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamWebLinkCounterByNameQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      webLink: // value for 'webLink'
 *   },
 * });
 */
export function useTeamWebLinkCounterByNameQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamWebLinkCounterByNameDocument, options);
}
export function useTeamWebLinkCounterByNameLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamWebLinkCounterByNameDocument, options);
}
export const TeamRTsNamesListDocument = gql `
  query TeamRTsNamesList($filter: TeamFundraisingFilter) {
    teamFundraisingsList(filter: $filter, first: 10) {
      items {
        id
        name
        campaign {
          id
        }
      }
    }
  }
`;
/**
 * __useTeamRTsNamesListQuery__
 *
 * To run a query within a React component, call `useTeamRTsNamesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamRTsNamesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamRTsNamesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamRTsNamesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamRTsNamesListDocument, options);
}
export function useTeamRTsNamesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamRTsNamesListDocument, options);
}
export const TicketingOptionDocument = gql `
  query TicketingOption($id: ID!) {
    ticketingOption(id: $id) {
      ...TicketingOptionFragment
    }
  }
  ${TicketingOptionFragmentFragmentDoc}
`;
/**
 * __useTicketingOptionQuery__
 *
 * To run a query within a React component, call `useTicketingOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketingOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketingOptionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TicketingOptionDocument, options);
}
export function useTicketingOptionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TicketingOptionDocument, options);
}
export const TicketingOptionsListDocument = gql `
  query TicketingOptionsList($filter: TicketingOptionFilter) {
    ticketingOptionsList(filter: $filter) {
      items {
        ...TicketingOptionFragment
      }
    }
  }
  ${TicketingOptionFragmentFragmentDoc}
`;
/**
 * __useTicketingOptionsListQuery__
 *
 * To run a query within a React component, call `useTicketingOptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketingOptionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTicketingOptionsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TicketingOptionsListDocument, options);
}
export function useTicketingOptionsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TicketingOptionsListDocument, options);
}
export const TicketingOptionDeleteDocument = gql `
  mutation TicketingOptionDelete($id: ID!) {
    ticketingOptionDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;
/**
 * __useTicketingOptionDeleteMutation__
 *
 * To run a mutation, you first call `useTicketingOptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingOptionDeleteMutation, { data, loading, error }] = useTicketingOptionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketingOptionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TicketingOptionDeleteDocument, options);
}
export const TicketingOptionUpdateDocument = gql `
  mutation TicketingOptionUpdate(
    $data: TicketingOptionUpdateInput!
    $filter: TicketingOptionKeyFilter!
  ) {
    ticketingOptionUpdate(data: $data, filter: $filter) {
      ...TicketingOptionFragment
    }
  }
  ${TicketingOptionFragmentFragmentDoc}
`;
/**
 * __useTicketingOptionUpdateMutation__
 *
 * To run a mutation, you first call `useTicketingOptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingOptionUpdateMutation, { data, loading, error }] = useTicketingOptionUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTicketingOptionUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TicketingOptionUpdateDocument, options);
}
export const TicketingOptionCreateDocument = gql `
  mutation TicketingOptionCreate($data: TicketingOptionCreateInput!) {
    ticketingOptionCreate(data: $data) {
      ...TicketingOptionFragment
    }
  }
  ${TicketingOptionFragmentFragmentDoc}
`;
/**
 * __useTicketingOptionCreateMutation__
 *
 * To run a mutation, you first call `useTicketingOptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingOptionCreateMutation, { data, loading, error }] = useTicketingOptionCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTicketingOptionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TicketingOptionCreateDocument, options);
}
export const GetOrdersDocument = gql `
  query GetOrders($filter: TransactionVizslaViewExpFilter, $skip: Int, $first: Int) {
    transactionVizslaViewExpsList(
      filter: $filter
      sort: { OrderDate: DESC }
      skip: $skip
      first: $first
    ) {
      count
      items {
        id
        OrderDate
        OrderStatus
        Ordersubtype
        OrderItemTransType
        OrderitemCommentDetail
        OrderItemsId
        UsersFirsName
        UsersLastName
        UsersEmail
        CampaignName
        ExperienceName
        ExperienceId
        OrderTotalAmount
        OrderItemsAmount
        OrderPaymentMethod
        OrderitemCommentDetail
        TransactionCategory
        Userid
        Name
        FullName
      }
    }
  }
`;
/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetOrdersDocument, options);
}
export function useGetOrdersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetOrdersDocument, options);
}
export const User_Status_UpdateDocument = gql `
  mutation User_status_update($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      status
    }
  }
`;
/**
 * __useUser_Status_UpdateMutation__
 *
 * To run a mutation, you first call `useUser_Status_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUser_Status_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStatusUpdateMutation, { data, loading, error }] = useUser_Status_UpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUser_Status_UpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(User_Status_UpdateDocument, options);
}
export const UserDataUpdateDocument = gql `
  mutation UserDataUpdate($filter: UserKeyFilter, $data: UserUpdateInput!) {
    userUpdate(filter: $filter, data: $data) {
      ...UserFragmentList
    }
  }
  ${UserFragmentListFragmentDoc}
`;
/**
 * __useUserDataUpdateMutation__
 *
 * To run a mutation, you first call `useUserDataUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDataUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDataUpdateMutation, { data, loading, error }] = useUserDataUpdateMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserDataUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UserDataUpdateDocument, options);
}
export const UserActivityLogDocument = gql `
  query UserActivityLog($filter: ActivityLogFilter, $skip: Int, $first: Int) {
    activityLogsList(filter: $filter, first: $first, skip: $skip) {
      items {
        id
        type
        description
        createdAt
      }
    }
  }
`;
/**
 * __useUserActivityLogQuery__
 *
 * To run a query within a React component, call `useUserActivityLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActivityLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActivityLogQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUserActivityLogQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserActivityLogDocument, options);
}
export function useUserActivityLogLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserActivityLogDocument, options);
}
export const GetHistoryMessagesDocument = gql `
  query GetHistoryMessages($userId: ID!, $skip: Int, $first: Int) {
    inboxMessagesList(
      filter: {
        inbox: { type: { in: ["Notification"] }, recipients: { some: { id: { equals: $userId } } } }
        createdBy: { id: { not_equals: $userId } }
      }
      first: $first
      skip: $skip
    ) {
      items {
        id
        body
        createdAt
        createdBy {
          fullName
          avatar {
            downloadUrl
          }
        }
      }
    }
  }
`;
/**
 * __useGetHistoryMessagesQuery__
 *
 * To run a query within a React component, call `useGetHistoryMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoryMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoryMessagesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetHistoryMessagesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetHistoryMessagesDocument, options);
}
export function useGetHistoryMessagesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetHistoryMessagesDocument, options);
}
export const UserListDocument = gql `
  query UserList($filter: UserFilter) {
    usersList(filter: $filter) {
      items {
        ...UserFragmentList
        avatar {
          id
          downloadUrl
        }
      }
    }
  }
  ${UserFragmentListFragmentDoc}
`;
/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserListDocument, options);
}
export function useUserListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserListDocument, options);
}
export const UsersEnsureCreateManyDocument = gql `
  mutation UsersEnsureCreateMany($data: [UserEnsureManyInput!]!) {
    users: userEnsureMany(data: $data) {
      count
      items {
        id
        firstName
        lastName
        fullName
        email
        phone {
          code
          number
        }
        birthDate
        roles {
          items {
            name
          }
        }
        attendees {
          items {
            id
          }
        }
        donors {
          items {
            id
          }
        }
      }
    }
  }
`;
/**
 * __useUsersEnsureCreateManyMutation__
 *
 * To run a mutation, you first call `useUsersEnsureCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersEnsureCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersEnsureCreateManyMutation, { data, loading, error }] = useUsersEnsureCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUsersEnsureCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UsersEnsureCreateManyDocument, options);
}
export const UserDataTransferDocument = gql `
  query UserDataTransfer($id: ID!, $organizationId: ID!) {
    user(id: $id) {
      attendees(
        filter: {
          experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
        }
      ) {
        items {
          teamMembership {
            id
            team {
              id
              name
              experience {
                id
                name
                campaign {
                  id
                  name
                }
              }
            }
          }
          fundraising {
            id
            title
            attendee {
              experience {
                id
                name
                campaign {
                  id
                  name
                }
              }
            }
          }
          experience {
            id
            name
            campaign {
              id
              name
            }
          }
        }
      }
    }
  }
`;
/**
 * __useUserDataTransferQuery__
 *
 * To run a query within a React component, call `useUserDataTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDataTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDataTransferQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUserDataTransferQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserDataTransferDocument, options);
}
export function useUserDataTransferLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserDataTransferDocument, options);
}
export const UsersCrmDocument = gql `
  query UsersCRM($first: Int, $skip: Int, $filter: UserFilter, $organizationId: ID) {
    users: usersList(first: $first, skip: $skip, filter: $filter) {
      count
      items {
        id
        firstName
        lastName
        email
        birthDate
        gender
        createdAt
        phone {
          code
          number
        }
        address {
          city
          state
          country
          zip
          street1
          street2
        }
        avatar {
          downloadUrl
        }
        allocatedDonations(first: 1, orderBy: createdAt_DESC) {
          items {
            id
            createdAt
          }
        }
        totalRaisedFundraiser: attendees(
          filter: {
            experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
          }
          groupBy: {
            query: {
              fundraising: {
                allocatedDonations: {
                  amount: { as: "count", fn: { aggregate: SUM, distinct: true } }
                }
              }
            }
          }
        ) {
          groups {
            count: Int
          }
        }
        teams: attendees(
          sort: { teamMembership: { team: { createdAt: DESC } } }
          filter: {
            teamMembership: {
              team: {
                id: { is_not_empty: true }
                campaign: { vizslaOrganization: { id: { equals: $organizationId } } }
              }
            }
          }
        ) {
          items {
            teamMembership {
              team {
                name
                createdAt
              }
            }
          }
        }
        campaigns: attendees(
          filter: {
            experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
          }
          groupBy: {
            query: {
              experience: {
                campaign: { id: { as: "count", fn: { aggregate: COUNT, distinct: true } } }
              }
            }
          }
        ) {
          groups {
            count: Int
          }
        }
      }
    }
  }
`;
/**
 * __useUsersCrmQuery__
 *
 * To run a query within a React component, call `useUsersCrmQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersCrmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersCrmQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUsersCrmQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsersCrmDocument, options);
}
export function useUsersCrmLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsersCrmDocument, options);
}
export const UserUpdateAlreadyLoggedInDocument = gql `
  mutation UserUpdateAlreadyLoggedIn(
    $hasAlreadyLoggedIn: Boolean
    $userId: String
    $email: String
  ) {
    __typename
    userUpdateAlreadyLoggedIn(
      data: { hasAlreadyLoggedIn: $hasAlreadyLoggedIn, userId: $userId, email: $email }
    ) {
      success
    }
  }
`;
/**
 * __useUserUpdateAlreadyLoggedInMutation__
 *
 * To run a mutation, you first call `useUserUpdateAlreadyLoggedInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateAlreadyLoggedInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateAlreadyLoggedInMutation, { data, loading, error }] = useUserUpdateAlreadyLoggedInMutation({
 *   variables: {
 *      hasAlreadyLoggedIn: // value for 'hasAlreadyLoggedIn'
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserUpdateAlreadyLoggedInMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UserUpdateAlreadyLoggedInDocument, options);
}
export const InboxesListDocument = gql `
  query InboxesList($filter: InboxFilter!) {
    inboxesList(filter: $filter, sort: [{ createdAt: DESC }]) {
      items {
        ...InboxFragmentWithLastMessage
      }
    }
  }
  ${InboxFragmentWithLastMessageFragmentDoc}
`;
/**
 * __useInboxesListQuery__
 *
 * To run a query within a React component, call `useInboxesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInboxesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(InboxesListDocument, options);
}
export function useInboxesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(InboxesListDocument, options);
}
export const SendMessageDocument = gql `
  mutation SendMessage($data: InboxMessageCreateInput!) {
    inboxMessage: inboxMessageCreate(data: $data) {
      ...InboxMessageFragment
      inbox {
        id
      }
    }
  }
  ${InboxMessageFragmentFragmentDoc}
`;
/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SendMessageDocument, options);
}
export const InboxMessagesDocument = gql `
  query InboxMessages($filter: InboxMessageFilter!) {
    inboxMessages: inboxMessagesList(filter: $filter, sort: [{ createdAt: ASC }]) {
      items {
        ...InboxMessageFragment
      }
    }
  }
  ${InboxMessageFragmentFragmentDoc}
`;
/**
 * __useInboxMessagesQuery__
 *
 * To run a query within a React component, call `useInboxMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxMessagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInboxMessagesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(InboxMessagesDocument, options);
}
export function useInboxMessagesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(InboxMessagesDocument, options);
}
export const DeleteInboxByUserDocument = gql `
  mutation DeleteInboxByUser($inboxId: ID!, $userId: ID!) {
    inboxUpdate(data: { id: $inboxId, deletedBy: { connect: { id: $userId } } }) {
      id
    }
  }
`;
/**
 * __useDeleteInboxByUserMutation__
 *
 * To run a mutation, you first call `useDeleteInboxByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInboxByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInboxByUserMutation, { data, loading, error }] = useDeleteInboxByUserMutation({
 *   variables: {
 *      inboxId: // value for 'inboxId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteInboxByUserMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteInboxByUserDocument, options);
}
export const MarkInboxMessagesAsReadDocument = gql `
  mutation MarkInboxMessagesAsRead($messageId: ID!, $userId: ID!) {
    inboxMessageUpdate(data: { id: $messageId, readBy: { connect: { id: $userId } } }) {
      id
    }
  }
`;
/**
 * __useMarkInboxMessagesAsReadMutation__
 *
 * To run a mutation, you first call `useMarkInboxMessagesAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInboxMessagesAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInboxMessagesAsReadMutation, { data, loading, error }] = useMarkInboxMessagesAsReadMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMarkInboxMessagesAsReadMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(MarkInboxMessagesAsReadDocument, options);
}
export const NewInboxMessageDocument = gql `
  subscription NewInboxMessage {
    InboxMessage(
      filter: {
        mutation_in: [create]
        node: { createdBy: { is_self: false }, inbox: { recipients: { some: { is_self: true } } } }
      }
    ) {
      node {
        ...InboxMessageFragment
        inbox {
          ...InboxFragment
        }
      }
    }
  }
  ${InboxMessageFragmentFragmentDoc}
  ${InboxFragmentFragmentDoc}
`;
/**
 * __useNewInboxMessageSubscription__
 *
 * To run a query within a React component, call `useNewInboxMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewInboxMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewInboxMessageSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewInboxMessageSubscription(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSubscription(NewInboxMessageDocument, options);
}
export const InboxCreateDocument = gql `
  mutation InboxCreate($data: InboxCreateInput!) {
    createInbox(data: $data) {
      ...InboxFragment
    }
  }
  ${InboxFragmentFragmentDoc}
`;
/**
 * __useInboxCreateMutation__
 *
 * To run a mutation, you first call `useInboxCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxCreateMutation, { data, loading, error }] = useInboxCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInboxCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(InboxCreateDocument, options);
}
export const MerchandiseExperienceDocument = gql `
  query MerchandiseExperience(
    $filter: GetAssetByExperienceFilter!
    $sort: [GetAssetByExperienceSort!]
    $first: Int
    $skip: Int
  ) {
    merchandise: getAssetByExperiencesList(
      filter: $filter
      first: $first
      skip: $skip
      sort: $sort
    ) {
      count
      items {
        ...AssetMerchandise
      }
    }
  }
  ${AssetMerchandiseFragmentDoc}
`;
/**
 * __useMerchandiseExperienceQuery__
 *
 * To run a query within a React component, call `useMerchandiseExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchandiseExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchandiseExperienceQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useMerchandiseExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(MerchandiseExperienceDocument, options);
}
export function useMerchandiseExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(MerchandiseExperienceDocument, options);
}
export const AttachFacebookFundraisingDocument = gql `
  mutation AttachFacebookFundraising($attendee: AttendeeKeyFilter!) {
    commit: attachFacebookFundraising(attendee: $attendee) {
      success
    }
  }
`;
/**
 * __useAttachFacebookFundraisingMutation__
 *
 * To run a mutation, you first call `useAttachFacebookFundraisingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachFacebookFundraisingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachFacebookFundraisingMutation, { data, loading, error }] = useAttachFacebookFundraisingMutation({
 *   variables: {
 *      attendee: // value for 'attendee'
 *   },
 * });
 */
export function useAttachFacebookFundraisingMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AttachFacebookFundraisingDocument, options);
}
export const AttendeeSignWaiverDocument = gql `
  mutation AttendeeSignWaiver(
    $id: ID!
    $waiverSignature: String!
    $waiverSignedBy: AttendeeWaiverSignedByUpdateRelationInput!
    $waiverSignedAt: DateTime!
  ) {
    attendee: attendeeUpdate(
      data: {
        id: $id
        waiverSignature: $waiverSignature
        waiverSignedBy: $waiverSignedBy
        waiverSignedAt: $waiverSignedAt
      }
    ) {
      id
    }
  }
`;
/**
 * __useAttendeeSignWaiverMutation__
 *
 * To run a mutation, you first call `useAttendeeSignWaiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendeeSignWaiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendeeSignWaiverMutation, { data, loading, error }] = useAttendeeSignWaiverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      waiverSignature: // value for 'waiverSignature'
 *      waiverSignedBy: // value for 'waiverSignedBy'
 *      waiverSignedAt: // value for 'waiverSignedAt'
 *   },
 * });
 */
export function useAttendeeSignWaiverMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AttendeeSignWaiverDocument, options);
}
export const AttendeeManagementDocument = gql `
  query AttendeeManagement($id: ID!) {
    attendee(id: $id) {
      ...ATTENDEE_FRAGMENT
      fundraising {
        ...INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT
      }
    }
  }
  ${Attendee_FragmentFragmentDoc}
  ${Individual_Fundraising_Management_FragmentFragmentDoc}
`;
/**
 * __useAttendeeManagementQuery__
 *
 * To run a query within a React component, call `useAttendeeManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeManagementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendeeManagementQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeManagementDocument, options);
}
export function useAttendeeManagementLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeManagementDocument, options);
}
export const ShoppingCartRegisterAttendeesDocument = gql `
  mutation ShoppingCartRegisterAttendees($data: [ShoppingCartRegisterAttendeesManyInput!]!) {
    attendees: shoppingCartRegisterAttendees(data: $data) {
      items {
        id
        user {
          id
        }
      }
    }
  }
`;
/**
 * __useShoppingCartRegisterAttendeesMutation__
 *
 * To run a mutation, you first call `useShoppingCartRegisterAttendeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShoppingCartRegisterAttendeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shoppingCartRegisterAttendeesMutation, { data, loading, error }] = useShoppingCartRegisterAttendeesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useShoppingCartRegisterAttendeesMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ShoppingCartRegisterAttendeesDocument, options);
}
export const AttendeesByExperienceLandingDocument = gql `
  query AttendeesByExperienceLanding($id: ID!, $first: Int, $skip: Int) {
    experienceLandingPeopleSectionAttendees(experienceId: $id, first: $first, skip: $skip) {
      items {
        createdAt
        id
        user {
          id
          firstName
          lastName
          email
          avatar {
            downloadUrl
          }
        }
        registrationOption {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useAttendeesByExperienceLandingQuery__
 *
 * To run a query within a React component, call `useAttendeesByExperienceLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesByExperienceLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeesByExperienceLandingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAttendeesByExperienceLandingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeesByExperienceLandingDocument, options);
}
export function useAttendeesByExperienceLandingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeesByExperienceLandingDocument, options);
}
export const GetAttendeesTotalRaisedByTeamDocument = gql `
  query getAttendeesTotalRaisedByTeam($teamId: String!, $attendeeId: String!) {
    getAttendeesTotalRaisedByTeam(data: { teamId: $teamId, attendeeId: $attendeeId }) {
      items {
        amount
        id
        donor {
          fullName
          id
        }
      }
    }
  }
`;
/**
 * __useGetAttendeesTotalRaisedByTeamQuery__
 *
 * To run a query within a React component, call `useGetAttendeesTotalRaisedByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendeesTotalRaisedByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendeesTotalRaisedByTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useGetAttendeesTotalRaisedByTeamQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAttendeesTotalRaisedByTeamDocument, options);
}
export function useGetAttendeesTotalRaisedByTeamLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAttendeesTotalRaisedByTeamDocument, options);
}
export const CheckFacebookFundraisingDocument = gql `
  query CheckFacebookFundraising($attendee: AttendeeKeyFilter!) {
    connection: checkFacebookFundraising(attendee: $attendee) {
      fundraisingPage
      hasCredentials
      hasCredentialsExpired
      hasFundraisingPage
    }
  }
`;
/**
 * __useCheckFacebookFundraisingQuery__
 *
 * To run a query within a React component, call `useCheckFacebookFundraisingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckFacebookFundraisingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckFacebookFundraisingQuery({
 *   variables: {
 *      attendee: // value for 'attendee'
 *   },
 * });
 */
export function useCheckFacebookFundraisingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CheckFacebookFundraisingDocument, options);
}
export function useCheckFacebookFundraisingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CheckFacebookFundraisingDocument, options);
}
export const ConnectAttendeeFacebookAccountDocument = gql `
  mutation ConnectAttendeeFacebookAccount(
    $token: String!
    $expiredAt: DateTime!
    $attendee: AttendeeKeyFilter!
  ) {
    connection: connectFacebookAccount(token: $token, expiredAt: $expiredAt, attendee: $attendee) {
      success
    }
  }
`;
/**
 * __useConnectAttendeeFacebookAccountMutation__
 *
 * To run a mutation, you first call `useConnectAttendeeFacebookAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectAttendeeFacebookAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectAttendeeFacebookAccountMutation, { data, loading, error }] = useConnectAttendeeFacebookAccountMutation({
 *   variables: {
 *      token: // value for 'token'
 *      expiredAt: // value for 'expiredAt'
 *      attendee: // value for 'attendee'
 *   },
 * });
 */
export function useConnectAttendeeFacebookAccountMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ConnectAttendeeFacebookAccountDocument, options);
}
export const RegisterAttendeesWithOutPaymentDocument = gql `
  mutation RegisterAttendeesWithOutPayment($data: registerAttendeesWithOutPaymentInput) {
    __typename
    registerAttendeesWithOutPayment(data: $data) {
      message
      success
    }
  }
`;
/**
 * __useRegisterAttendeesWithOutPaymentMutation__
 *
 * To run a mutation, you first call `useRegisterAttendeesWithOutPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAttendeesWithOutPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAttendeesWithOutPaymentMutation, { data, loading, error }] = useRegisterAttendeesWithOutPaymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterAttendeesWithOutPaymentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegisterAttendeesWithOutPaymentDocument, options);
}
export const ClientCampaignDocument = gql `
  query ClientCampaign($id: ID!) {
    campaign(id: $id) {
      ...ClientCampaignFragment
    }
  }
  ${ClientCampaignFragmentFragmentDoc}
`;
/**
 * __useClientCampaignQuery__
 *
 * To run a query within a React component, call `useClientCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientCampaignQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ClientCampaignDocument, options);
}
export function useClientCampaignLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ClientCampaignDocument, options);
}
export const PublicTemplatesByCampaignIdDocument = gql `
  query PublicTemplatesByCampaignId($campaignId: ID!) {
    campaign(id: $campaignId) {
      campaignTemplates {
        id
        templates {
          items {
            id
            templateType {
              type
              title
            }
          }
        }
      }
    }
  }
`;
/**
 * __usePublicTemplatesByCampaignIdQuery__
 *
 * To run a query within a React component, call `usePublicTemplatesByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicTemplatesByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicTemplatesByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function usePublicTemplatesByCampaignIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PublicTemplatesByCampaignIdDocument, options);
}
export function usePublicTemplatesByCampaignIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PublicTemplatesByCampaignIdDocument, options);
}
export const ClientSingleCampaignStatisticsDocument = gql `
  query ClientSingleCampaignStatistics($campaignId: ID!) {
    attendeesList(filter: { experience: { campaign: { id: { equals: $campaignId } } } }) {
      count
      items {
        id
        createdAt
      }
    }
    fundraisersList: attendeesList(
      filter: {
        experience: { campaign: { id: { equals: $campaignId } } }
        fundraisingPageLink: { is_not_empty: true }
      }
    ) {
      count
      items {
        id
        createdAt
      }
    }
  }
`;
/**
 * __useClientSingleCampaignStatisticsQuery__
 *
 * To run a query within a React component, call `useClientSingleCampaignStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientSingleCampaignStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientSingleCampaignStatisticsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useClientSingleCampaignStatisticsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ClientSingleCampaignStatisticsDocument, options);
}
export function useClientSingleCampaignStatisticsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ClientSingleCampaignStatisticsDocument, options);
}
export const CampaignHeaderImageDocument = gql `
  query CampaignHeaderImage($id: ID!) {
    campaign(id: $id) {
      ...CampaignHeaderImageFragment
    }
  }
  ${CampaignHeaderImageFragmentFragmentDoc}
`;
/**
 * __useCampaignHeaderImageQuery__
 *
 * To run a query within a React component, call `useCampaignHeaderImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignHeaderImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignHeaderImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignHeaderImageQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignHeaderImageDocument, options);
}
export function useCampaignHeaderImageLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignHeaderImageDocument, options);
}
export const CampaignLogoImageDocument = gql `
  query CampaignLogoImage($id: ID!) {
    campaign(id: $id) {
      ...CampaignHeaderLogoFragment
    }
  }
  ${CampaignHeaderLogoFragmentFragmentDoc}
`;
/**
 * __useCampaignLogoImageQuery__
 *
 * To run a query within a React component, call `useCampaignLogoImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignLogoImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignLogoImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignLogoImageQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignLogoImageDocument, options);
}
export function useCampaignLogoImageLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignLogoImageDocument, options);
}
export const CampaignHeaderImagesUpdateDocument = gql `
  mutation CampaignHeaderImagesUpdate($data: CampaignUpdateInput!, $filter: CampaignKeyFilter) {
    campaign: campaignUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...CampaignHeaderImageFragment
    }
  }
  ${CampaignHeaderImageFragmentFragmentDoc}
`;
/**
 * __useCampaignHeaderImagesUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignHeaderImagesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignHeaderImagesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignHeaderImagesUpdateMutation, { data, loading, error }] = useCampaignHeaderImagesUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignHeaderImagesUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignHeaderImagesUpdateDocument, options);
}
export const CampaignHeaderLogoUpdateDocument = gql `
  mutation CampaignHeaderLogoUpdate($data: CampaignUpdateInput!, $filter: CampaignKeyFilter) {
    campaign: campaignUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...CampaignHeaderLogoFragment
    }
  }
  ${CampaignHeaderLogoFragmentFragmentDoc}
`;
/**
 * __useCampaignHeaderLogoUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignHeaderLogoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignHeaderLogoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignHeaderLogoUpdateMutation, { data, loading, error }] = useCampaignHeaderLogoUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignHeaderLogoUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignHeaderLogoUpdateDocument, options);
}
export const CheckoutDocument = gql `
  mutation Checkout(
    $experience: ExperienceKeyFilter!
    $parentOrder: OrderKeyFilter
    $cart: JSON!
    $billingInfo: JSON
  ) {
    checkout: checkoutShoppingCart(
      data: {
        experience: $experience
        parentOrder: $parentOrder
        shoppingCart: $cart
        billingInfo: $billingInfo
      }
    ) {
      needPayment
      paymentIntent {
        provider
        metadata
      }
    }
  }
`;
/**
 * __useCheckoutMutation__
 *
 * To run a mutation, you first call `useCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutMutation, { data, loading, error }] = useCheckoutMutation({
 *   variables: {
 *      experience: // value for 'experience'
 *      parentOrder: // value for 'parentOrder'
 *      cart: // value for 'cart'
 *      billingInfo: // value for 'billingInfo'
 *   },
 * });
 */
export function useCheckoutMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CheckoutDocument, options);
}
export const ConsumerImpactDocument = gql `
  query ConsumerImpact(
    $filter: DonationAllocationFilter!
    $filterTotalDonations: DonationAllocationFilter!
  ) {
    totalRaised: donationAllocationsList(
      filter: $filter
      groupBy: { query: { amount: { as: "totalRaised", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalRaised: Float
      }
    }
    averageDonation: donationAllocationsList(
      filter: $filter
      groupBy: { query: { amount: { as: "averageDonation", fn: { aggregate: AVG } } } }
    ) {
      groups {
        averageDonation: Float
      }
    }
    totalDonors: donationAllocationsList(
      filter: $filter
      groupBy: {
        query: { donor: { id: { as: "donorID" } } }
        having: { alias: "donorID", string: { is_not_empty: true } }
      }
    ) {
      count
    }
    totalDonations: donationAllocationsList(
      filter: $filterTotalDonations
      groupBy: { query: { amount: { as: "totalDonations", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalDonations: Float
      }
    }
  }
`;
/**
 * __useConsumerImpactQuery__
 *
 * To run a query within a React component, call `useConsumerImpactQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumerImpactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerImpactQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      filterTotalDonations: // value for 'filterTotalDonations'
 *   },
 * });
 */
export function useConsumerImpactQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ConsumerImpactDocument, options);
}
export function useConsumerImpactLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ConsumerImpactDocument, options);
}
export const CreateFacebookAccountRedirectDocument = gql `
  mutation CreateFacebookAccountRedirect(
    $attendee: AttendeeKeyFilter!
    $redirectUri: String!
    $permissions: [FacebookOAuthPermission!]
  ) {
    url: createFacebookAccountRedirect(
      attendee: $attendee
      redirectUri: $redirectUri
      permissions: $permissions
    )
  }
`;
/**
 * __useCreateFacebookAccountRedirectMutation__
 *
 * To run a mutation, you first call `useCreateFacebookAccountRedirectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFacebookAccountRedirectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFacebookAccountRedirectMutation, { data, loading, error }] = useCreateFacebookAccountRedirectMutation({
 *   variables: {
 *      attendee: // value for 'attendee'
 *      redirectUri: // value for 'redirectUri'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useCreateFacebookAccountRedirectMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateFacebookAccountRedirectDocument, options);
}
export const QuestionsDonationDocument = gql `
  query questionsDonation($orderItemId: ID!) {
    orderItem(id: $orderItemId) {
      id
      donationFulfillments {
        customQuestionAnswers {
          items {
            id
            question {
              id
              title
              options
              type
              format
              category
              order
              isRequiredAttendee
              isRequiredDonor
              isRequiredTeam
            }
            text
          }
        }
      }
    }
  }
`;
/**
 * __useQuestionsDonationQuery__
 *
 * To run a query within a React component, call `useQuestionsDonationQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsDonationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsDonationQuery({
 *   variables: {
 *      orderItemId: // value for 'orderItemId'
 *   },
 * });
 */
export function useQuestionsDonationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(QuestionsDonationDocument, options);
}
export function useQuestionsDonationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(QuestionsDonationDocument, options);
}
export const SearchDiscountCodeByCampaignDocument = gql `
  query SearchDiscountCodeByCampaign($name: String!, $experienceId: ID!) {
    discount: searchDiscountCode(filter: { name: $name, experienceId: $experienceId }) {
      id
      name
      category
      amount
      amountType
      limitToOneUsePerAttendee
    }
  }
`;
/**
 * __useSearchDiscountCodeByCampaignQuery__
 *
 * To run a query within a React component, call `useSearchDiscountCodeByCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDiscountCodeByCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDiscountCodeByCampaignQuery({
 *   variables: {
 *      name: // value for 'name'
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useSearchDiscountCodeByCampaignQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SearchDiscountCodeByCampaignDocument, options);
}
export function useSearchDiscountCodeByCampaignLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SearchDiscountCodeByCampaignDocument, options);
}
export const DnsRecordQueryBySlugDocument = gql `
  query DnsRecordQueryBySlug($slug: String!) {
    dnsRecord(slug: $slug) {
      ...DnsRecordFragment
    }
  }
  ${DnsRecordFragmentFragmentDoc}
`;
/**
 * __useDnsRecordQueryBySlugQuery__
 *
 * To run a query within a React component, call `useDnsRecordQueryBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useDnsRecordQueryBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDnsRecordQueryBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDnsRecordQueryBySlugQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DnsRecordQueryBySlugDocument, options);
}
export function useDnsRecordQueryBySlugLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DnsRecordQueryBySlugDocument, options);
}
export const DnsRecordCreateDocument = gql `
  mutation DnsRecordCreate($slug: String!, $campaignId: ID!) {
    dnsRecordCreate(data: { slug: $slug, campaign: { connect: { id: $campaignId } } }) {
      id
      slug
      campaign {
        id
        name
      }
    }
  }
`;
/**
 * __useDnsRecordCreateMutation__
 *
 * To run a mutation, you first call `useDnsRecordCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDnsRecordCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dnsRecordCreateMutation, { data, loading, error }] = useDnsRecordCreateMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDnsRecordCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DnsRecordCreateDocument, options);
}
export const PublicCampaignInfoBySlugDocument = gql `
  query PublicCampaignInfoBySlug($slug: String) {
    dnsRecord(slug: $slug) {
      id
      campaign {
        id
        name
        fundraisingGoal
        contactEmail
        campaignType
        contactPhone {
          code
          number
        }
        campaignStatus
        totalRaised
        campaignTemplates {
          id
          templates {
            items {
              id
              templateType {
                type
                title
              }
              structure
            }
          }
        }
        vizslaOrganization {
          id
          name
          socialMedia {
            items {
              id
              uRL
              socialMedia
            }
          }
        }
        logo {
          id
          fileId
          filename
          downloadUrl
        }
        experiences {
          items {
            id
            name
            experienceType
            experienceCategory
            startDate
            endDate
            waiverAllowMultipleAttendeeConsent
            waiverTextBody
            experienceHeaderImage {
              id
              filename
              downloadUrl
              fileId
              public
            }
            experienceLogoImage {
              fileId
              filename
              id
              public
              downloadUrl
            }
            registrationOptions {
              items {
                id
                name
                registrationStartDate
                registrationEndDate
                pricingSettings {
                  id
                  pricingType
                  pricingTiers {
                    items {
                      id
                      price
                      startDate
                      endDate
                    }
                  }
                  pricingEnabled
                }
              }
            }
            venues {
              items {
                id
                name
                notes
                address
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __usePublicCampaignInfoBySlugQuery__
 *
 * To run a query within a React component, call `usePublicCampaignInfoBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicCampaignInfoBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicCampaignInfoBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicCampaignInfoBySlugQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PublicCampaignInfoBySlugDocument, options);
}
export function usePublicCampaignInfoBySlugLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PublicCampaignInfoBySlugDocument, options);
}
export const GetPublicCampaignInfoBySlugDocument = gql `
  query GetPublicCampaignInfoBySlug($slug: String!) {
    getPublicCampaignInfoBySlug(slug: $slug) {
      organization
      campaign
      experiences
      campaignTemplates
    }
  }
`;
/**
 * __useGetPublicCampaignInfoBySlugQuery__
 *
 * To run a query within a React component, call `useGetPublicCampaignInfoBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCampaignInfoBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCampaignInfoBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPublicCampaignInfoBySlugQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetPublicCampaignInfoBySlugDocument, options);
}
export function useGetPublicCampaignInfoBySlugLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetPublicCampaignInfoBySlugDocument, options);
}
export const DonationOptionByIdDocument = gql `
  query donationOptionById($id: ID!) {
    donationOption(id: $id) {
      ...DonationOptionFragment
    }
  }
  ${DonationOptionFragmentFragmentDoc}
`;
/**
 * __useDonationOptionByIdQuery__
 *
 * To run a query within a React component, call `useDonationOptionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationOptionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonationOptionByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DonationOptionByIdDocument, options);
}
export function useDonationOptionByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DonationOptionByIdDocument, options);
}
export const DonationOptionsListDocument = gql `
  query DonationOptionsList($filter: DonationOptionFilter) {
    donationOptionsList(filter: $filter) {
      items {
        ...DonationOptionFragment
      }
    }
  }
  ${DonationOptionFragmentFragmentDoc}
`;
/**
 * __useDonationOptionsListQuery__
 *
 * To run a query within a React component, call `useDonationOptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationOptionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDonationOptionsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DonationOptionsListDocument, options);
}
export function useDonationOptionsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DonationOptionsListDocument, options);
}
export const DonationOptionUpdateDocument = gql `
  mutation DonationOptionUpdate($data: DonationOptionUpdateInput!) {
    donationOptionUpdate(data: $data) {
      ...DonationOptionFragment
    }
  }
  ${DonationOptionFragmentFragmentDoc}
`;
/**
 * __useDonationOptionUpdateMutation__
 *
 * To run a mutation, you first call `useDonationOptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donationOptionUpdateMutation, { data, loading, error }] = useDonationOptionUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDonationOptionUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DonationOptionUpdateDocument, options);
}
export const DonationOptionCreateDocument = gql `
  mutation DonationOptionCreate($data: DonationOptionCreateInput!) {
    donationOptionCreate(data: $data) {
      ...DonationOptionFragment
    }
  }
  ${DonationOptionFragmentFragmentDoc}
`;
/**
 * __useDonationOptionCreateMutation__
 *
 * To run a mutation, you first call `useDonationOptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donationOptionCreateMutation, { data, loading, error }] = useDonationOptionCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDonationOptionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DonationOptionCreateDocument, options);
}
export const DonationOptionCreateManyDocument = gql `
  mutation DonationOptionCreateMany($data: [DonationOptionCreateManyInput]!) {
    donationOptionCreateMany(data: $data) {
      items {
        ...DonationOptionFragment
      }
    }
  }
  ${DonationOptionFragmentFragmentDoc}
`;
/**
 * __useDonationOptionCreateManyMutation__
 *
 * To run a mutation, you first call `useDonationOptionCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donationOptionCreateManyMutation, { data, loading, error }] = useDonationOptionCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDonationOptionCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DonationOptionCreateManyDocument, options);
}
export const DonationOptionDeleteDocument = gql `
  mutation DonationOptionDelete($id: ID!) {
    donationOptionDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useDonationOptionDeleteMutation__
 *
 * To run a mutation, you first call `useDonationOptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donationOptionDeleteMutation, { data, loading, error }] = useDonationOptionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonationOptionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DonationOptionDeleteDocument, options);
}
export const UnallocatedDonationResumeDocument = gql `
  query UnallocatedDonationResume($filter: DonationAllocationFilter!) {
    metrics: donationAllocationsList(
      filter: $filter
      groupBy: {
        query: {
          amount: { as: "total", fn: { aggregate: SUM } }
          id: { as: "count", fn: { aggregate: COUNT } }
        }
      }
    ) {
      donations: items {
        id
        campaign {
          id
        }
        experience {
          id
        }
        teamFundraising {
          id
        }
      }
      groups {
        total: Float
        count: Int
      }
    }
  }
`;
/**
 * __useUnallocatedDonationResumeQuery__
 *
 * To run a query within a React component, call `useUnallocatedDonationResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnallocatedDonationResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnallocatedDonationResumeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUnallocatedDonationResumeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UnallocatedDonationResumeDocument, options);
}
export function useUnallocatedDonationResumeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UnallocatedDonationResumeDocument, options);
}
export const AllocatedDonationsMetricsDocument = gql `
  query AllocatedDonationsMetrics($filter: DonationAllocationFilter!) {
    donations: donationAllocationsList(
      filter: $filter
      groupBy: {
        query: {
          createdAt: { as: "date", fn: { datePart: DATE } }
          amount: { as: "amount", fn: { aggregate: SUM } }
          id: { as: "count", fn: { aggregate: COUNT } }
        }
      }
    ) {
      groups {
        date: Date
        amount: Float
        count: Int
      }
    }
  }
`;
/**
 * __useAllocatedDonationsMetricsQuery__
 *
 * To run a query within a React component, call `useAllocatedDonationsMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllocatedDonationsMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllocatedDonationsMetricsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllocatedDonationsMetricsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllocatedDonationsMetricsDocument, options);
}
export function useAllocatedDonationsMetricsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllocatedDonationsMetricsDocument, options);
}
export const AllocatedDonationsDocument = gql `
  query AllocatedDonations($filter: DonationAllocationFilter!, $skip: Int, $first: Int) {
    donations: donationAllocationsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        amount
        createdAt
        individualFundraising {
          id
          attendee {
            user {
              firstName
              lastName
            }
          }
        }
        parents {
          count
        }
        teamFundraising {
          id
          name
        }
        donor {
          firstName
          lastName
          avatar {
            downloadUrl
          }
          allocatedDonations(filter: $filter, first: 1, orderBy: createdAt_ASC) {
            items {
              id
            }
          }
        }
      }
    }
  }
`;
/**
 * __useAllocatedDonationsQuery__
 *
 * To run a query within a React component, call `useAllocatedDonationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllocatedDonationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllocatedDonationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllocatedDonationsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllocatedDonationsDocument, options);
}
export function useAllocatedDonationsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllocatedDonationsDocument, options);
}
export const DonorCommitmentsListDocument = gql `
  query DonorCommitmentsList($vizlaOrganizationID: ID) {
    donorCommitmentsList(
      filter: { campaign: { vizslaOrganization: { id: { equals: $vizlaOrganizationID } } } }
    ) {
      items {
        ...DonorCommitmentFragment
      }
    }
  }
  ${DonorCommitmentFragmentFragmentDoc}
`;
/**
 * __useDonorCommitmentsListQuery__
 *
 * To run a query within a React component, call `useDonorCommitmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorCommitmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorCommitmentsListQuery({
 *   variables: {
 *      vizlaOrganizationID: // value for 'vizlaOrganizationID'
 *   },
 * });
 */
export function useDonorCommitmentsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DonorCommitmentsListDocument, options);
}
export function useDonorCommitmentsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DonorCommitmentsListDocument, options);
}
export const DonorCommitmentCreateDocument = gql `
  mutation DonorCommitmentCreate($data: DonorCommitmentCreateInput!) {
    donorCommitmentCreate(data: $data) {
      ...DonorCommitmentFragment
    }
  }
  ${DonorCommitmentFragmentFragmentDoc}
`;
/**
 * __useDonorCommitmentCreateMutation__
 *
 * To run a mutation, you first call `useDonorCommitmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonorCommitmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donorCommitmentCreateMutation, { data, loading, error }] = useDonorCommitmentCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDonorCommitmentCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DonorCommitmentCreateDocument, options);
}
export const DonorCommitmentUpdateDocument = gql `
  mutation DonorCommitmentUpdate(
    $data: DonorCommitmentUpdateInput!
    $filter: DonorCommitmentKeyFilter!
  ) {
    donorCommitmentUpdate(data: $data, filter: $filter) {
      ...DonorCommitmentFragment
    }
  }
  ${DonorCommitmentFragmentFragmentDoc}
`;
/**
 * __useDonorCommitmentUpdateMutation__
 *
 * To run a mutation, you first call `useDonorCommitmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonorCommitmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donorCommitmentUpdateMutation, { data, loading, error }] = useDonorCommitmentUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDonorCommitmentUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DonorCommitmentUpdateDocument, options);
}
export const ExperienceHeaderImageDocument = gql `
  query ExperienceHeaderImage($id: ID!) {
    experience(id: $id) {
      ...ExperienceHeaderImageFragment
    }
  }
  ${ExperienceHeaderImageFragmentFragmentDoc}
`;
/**
 * __useExperienceHeaderImageQuery__
 *
 * To run a query within a React component, call `useExperienceHeaderImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceHeaderImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceHeaderImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceHeaderImageQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceHeaderImageDocument, options);
}
export function useExperienceHeaderImageLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceHeaderImageDocument, options);
}
export const ExperienceLogoImageDocument = gql `
  query ExperienceLogoImage($id: ID!) {
    experience(id: $id) {
      ...ExperienceHeaderLogoFragment
    }
  }
  ${ExperienceHeaderLogoFragmentFragmentDoc}
`;
/**
 * __useExperienceLogoImageQuery__
 *
 * To run a query within a React component, call `useExperienceLogoImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceLogoImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceLogoImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceLogoImageQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceLogoImageDocument, options);
}
export function useExperienceLogoImageLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceLogoImageDocument, options);
}
export const ExperienceHeaderImagesUpdateDocument = gql `
  mutation ExperienceHeaderImagesUpdate(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter
  ) {
    experience: experienceUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ExperienceHeaderImageFragment
    }
  }
  ${ExperienceHeaderImageFragmentFragmentDoc}
`;
/**
 * __useExperienceHeaderImagesUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceHeaderImagesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceHeaderImagesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceHeaderImagesUpdateMutation, { data, loading, error }] = useExperienceHeaderImagesUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceHeaderImagesUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceHeaderImagesUpdateDocument, options);
}
export const ExperienceHeaderLogoUpdateDocument = gql `
  mutation ExperienceHeaderLogoUpdate($data: ExperienceUpdateInput!, $filter: ExperienceKeyFilter) {
    experience: experienceUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ExperienceHeaderLogoFragment
    }
  }
  ${ExperienceHeaderLogoFragmentFragmentDoc}
`;
/**
 * __useExperienceHeaderLogoUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceHeaderLogoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceHeaderLogoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceHeaderLogoUpdateMutation, { data, loading, error }] = useExperienceHeaderLogoUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceHeaderLogoUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceHeaderLogoUpdateDocument, options);
}
export const ExperienceGalleryImagesDocument = gql `
  query ExperienceGalleryImages($id: ID!) {
    experience: experience(id: $id) {
      ...ExperienceGalleryImagesFragment
    }
  }
  ${ExperienceGalleryImagesFragmentFragmentDoc}
`;
/**
 * __useExperienceGalleryImagesQuery__
 *
 * To run a query within a React component, call `useExperienceGalleryImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceGalleryImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceGalleryImagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceGalleryImagesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceGalleryImagesDocument, options);
}
export function useExperienceGalleryImagesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceGalleryImagesDocument, options);
}
export const ExperienceGalleryAttachImagesDocument = gql `
  mutation ExperienceGalleryAttachImages(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experience: experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceGalleryImagesFragment
    }
  }
  ${ExperienceGalleryImagesFragmentFragmentDoc}
`;
/**
 * __useExperienceGalleryAttachImagesMutation__
 *
 * To run a mutation, you first call `useExperienceGalleryAttachImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceGalleryAttachImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceGalleryAttachImagesMutation, { data, loading, error }] = useExperienceGalleryAttachImagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceGalleryAttachImagesMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceGalleryAttachImagesDocument, options);
}
export const ExperienceGalleryDetachImageDocument = gql `
  mutation ExperienceGalleryDetachImage(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experience: experienceUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ExperienceGalleryImagesFragment
    }
  }
  ${ExperienceGalleryImagesFragmentFragmentDoc}
`;
/**
 * __useExperienceGalleryDetachImageMutation__
 *
 * To run a mutation, you first call `useExperienceGalleryDetachImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceGalleryDetachImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceGalleryDetachImageMutation, { data, loading, error }] = useExperienceGalleryDetachImageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceGalleryDetachImageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceGalleryDetachImageDocument, options);
}
export const FundraisingSettingsByExperienceDocument = gql `
  query FundraisingSettingsByExperience($filter: ExperienceFundraisingFilter) {
    experienceFundraisingsList(filter: $filter) {
      items {
        id
        generalSettings {
          id
          isEnabled
          isObligation
          isWithoutPenalty
          pageSetUp
          date
          chargeDate
          isFacebookEnabled
          isInstagramEnabled
        }
        donationSettings {
          id
          isEnabled
          allowAnonymousDonations
          allowSelfDonations
          allowDonorsToOptIn
          allowDonorsToDedicateDonations
          coveredFees
          percentageAmount
          feesEnabled
          feesMessage
          roundupEnabled
          roundupMessage
          benefittingCharities
          benefittingCharitiesOrganizationName
        }
        teamSettings {
          id
          isEnabled
          teamCapabilities
          joiningTeamEnabled
          allowTeamsMakeTheirTeamInvite
          allowAttendeeRequestAdmissionOntoTeam
          maximumTeams
          maximumTeamMembers
          teamSharingEnabled
          appealMessage
          thankYouMessage
        }
        individualSettings {
          id
          isEnabled
          appealMessage
          thankYouMessage
        }
      }
    }
  }
`;
/**
 * __useFundraisingSettingsByExperienceQuery__
 *
 * To run a query within a React component, call `useFundraisingSettingsByExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundraisingSettingsByExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundraisingSettingsByExperienceQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFundraisingSettingsByExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FundraisingSettingsByExperienceDocument, options);
}
export function useFundraisingSettingsByExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FundraisingSettingsByExperienceDocument, options);
}
export const TopDonationExperienceDocument = gql `
  query TopDonationExperience($id: ID!) {
    topDonationByExperience(experienceId: $id) {
      items {
        fundraiserId
        user {
          id
          attendeeId
          firstName
          lastName
          fullName
          email
          status
          avatar {
            downloadUrl
          }
        }
        allocatedDonationsTotal {
          count
          total
        }
      }
    }
  }
`;
/**
 * __useTopDonationExperienceQuery__
 *
 * To run a query within a React component, call `useTopDonationExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopDonationExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopDonationExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopDonationExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TopDonationExperienceDocument, options);
}
export function useTopDonationExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TopDonationExperienceDocument, options);
}
export const TopAverageDonationExperienceDocument = gql `
  query TopAverageDonationExperience($id: ID!) {
    topAverageDonationByExperience(experienceId: $id) {
      items {
        fundraiserId
        user {
          id
          attendeeId
          firstName
          lastName
          fullName
          email
          status
          avatar {
            downloadUrl
          }
        }
        allocatedDonationsTotal {
          average
        }
      }
    }
  }
`;
/**
 * __useTopAverageDonationExperienceQuery__
 *
 * To run a query within a React component, call `useTopAverageDonationExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopAverageDonationExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopAverageDonationExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTopAverageDonationExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TopAverageDonationExperienceDocument, options);
}
export function useTopAverageDonationExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TopAverageDonationExperienceDocument, options);
}
export const FileDocument = gql `
  query File($id: ID, $fileId: String) {
    file(id: $id, fileId: $fileId) {
      ...File
    }
  }
  ${FileFragmentDoc}
`;
/**
 * __useFileQuery__
 *
 * To run a query within a React component, call `useFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FileDocument, options);
}
export function useFileLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FileDocument, options);
}
export const GenerateReportDocument = gql `
  mutation GenerateReport($report: ReportKeyFilter!) {
    commit: generateReport(report: $report) {
      success
      message
    }
  }
`;
/**
 * __useGenerateReportMutation__
 *
 * To run a mutation, you first call `useGenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReportMutation, { data, loading, error }] = useGenerateReportMutation({
 *   variables: {
 *      report: // value for 'report'
 *   },
 * });
 */
export function useGenerateReportMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(GenerateReportDocument, options);
}
export const GlobalOrganizationListDocument = gql `
  query GlobalOrganizationList($filter: GlobalOrganizationFilter, $skip: Int, $first: Int) {
    globalOrganizations: globalOrganizationsList(filter: $filter, skip: $skip, first: $first) {
      items {
        ...GlobalOrganization
      }
    }
  }
  ${GlobalOrganizationFragmentDoc}
`;
/**
 * __useGlobalOrganizationListQuery__
 *
 * To run a query within a React component, call `useGlobalOrganizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalOrganizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalOrganizationListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGlobalOrganizationListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GlobalOrganizationListDocument, options);
}
export function useGlobalOrganizationListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GlobalOrganizationListDocument, options);
}
export const IndividualFundraisingManagementDocument = gql `
  query IndividualFundraisingManagement($filter: IndividualFundraisingFilter!) {
    individualFundraisingsList(filter: $filter) {
      count
      items {
        ...INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT
      }
    }
  }
  ${Individual_Fundraising_Content_FragmentFragmentDoc}
`;
/**
 * __useIndividualFundraisingManagementQuery__
 *
 * To run a query within a React component, call `useIndividualFundraisingManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualFundraisingManagementQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingManagementQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(IndividualFundraisingManagementDocument, options);
}
export function useIndividualFundraisingManagementLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(IndividualFundraisingManagementDocument, options);
}
export const IndividualFundraisingUpdateContentDocument = gql `
  mutation IndividualFundraisingUpdateContent(
    $data: IndividualFundraisingUpdateInput!
    $filter: IndividualFundraisingKeyFilter!
  ) {
    individualFundraising: individualFundraisingUpdate(data: $data, filter: $filter) {
      ...INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT
    }
  }
  ${Individual_Fundraising_Content_FragmentFragmentDoc}
`;
/**
 * __useIndividualFundraisingUpdateContentMutation__
 *
 * To run a mutation, you first call `useIndividualFundraisingUpdateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingUpdateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [individualFundraisingUpdateContentMutation, { data, loading, error }] = useIndividualFundraisingUpdateContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingUpdateContentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IndividualFundraisingUpdateContentDocument, options);
}
export const IndividualFundraisingCreateContentDocument = gql `
  mutation IndividualFundraisingCreateContent($data: IndividualFundraisingCreateInput!) {
    individualFundraising: individualFundraisingCreate(data: $data) {
      ...INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT
    }
  }
  ${Individual_Fundraising_Management_FragmentFragmentDoc}
`;
/**
 * __useIndividualFundraisingCreateContentMutation__
 *
 * To run a mutation, you first call `useIndividualFundraisingCreateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingCreateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [individualFundraisingCreateContentMutation, { data, loading, error }] = useIndividualFundraisingCreateContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useIndividualFundraisingCreateContentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IndividualFundraisingCreateContentDocument, options);
}
export const IndividualFundraisingUpdateGalleryDocument = gql `
  mutation IndividualFundraisingUpdateGallery(
    $data: IndividualFundraisingUpdateInput!
    $filter: IndividualFundraisingKeyFilter!
  ) {
    individualFundraising: individualFundraisingUpdate(
      data: $data
      filter: $filter
      destroyDetached: true
    ) {
      ...INDIVIDUAL_FUNDRAISING_GALLERY_FRAGMENT
    }
  }
  ${Individual_Fundraising_Gallery_FragmentFragmentDoc}
`;
/**
 * __useIndividualFundraisingUpdateGalleryMutation__
 *
 * To run a mutation, you first call `useIndividualFundraisingUpdateGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingUpdateGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [individualFundraisingUpdateGalleryMutation, { data, loading, error }] = useIndividualFundraisingUpdateGalleryMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingUpdateGalleryMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IndividualFundraisingUpdateGalleryDocument, options);
}
export const IndividualFundraisingUpdateHeaderDocument = gql `
  mutation IndividualFundraisingUpdateHeader(
    $data: IndividualFundraisingUpdateInput!
    $filter: IndividualFundraisingKeyFilter!
  ) {
    individualFundraising: individualFundraisingUpdate(
      data: $data
      filter: $filter
      destroyDetached: true
    ) {
      ...INDIVIDUAL_FUNDRAISING_HEADER_FRAGMENT
    }
  }
  ${Individual_Fundraising_Header_FragmentFragmentDoc}
`;
/**
 * __useIndividualFundraisingUpdateHeaderMutation__
 *
 * To run a mutation, you first call `useIndividualFundraisingUpdateHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingUpdateHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [individualFundraisingUpdateHeaderMutation, { data, loading, error }] = useIndividualFundraisingUpdateHeaderMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingUpdateHeaderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IndividualFundraisingUpdateHeaderDocument, options);
}
export const IntegrationsListDocument = gql `
  query IntegrationsList {
    integrationsList {
      items {
        ...IntegrationDataFragment
      }
    }
  }
  ${IntegrationDataFragmentFragmentDoc}
`;
/**
 * __useIntegrationsListQuery__
 *
 * To run a query within a React component, call `useIntegrationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(IntegrationsListDocument, options);
}
export function useIntegrationsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(IntegrationsListDocument, options);
}
export const IntegrationCreateManyDocument = gql `
  mutation IntegrationCreateMany($data: [IntegrationCreateManyInput]!) {
    integrationCreateMany(data: $data) {
      items {
        ...IntegrationDataFragment
      }
    }
  }
  ${IntegrationDataFragmentFragmentDoc}
`;
/**
 * __useIntegrationCreateManyMutation__
 *
 * To run a mutation, you first call `useIntegrationCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationCreateManyMutation, { data, loading, error }] = useIntegrationCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useIntegrationCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IntegrationCreateManyDocument, options);
}
export const IntegrationUpdateDocument = gql `
  mutation IntegrationUpdate($id: ID!, $data: IntegrationUpdateInput!) {
    integrationUpdate(data: $data, filter: { id: $id }) {
      ...IntegrationDataFragment
    }
  }
  ${IntegrationDataFragmentFragmentDoc}
`;
/**
 * __useIntegrationUpdateMutation__
 *
 * To run a mutation, you first call `useIntegrationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationUpdateMutation, { data, loading, error }] = useIntegrationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useIntegrationUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IntegrationUpdateDocument, options);
}
export const IntegrationConfigListDocument = gql `
  query IntegrationConfigList($vizslaOrganizationId: String!) {
    integrationConfigsList(filter: { vizslaOrganizationId: { equals: $vizslaOrganizationId } }) {
      items {
        ...IntegrationConfigDataFragment
      }
    }
  }
  ${IntegrationConfigDataFragmentFragmentDoc}
`;
/**
 * __useIntegrationConfigListQuery__
 *
 * To run a query within a React component, call `useIntegrationConfigListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationConfigListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationConfigListQuery({
 *   variables: {
 *      vizslaOrganizationId: // value for 'vizslaOrganizationId'
 *   },
 * });
 */
export function useIntegrationConfigListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(IntegrationConfigListDocument, options);
}
export function useIntegrationConfigListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(IntegrationConfigListDocument, options);
}
export const IntegrationConfigCreateManyDocument = gql `
  mutation IntegrationConfigCreateMany($data: [IntegrationConfigCreateManyInput]!) {
    integrationConfigCreateMany(data: $data) {
      items {
        ...IntegrationConfigDataFragment
      }
    }
  }
  ${IntegrationConfigDataFragmentFragmentDoc}
`;
/**
 * __useIntegrationConfigCreateManyMutation__
 *
 * To run a mutation, you first call `useIntegrationConfigCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationConfigCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationConfigCreateManyMutation, { data, loading, error }] = useIntegrationConfigCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useIntegrationConfigCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IntegrationConfigCreateManyDocument, options);
}
export const IntegrationConfigUpdateDocument = gql `
  mutation IntegrationConfigUpdate($id: ID!, $data: IntegrationConfigUpdateInput!) {
    integrationConfigUpdate(data: $data, filter: { id: $id }) {
      ...IntegrationConfigDataFragment
    }
  }
  ${IntegrationConfigDataFragmentFragmentDoc}
`;
/**
 * __useIntegrationConfigUpdateMutation__
 *
 * To run a mutation, you first call `useIntegrationConfigUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntegrationConfigUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [integrationConfigUpdateMutation, { data, loading, error }] = useIntegrationConfigUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useIntegrationConfigUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IntegrationConfigUpdateDocument, options);
}
export const UploadTo8baseSystemDocument = gql `
  mutation uploadTo8baseSystem($url: String!) {
    system {
      fileUploadByUrl(data: { url: $url }) {
        fileId
      }
    }
  }
`;
/**
 * __useUploadTo8baseSystemMutation__
 *
 * To run a mutation, you first call `useUploadTo8baseSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTo8baseSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTo8baseSystemMutation, { data, loading, error }] = useUploadTo8baseSystemMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUploadTo8baseSystemMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UploadTo8baseSystemDocument, options);
}
export const InventoriesListByIdDocument = gql `
  query inventoriesListById($filter: InventoryFilter!) {
    inventoriesList(filter: $filter) {
      items {
        unitPrice
        storeAssetId
        OrgID
        id
        sales
        profit
        refund
        priceSales
        storeAssetName
        variantsValues
      }
    }
  }
`;
/**
 * __useInventoriesListByIdQuery__
 *
 * To run a query within a React component, call `useInventoriesListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoriesListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoriesListByIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInventoriesListByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(InventoriesListByIdDocument, options);
}
export function useInventoriesListByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(InventoriesListByIdDocument, options);
}
export const CreateOfflineRegistrationDocument = gql `
  mutation CreateOfflineRegistration($content: JSON!) {
    commit: createOfflineRegistration(content: $content) {
      success
    }
  }
`;
/**
 * __useCreateOfflineRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateOfflineRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfflineRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfflineRegistrationMutation, { data, loading, error }] = useCreateOfflineRegistrationMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateOfflineRegistrationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateOfflineRegistrationDocument, options);
}
export const OrganizationLogoUpdateDocument = gql `
  mutation OrganizationLogoUpdate(
    $data: VizslaOrganizationUpdateInput!
    $filter: VizslaOrganizationKeyFilter
  ) {
    vizslaOrganizationUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ORGANIZATION_LOGO_FRAGMENT
    }
  }
  ${Organization_Logo_FragmentFragmentDoc}
`;
/**
 * __useOrganizationLogoUpdateMutation__
 *
 * To run a mutation, you first call `useOrganizationLogoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLogoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationLogoUpdateMutation, { data, loading, error }] = useOrganizationLogoUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationLogoUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(OrganizationLogoUpdateDocument, options);
}
export const SocialMediaCreateDocument = gql `
  mutation SocialMediaCreate($data: SocialMediaCreateInput!) {
    socialMediaCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useSocialMediaCreateMutation__
 *
 * To run a mutation, you first call `useSocialMediaCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialMediaCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialMediaCreateMutation, { data, loading, error }] = useSocialMediaCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSocialMediaCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SocialMediaCreateDocument, options);
}
export const SocialMediaDocument = gql `
  query SocialMedia($filter: SocialMediaFilter, $skip: Int, $first: Int) {
    socialMedias: socialMediasList(filter: $filter, skip: $skip, first: $first) {
      items {
        id
        organization {
          id
          name
        }
        socialMedia
        uRL
      }
    }
  }
`;
/**
 * __useSocialMediaQuery__
 *
 * To run a query within a React component, call `useSocialMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialMediaQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSocialMediaQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SocialMediaDocument, options);
}
export function useSocialMediaLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SocialMediaDocument, options);
}
export const SocialMediaDeleteDocument = gql `
  mutation SocialMediaDelete($id: ID!) {
    socialMediaDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;
/**
 * __useSocialMediaDeleteMutation__
 *
 * To run a mutation, you first call `useSocialMediaDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialMediaDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialMediaDeleteMutation, { data, loading, error }] = useSocialMediaDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSocialMediaDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SocialMediaDeleteDocument, options);
}
export const GetOrganizationByExperienceDocument = gql `
  query GetOrganizationByExperience($id: ID!) {
    experience(id: $id) {
      id
      name
      timeZone
      venues {
        items {
          id
          name
          address
        }
      }
      campaign {
        vizslaOrganization {
          id
          name
          timeZone
        }
      }
    }
  }
`;
/**
 * __useGetOrganizationByExperienceQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationByExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetOrganizationByExperienceDocument, options);
}
export function useGetOrganizationByExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetOrganizationByExperienceDocument, options);
}
export const OrganizationImpactByIdDocument = gql `
  query OrganizationImpactByID($organizationID: ID!, $userID: ID!) {
    organization: vizslaOrganization(id: $organizationID) {
      id
      name
      ein
      contactEmail
      logo {
        ...FileURLFragment
      }
    }
    registrations: attendeesList(
      sort: { createdAt: ASC }
      filter: {
        AND: [
          { experience: { campaign: { vizslaOrganization: { id: { equals: $organizationID } } } } }
        ]
      }
    ) {
      items {
        id
        createdAt
      }
    }
    totalRaised: donationAllocationsList(
      filter: { AND: [{ campaign: { vizslaOrganization: { id: { equals: $organizationID } } } }] }
      groupBy: { query: { amount: { as: "totalRaised", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalRaised: Int
      }
    }
    averageDonation: donationAllocationsList(
      filter: {
        AND: [
          { campaign: { vizslaOrganization: { id: { equals: $organizationID } } } }
          { donationFulfillment: { donor: { id: { equals: $userID } } } }
        ]
      }
      groupBy: { query: { amount: { as: "averageDonation", fn: { aggregate: AVG } } } }
    ) {
      groups {
        averageDonation: Int
      }
    }
    totalDonations: donationAllocationsList(
      filter: {
        AND: [
          { campaign: { vizslaOrganization: { id: { equals: $organizationID } } } }
          { donationFulfillment: { donor: { id: { equals: $userID } } } }
        ]
      }
      groupBy: { query: { amount: { as: "totalDonations", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalDonations: Int
      }
    }
  }
  ${FileUrlFragmentFragmentDoc}
`;
/**
 * __useOrganizationImpactByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationImpactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationImpactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationImpactByIdQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useOrganizationImpactByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationImpactByIdDocument, options);
}
export function useOrganizationImpactByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationImpactByIdDocument, options);
}
export const GetPartnershipMatchingCommitmentsDocument = gql `
  query GetPartnershipMatchingCommitments($campaignId: String, $experienceId: String) {
    getPartnershipMatchingCommitments(
      data: { campaignId: $campaignId, experienceId: $experienceId }
    ) {
      matchingCommitments {
        amount
        partnershipName
        campaignId
        description
        endDate
        experienceId
        id
        partnershipOrganizationId
        usedAmount
        receivedAmount
        startDate
        status
        matchLimit
        type
      }
    }
  }
`;
/**
 * __useGetPartnershipMatchingCommitmentsQuery__
 *
 * To run a query within a React component, call `useGetPartnershipMatchingCommitmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnershipMatchingCommitmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnershipMatchingCommitmentsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useGetPartnershipMatchingCommitmentsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetPartnershipMatchingCommitmentsDocument, options);
}
export function useGetPartnershipMatchingCommitmentsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetPartnershipMatchingCommitmentsDocument, options);
}
export const PayCommitmentDocument = gql `
  mutation PayCommitment($data: PayCommitmentInput!) {
    payCommitment(data: $data) {
      success
      message
    }
  }
`;
/**
 * __usePayCommitmentMutation__
 *
 * To run a mutation, you first call `usePayCommitmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayCommitmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payCommitmentMutation, { data, loading, error }] = usePayCommitmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePayCommitmentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(PayCommitmentDocument, options);
}
export const FormQuestionsDocument = gql `
  query FormQuestions(
    $filterDefaultQuestions: DefaultCampaignQuestionFilter
    $filterCustomQuestions: CustomCampaignQuestionFilter
  ) {
    defaultQuestions: defaultCampaignQuestionsList(filter: $filterDefaultQuestions) {
      count
      items {
        ...DefaultCampaignQuestion
        question {
          ...DefaultQuestion
        }
      }
    }
    customQuestions: customCampaignQuestionsList(filter: $filterCustomQuestions) {
      count
      items {
        ...CustomQuestion
      }
    }
  }
  ${DefaultCampaignQuestionFragmentDoc}
  ${DefaultQuestionFragmentDoc}
  ${CustomQuestionFragmentDoc}
`;
/**
 * __useFormQuestionsQuery__
 *
 * To run a query within a React component, call `useFormQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQuestionsQuery({
 *   variables: {
 *      filterDefaultQuestions: // value for 'filterDefaultQuestions'
 *      filterCustomQuestions: // value for 'filterCustomQuestions'
 *   },
 * });
 */
export function useFormQuestionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FormQuestionsDocument, options);
}
export function useFormQuestionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FormQuestionsDocument, options);
}
export const CreateRefundItemDocument = gql `
  mutation CreateRefundItem(
    $orderId: String!
    $orderItemId: String!
    $assetInventoryId: String!
    $organizationId: String!
  ) {
    refundItem(
      data: {
        orderId: $orderId
        orderItemId: $orderItemId
        assetInventoryId: $assetInventoryId
        organizationId: $organizationId
      }
    ) {
      success
      message
    }
  }
`;
/**
 * __useCreateRefundItemMutation__
 *
 * To run a mutation, you first call `useCreateRefundItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRefundItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRefundItemMutation, { data, loading, error }] = useCreateRefundItemMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      orderItemId: // value for 'orderItemId'
 *      assetInventoryId: // value for 'assetInventoryId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateRefundItemMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateRefundItemDocument, options);
}
export const ReportDataSourcesDocument = gql `
  query ReportDataSources(
    $filter: ReportDataSourceFilter
    $first: Int
    $skip: Int
    $orderBy: [ReportDataSourceOrderBy!]
    $sortBy: [ReportDataSourceSort!]
  ) {
    datasources: reportDataSourcesList(
      filter: $filter
      first: $first
      skip: $skip
      orderBy: $orderBy
      sort: $sortBy
    ) {
      count
      items {
        ...ReportDataSource
      }
    }
  }
  ${ReportDataSourceFragmentDoc}
`;
/**
 * __useReportDataSourcesQuery__
 *
 * To run a query within a React component, call `useReportDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportDataSourcesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useReportDataSourcesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReportDataSourcesDocument, options);
}
export function useReportDataSourcesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReportDataSourcesDocument, options);
}
export const ReportFoldersDocument = gql `
  query ReportFolders($filter: ReportFolderFilter, $first: Int, $skip: Int) {
    folders: reportFoldersList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        ...ReportFolder
      }
    }
  }
  ${ReportFolderFragmentDoc}
`;
/**
 * __useReportFoldersQuery__
 *
 * To run a query within a React component, call `useReportFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportFoldersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useReportFoldersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReportFoldersDocument, options);
}
export function useReportFoldersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReportFoldersDocument, options);
}
export const ReportFolderCreateDocument = gql `
  mutation ReportFolderCreate($data: ReportFolderCreateInput!) {
    folder: reportFolderCreate(data: $data) {
      ...ReportFolder
    }
  }
  ${ReportFolderFragmentDoc}
`;
/**
 * __useReportFolderCreateMutation__
 *
 * To run a mutation, you first call `useReportFolderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportFolderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportFolderCreateMutation, { data, loading, error }] = useReportFolderCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReportFolderCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ReportFolderCreateDocument, options);
}
export const ReportFolderCreateManyDocument = gql `
  mutation ReportFolderCreateMany($data: [ReportFolderCreateManyInput!]!) {
    folders: reportFolderCreateMany(data: $data) {
      count
      items {
        ...ReportFolder
      }
    }
  }
  ${ReportFolderFragmentDoc}
`;
/**
 * __useReportFolderCreateManyMutation__
 *
 * To run a mutation, you first call `useReportFolderCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportFolderCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportFolderCreateManyMutation, { data, loading, error }] = useReportFolderCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReportFolderCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ReportFolderCreateManyDocument, options);
}
export const ReportFolderUpdateDocument = gql `
  mutation ReportFolderUpdate($filter: ReportFolderKeyFilter, $data: ReportFolderUpdateInput!) {
    folder: reportFolderUpdate(filter: $filter, data: $data) {
      ...ReportFolder
    }
  }
  ${ReportFolderFragmentDoc}
`;
/**
 * __useReportFolderUpdateMutation__
 *
 * To run a mutation, you first call `useReportFolderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportFolderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportFolderUpdateMutation, { data, loading, error }] = useReportFolderUpdateMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReportFolderUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ReportFolderUpdateDocument, options);
}
export const ReportFolderUpdateManyDocument = gql `
  mutation ReportFolderUpdateMany(
    $filter: ReportFolderFilter
    $data: ReportFolderUpdateByFilterInput!
  ) {
    folders: reportFolderUpdateByFilter(filter: $filter, data: $data) {
      count
      items {
        ...ReportFolder
      }
    }
  }
  ${ReportFolderFragmentDoc}
`;
/**
 * __useReportFolderUpdateManyMutation__
 *
 * To run a mutation, you first call `useReportFolderUpdateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportFolderUpdateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportFolderUpdateManyMutation, { data, loading, error }] = useReportFolderUpdateManyMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReportFolderUpdateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ReportFolderUpdateManyDocument, options);
}
export const ReportDocument = gql `
  query Report($id: ID!) {
    report(id: $id) {
      ...Report
    }
  }
  ${ReportFragmentDoc}
`;
/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReportDocument, options);
}
export function useReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReportDocument, options);
}
export const ReportsDocument = gql `
  query Reports(
    $filter: ReportFilter
    $first: Int
    $skip: Int
    $orderBy: [ReportOrderBy!]
    $sortBy: [ReportSort!]
  ) {
    reports: reportsList(
      filter: $filter
      first: $first
      skip: $skip
      orderBy: $orderBy
      sort: $sortBy
    ) {
      count
      items {
        ...Report
      }
    }
  }
  ${ReportFragmentDoc}
`;
/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useReportsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReportsDocument, options);
}
export function useReportsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReportsDocument, options);
}
export const ReportUpdateDocument = gql `
  mutation ReportUpdate($filter: ReportKeyFilter, $data: ReportUpdateInput!) {
    report: reportUpdate(filter: $filter, data: $data) {
      ...Report
    }
  }
  ${ReportFragmentDoc}
`;
/**
 * __useReportUpdateMutation__
 *
 * To run a mutation, you first call `useReportUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportUpdateMutation, { data, loading, error }] = useReportUpdateMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReportUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ReportUpdateDocument, options);
}
export const ReportRemoveDocument = gql `
  mutation ReportRemove($filter: ReportKeyFilter, $force: Boolean) {
    commit: reportDelete(filter: $filter, force: $force) {
      success
    }
  }
`;
/**
 * __useReportRemoveMutation__
 *
 * To run a mutation, you first call `useReportRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportRemoveMutation, { data, loading, error }] = useReportRemoveMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useReportRemoveMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ReportRemoveDocument, options);
}
export const DownloadActiveCampaignsAndExperiencesReportDocument = gql `
  query DownloadActiveCampaignsAndExperiencesReport(
    $report: ReportKeyFilter!
    $format: ActiveCampaignsAndExperiencesReportDownloadFormat
  ) {
    source: downloadActiveCampaignsAndExperiencesReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadActiveCampaignsAndExperiencesReportQuery__
 *
 * To run a query within a React component, call `useDownloadActiveCampaignsAndExperiencesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadActiveCampaignsAndExperiencesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadActiveCampaignsAndExperiencesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadActiveCampaignsAndExperiencesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadActiveCampaignsAndExperiencesReportDocument, options);
}
export function useDownloadActiveCampaignsAndExperiencesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadActiveCampaignsAndExperiencesReportDocument, options);
}
export const DownloadAttendeesReportDocument = gql `
  query DownloadAttendeesReport($report: ReportKeyFilter!, $format: AttendeesReportDownloadFormat) {
    source: downloadAttendeesReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadAttendeesReportQuery__
 *
 * To run a query within a React component, call `useDownloadAttendeesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadAttendeesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadAttendeesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadAttendeesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadAttendeesReportDocument, options);
}
export function useDownloadAttendeesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadAttendeesReportDocument, options);
}
export const DownloadCampaignsReportDocument = gql `
  query DownloadCampaignsReport($report: ReportKeyFilter!, $format: CampaignsReportDownloadFormat) {
    source: downloadCampaignsReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadCampaignsReportQuery__
 *
 * To run a query within a React component, call `useDownloadCampaignsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCampaignsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCampaignsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadCampaignsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadCampaignsReportDocument, options);
}
export function useDownloadCampaignsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadCampaignsReportDocument, options);
}
export const DownloadComparisonExperiencesReportDocument = gql `
  query DownloadComparisonExperiencesReport(
    $report: ReportKeyFilter!
    $format: ComparisonExperiencesReportDownloadFormat
  ) {
    source: downloadComparisonExperiencesReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadComparisonExperiencesReportQuery__
 *
 * To run a query within a React component, call `useDownloadComparisonExperiencesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadComparisonExperiencesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadComparisonExperiencesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadComparisonExperiencesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadComparisonExperiencesReportDocument, options);
}
export function useDownloadComparisonExperiencesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadComparisonExperiencesReportDocument, options);
}
export const DownloadCumulativeDonationGrowthReportDocument = gql `
  query DownloadCumulativeDonationGrowthReport(
    $report: ReportKeyFilter!
    $format: CumulativeDonationGrowthReportDownloadFormat
  ) {
    source: downloadCumulativeDonationGrowthReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadCumulativeDonationGrowthReportQuery__
 *
 * To run a query within a React component, call `useDownloadCumulativeDonationGrowthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCumulativeDonationGrowthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCumulativeDonationGrowthReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadCumulativeDonationGrowthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadCumulativeDonationGrowthReportDocument, options);
}
export function useDownloadCumulativeDonationGrowthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadCumulativeDonationGrowthReportDocument, options);
}
export const DownloadDonationsByMonthReportDocument = gql `
  query DownloadDonationsByMonthReport(
    $report: ReportKeyFilter!
    $format: DonationsByMonthReportDownloadFormat
  ) {
    source: downloadDonationsByMonthReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadDonationsByMonthReportQuery__
 *
 * To run a query within a React component, call `useDownloadDonationsByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadDonationsByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadDonationsByMonthReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadDonationsByMonthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadDonationsByMonthReportDocument, options);
}
export function useDownloadDonationsByMonthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadDonationsByMonthReportDocument, options);
}
export const DownloadDonationsImpactByMonthReportDocument = gql `
  query DownloadDonationsImpactByMonthReport(
    $report: ReportKeyFilter!
    $format: DonationsImpactByMonthReportDownloadFormat
  ) {
    source: downloadDonationsImpactByMonthReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadDonationsImpactByMonthReportQuery__
 *
 * To run a query within a React component, call `useDownloadDonationsImpactByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadDonationsImpactByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadDonationsImpactByMonthReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadDonationsImpactByMonthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadDonationsImpactByMonthReportDocument, options);
}
export function useDownloadDonationsImpactByMonthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadDonationsImpactByMonthReportDocument, options);
}
export const DownloadDonationsReportDocument = gql `
  query DownloadDonationsReport($report: ReportKeyFilter!, $format: DonationsReportDownloadFormat) {
    source: downloadDonationsReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadDonationsReportQuery__
 *
 * To run a query within a React component, call `useDownloadDonationsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadDonationsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadDonationsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadDonationsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadDonationsReportDocument, options);
}
export function useDownloadDonationsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadDonationsReportDocument, options);
}
export const DownloadDonationsSourcesReportDocument = gql `
  query DownloadDonationsSourcesReport(
    $report: ReportKeyFilter!
    $format: DonationsSourcesReportDownloadFormat
  ) {
    source: downloadDonationsSourcesReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadDonationsSourcesReportQuery__
 *
 * To run a query within a React component, call `useDownloadDonationsSourcesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadDonationsSourcesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadDonationsSourcesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadDonationsSourcesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadDonationsSourcesReportDocument, options);
}
export function useDownloadDonationsSourcesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadDonationsSourcesReportDocument, options);
}
export const DownloadExperiencesReportDocument = gql `
  query DownloadExperiencesReport(
    $report: ReportKeyFilter!
    $format: ExperiencesReportDownloadFormat
  ) {
    source: downloadExperiencesReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadExperiencesReportQuery__
 *
 * To run a query within a React component, call `useDownloadExperiencesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadExperiencesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadExperiencesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadExperiencesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadExperiencesReportDocument, options);
}
export function useDownloadExperiencesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadExperiencesReportDocument, options);
}
export const DownloadFailingRecurringGivingPlansReportDocument = gql `
  query DownloadFailingRecurringGivingPlansReport(
    $report: ReportKeyFilter!
    $format: FailingRecurringGivingPlansReportDownloadFormat
  ) {
    source: downloadFailingRecurringGivingPlansReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadFailingRecurringGivingPlansReportQuery__
 *
 * To run a query within a React component, call `useDownloadFailingRecurringGivingPlansReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadFailingRecurringGivingPlansReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadFailingRecurringGivingPlansReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadFailingRecurringGivingPlansReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadFailingRecurringGivingPlansReportDocument, options);
}
export function useDownloadFailingRecurringGivingPlansReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadFailingRecurringGivingPlansReportDocument, options);
}
export const DownloadMedianDonationsMonthReportDocument = gql `
  query DownloadMedianDonationsMonthReport(
    $report: ReportKeyFilter!
    $format: MedianDonationsMonthReportDownloadFormat
  ) {
    source: downloadMedianDonationsMonthReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadMedianDonationsMonthReportQuery__
 *
 * To run a query within a React component, call `useDownloadMedianDonationsMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadMedianDonationsMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadMedianDonationsMonthReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadMedianDonationsMonthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadMedianDonationsMonthReportDocument, options);
}
export function useDownloadMedianDonationsMonthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadMedianDonationsMonthReportDocument, options);
}
export const DownloadMerchandiseReportDocument = gql `
  query DownloadMerchandiseReport(
    $report: ReportKeyFilter!
    $format: MerchandiseReportDownloadFormat
  ) {
    source: downloadMerchandiseReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadMerchandiseReportQuery__
 *
 * To run a query within a React component, call `useDownloadMerchandiseReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadMerchandiseReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadMerchandiseReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadMerchandiseReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadMerchandiseReportDocument, options);
}
export function useDownloadMerchandiseReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadMerchandiseReportDocument, options);
}
export const DownloadPartnershipsReportDocument = gql `
  query DownloadPartnershipsReport(
    $report: ReportKeyFilter!
    $format: downloadPartnershipsReportDownloadFormat
  ) {
    source: downloadPartnershipsReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadPartnershipsReportQuery__
 *
 * To run a query within a React component, call `useDownloadPartnershipsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadPartnershipsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadPartnershipsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadPartnershipsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadPartnershipsReportDocument, options);
}
export function useDownloadPartnershipsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadPartnershipsReportDocument, options);
}
export const DownloadPeopleReportDocument = gql `
  query DownloadPeopleReport($report: ReportKeyFilter!, $format: PeopleReportDownloadFormat) {
    source: downloadPeopleReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadPeopleReportQuery__
 *
 * To run a query within a React component, call `useDownloadPeopleReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadPeopleReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadPeopleReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadPeopleReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadPeopleReportDocument, options);
}
export function useDownloadPeopleReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadPeopleReportDocument, options);
}
export const DownloadRevenueCategoriesByMonthReportDocument = gql `
  query DownloadRevenueCategoriesByMonthReport(
    $report: ReportKeyFilter!
    $format: RevenueCategoriesByMonthReportDownloadFormat
  ) {
    source: downloadRevenueCategoriesByMonthReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadRevenueCategoriesByMonthReportQuery__
 *
 * To run a query within a React component, call `useDownloadRevenueCategoriesByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadRevenueCategoriesByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadRevenueCategoriesByMonthReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadRevenueCategoriesByMonthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadRevenueCategoriesByMonthReportDocument, options);
}
export function useDownloadRevenueCategoriesByMonthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadRevenueCategoriesByMonthReportDocument, options);
}
export const DownloadRevenueCategoriesByYearReportDocument = gql `
  query DownloadRevenueCategoriesByYearReport(
    $report: ReportKeyFilter!
    $format: RevenueCategoriesByYearReportDownloadFormat
  ) {
    source: downloadRevenueCategoriesByYearReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadRevenueCategoriesByYearReportQuery__
 *
 * To run a query within a React component, call `useDownloadRevenueCategoriesByYearReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadRevenueCategoriesByYearReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadRevenueCategoriesByYearReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadRevenueCategoriesByYearReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadRevenueCategoriesByYearReportDocument, options);
}
export function useDownloadRevenueCategoriesByYearReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadRevenueCategoriesByYearReportDocument, options);
}
export const DownloadTeamsReportDocument = gql `
  query DownloadTeamsReport($report: ReportKeyFilter!, $format: TeamsReportDownloadFormat) {
    source: downloadTeamsReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadTeamsReportQuery__
 *
 * To run a query within a React component, call `useDownloadTeamsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadTeamsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadTeamsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadTeamsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadTeamsReportDocument, options);
}
export function useDownloadTeamsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadTeamsReportDocument, options);
}
export const DownloadTransactionsReportDocument = gql `
  query DownloadTransactionsReport(
    $report: ReportKeyFilter!
    $format: TransactionsReportDownloadFormat
  ) {
    source: downloadTransactionsReport(report: $report, format: $format) {
      filename
      downloadUrl
    }
  }
`;
/**
 * __useDownloadTransactionsReportQuery__
 *
 * To run a query within a React component, call `useDownloadTransactionsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadTransactionsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadTransactionsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useDownloadTransactionsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DownloadTransactionsReportDocument, options);
}
export function useDownloadTransactionsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DownloadTransactionsReportDocument, options);
}
export const ReadActiveCampaignsAndExperiencesReportDocument = gql `
  query ReadActiveCampaignsAndExperiencesReport(
    $report: ReportKeyFilter!
    $options: ReadActiveCampaignsAndExperiencesReportOptions
  ) {
    report: readActiveCampaignsAndExperiencesReport(report: $report, options: $options) {
      count
      items {
        ...ActiveCampaignsAndExperiencesReport
      }
      report {
        ...Report
      }
    }
  }
  ${ActiveCampaignsAndExperiencesReportFragmentDoc}
  ${ReportFragmentDoc}
`;
/**
 * __useReadActiveCampaignsAndExperiencesReportQuery__
 *
 * To run a query within a React component, call `useReadActiveCampaignsAndExperiencesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadActiveCampaignsAndExperiencesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadActiveCampaignsAndExperiencesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadActiveCampaignsAndExperiencesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadActiveCampaignsAndExperiencesReportDocument, options);
}
export function useReadActiveCampaignsAndExperiencesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadActiveCampaignsAndExperiencesReportDocument, options);
}
export const GetActiveCampaignsAndExperiencesLastMonthsDocument = gql `
  query GetActiveCampaignsAndExperiencesLastMonths(
    $data: GetActiveCampaignsAndExperiencesLastMonthsInput
  ) {
    getActiveCampaignsAndExperiencesLastMonths(data: $data) {
      activeCampaignsAndExperiences {
        month
        numberOfCampaigns
        numberOfExperiences
      }
    }
  }
`;
/**
 * __useGetActiveCampaignsAndExperiencesLastMonthsQuery__
 *
 * To run a query within a React component, call `useGetActiveCampaignsAndExperiencesLastMonthsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCampaignsAndExperiencesLastMonthsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCampaignsAndExperiencesLastMonthsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetActiveCampaignsAndExperiencesLastMonthsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetActiveCampaignsAndExperiencesLastMonthsDocument, options);
}
export function useGetActiveCampaignsAndExperiencesLastMonthsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetActiveCampaignsAndExperiencesLastMonthsDocument, options);
}
export const ReadActiveUsersByTypeReportDocument = gql `
  query ReadActiveUsersByTypeReport(
    $report: ReportKeyFilter!
    $options: ReadActiveUsersByTypeReportOptions
  ) {
    report: readActiveUsersByTypeReport(report: $report, options: $options) {
      count
      items {
        ...ActiveUsersByTypeReport
      }
      report {
        ...Report
      }
    }
  }
  ${ActiveUsersByTypeReportFragmentDoc}
  ${ReportFragmentDoc}
`;
/**
 * __useReadActiveUsersByTypeReportQuery__
 *
 * To run a query within a React component, call `useReadActiveUsersByTypeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadActiveUsersByTypeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadActiveUsersByTypeReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadActiveUsersByTypeReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadActiveUsersByTypeReportDocument, options);
}
export function useReadActiveUsersByTypeReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadActiveUsersByTypeReportDocument, options);
}
export const GetActiveUsersByTypeLastMonthsDocument = gql `
  query GetActiveUsersByTypeLastMonths($data: GetActiveUsersByTypeLastMonthsInput) {
    getActiveUsersByTypeLastMonths(data: $data) {
      activeUsersByType {
        month
        numberOfTeams
        numberOfDonors
        numberOfAttendees
        numberOfFundraisers
      }
    }
  }
`;
/**
 * __useGetActiveUsersByTypeLastMonthsQuery__
 *
 * To run a query within a React component, call `useGetActiveUsersByTypeLastMonthsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveUsersByTypeLastMonthsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveUsersByTypeLastMonthsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetActiveUsersByTypeLastMonthsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetActiveUsersByTypeLastMonthsDocument, options);
}
export function useGetActiveUsersByTypeLastMonthsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetActiveUsersByTypeLastMonthsDocument, options);
}
export const ReadAdoptionByOrganizationReportDocument = gql `
  query ReadAdoptionByOrganizationReport(
    $report: ReportKeyFilter!
    $options: ReadAdoptionByOrganizationReportOptions
  ) {
    report: readAdoptionByOrganizationReport(report: $report, options: $options) {
      count
      items {
        ...AdoptionByOrganizationReport
      }
      report {
        ...Report
      }
    }
  }
  ${AdoptionByOrganizationReportFragmentDoc}
  ${ReportFragmentDoc}
`;
/**
 * __useReadAdoptionByOrganizationReportQuery__
 *
 * To run a query within a React component, call `useReadAdoptionByOrganizationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAdoptionByOrganizationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAdoptionByOrganizationReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadAdoptionByOrganizationReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadAdoptionByOrganizationReportDocument, options);
}
export function useReadAdoptionByOrganizationReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadAdoptionByOrganizationReportDocument, options);
}
export const GetAdoptionByOrganizationDocument = gql `
  query GetAdoptionByOrganization($data: GetAdoptionByOrganizationInput) {
    getAdoptionByOrganization(data: $data) {
      adoptionByOrganization {
        month
        organizationsActive
        usersActive
      }
    }
  }
`;
/**
 * __useGetAdoptionByOrganizationQuery__
 *
 * To run a query within a React component, call `useGetAdoptionByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdoptionByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdoptionByOrganizationQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetAdoptionByOrganizationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAdoptionByOrganizationDocument, options);
}
export function useGetAdoptionByOrganizationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAdoptionByOrganizationDocument, options);
}
export const GetAttendeeRegistrationsReportQueryDocument = gql `
  query GetAttendeeRegistrationsReportQuery($data: GetAttendeeRegistrationsReportInput) {
    getAttendeeRegistrationsReport(data: $data) {
      attendeeRegistrations {
        month
        numberOfAttendees
      }
    }
  }
`;
/**
 * __useGetAttendeeRegistrationsReportQueryQuery__
 *
 * To run a query within a React component, call `useGetAttendeeRegistrationsReportQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendeeRegistrationsReportQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendeeRegistrationsReportQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetAttendeeRegistrationsReportQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAttendeeRegistrationsReportQueryDocument, options);
}
export function useGetAttendeeRegistrationsReportQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAttendeeRegistrationsReportQueryDocument, options);
}
export const ReadAttendeesReportDocument = gql `
  query ReadAttendeesReport($report: ReportKeyFilter!, $options: ReadAttendeesReportOptions) {
    report: readAttendeesReport(report: $report, options: $options) {
      count
      items {
        ...AttendeesReport
      }
      report {
        ...Report
      }
      metadata {
        ...AttendeesReportMetadata
      }
    }
  }
  ${AttendeesReportFragmentDoc}
  ${ReportFragmentDoc}
  ${AttendeesReportMetadataFragmentDoc}
`;
/**
 * __useReadAttendeesReportQuery__
 *
 * To run a query within a React component, call `useReadAttendeesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAttendeesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAttendeesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadAttendeesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadAttendeesReportDocument, options);
}
export function useReadAttendeesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadAttendeesReportDocument, options);
}
export const ReadCampaignsReportDocument = gql `
  query ReadCampaignsReport($report: ReportKeyFilter!, $options: ReadCampaignsReportOptions) {
    report: readCampaignsReport(report: $report, options: $options) {
      count
      items {
        ...CampaignsReport
      }
      report {
        ...Report
      }
      metadata {
        ...CampaignsReportMetadata
      }
    }
  }
  ${CampaignsReportFragmentDoc}
  ${ReportFragmentDoc}
  ${CampaignsReportMetadataFragmentDoc}
`;
/**
 * __useReadCampaignsReportQuery__
 *
 * To run a query within a React component, call `useReadCampaignsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCampaignsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCampaignsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadCampaignsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadCampaignsReportDocument, options);
}
export function useReadCampaignsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadCampaignsReportDocument, options);
}
export const ReadComparisonExperiencesReportDocument = gql `
  query ReadComparisonExperiencesReport(
    $report: ReportKeyFilter!
    $options: ReadComparisonExperiencesReportOptions
  ) {
    report: readComparisonExperiencesReport(report: $report, options: $options) {
      count
      attendeeRetention
      returningAttendees
      experiencePrimary {
        id
        experienceName
        experienceStartDate
        experienceEndDate
        experienceType
        campaignName
      }
      experienceComparison {
        id
        experienceName
        experienceStartDate
        experienceEndDate
        experienceType
        campaignName
      }
      items {
        ...ComparisonExperiencesReport
      }
      report {
        ...Report
      }
      metadata {
        ...ComparisonExperiencesReportMetadata
      }
    }
  }
  ${ComparisonExperiencesReportFragmentDoc}
  ${ReportFragmentDoc}
  ${ComparisonExperiencesReportMetadataFragmentDoc}
`;
/**
 * __useReadComparisonExperiencesReportQuery__
 *
 * To run a query within a React component, call `useReadComparisonExperiencesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadComparisonExperiencesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadComparisonExperiencesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadComparisonExperiencesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadComparisonExperiencesReportDocument, options);
}
export function useReadComparisonExperiencesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadComparisonExperiencesReportDocument, options);
}
export const ReadCumulativeDonationGrowthReportDocument = gql `
  query ReadCumulativeDonationGrowthReport(
    $report: ReportKeyFilter!
    $options: ReadCumulativeDonationGrowthReportOptions
  ) {
    report: readCumulativeDonationGrowthReport(report: $report, options: $options) {
      count
      items {
        ...CumulativeDonationGrowthReport
      }
      report {
        ...Report
      }
      metadata {
        ...CumulativeDonationGrowthReportMetadata
      }
    }
  }
  ${CumulativeDonationGrowthReportFragmentDoc}
  ${ReportFragmentDoc}
  ${CumulativeDonationGrowthReportMetadataFragmentDoc}
`;
/**
 * __useReadCumulativeDonationGrowthReportQuery__
 *
 * To run a query within a React component, call `useReadCumulativeDonationGrowthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCumulativeDonationGrowthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCumulativeDonationGrowthReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadCumulativeDonationGrowthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadCumulativeDonationGrowthReportDocument, options);
}
export function useReadCumulativeDonationGrowthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadCumulativeDonationGrowthReportDocument, options);
}
export const ReadDonationImpactByYearReportDocument = gql `
  query ReadDonationImpactByYearReport(
    $report: ReportKeyFilter!
    $options: ReadDonationImpactByYearReportOptions
  ) {
    report: readDonationImpactByYearReport(report: $report, options: $options) {
      count
      items {
        ...ReadDonationImpactByYearReport
      }
      report {
        ...Report
      }
      metadata {
        ...ReadDonationImpactByYearReportMetadata
      }
    }
  }
  ${ReadDonationImpactByYearReportFragmentDoc}
  ${ReportFragmentDoc}
  ${ReadDonationImpactByYearReportMetadataFragmentDoc}
`;
/**
 * __useReadDonationImpactByYearReportQuery__
 *
 * To run a query within a React component, call `useReadDonationImpactByYearReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDonationImpactByYearReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDonationImpactByYearReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadDonationImpactByYearReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadDonationImpactByYearReportDocument, options);
}
export function useReadDonationImpactByYearReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadDonationImpactByYearReportDocument, options);
}
export const ReadDonationsByMonthReportDocument = gql `
  query ReadDonationsByMonthReport(
    $report: ReportKeyFilter!
    $options: ReadDonationsByMonthReportOptions
  ) {
    report: readDonationsByMonthReport(report: $report, options: $options) {
      count
      items {
        amount
        month
        averageAmount
      }
      report {
        ...Report
      }
    }
  }
  ${ReportFragmentDoc}
`;
/**
 * __useReadDonationsByMonthReportQuery__
 *
 * To run a query within a React component, call `useReadDonationsByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDonationsByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDonationsByMonthReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadDonationsByMonthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadDonationsByMonthReportDocument, options);
}
export function useReadDonationsByMonthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadDonationsByMonthReportDocument, options);
}
export const GetDonationsByMonthReportDocument = gql `
  query GetDonationsByMonthReport($data: GetDonationsByMonthInput) {
    getDonationsByMonthReport(data: $data) {
      donationsByMonth {
        month
        amount
        averageAmount
      }
    }
  }
`;
/**
 * __useGetDonationsByMonthReportQuery__
 *
 * To run a query within a React component, call `useGetDonationsByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonationsByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonationsByMonthReportQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDonationsByMonthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetDonationsByMonthReportDocument, options);
}
export function useGetDonationsByMonthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetDonationsByMonthReportDocument, options);
}
export const ReadDonationsImpactByMonthReportDocument = gql `
  query ReadDonationsImpactByMonthReport(
    $report: ReportKeyFilter!
    $options: ReadDonationsImpactByMonthReportOptions
  ) {
    report: readDonationsImpactByMonthReport(report: $report, options: $options) {
      count
      items {
        ...DonationsImpactByMonthReport
      }
      report {
        ...Report
      }
      metadata {
        ...DonationsImpactByMonthReportMetadata
      }
    }
  }
  ${DonationsImpactByMonthReportFragmentDoc}
  ${ReportFragmentDoc}
  ${DonationsImpactByMonthReportMetadataFragmentDoc}
`;
/**
 * __useReadDonationsImpactByMonthReportQuery__
 *
 * To run a query within a React component, call `useReadDonationsImpactByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDonationsImpactByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDonationsImpactByMonthReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadDonationsImpactByMonthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadDonationsImpactByMonthReportDocument, options);
}
export function useReadDonationsImpactByMonthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadDonationsImpactByMonthReportDocument, options);
}
export const ReadDonationsReportDocument = gql `
  query ReadDonationsReport($report: ReportKeyFilter!, $options: ReadDonationsReportOptions) {
    report: readDonationsReport(report: $report, options: $options) {
      count
      items {
        ...DonationsReport
      }
      report {
        ...Report
      }
      metadata {
        ...DonationsReportMetadata
      }
    }
  }
  ${DonationsReportFragmentDoc}
  ${ReportFragmentDoc}
  ${DonationsReportMetadataFragmentDoc}
`;
/**
 * __useReadDonationsReportQuery__
 *
 * To run a query within a React component, call `useReadDonationsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDonationsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDonationsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadDonationsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadDonationsReportDocument, options);
}
export function useReadDonationsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadDonationsReportDocument, options);
}
export const ReadDonationsSourcesReportDocument = gql `
  query ReadDonationsSourcesReport(
    $report: ReportKeyFilter!
    $options: ReadDonationsSourcesReportOptions
  ) {
    report: readDonationsSourcesReport(report: $report, options: $options) {
      count
      items {
        ...DonationsSourcesReport
      }
      report {
        ...Report
      }
      metadata {
        ...DonationsSourcesReportMetadata
      }
    }
  }
  ${DonationsSourcesReportFragmentDoc}
  ${ReportFragmentDoc}
  ${DonationsSourcesReportMetadataFragmentDoc}
`;
/**
 * __useReadDonationsSourcesReportQuery__
 *
 * To run a query within a React component, call `useReadDonationsSourcesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDonationsSourcesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDonationsSourcesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadDonationsSourcesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadDonationsSourcesReportDocument, options);
}
export function useReadDonationsSourcesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadDonationsSourcesReportDocument, options);
}
export const GetDonorsPerFundraiserByMonthReportDocument = gql `
  query GetDonorsPerFundraiserByMonthReport($data: GetDonorsPerFundraiserByMonthInput) {
    getDonorsPerFundraiserByMonth(data: $data) {
      donorsPerFundraiser {
        donors
        month
        donorIds
      }
    }
  }
`;
/**
 * __useGetDonorsPerFundraiserByMonthReportQuery__
 *
 * To run a query within a React component, call `useGetDonorsPerFundraiserByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonorsPerFundraiserByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonorsPerFundraiserByMonthReportQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDonorsPerFundraiserByMonthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetDonorsPerFundraiserByMonthReportDocument, options);
}
export function useGetDonorsPerFundraiserByMonthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetDonorsPerFundraiserByMonthReportDocument, options);
}
export const ReadDonorsPerFundraiserReportDocument = gql `
  query ReadDonorsPerFundraiserReport(
    $report: ReportKeyFilter!
    $options: ReadDonorsPerFundraiserReportOptions
  ) {
    report: readDonorsPerFundraiserReport(report: $report, options: $options) {
      count
      items {
        ...DonorsPerFundraiserReport
      }
      report {
        ...Report
      }
    }
  }
  ${DonorsPerFundraiserReportFragmentDoc}
  ${ReportFragmentDoc}
`;
/**
 * __useReadDonorsPerFundraiserReportQuery__
 *
 * To run a query within a React component, call `useReadDonorsPerFundraiserReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDonorsPerFundraiserReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDonorsPerFundraiserReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadDonorsPerFundraiserReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadDonorsPerFundraiserReportDocument, options);
}
export function useReadDonorsPerFundraiserReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadDonorsPerFundraiserReportDocument, options);
}
export const GetDonorsPerFundraiserDocument = gql `
  query GetDonorsPerFundraiser($data: GetDonorsPerFundraiserByMonthInput) {
    getDonorsPerFundraiserByMonth(data: $data) {
      donorsPerFundraiser {
        donorIds
        donors
        month
      }
    }
  }
`;
/**
 * __useGetDonorsPerFundraiserQuery__
 *
 * To run a query within a React component, call `useGetDonorsPerFundraiserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDonorsPerFundraiserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDonorsPerFundraiserQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDonorsPerFundraiserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetDonorsPerFundraiserDocument, options);
}
export function useGetDonorsPerFundraiserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetDonorsPerFundraiserDocument, options);
}
export const ReadExperiencesReportDocument = gql `
  query ReadExperiencesReport($report: ReportKeyFilter!, $options: ReadExperiencesReportOptions) {
    report: readExperiencesReport(report: $report, options: $options) {
      count
      items {
        ...ExperiencesReport
      }
      report {
        ...Report
      }
      metadata {
        ...ExperiencesReportMetadata
      }
    }
  }
  ${ExperiencesReportFragmentDoc}
  ${ReportFragmentDoc}
  ${ExperiencesReportMetadataFragmentDoc}
`;
/**
 * __useReadExperiencesReportQuery__
 *
 * To run a query within a React component, call `useReadExperiencesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadExperiencesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadExperiencesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadExperiencesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadExperiencesReportDocument, options);
}
export function useReadExperiencesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadExperiencesReportDocument, options);
}
export const ReadFailingRecurringGivingPlansReportDocument = gql `
  query ReadFailingRecurringGivingPlansReport(
    $report: ReportKeyFilter!
    $options: ReadFailingRecurringGivingPlansReportOptions
  ) {
    report: readFailingRecurringGivingPlansReport(report: $report, options: $options) {
      count
      items {
        ...FailingRecurringGivingPlansReport
      }
      report {
        ...Report
      }
      metadata {
        ...FailingRecurringGivingPlansReportMetadata
      }
    }
  }
  ${FailingRecurringGivingPlansReportFragmentDoc}
  ${ReportFragmentDoc}
  ${FailingRecurringGivingPlansReportMetadataFragmentDoc}
`;
/**
 * __useReadFailingRecurringGivingPlansReportQuery__
 *
 * To run a query within a React component, call `useReadFailingRecurringGivingPlansReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadFailingRecurringGivingPlansReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadFailingRecurringGivingPlansReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadFailingRecurringGivingPlansReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadFailingRecurringGivingPlansReportDocument, options);
}
export function useReadFailingRecurringGivingPlansReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadFailingRecurringGivingPlansReportDocument, options);
}
export const GetLastDonationActivityMonthReportQueryDocument = gql `
  query GetLastDonationActivityMonthReportQuery($data: GetLastDonationActivityMonthReportInput) {
    getLastDonationActivityMonthReport(data: $data) {
      donationsByMonth {
        month
        oneTimeDonations
        recurringDonations
        oneTimeDonors
        recurringDonors
      }
    }
  }
`;
/**
 * __useGetLastDonationActivityMonthReportQueryQuery__
 *
 * To run a query within a React component, call `useGetLastDonationActivityMonthReportQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastDonationActivityMonthReportQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastDonationActivityMonthReportQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetLastDonationActivityMonthReportQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetLastDonationActivityMonthReportQueryDocument, options);
}
export function useGetLastDonationActivityMonthReportQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetLastDonationActivityMonthReportQueryDocument, options);
}
export const ReadMedianDonationsMonthReportDocument = gql `
  query ReadMedianDonationsMonthReport(
    $report: ReportKeyFilter!
    $options: ReadMedianDonationsMonthReportOptions
  ) {
    report: readMedianDonationsMonthReport(report: $report, options: $options) {
      count
      items {
        ...MedianDonationsMonthReport
      }
      report {
        ...Report
      }
      metadata {
        ...MedianDonationsMonthReportMetadata
      }
    }
  }
  ${MedianDonationsMonthReportFragmentDoc}
  ${ReportFragmentDoc}
  ${MedianDonationsMonthReportMetadataFragmentDoc}
`;
/**
 * __useReadMedianDonationsMonthReportQuery__
 *
 * To run a query within a React component, call `useReadMedianDonationsMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMedianDonationsMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMedianDonationsMonthReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadMedianDonationsMonthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadMedianDonationsMonthReportDocument, options);
}
export function useReadMedianDonationsMonthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadMedianDonationsMonthReportDocument, options);
}
export const ReadMerchandiseReportDocument = gql `
  query ReadMerchandiseReport($report: ReportKeyFilter!, $options: ReadMerchandiseReportOptions) {
    report: readMerchandiseReport(report: $report, options: $options) {
      count
      items {
        ...MerchandiseReport
      }
      report {
        ...Report
      }
      metadata {
        ...MerchandiseReportMetadata
      }
    }
  }
  ${MerchandiseReportFragmentDoc}
  ${ReportFragmentDoc}
  ${MerchandiseReportMetadataFragmentDoc}
`;
/**
 * __useReadMerchandiseReportQuery__
 *
 * To run a query within a React component, call `useReadMerchandiseReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMerchandiseReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMerchandiseReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadMerchandiseReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadMerchandiseReportDocument, options);
}
export function useReadMerchandiseReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadMerchandiseReportDocument, options);
}
export const ReadNonProfitRevenueSourcesReportDocument = gql `
  query ReadNonProfitRevenueSourcesReport(
    $report: ReportKeyFilter!
    $options: ReadNonProfitRevenueSourcesReportOptions
  ) {
    report: readNonProfitRevenueSourcesReport(report: $report, options: $options) {
      count
      items {
        ...NonProfitRevenueSourcesReport
      }
      report {
        ...Report
      }
    }
  }
  ${NonProfitRevenueSourcesReportFragmentDoc}
  ${ReportFragmentDoc}
`;
/**
 * __useReadNonProfitRevenueSourcesReportQuery__
 *
 * To run a query within a React component, call `useReadNonProfitRevenueSourcesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadNonProfitRevenueSourcesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadNonProfitRevenueSourcesReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadNonProfitRevenueSourcesReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadNonProfitRevenueSourcesReportDocument, options);
}
export function useReadNonProfitRevenueSourcesReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadNonProfitRevenueSourcesReportDocument, options);
}
export const GetNonProfitRevenueSourcesLastMonthsDocument = gql `
  query GetNonProfitRevenueSourcesLastMonths($data: GetNonProfitRevenueSourcesLastMonthsInput) {
    getNonProfitRevenueSourcesLastMonths(data: $data) {
      nonProfitRevenueSources {
        month
        taxes
        collieRevenue
        donations
        merchandise
        registration
        sponsorship
      }
    }
  }
`;
/**
 * __useGetNonProfitRevenueSourcesLastMonthsQuery__
 *
 * To run a query within a React component, call `useGetNonProfitRevenueSourcesLastMonthsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonProfitRevenueSourcesLastMonthsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonProfitRevenueSourcesLastMonthsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetNonProfitRevenueSourcesLastMonthsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetNonProfitRevenueSourcesLastMonthsDocument, options);
}
export function useGetNonProfitRevenueSourcesLastMonthsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetNonProfitRevenueSourcesLastMonthsDocument, options);
}
export const ReadPartnershipsReportDocument = gql `
  query ReadPartnershipsReport($report: ReportKeyFilter!, $options: ReadPartnershipsReportOptions) {
    report: readPartnershipsReport(report: $report, options: $options) {
      count
      items {
        ...PartnershipsReport
      }
      report {
        ...Report
      }
      metadata {
        ...PartnershipsReportMetadata
      }
    }
  }
  ${PartnershipsReportFragmentDoc}
  ${ReportFragmentDoc}
  ${PartnershipsReportMetadataFragmentDoc}
`;
/**
 * __useReadPartnershipsReportQuery__
 *
 * To run a query within a React component, call `useReadPartnershipsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPartnershipsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPartnershipsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadPartnershipsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadPartnershipsReportDocument, options);
}
export function useReadPartnershipsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadPartnershipsReportDocument, options);
}
export const ReadPeopleReportDocument = gql `
  query ReadPeopleReport($report: ReportKeyFilter!, $options: ReadPeopleReportOptions) {
    report: readPeopleReport(report: $report, options: $options) {
      count
      items {
        ...PeopleReport
      }
      report {
        ...Report
      }
      metadata {
        ...PeopleReportMetadata
      }
    }
  }
  ${PeopleReportFragmentDoc}
  ${ReportFragmentDoc}
  ${PeopleReportMetadataFragmentDoc}
`;
/**
 * __useReadPeopleReportQuery__
 *
 * To run a query within a React component, call `useReadPeopleReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPeopleReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPeopleReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadPeopleReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadPeopleReportDocument, options);
}
export function useReadPeopleReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadPeopleReportDocument, options);
}
export const ReadRevenueCategoriesByMonthReportDocument = gql `
  query readRevenueCategoriesByMonthReport(
    $report: ReportKeyFilter!
    $options: ReadRevenueCategoriesByMonthReportOptions
  ) {
    report: readRevenueCategoriesByMonthReport(report: $report, options: $options) {
      count
      items {
        ...RevenueCategoriesByMonthReport
      }
      report {
        ...Report
      }
      metadata {
        ...RevenueCategoriesByMonthReportMetadata
      }
    }
  }
  ${RevenueCategoriesByMonthReportFragmentDoc}
  ${ReportFragmentDoc}
  ${RevenueCategoriesByMonthReportMetadataFragmentDoc}
`;
/**
 * __useReadRevenueCategoriesByMonthReportQuery__
 *
 * To run a query within a React component, call `useReadRevenueCategoriesByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadRevenueCategoriesByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadRevenueCategoriesByMonthReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadRevenueCategoriesByMonthReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadRevenueCategoriesByMonthReportDocument, options);
}
export function useReadRevenueCategoriesByMonthReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadRevenueCategoriesByMonthReportDocument, options);
}
export const ReadRevenueCategoriesByYearReportDocument = gql `
  query ReadRevenueCategoriesByYearReport(
    $report: ReportKeyFilter!
    $options: ReadRevenueCategoriesByYearReportOptions
  ) {
    report: readRevenueCategoriesByYearReport(report: $report, options: $options) {
      count
      items {
        ...RevenueCategoriesByYearReport
      }
      report {
        ...Report
      }
      metadata {
        ...RevenueCategoriesByYearReportMetadata
      }
    }
  }
  ${RevenueCategoriesByYearReportFragmentDoc}
  ${ReportFragmentDoc}
  ${RevenueCategoriesByYearReportMetadataFragmentDoc}
`;
/**
 * __useReadRevenueCategoriesByYearReportQuery__
 *
 * To run a query within a React component, call `useReadRevenueCategoriesByYearReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadRevenueCategoriesByYearReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadRevenueCategoriesByYearReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadRevenueCategoriesByYearReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadRevenueCategoriesByYearReportDocument, options);
}
export function useReadRevenueCategoriesByYearReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadRevenueCategoriesByYearReportDocument, options);
}
export const GetTeamActiveReportDocument = gql `
  query GetTeamActiveReport($filter: GetTeamActiveReportInput) {
    getTeamActiveReport(filter: $filter) {
      teams {
        id
        month
        teamsActive
        teamsCreated
      }
    }
  }
`;
/**
 * __useGetTeamActiveReportQuery__
 *
 * To run a query within a React component, call `useGetTeamActiveReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamActiveReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamActiveReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTeamActiveReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTeamActiveReportDocument, options);
}
export function useGetTeamActiveReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTeamActiveReportDocument, options);
}
export const ReadTeamsReportDocument = gql `
  query ReadTeamsReport($report: ReportKeyFilter!, $options: ReadTeamsReportOptions) {
    report: readTeamsReport(report: $report, options: $options) {
      count
      items {
        ...TeamsReport
      }
      report {
        ...Report
      }
      metadata {
        ...TeamsReportMetadata
      }
    }
  }
  ${TeamsReportFragmentDoc}
  ${ReportFragmentDoc}
  ${TeamsReportMetadataFragmentDoc}
`;
/**
 * __useReadTeamsReportQuery__
 *
 * To run a query within a React component, call `useReadTeamsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTeamsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTeamsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadTeamsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadTeamsReportDocument, options);
}
export function useReadTeamsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadTeamsReportDocument, options);
}
export const GetTopTenFundraisersReportQueryDocument = gql `
  query GetTopTenFundraisersReportQuery($data: GetTopTenFundraisersReportInput) {
    getTopTenFundraisersReport(data: $data) {
      fundraisers {
        id
        attendeeName
        team
        currentFundraising
        joinedDate
      }
    }
  }
`;
/**
 * __useGetTopTenFundraisersReportQueryQuery__
 *
 * To run a query within a React component, call `useGetTopTenFundraisersReportQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopTenFundraisersReportQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopTenFundraisersReportQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetTopTenFundraisersReportQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTopTenFundraisersReportQueryDocument, options);
}
export function useGetTopTenFundraisersReportQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTopTenFundraisersReportQueryDocument, options);
}
export const GetTopTenMerchandiseReportQueryDocument = gql `
  query GetTopTenMerchandiseReportQuery($data: GetTopTenMerchandiseReportInput!) {
    getTopTenMerchandiseReport(data: $data) {
      merchandise {
        availableInventory
        id
        item
        price
        profit
        qtySold
      }
    }
  }
`;
/**
 * __useGetTopTenMerchandiseReportQueryQuery__
 *
 * To run a query within a React component, call `useGetTopTenMerchandiseReportQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopTenMerchandiseReportQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopTenMerchandiseReportQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetTopTenMerchandiseReportQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTopTenMerchandiseReportQueryDocument, options);
}
export function useGetTopTenMerchandiseReportQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTopTenMerchandiseReportQueryDocument, options);
}
export const GetTopTenPartnershipsReportQueryDocument = gql `
  query GetTopTenPartnershipsReportQuery($data: GetTopTenPartnershipsReportInput) {
    getTopTenPartnershipsReport(data: $data) {
      partnerships {
        partnerName
        commitmentType
        commitmentAmount
        commitmentReceived
      }
    }
  }
`;
/**
 * __useGetTopTenPartnershipsReportQueryQuery__
 *
 * To run a query within a React component, call `useGetTopTenPartnershipsReportQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopTenPartnershipsReportQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopTenPartnershipsReportQueryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetTopTenPartnershipsReportQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTopTenPartnershipsReportQueryDocument, options);
}
export function useGetTopTenPartnershipsReportQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTopTenPartnershipsReportQueryDocument, options);
}
export const ReadTransactionsReportDocument = gql `
  query ReadTransactionsReport($report: ReportKeyFilter!, $options: ReadTransactionsReportOptions) {
    report: readTransactionsReport(report: $report, options: $options) {
      count
      items {
        ...TransactionsReport
      }
      report {
        ...Report
      }
      metadata {
        ...TransactionsReportMetadata
      }
    }
  }
  ${TransactionsReportFragmentDoc}
  ${ReportFragmentDoc}
  ${TransactionsReportMetadataFragmentDoc}
`;
/**
 * __useReadTransactionsReportQuery__
 *
 * To run a query within a React component, call `useReadTransactionsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTransactionsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTransactionsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadTransactionsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadTransactionsReportDocument, options);
}
export function useReadTransactionsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadTransactionsReportDocument, options);
}
export const ReadWeeksComparisonAttendeesRevenueAndTeamsReportDocument = gql `
  query ReadWeeksComparisonAttendeesRevenueAndTeamsReport(
    $report: ReportKeyFilter!
    $options: ReadWeeksComparisonAttendeesRevenueAndTeamsReportOptions
  ) {
    report: readWeeksComparisonAttendeesRevenueAndTeamsReport(report: $report, options: $options) {
      count
      items {
        ...WeeksComparisonAttendeesRevenueAndTeamsReport
      }
      report {
        ...Report
      }
      metadata {
        ...WeeksComparisonAttendeesRevenueAndTeamsReportMetadata
      }
    }
  }
  ${WeeksComparisonAttendeesRevenueAndTeamsReportFragmentDoc}
  ${ReportFragmentDoc}
  ${WeeksComparisonAttendeesRevenueAndTeamsReportMetadataFragmentDoc}
`;
/**
 * __useReadWeeksComparisonAttendeesRevenueAndTeamsReportQuery__
 *
 * To run a query within a React component, call `useReadWeeksComparisonAttendeesRevenueAndTeamsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadWeeksComparisonAttendeesRevenueAndTeamsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadWeeksComparisonAttendeesRevenueAndTeamsReportQuery({
 *   variables: {
 *      report: // value for 'report'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useReadWeeksComparisonAttendeesRevenueAndTeamsReportQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ReadWeeksComparisonAttendeesRevenueAndTeamsReportDocument, options);
}
export function useReadWeeksComparisonAttendeesRevenueAndTeamsReportLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ReadWeeksComparisonAttendeesRevenueAndTeamsReportDocument, options);
}
export const SetupFacebookIntegrationDocument = gql `
  mutation SetupFacebookIntegration(
    $client: String!
    $clientSecret: String!
    $organization: VizslaOrganizationKeyFilter!
  ) {
    commit: setupFacebookIntegration(
      client: $client
      clientSecret: $clientSecret
      organization: $organization
    ) {
      success
    }
  }
`;
/**
 * __useSetupFacebookIntegrationMutation__
 *
 * To run a mutation, you first call `useSetupFacebookIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupFacebookIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupFacebookIntegrationMutation, { data, loading, error }] = useSetupFacebookIntegrationMutation({
 *   variables: {
 *      client: // value for 'client'
 *      clientSecret: // value for 'clientSecret'
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useSetupFacebookIntegrationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SetupFacebookIntegrationDocument, options);
}
export const GenerateShoppingCartTaxesDocument = gql `
  query GenerateShoppingCartTaxes($content: JSON!) {
    taxes: generateShoppingCartTaxes(content: $content)
  }
`;
/**
 * __useGenerateShoppingCartTaxesQuery__
 *
 * To run a query within a React component, call `useGenerateShoppingCartTaxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateShoppingCartTaxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateShoppingCartTaxesQuery({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useGenerateShoppingCartTaxesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GenerateShoppingCartTaxesDocument, options);
}
export function useGenerateShoppingCartTaxesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GenerateShoppingCartTaxesDocument, options);
}
export const StripeCreatePaymentIntentForItemDocument = gql `
  mutation StripeCreatePaymentIntentForItem(
    $amount: Float!
    $meta: JSON!
    $connectedStripeAccountId: String!
    $email: String
  ) {
    stripeCreatePaymentIntentForItem(
      amount: $amount
      meta: $meta
      connectedStripeAccountId: $connectedStripeAccountId
      email: $email
    ) {
      paymentIntent
    }
  }
`;
/**
 * __useStripeCreatePaymentIntentForItemMutation__
 *
 * To run a mutation, you first call `useStripeCreatePaymentIntentForItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreatePaymentIntentForItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreatePaymentIntentForItemMutation, { data, loading, error }] = useStripeCreatePaymentIntentForItemMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      meta: // value for 'meta'
 *      connectedStripeAccountId: // value for 'connectedStripeAccountId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useStripeCreatePaymentIntentForItemMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripeCreatePaymentIntentForItemDocument, options);
}
export const StripeCreateConnectAccountLinkDocument = gql `
  mutation StripeCreateConnectAccountLink(
    $refreshUrl: String!
    $returnUrl: String!
    $organizationId: ID!
  ) {
    stripeCreateConnectAccountLink(
      refreshUrl: $refreshUrl
      returnUrl: $returnUrl
      organizationId: $organizationId
    ) {
      accountLink
    }
  }
`;
/**
 * __useStripeCreateConnectAccountLinkMutation__
 *
 * To run a mutation, you first call `useStripeCreateConnectAccountLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreateConnectAccountLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreateConnectAccountLinkMutation, { data, loading, error }] = useStripeCreateConnectAccountLinkMutation({
 *   variables: {
 *      refreshUrl: // value for 'refreshUrl'
 *      returnUrl: // value for 'returnUrl'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useStripeCreateConnectAccountLinkMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripeCreateConnectAccountLinkDocument, options);
}
export const StripeRetrieveAccountDocument = gql `
  query StripeRetrieveAccount($organizationId: ID!) {
    stripeRetrieveAccount(organizationId: $organizationId) {
      stripeAccount
    }
  }
`;
/**
 * __useStripeRetrieveAccountQuery__
 *
 * To run a query within a React component, call `useStripeRetrieveAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeRetrieveAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeRetrieveAccountQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useStripeRetrieveAccountQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StripeRetrieveAccountDocument, options);
}
export function useStripeRetrieveAccountLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StripeRetrieveAccountDocument, options);
}
export const SwagBagRegistrationOptionDocument = gql `
  query SwagBagRegistrationOption(
    $filter: AssetByRegistrationOptionViewFilter!
    $sort: [AssetByRegistrationOptionViewSort!]
    $first: Int
    $skip: Int
  ) {
    assets: assetByRegistrationOptionViewsList(
      filter: $filter
      first: $first
      skip: $skip
      sort: $sort
    ) {
      count
      items {
        ...AssetSwagbag
      }
    }
  }
  ${AssetSwagbagFragmentDoc}
`;
/**
 * __useSwagBagRegistrationOptionQuery__
 *
 * To run a query within a React component, call `useSwagBagRegistrationOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwagBagRegistrationOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwagBagRegistrationOptionQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useSwagBagRegistrationOptionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SwagBagRegistrationOptionDocument, options);
}
export function useSwagBagRegistrationOptionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SwagBagRegistrationOptionDocument, options);
}
export const SwagBagListByRegistrationOptionDocument = gql `
  query SwagBagListByRegistrationOption(
    $filter: AssetSwagbagListFilter!
    $sort: [AssetSwagbagListSort!]
    $first: Int
    $skip: Int
  ) {
    assets: assetSwagbagListsList(filter: $filter, first: $first, skip: $skip, sort: $sort) {
      count
      items {
        ...AssetSwagbagByRegistrationOption
      }
    }
  }
  ${AssetSwagbagByRegistrationOptionFragmentDoc}
`;
/**
 * __useSwagBagListByRegistrationOptionQuery__
 *
 * To run a query within a React component, call `useSwagBagListByRegistrationOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwagBagListByRegistrationOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwagBagListByRegistrationOptionQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useSwagBagListByRegistrationOptionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SwagBagListByRegistrationOptionDocument, options);
}
export function useSwagBagListByRegistrationOptionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SwagBagListByRegistrationOptionDocument, options);
}
export const JoinTeamFundraisingDocument = gql `
  mutation JoinTeamFundraising($membership: CampaignTeamMemberCreateInput!) {
    membership: campaignTeamMemberCreate(data: $membership) {
      ...TEAM_MEMBERSHIP_FRAGMENT
    }
  }
  ${Team_Membership_FragmentFragmentDoc}
`;
/**
 * __useJoinTeamFundraisingMutation__
 *
 * To run a mutation, you first call `useJoinTeamFundraisingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTeamFundraisingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTeamFundraisingMutation, { data, loading, error }] = useJoinTeamFundraisingMutation({
 *   variables: {
 *      membership: // value for 'membership'
 *   },
 * });
 */
export function useJoinTeamFundraisingMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(JoinTeamFundraisingDocument, options);
}
export const TeamFundraisingByExperienceIdDocument = gql `
  query TeamFundraisingByExperienceID($experienceID: ID!) {
    teams: teamFundraisingsList(filter: { experience: { id: { equals: $experienceID } } }) {
      items {
        ...TEAM_FUNDRAISING_FRAGMENT
      }
    }
  }
  ${Team_Fundraising_FragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingByExperienceIdQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingByExperienceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingByExperienceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingByExperienceIdQuery({
 *   variables: {
 *      experienceID: // value for 'experienceID'
 *   },
 * });
 */
export function useTeamFundraisingByExperienceIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingByExperienceIdDocument, options);
}
export function useTeamFundraisingByExperienceIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingByExperienceIdDocument, options);
}
export const LeaveTeamFundraisingDocument = gql `
  mutation LeaveTeamFundraising($attendeeId: ID!, $teamId: ID!) {
    removal: campaignTeamMemberDeleteByFilter(
      filter: { team: { id: { equals: $teamId } }, attendee: { id: { equals: $attendeeId } } }
    ) {
      success
    }
  }
`;
/**
 * __useLeaveTeamFundraisingMutation__
 *
 * To run a mutation, you first call `useLeaveTeamFundraisingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTeamFundraisingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTeamFundraisingMutation, { data, loading, error }] = useLeaveTeamFundraisingMutation({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useLeaveTeamFundraisingMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(LeaveTeamFundraisingDocument, options);
}
export const TeamFundraisingRaisedDocument = gql `
  query TeamFundraisingRaised($experienceId: ID!) {
    teamFundraisingsList(filter: { experience: { id: { equals: $experienceId } } }) {
      count
      items {
        totalRaised
        name
        id
        webLink
        avatar {
          id
          downloadUrl
        }
      }
    }
  }
`;
/**
 * __useTeamFundraisingRaisedQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingRaisedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingRaisedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingRaisedQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useTeamFundraisingRaisedQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingRaisedDocument, options);
}
export function useTeamFundraisingRaisedLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingRaisedDocument, options);
}
export const TeamFundraisingCreateDocument = gql `
  mutation TeamFundraisingCreate($data: TeamFundraisingCreateInput!) {
    team: teamFundraisingCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useTeamFundraisingCreateMutation__
 *
 * To run a mutation, you first call `useTeamFundraisingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamFundraisingCreateMutation, { data, loading, error }] = useTeamFundraisingCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTeamFundraisingCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TeamFundraisingCreateDocument, options);
}
export const TeamFundraisingManagementDocument = gql `
  query TeamFundraisingManagement($id: ID!) {
    teamFundraising(id: $id) {
      ...TeamFundraisingManagementFragment
    }
  }
  ${TeamFundraisingManagementFragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingManagementQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingManagementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamFundraisingManagementQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingManagementDocument, options);
}
export function useTeamFundraisingManagementLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingManagementDocument, options);
}
export const CurrentUserTeamFundraisingPageDocument = gql `
  query CurrentUserTeamFundraisingPage($teamID: ID!) {
    user {
      firstName
      lastName
      attendees(filter: { teamMembership: { team: { id: { equals: $teamID } } } }) {
        items {
          id
          fundraising {
            id
            goal
            raised
            hero {
              downloadUrl
            }
            logo {
              downloadUrl
            }
          }
        }
      }
    }
  }
`;
/**
 * __useCurrentUserTeamFundraisingPageQuery__
 *
 * To run a query within a React component, call `useCurrentUserTeamFundraisingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserTeamFundraisingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserTeamFundraisingPageQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *   },
 * });
 */
export function useCurrentUserTeamFundraisingPageQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserTeamFundraisingPageDocument, options);
}
export function useCurrentUserTeamFundraisingPageLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserTeamFundraisingPageDocument, options);
}
export const TeamFundraisingTopDocument = gql `
  query teamFundraisingTop($teamId: ID!) {
    teamFundraising: teamFundraisingsList(filter: { id: { equals: $teamId } }) {
      items {
        members {
          items {
            attendee {
              user {
                firstName
              }
              fundraising {
                allocatedDonations {
                  items {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useTeamFundraisingTopQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingTopQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingTopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingTopQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamFundraisingTopQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingTopDocument, options);
}
export function useTeamFundraisingTopLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingTopDocument, options);
}
export const TopDonationTeamFundraisersDocument = gql `
  query TopDonationTeamFundraisers($teamId: ID!) {
    fundraisers: individualFundraisingsList(
      filter: { attendee: { teamMembership: { team: { id: { equals: $teamId } } } } }
    ) {
      count
      items {
        id
        attendee {
          user {
            ...UserFragment
          }
        }
        allocatedDonations(
          groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
        ) {
          groups {
            total: Float
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
/**
 * __useTopDonationTeamFundraisersQuery__
 *
 * To run a query within a React component, call `useTopDonationTeamFundraisersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopDonationTeamFundraisersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopDonationTeamFundraisersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTopDonationTeamFundraisersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TopDonationTeamFundraisersDocument, options);
}
export function useTopDonationTeamFundraisersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TopDonationTeamFundraisersDocument, options);
}
export const Top_Average_Donation_Team_FundraisersDocument = gql `
  query TOP_AVERAGE_DONATION_TEAM_FUNDRAISERS($teamId: ID!) {
    fundraisers: individualFundraisingsList(
      filter: { attendee: { teamMembership: { team: { id: { equals: $teamId } } } } }
    ) {
      count
      items {
        id
        attendee {
          user {
            ...UserFragment
          }
        }
        allocatedDonations(
          groupBy: { query: { amount: { as: "average", fn: { aggregate: AVG } } } }
        ) {
          groups {
            average: Float
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
/**
 * __useTop_Average_Donation_Team_FundraisersQuery__
 *
 * To run a query within a React component, call `useTop_Average_Donation_Team_FundraisersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTop_Average_Donation_Team_FundraisersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTop_Average_Donation_Team_FundraisersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTop_Average_Donation_Team_FundraisersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(Top_Average_Donation_Team_FundraisersDocument, options);
}
export function useTop_Average_Donation_Team_FundraisersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(Top_Average_Donation_Team_FundraisersDocument, options);
}
export const TopDonorsTeamFundraisersDocument = gql `
  query TopDonorsTeamFundraisers($teamId: ID!) {
    fundraisers: individualFundraisingsList(
      filter: { attendee: { teamMembership: { team: { id: { equals: $teamId } } } } }
    ) {
      items {
        attendee {
          user {
            ...UserFragment
          }
        }
        allocatedDonations(filter: { teamFundraising: { id: { equals: $teamId } } }) {
          count
          items {
            amount
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
/**
 * __useTopDonorsTeamFundraisersQuery__
 *
 * To run a query within a React component, call `useTopDonorsTeamFundraisersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopDonorsTeamFundraisersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopDonorsTeamFundraisersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTopDonorsTeamFundraisersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TopDonorsTeamFundraisersDocument, options);
}
export function useTopDonorsTeamFundraisersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TopDonorsTeamFundraisersDocument, options);
}
export const TopDonorByTeamDocument = gql `
  query topDonorByTeam($teamId: ID!) {
    donors: usersList(
      filter: { allocatedDonations: { some: { teamFundraising: { id: { equals: $teamId } } } } }
    ) {
      items {
        id
        firstName
        lastName
        avatar {
          downloadUrl
        }
        allocatedDonations(
          groupBy: {
            sort: { alias: "total", direction: ASC }
            query: { amount: { as: "total", fn: { aggregate: COUNT } } }
          }
        ) {
          groups {
            total: Float
          }
        }
      }
    }
  }
`;
/**
 * __useTopDonorByTeamQuery__
 *
 * To run a query within a React component, call `useTopDonorByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopDonorByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopDonorByTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTopDonorByTeamQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TopDonorByTeamDocument, options);
}
export function useTopDonorByTeamLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TopDonorByTeamDocument, options);
}
export const TeamFundraisingManagementByFilterDocument = gql `
  query TeamFundraisingManagementByFilter($filter: TeamFundraisingFilter!) {
    teamFundraisingsList(filter: $filter) {
      count
      items {
        ...TeamFundraisingManagementFragment
      }
    }
  }
  ${TeamFundraisingManagementFragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingManagementByFilterQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingManagementByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingManagementByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingManagementByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingManagementByFilterQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingManagementByFilterDocument, options);
}
export function useTeamFundraisingManagementByFilterLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingManagementByFilterDocument, options);
}
export const TeamFundraisingUpdateGalleryDocument = gql `
  mutation TeamFundraisingUpdateGallery(
    $data: TeamFundraisingUpdateInput!
    $filter: TeamFundraisingKeyFilter!
  ) {
    teamFundraising: teamFundraisingUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...TeamFundraisingGalleryFragment
    }
  }
  ${TeamFundraisingGalleryFragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingUpdateGalleryMutation__
 *
 * To run a mutation, you first call `useTeamFundraisingUpdateGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingUpdateGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamFundraisingUpdateGalleryMutation, { data, loading, error }] = useTeamFundraisingUpdateGalleryMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingUpdateGalleryMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TeamFundraisingUpdateGalleryDocument, options);
}
export const TeamFundraisingContentAttachImageDocument = gql `
  mutation TeamFundraisingContentAttachImage(
    $data: TeamFundraisingUpdateInput!
    $filter: TeamFundraisingKeyFilter!
  ) {
    teamFundraising: teamFundraisingUpdate(data: $data, filter: $filter) {
      ...TeamFundraisingContentFragment
    }
  }
  ${TeamFundraisingContentFragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingContentAttachImageMutation__
 *
 * To run a mutation, you first call `useTeamFundraisingContentAttachImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingContentAttachImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamFundraisingContentAttachImageMutation, { data, loading, error }] = useTeamFundraisingContentAttachImageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingContentAttachImageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TeamFundraisingContentAttachImageDocument, options);
}
export const TeamFundraisingUpdateHeaderDocument = gql `
  mutation TeamFundraisingUpdateHeader(
    $data: TeamFundraisingUpdateInput!
    $filter: TeamFundraisingKeyFilter!
  ) {
    teamFundraising: teamFundraisingUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...TeamFundraisingHeaderFragment
    }
  }
  ${TeamFundraisingHeaderFragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingUpdateHeaderMutation__
 *
 * To run a mutation, you first call `useTeamFundraisingUpdateHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingUpdateHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamFundraisingUpdateHeaderMutation, { data, loading, error }] = useTeamFundraisingUpdateHeaderMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingUpdateHeaderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TeamFundraisingUpdateHeaderDocument, options);
}
export const TeamsNamePerExperienceDocument = gql `
  query TeamsNamePerExperience($experienceId: ID!, $teamName: String) {
    experience(id: $experienceId) {
      teams(filter: { name: { equals: $teamName } }) {
        count
        items {
          name
        }
      }
    }
  }
`;
/**
 * __useTeamsNamePerExperienceQuery__
 *
 * To run a query within a React component, call `useTeamsNamePerExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsNamePerExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsNamePerExperienceQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useTeamsNamePerExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamsNamePerExperienceDocument, options);
}
export function useTeamsNamePerExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamsNamePerExperienceDocument, options);
}
export const GetTeamsByExperienceDocument = gql `
  query GetTeamsByExperience($experienceId: ID!) {
    teamFundraisingsList(
      filter: { campaign: { experiences: { some: { id: { equals: $experienceId } } } } }
    ) {
      items {
        id
        name
      }
    }
  }
`;
/**
 * __useGetTeamsByExperienceQuery__
 *
 * To run a query within a React component, call `useGetTeamsByExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsByExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsByExperienceQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useGetTeamsByExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTeamsByExperienceDocument, options);
}
export function useGetTeamsByExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTeamsByExperienceDocument, options);
}
export const SaveExperienceTimeZoneDocument = gql `
  mutation SaveExperienceTimeZone($data: ExperienceUpdateInput!) {
    experienceUpdate(data: $data) {
      id
    }
  }
`;
/**
 * __useSaveExperienceTimeZoneMutation__
 *
 * To run a mutation, you first call `useSaveExperienceTimeZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveExperienceTimeZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveExperienceTimeZoneMutation, { data, loading, error }] = useSaveExperienceTimeZoneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveExperienceTimeZoneMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SaveExperienceTimeZoneDocument, options);
}
export const OrderItemScheduleDocument = gql `
  query orderItemSchedule($id: ID) {
    orderItem(id: $id) {
      schedule
    }
  }
`;
/**
 * __useOrderItemScheduleQuery__
 *
 * To run a query within a React component, call `useOrderItemScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderItemScheduleQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrderItemScheduleDocument, options);
}
export function useOrderItemScheduleLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrderItemScheduleDocument, options);
}
export const TransactionBillingDocument = gql `
  query transactionBilling($orderId: ID!) {
    order(id: $orderId) {
      id
      payment {
        billingInfo
        id
      }
      parent {
        id
        payment {
          id
          billingInfo
        }
      }
      totalAmount
      _description
      orderItems {
        items {
          amount
          category
          transactionType
          description
          comment
          id
          assetFulfillment {
            storeInventoryItem {
              id
              storeAsset {
                name
              }
              variantsValues
            }
          }
          assetSwagbagFulfillment {
            storeInventoryItem {
              id
              storeAsset {
                name
              }
              variantsValues
            }
          }
        }
      }
      registrationOptionFulfillment {
        items {
          id
        }
      }
      user {
        _description
        email
        firstName
        lastName
        fullName
        companyName
        gender
        createdAt
        phone {
          number
          code
        }
        customer
        address {
          city
          country
          state
          street1
          street2
          zip
        }
      }
      donations {
        items {
          donationAllocations {
            items {
              transferedFrom {
                id
              }
              receivedBy {
                id
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useTransactionBillingQuery__
 *
 * To run a query within a React component, call `useTransactionBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionBillingQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useTransactionBillingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TransactionBillingDocument, options);
}
export function useTransactionBillingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TransactionBillingDocument, options);
}
export const TransferDonationDocument = gql `
  mutation TransferDonation($data: TransferDonationInput!) {
    transferDonation(data: $data) {
      success
      message
    }
  }
`;
/**
 * __useTransferDonationMutation__
 *
 * To run a mutation, you first call `useTransferDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferDonationMutation, { data, loading, error }] = useTransferDonationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTransferDonationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TransferDonationDocument, options);
}
export const UsersListDocument = gql `
  query UsersList($filter: UserFilter, $first: Int, $skip: Int) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      items {
        ...UserFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useUsersListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsersListDocument, options);
}
export function useUsersListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsersListDocument, options);
}
export const UsersListOrganizationDocument = gql `
  query UsersListOrganization($filter: UserFilter, $first: Int, $skip: Int) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      items {
        ...UserFragmentOrganization
      }
    }
  }
  ${UserFragmentOrganizationFragmentDoc}
`;
/**
 * __useUsersListOrganizationQuery__
 *
 * To run a query within a React component, call `useUsersListOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListOrganizationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useUsersListOrganizationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsersListOrganizationDocument, options);
}
export function useUsersListOrganizationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsersListOrganizationDocument, options);
}
export const UserAttendeeExperienceDocument = gql `
  query userAttendeeExperience($userId: ID!, $experienceId: ID!) {
    attendeesList(
      filter: { user: { id: { equals: $userId } }, experience: { id: { equals: $experienceId } } }
    ) {
      items {
        id
        user {
          firstName
          lastName
        }
        experience {
          id
          name
        }
        teamMembership {
          id
          team {
            name
          }
        }
      }
    }
  }
`;
/**
 * __useUserAttendeeExperienceQuery__
 *
 * To run a query within a React component, call `useUserAttendeeExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAttendeeExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAttendeeExperienceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useUserAttendeeExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserAttendeeExperienceDocument, options);
}
export function useUserAttendeeExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserAttendeeExperienceDocument, options);
}
export const IndividualFundraisingByAttendeeDocument = gql `
  query individualFundraisingByAttendee($filter: AttendeeFilter!) {
    attendeesList(filter: $filter) {
      items {
        fundraising {
          id
        }
        experience {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useIndividualFundraisingByAttendeeQuery__
 *
 * To run a query within a React component, call `useIndividualFundraisingByAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingByAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualFundraisingByAttendeeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingByAttendeeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(IndividualFundraisingByAttendeeDocument, options);
}
export function useIndividualFundraisingByAttendeeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(IndividualFundraisingByAttendeeDocument, options);
}
export const UserDocument = gql `
  query User {
    user {
      id
      email
      firstName
      lastName
      currentApplication
      hasAlreadyLoggedIn
      isTestMode
      currentOrganization {
        id
      }
      phone {
        code
        number
      }
      address {
        city
        state
        street1
        street2
        zip
        country
      }
      status
      customer
      attendees {
        count
        items {
          teamMembership {
            team {
              name
            }
          }
        }
      }
      avatar {
        id
        downloadUrl
      }
      roles {
        items {
          id
          name
        }
      }
      vizslaOrganizationStaff {
        items {
          id
          roles
          vizslaOrganization {
            id
          }
        }
      }
      minor
      parent {
        id
        firstName
        lastName
        fullName
        email
      }
      childrens {
        items {
          id
          firstName
          lastName
          fullName
          email
        }
      }
    }
  }
`;
/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserDocument, options);
}
export function useUserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserDocument, options);
}
export const ChangeUserCurrentAppDocument = gql `
  mutation ChangeUserCurrentApp($userEmail: String!, $applicationId: String!) {
    userUpdate(filter: { email: $userEmail }, data: { currentApplication: $applicationId }) {
      id
      email
      currentApplication
    }
  }
`;
/**
 * __useChangeUserCurrentAppMutation__
 *
 * To run a mutation, you first call `useChangeUserCurrentAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserCurrentAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserCurrentAppMutation, { data, loading, error }] = useChangeUserCurrentAppMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useChangeUserCurrentAppMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChangeUserCurrentAppDocument, options);
}
export const CurrentAttendeeDocument = gql `
  query currentAttendee($attendeeid: ID) {
    attendee(id: $attendeeid) {
      user {
        id
        email
        firstName
        lastName
        avatar {
          id
          downloadUrl
        }
        gender
        createdAt
        phone {
          code
          number
        }
        birthDate
        address {
          city
          country
          state
          street1
          street2
          zip
        }
      }
    }
  }
`;
/**
 * __useCurrentAttendeeQuery__
 *
 * To run a query within a React component, call `useCurrentAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAttendeeQuery({
 *   variables: {
 *      attendeeid: // value for 'attendeeid'
 *   },
 * });
 */
export function useCurrentAttendeeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentAttendeeDocument, options);
}
export function useCurrentAttendeeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentAttendeeDocument, options);
}
export const GetUserByEmailDocument = gql `
  query getUserByEmail($email: String!) {
    user(email: $email) {
      id
      email
      firstName
      lastName
      fullName
      gender
      birthDate
      status
      phone {
        code
        number
      }
      address {
        city
        state
        country
        zip
        street1
        street2
      }
      avatar {
        id
        downloadUrl
      }
      roles {
        items {
          id
          name
        }
      }
      vizslaOrganizationStaff {
        items {
          id
          roles
          vizslaOrganization {
            id
          }
        }
      }
    }
  }
`;
/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetUserByEmailDocument, options);
}
export function useGetUserByEmailLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetUserByEmailDocument, options);
}
export const GetUserListFromOrganizationDocument = gql `
  query getUserListFromOrganization($filter: UserFilter, $first: Int, $skip: Int) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        email
        attendees(filter: { id: { is_empty: false } }) {
          count
        }
      }
    }
  }
`;
/**
 * __useGetUserListFromOrganizationQuery__
 *
 * To run a query within a React component, call `useGetUserListFromOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListFromOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListFromOrganizationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetUserListFromOrganizationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetUserListFromOrganizationDocument, options);
}
export function useGetUserListFromOrganizationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetUserListFromOrganizationDocument, options);
}
export const CheckUserStatusDocument = gql `
  query CheckUserStatus($email: String!) {
    checkUserStatus(email: $email) {
      exists
      status
      error
    }
  }
`;
/**
 * __useCheckUserStatusQuery__
 *
 * To run a query within a React component, call `useCheckUserStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserStatusQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckUserStatusQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CheckUserStatusDocument, options);
}
export function useCheckUserStatusLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CheckUserStatusDocument, options);
}
export const VizslaOrganizationDocument = gql `
  query VizslaOrganization($id: ID!) {
    vizslaOrganization(id: $id) {
      id
      name
      ein
      fee
      isActive
      notA501c3
      socialMedia {
        items {
          id
          uRL
          socialMedia
        }
      }
      contactPhone {
        code
        number
      }
      contactAddress {
        country
        city
        state
        zip
        street1
        street2
      }
      contactEmail
      stripeIntegration {
        createdAt
        id
        status
        stripeAccountID
      }
      logo {
        id
        downloadUrl
      }
      negativeLogo {
        id
        downloadUrl
      }
      currency
      language
      preferredDateFormat
      timeZone
      globalOrganization {
        id
      }
      stripeFee
    }
  }
`;
/**
 * __useVizslaOrganizationQuery__
 *
 * To run a query within a React component, call `useVizslaOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVizslaOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVizslaOrganizationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(VizslaOrganizationDocument, options);
}
export function useVizslaOrganizationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(VizslaOrganizationDocument, options);
}
export const VerifyEarlyCodeDocument = gql `
  query VerifyEarlyCode($code: String!, $experienceId: ID!) {
    verifyEarlyBirdCode(filter: { code: $code, experienceId: $experienceId }) {
      valid
    }
  }
`;
/**
 * __useVerifyEarlyCodeQuery__
 *
 * To run a query within a React component, call `useVerifyEarlyCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyEarlyCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyEarlyCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useVerifyEarlyCodeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(VerifyEarlyCodeDocument, options);
}
export function useVerifyEarlyCodeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(VerifyEarlyCodeDocument, options);
}
export const GetRecentActivityDocument = gql `
  query GetRecentActivity($data: GetRecentActivityInput) {
    getRecentActivity(data: $data) {
      totalActivities
      activities {
        activityDate
        activityType
        donationAmount
        firstName
        lastName
        fullName
        id
        avatar {
          downloadUrl
          id
        }
        registrationName
      }
    }
  }
`;
/**
 * __useGetRecentActivityQuery__
 *
 * To run a query within a React component, call `useGetRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentActivityQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetRecentActivityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRecentActivityDocument, options);
}
export function useGetRecentActivityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRecentActivityDocument, options);
}
export const AttendeeRegistrationQueryDocument = gql `
  query AttendeeRegistrationQuery($emails: [String!], $experienceId: ID!) {
    attendeesList(
      filter: { user: { email: { in: $emails } }, experience: { id: { equals: $experienceId } } }
    ) {
      items {
        id
        user {
          id
          firstName
          email
        }
        registrationOption {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useAttendeeRegistrationQueryQuery__
 *
 * To run a query within a React component, call `useAttendeeRegistrationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeRegistrationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeRegistrationQueryQuery({
 *   variables: {
 *      emails: // value for 'emails'
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useAttendeeRegistrationQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeRegistrationQueryDocument, options);
}
export function useAttendeeRegistrationQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeRegistrationQueryDocument, options);
}
export const AttendeeFundraiserLandingDocument = gql `
  query AttendeeFundraiserLanding($idOrSlug: String!) {
    fundraiserLandingData(idOrSlug: $idOrSlug) {
      items {
        id
        fundraiserGoal
        totalRaised
        user {
          lastName
          firstName
          id
          email
          avatar {
            id
            downloadUrl
          }
        }
        fundraisingPageLink
        teamMembership {
          id
          role
          team {
            id
            name
            webLink
            avatar {
              id
              downloadUrl
            }
            fundraiserGoal
            totalRaised
          }
        }
        fundraising {
          goal
          id
          description
          raised
          title
          webLink
          allocatedDonations {
            items {
              id
              amount
            }
          }
          logo {
            downloadUrl
          }
          hero {
            downloadUrl
          }
          gallery {
            items {
              downloadUrl
            }
          }
        }
        experience {
          id
          name
          experienceLogoImage {
            downloadUrl
          }
          experienceHeaderImage {
            downloadUrl
          }
          campaign {
            id
            name
            dnsRecord {
              slug
            }
            logo {
              id
              downloadUrl
            }
            image {
              id
              downloadUrl
            }
            fundraisingGoal
            totalRaised
            vizslaOrganization {
              id
            }
          }
        }
      }
    }
  }
`;
/**
 * __useAttendeeFundraiserLandingQuery__
 *
 * To run a query within a React component, call `useAttendeeFundraiserLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeFundraiserLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeFundraiserLandingQuery({
 *   variables: {
 *      idOrSlug: // value for 'idOrSlug'
 *   },
 * });
 */
export function useAttendeeFundraiserLandingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeFundraiserLandingDocument, options);
}
export function useAttendeeFundraiserLandingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeFundraiserLandingDocument, options);
}
export const RegisterAttendeeDocument = gql `
  mutation RegisterAttendee($attendees: [RegisterAttendeeInfo]!, $transactionId: ID) {
    registerAttendee(attendees: $attendees, transactionId: $transactionId) {
      attendees {
        id
      }
    }
  }
`;
/**
 * __useRegisterAttendeeMutation__
 *
 * To run a mutation, you first call `useRegisterAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAttendeeMutation, { data, loading, error }] = useRegisterAttendeeMutation({
 *   variables: {
 *      attendees: // value for 'attendees'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useRegisterAttendeeMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegisterAttendeeDocument, options);
}
export const ResolvePublicAttendeeRegistrationFormDocument = gql `
  mutation ResolvePublicAttendeeRegistrationForm(
    $attendees: [RegisterAttendeeInfo]!
    $donation: AddDonationInput
    $purchasedStoreItems: [StorePurchaseEventCreatePurchasedStoreItems]
  ) {
    resolvePublicAttendeeRegistrationForm(
      attendees: $attendees
      donation: $donation
      purchasedStoreItems: $purchasedStoreItems
    ) {
      success
    }
  }
`;
/**
 * __useResolvePublicAttendeeRegistrationFormMutation__
 *
 * To run a mutation, you first call `useResolvePublicAttendeeRegistrationFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolvePublicAttendeeRegistrationFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolvePublicAttendeeRegistrationFormMutation, { data, loading, error }] = useResolvePublicAttendeeRegistrationFormMutation({
 *   variables: {
 *      attendees: // value for 'attendees'
 *      donation: // value for 'donation'
 *      purchasedStoreItems: // value for 'purchasedStoreItems'
 *   },
 * });
 */
export function useResolvePublicAttendeeRegistrationFormMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ResolvePublicAttendeeRegistrationFormDocument, options);
}
export const AttendeOrderDataListDocument = gql `
  query attendeOrderDataList(
    $registrationFilter: RegistrationOptionFulfillmentFilter
    $assetFilter: AssetFullfillmentFilter
    $attendeeId: ID
    $swagbagFilter: AssetSwagbagFulfillmentFilter
  ) {
    attendee(id: $attendeeId) {
      id
      fundraisingPageLink
      fundraiserGoal
      totalRaised
      waiverSignedAt
      waiverSignature
      assetSwagbag(filter: $swagbagFilter) {
        items {
          id
          status
          assetSwagbagFulfillmentOrderItem {
            id
            description
            comment
            orderItemRefunded {
              id
            }
          }
          amount
          storeInventoryItem {
            id
            variantsValues
            countOfTotal
            itemToSellWhenOutOfStock
            storeAsset {
              id
              name
              image {
                downloadUrl
              }
            }
          }
        }
        count
      }
      registrationOption {
        id
        name
        pricingSettings {
          pricingType
          pricingTiers {
            items {
              price
              taxDeductibleCurrencyAmount
              taxDeductiblePercentsAmount
              taxDeductibleType
            }
          }
        }
      }
      ticketingOption {
        id
        ticketName
      }
      bibAssignment {
        bibNumber
      }
      experience {
        experienceType
        waiverEnabled
        waiverTextBody
        campaign {
          vizslaOrganization {
            id
          }
        }
      }
      checkIn
      assetFulfillment(filter: $assetFilter) {
        items {
          id
          amount
          status
          assetFulfillmentOrderItem {
            id
            description
            comment
            orderItemRefunded {
              id
            }
          }
          storeInventoryItem {
            id
            name
            itemToSellWhenOutOfStock
            variantsValues
            storeAsset {
              name
              image {
                downloadUrl
              }
            }
          }
        }
      }
      fundraising {
        id
        raised
        goal
        webLink
      }
      teamMembership {
        team {
          id
          name
        }
      }
      user {
        id
        email
        avatar {
          id
          downloadUrl
          previewUrl
        }
        firstName
        lastName
        phone {
          number
        }
        gender
        birthDate
        address {
          city
          state
        }
        status
        createdAt
      }
      registrationOptionFulfillment(filter: $registrationFilter) {
        items {
          registrationOption {
            id
            name
            experience {
              id
            }
          }
          order {
            id
            number
            payment {
              gateway
              reasonStatus
              status
              totalAmount
              method
              category
            }
            createdAt
            registrationOptionFulfillment {
              items {
                amount
                registrationOption {
                  id
                  name
                  experience {
                    name
                    id
                  }
                }
                attendee {
                  id
                  checkIn
                  user {
                    firstName
                    lastName
                    avatar {
                      id
                      downloadUrl
                      previewUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useAttendeOrderDataListQuery__
 *
 * To run a query within a React component, call `useAttendeOrderDataListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeOrderDataListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeOrderDataListQuery({
 *   variables: {
 *      registrationFilter: // value for 'registrationFilter'
 *      assetFilter: // value for 'assetFilter'
 *      attendeeId: // value for 'attendeeId'
 *      swagbagFilter: // value for 'swagbagFilter'
 *   },
 * });
 */
export function useAttendeOrderDataListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeOrderDataListDocument, options);
}
export function useAttendeOrderDataListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeOrderDataListDocument, options);
}
export const AttendeeEmailAlreadyRegisteredDocument = gql `
  query AttendeeEmailAlreadyRegistered($email: String, $experienceId: ID!) {
    attendeesList(
      filter: { user: { email: { equals: $email } }, experience: { id: { equals: $experienceId } } }
      first: 1
    ) {
      count
    }
  }
`;
/**
 * __useAttendeeEmailAlreadyRegisteredQuery__
 *
 * To run a query within a React component, call `useAttendeeEmailAlreadyRegisteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeEmailAlreadyRegisteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeEmailAlreadyRegisteredQuery({
 *   variables: {
 *      email: // value for 'email'
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useAttendeeEmailAlreadyRegisteredQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeEmailAlreadyRegisteredDocument, options);
}
export function useAttendeeEmailAlreadyRegisteredLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeEmailAlreadyRegisteredDocument, options);
}
export const AttendeeIdByEmailDocument = gql `
  query AttendeeIdByEmail($email: String, $experienceId: ID!) {
    experience(id: $experienceId) {
      attendees(filter: { user: { email: { equals: $email } } }) {
        items {
          id
        }
      }
    }
  }
`;
/**
 * __useAttendeeIdByEmailQuery__
 *
 * To run a query within a React component, call `useAttendeeIdByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeIdByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeIdByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useAttendeeIdByEmailQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeIdByEmailDocument, options);
}
export function useAttendeeIdByEmailLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeIdByEmailDocument, options);
}
export const PublicClientDnsRecordBySlugDocument = gql `
  query PublicClientDnsRecordBySlug($slug: String!) {
    dnsRecord(slug: $slug) {
      ...PublicClientDnsRecordFragment
    }
  }
  ${PublicClientDnsRecordFragmentFragmentDoc}
`;
/**
 * __usePublicClientDnsRecordBySlugQuery__
 *
 * To run a query within a React component, call `usePublicClientDnsRecordBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicClientDnsRecordBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicClientDnsRecordBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicClientDnsRecordBySlugQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PublicClientDnsRecordBySlugDocument, options);
}
export function usePublicClientDnsRecordBySlugLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PublicClientDnsRecordBySlugDocument, options);
}
export const AddDonationDocument = gql `
  mutation AddDonation($input: AddDonationInput, $paymentInfo: AddDonationPaymentInfo!) {
    addDonation(input: $input, paymentInfo: $paymentInfo) {
      id
    }
  }
`;
/**
 * __useAddDonationMutation__
 *
 * To run a mutation, you first call `useAddDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDonationMutation, { data, loading, error }] = useAddDonationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      paymentInfo: // value for 'paymentInfo'
 *   },
 * });
 */
export function useAddDonationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AddDonationDocument, options);
}
export const ExperienceLandingDocument = gql `
  query ExperienceLanding($id: ID!) {
    experience(id: $id) {
      ...ExperienceLandingFragment
    }
  }
  ${ExperienceLandingFragmentFragmentDoc}
`;
/**
 * __useExperienceLandingQuery__
 *
 * To run a query within a React component, call `useExperienceLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceLandingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceLandingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceLandingDocument, options);
}
export function useExperienceLandingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceLandingDocument, options);
}
export const ExperienceRegistrationByIdDocument = gql `
  query ExperienceRegistrationById($id: ID!) {
    experience(id: $id) {
      ...ExperienceRegistration
    }
  }
  ${ExperienceRegistrationFragmentDoc}
`;
/**
 * __useExperienceRegistrationByIdQuery__
 *
 * To run a query within a React component, call `useExperienceRegistrationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceRegistrationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceRegistrationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceRegistrationByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceRegistrationByIdDocument, options);
}
export function useExperienceRegistrationByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceRegistrationByIdDocument, options);
}
export const ExperienceAttendeesDocument = gql `
  query ExperienceAttendees($id: ID!) {
    attendees: attendeesList(filter: { experience: { id: { equals: $id } } }) {
      count
      items {
        id
        user {
          id
          email
        }
      }
    }
  }
`;
/**
 * __useExperienceAttendeesQuery__
 *
 * To run a query within a React component, call `useExperienceAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceAttendeesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceAttendeesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceAttendeesDocument, options);
}
export function useExperienceAttendeesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceAttendeesDocument, options);
}
export const WelcomeEmailSenderDocument = gql `
  mutation WelcomeEmailSender(
    $email: String!
    $firstName: String!
    $lastName: String!
    $url: String!
  ) {
    welcomeEmailSender(email: $email, firstName: $firstName, lastName: $lastName, url: $url) {
      result
    }
  }
`;
/**
 * __useWelcomeEmailSenderMutation__
 *
 * To run a mutation, you first call `useWelcomeEmailSenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWelcomeEmailSenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [welcomeEmailSenderMutation, { data, loading, error }] = useWelcomeEmailSenderMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useWelcomeEmailSenderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(WelcomeEmailSenderDocument, options);
}
export const NewOrderShoppingCartDocument = gql `
  mutation NewOrderShoppingCart($data: OrderCreateInput!) {
    orderCreate(data: $data) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;
/**
 * __useNewOrderShoppingCartMutation__
 *
 * To run a mutation, you first call `useNewOrderShoppingCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderShoppingCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderShoppingCartMutation, { data, loading, error }] = useNewOrderShoppingCartMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewOrderShoppingCartMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewOrderShoppingCartDocument, options);
}
export const NewOrderItemDocument = gql `
  mutation NewOrderItem($data: OrderItemCreateInput!) {
    orderItemCreate(data: $data) {
      id
      description
      category
      transactionType
      amount
      order {
        id
      }
    }
  }
`;
/**
 * __useNewOrderItemMutation__
 *
 * To run a mutation, you first call `useNewOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderItemMutation, { data, loading, error }] = useNewOrderItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewOrderItemMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewOrderItemDocument, options);
}
export const NewDiscountUsageDocument = gql `
  mutation NewDiscountUsage(
    $discount: DiscountCodeSettingKeyFilter!
    $order: OrderKeyFilter!
    $amount: Float!
  ) {
    shoppingCartRegisterDiscount(discount: $discount, order: $order, amount: $amount) {
      id
      description
      category
      transactionType
      amount
      order {
        id
      }
    }
  }
`;
/**
 * __useNewDiscountUsageMutation__
 *
 * To run a mutation, you first call `useNewDiscountUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDiscountUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDiscountUsageMutation, { data, loading, error }] = useNewDiscountUsageMutation({
 *   variables: {
 *      discount: // value for 'discount'
 *      order: // value for 'order'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useNewDiscountUsageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewDiscountUsageDocument, options);
}
export const NewStatusOrderDocument = gql `
  mutation NewStatusOrder($data: OrderHistoryCreateInput!) {
    orderHistoryCreate(data: $data) {
      id
      status
      reason
      order {
        id
      }
    }
  }
`;
/**
 * __useNewStatusOrderMutation__
 *
 * To run a mutation, you first call `useNewStatusOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewStatusOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newStatusOrderMutation, { data, loading, error }] = useNewStatusOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewStatusOrderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewStatusOrderDocument, options);
}
export const NewRegistratioOptionFullfimentDocument = gql `
  mutation NewRegistratioOptionFullfiment($data: RegistrationOptionFulfillmentCreateInput!) {
    registrationOptionFulfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewRegistratioOptionFullfimentMutation__
 *
 * To run a mutation, you first call `useNewRegistratioOptionFullfimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewRegistratioOptionFullfimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newRegistratioOptionFullfimentMutation, { data, loading, error }] = useNewRegistratioOptionFullfimentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewRegistratioOptionFullfimentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewRegistratioOptionFullfimentDocument, options);
}
export const NewAssetFullfimentDocument = gql `
  mutation NewAssetFullfiment($data: AssetFullfillmentCreateInput!) {
    assetFullfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewAssetFullfimentMutation__
 *
 * To run a mutation, you first call `useNewAssetFullfimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewAssetFullfimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newAssetFullfimentMutation, { data, loading, error }] = useNewAssetFullfimentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewAssetFullfimentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewAssetFullfimentDocument, options);
}
export const NewSwagbagFullfimentDocument = gql `
  mutation NewSwagbagFullfiment($data: AssetSwagbagFulfillmentCreateInput!) {
    assetSwagbagFulfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewSwagbagFullfimentMutation__
 *
 * To run a mutation, you first call `useNewSwagbagFullfimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewSwagbagFullfimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newSwagbagFullfimentMutation, { data, loading, error }] = useNewSwagbagFullfimentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewSwagbagFullfimentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewSwagbagFullfimentDocument, options);
}
export const NewPaymentOrderDocument = gql `
  mutation NewPaymentOrder($data: PaymentCreateInput!) {
    paymentCreate(data: $data) {
      id
      status
      gateway
      method
      category
      reasonStatus
      totalAmount
      order {
        id
      }
    }
  }
`;
/**
 * __useNewPaymentOrderMutation__
 *
 * To run a mutation, you first call `useNewPaymentOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewPaymentOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newPaymentOrderMutation, { data, loading, error }] = useNewPaymentOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewPaymentOrderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewPaymentOrderDocument, options);
}
export const NewDonationOrderDocument = gql `
  mutation NewDonationOrder($data: ExperienceDonationCreateInput!) {
    experienceDonationCreate(data: $data) {
      id
      amount
    }
  }
`;
/**
 * __useNewDonationOrderMutation__
 *
 * To run a mutation, you first call `useNewDonationOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDonationOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDonationOrderMutation, { data, loading, error }] = useNewDonationOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewDonationOrderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewDonationOrderDocument, options);
}
export const NewDonationOrderIndividualDocument = gql `
  mutation NewDonationOrderIndividual($data: IndividualFundraisingDonationCreateInput!) {
    individualFundraisingDonationCreate(data: $data) {
      id
      amount
    }
  }
`;
/**
 * __useNewDonationOrderIndividualMutation__
 *
 * To run a mutation, you first call `useNewDonationOrderIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDonationOrderIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDonationOrderIndividualMutation, { data, loading, error }] = useNewDonationOrderIndividualMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewDonationOrderIndividualMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewDonationOrderIndividualDocument, options);
}
export const DefaultQuestionsByExperienceIdDocument = gql `
  query DefaultQuestionsByExperienceId($experienceId: ID!) {
    defaultCampaignQuestionsList(
      filter: {
        OR: [
          { experience: { every: null } }
          { experience: { some: { id: { equals: $experienceId } } } }
        ]
        campaign: { experiences: { some: { id: { equals: $experienceId } } } }
        shouldDisplay: { equals: true }
        isEnabledAttendee: { equals: true }
      }
      sort: [{ order: ASC }]
    ) {
      count
      items {
        isRequiredAttendee
        question {
          id
          title
          category
          type
          options
          name
          format
          isMandatory
        }
      }
    }
  }
`;
/**
 * __useDefaultQuestionsByExperienceIdQuery__
 *
 * To run a query within a React component, call `useDefaultQuestionsByExperienceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultQuestionsByExperienceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultQuestionsByExperienceIdQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useDefaultQuestionsByExperienceIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DefaultQuestionsByExperienceIdDocument, options);
}
export function useDefaultQuestionsByExperienceIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DefaultQuestionsByExperienceIdDocument, options);
}
export const CustomCampaignQuestionsByExperienceDocument = gql `
  query CustomCampaignQuestionsByExperience($experienceId: ID!) {
    customCampaignQuestionsList(
      filter: {
        OR: [
          { experience: { every: null } }
          { experience: { some: { id: { equals: $experienceId } } } }
        ]
        campaign: { experiences: { some: { id: { equals: $experienceId } } } }
        isEnabledAttendee: { equals: true }
      }
      sort: [{ order: ASC }]
    ) {
      count
      items {
        id
        title
        type
        options
        isRequiredAttendee
        category
        format
      }
    }
  }
`;
/**
 * __useCustomCampaignQuestionsByExperienceQuery__
 *
 * To run a query within a React component, call `useCustomCampaignQuestionsByExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomCampaignQuestionsByExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomCampaignQuestionsByExperienceQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useCustomCampaignQuestionsByExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CustomCampaignQuestionsByExperienceDocument, options);
}
export function useCustomCampaignQuestionsByExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CustomCampaignQuestionsByExperienceDocument, options);
}
export const RegistrationOptionsDocument = gql `
  query RegistrationOptions($ids: [ID!]) {
    registrationOptionsList(filter: { id: { in: $ids } }) {
      items {
        id
        name
        ageRequirement
      }
    }
  }
`;
/**
 * __useRegistrationOptionsQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRegistrationOptionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionsDocument, options);
}
export function useRegistrationOptionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionsDocument, options);
}
export const RegistrationOptionListDocument = gql `
  query RegistrationOptionList($filter: RegistrationOptionFilter) {
    options: registrationOptionsList(filter: $filter) {
      count
      items {
        id
        name
        description
        ageRequirement
        ageRequirementAsOfDate
        attendeesPerRegistration
        maximumRegistrationPerOrder
        registrationStartDate
        registrationEndDate
        fundraisingMinimum
        experience {
          id
          name
          startDate
          endDate
        }
        pricingSettings {
          pricingType
          pricingEnabled
          pricingTiers {
            items {
              id
              taxDeductibleType
              taxDeductiblePercentsAmount
              taxDeductibleCurrencyAmount
              price
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`;
/**
 * __useRegistrationOptionListQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationOptionListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionListDocument, options);
}
export function useRegistrationOptionListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionListDocument, options);
}
export const CheckSoldOutRegistrationDocument = gql `
  query CheckSoldOutRegistration(
    $experienceId: ID!
    $amountOfRegistered: Int!
    $registerOptionId: ID
  ) {
    checkSoldOutRegistration(
      data: {
        experienceId: $experienceId
        amountOfRegistered: $amountOfRegistered
        registerOptionId: $registerOptionId
      }
    ) {
      registrations {
        registerId
        isSoldOut
      }
    }
  }
`;
/**
 * __useCheckSoldOutRegistrationQuery__
 *
 * To run a query within a React component, call `useCheckSoldOutRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSoldOutRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSoldOutRegistrationQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      amountOfRegistered: // value for 'amountOfRegistered'
 *      registerOptionId: // value for 'registerOptionId'
 *   },
 * });
 */
export function useCheckSoldOutRegistrationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CheckSoldOutRegistrationDocument, options);
}
export function useCheckSoldOutRegistrationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CheckSoldOutRegistrationDocument, options);
}
export const GetRegistrationOptionDetailsDocument = gql `
  query getRegistrationOptionDetails($registrationOptionId: String!) {
    getRegistrationOptionDetails(data: { registrationOptionId: $registrationOptionId }) {
      registrationStartDate
      registrationEndDate
      fundraisingMinimum
      description
    }
  }
`;
/**
 * __useGetRegistrationOptionDetailsQuery__
 *
 * To run a query within a React component, call `useGetRegistrationOptionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistrationOptionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistrationOptionDetailsQuery({
 *   variables: {
 *      registrationOptionId: // value for 'registrationOptionId'
 *   },
 * });
 */
export function useGetRegistrationOptionDetailsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRegistrationOptionDetailsDocument, options);
}
export function useGetRegistrationOptionDetailsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRegistrationOptionDetailsDocument, options);
}
export const PublicClientStoreAssetsByExperienceIdDocument = gql `
  query PublicClientStoreAssetsByExperienceId($experienceId: ID!) {
    experienceStoreAssetsList(filter: { experience: { id: { equals: $experienceId } } }) {
      items {
        ...PublicClientExperienceStoreAssetFragment
      }
    }
  }
  ${PublicClientExperienceStoreAssetFragmentFragmentDoc}
`;
/**
 * __usePublicClientStoreAssetsByExperienceIdQuery__
 *
 * To run a query within a React component, call `usePublicClientStoreAssetsByExperienceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicClientStoreAssetsByExperienceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicClientStoreAssetsByExperienceIdQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function usePublicClientStoreAssetsByExperienceIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PublicClientStoreAssetsByExperienceIdDocument, options);
}
export function usePublicClientStoreAssetsByExperienceIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PublicClientStoreAssetsByExperienceIdDocument, options);
}
export const TmpPublicClientStorePurchaseEventCreateDocument = gql `
  mutation TmpPublicClientStorePurchaseEventCreate($data: StorePurchaseEventCreateInput!) {
    storePurchaseEventCreate(input: $data) {
      success
    }
  }
`;
/**
 * __useTmpPublicClientStorePurchaseEventCreateMutation__
 *
 * To run a mutation, you first call `useTmpPublicClientStorePurchaseEventCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTmpPublicClientStorePurchaseEventCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tmpPublicClientStorePurchaseEventCreateMutation, { data, loading, error }] = useTmpPublicClientStorePurchaseEventCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTmpPublicClientStorePurchaseEventCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TmpPublicClientStorePurchaseEventCreateDocument, options);
}
export const StripeOverwritePaymentMethodDocument = gql `
  mutation stripeOverwritePaymentMethod($customerId: String!) {
    stripeRemovePaymentMethod(customerId: $customerId) {
      success
      error
    }
  }
`;
/**
 * __useStripeOverwritePaymentMethodMutation__
 *
 * To run a mutation, you first call `useStripeOverwritePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeOverwritePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeOverwritePaymentMethodMutation, { data, loading, error }] = useStripeOverwritePaymentMethodMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useStripeOverwritePaymentMethodMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripeOverwritePaymentMethodDocument, options);
}
export const GetTaxesDocument = gql `
  mutation getTaxes($billingAddress: BillingData!, $items: [AvalaraCartItem!]!) {
    avalaraTaxes(billingAddress: $billingAddress, items: $items) {
      amountTotal
      totalTax
      totalTaxable
    }
  }
`;
/**
 * __useGetTaxesMutation__
 *
 * To run a mutation, you first call `useGetTaxesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTaxesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTaxesMutation, { data, loading, error }] = useGetTaxesMutation({
 *   variables: {
 *      billingAddress: // value for 'billingAddress'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useGetTaxesMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(GetTaxesDocument, options);
}
export const TeamFundraisingLandingDocument = gql `
  query TeamFundraisingLanding($idOrWebLink: String!) {
    teamFundraiserLandingData(idOrWebLink: $idOrWebLink) {
      items {
        id
        name
        createdAt
        avatar {
          id
          downloadUrl
        }
        gallery {
          items {
            downloadUrl
          }
        }
        teamMissionTitle
        teamMissionDescription
        teamPhoto {
          downloadUrl
        }
        teamCaptain {
          items {
            id
            role
            attendee {
              id
              fundraiserGoal
              totalRaised
              user {
                id
                firstName
                lastName
                avatar {
                  id
                  downloadUrl
                }
              }
              fundraising {
                raised
                goal
              }
            }
          }
        }
        members {
          count
          items {
            id
            role
            attendee {
              id
              totalRaised
              createdAt
              user {
                id
                createdAt
                firstName
                lastName
                avatar {
                  id
                  downloadUrl
                }
              }
              registrationOption {
                id
                name
              }
            }
          }
        }
        fundraiserGoal
        totalRaised
        experience {
          id
          experienceLogoImage {
            downloadUrl
          }
          experienceHeaderImage {
            downloadUrl
          }
        }
        donations {
          items {
            id
          }
        }
        campaign {
          id
          name
          logo {
            id
            downloadUrl
          }
          image {
            id
            downloadUrl
          }
          fundraisingGoal
          totalRaised
        }
      }
    }
  }
`;
/**
 * __useTeamFundraisingLandingQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingLandingQuery({
 *   variables: {
 *      idOrWebLink: // value for 'idOrWebLink'
 *   },
 * });
 */
export function useTeamFundraisingLandingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingLandingDocument, options);
}
export function useTeamFundraisingLandingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingLandingDocument, options);
}
export const TopTeamMetricsLeaderboardFundraisingDocument = gql `
  query TopTeamMetricsLeaderboardFundraising($teamId: String!) {
    TopTeamMetricsLeaderboard(data: { teamId: $teamId }) {
      highestAverageDonation {
        amount
        name
        avatar
      }
      mostDonor {
        amount
        name
        avatar
      }
      topFundraiser {
        amount
        name
        avatar
      }
    }
  }
`;
/**
 * __useTopTeamMetricsLeaderboardFundraisingQuery__
 *
 * To run a query within a React component, call `useTopTeamMetricsLeaderboardFundraisingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopTeamMetricsLeaderboardFundraisingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopTeamMetricsLeaderboardFundraisingQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTopTeamMetricsLeaderboardFundraisingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TopTeamMetricsLeaderboardFundraisingDocument, options);
}
export function useTopTeamMetricsLeaderboardFundraisingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TopTeamMetricsLeaderboardFundraisingDocument, options);
}
export const TeamFundraisingDocument = gql `
  query TeamFundraising($filter: TeamFundraisingFilter!) {
    team: teamFundraisingsList(filter: $filter) {
      items {
        id
        name
        avatar {
          downloadUrl
        }
        experience {
          id
        }
      }
    }
  }
`;
/**
 * __useTeamFundraisingQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingDocument, options);
}
export function useTeamFundraisingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingDocument, options);
}
export const IndividualFundraisingDocument = gql `
  query individualFundraising($filter: IndividualFundraisingFilter!) {
    fundraisers: individualFundraisingsList(filter: $filter) {
      items {
        id
        title
        logo {
          downloadUrl
        }
        attendee {
          experience {
            id
          }
          user {
            firstName
            lastName
          }
          teamMembership {
            team {
              id
              name
            }
          }
        }
      }
    }
  }
`;
/**
 * __useIndividualFundraisingQuery__
 *
 * To run a query within a React component, call `useIndividualFundraisingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualFundraisingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(IndividualFundraisingDocument, options);
}
export function useIndividualFundraisingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(IndividualFundraisingDocument, options);
}
export const TicketingOptionListDocument = gql `
  query TicketingOptionList($filter: TicketingOptionFilter) {
    options: ticketingOptionsList(filter: $filter) {
      items {
        id
        ticketName
        ticketingAvailabilityStartDateTime
        ticketingAvailabilityEndDateTime
        description
        ageRequirement
        ageRequirementAsOfDate
        attendeesPerTicket
        maximumAttendees
        maximumPerOrder
        attendeesPerTicket
        attendees {
          count
        }
        experience {
          id
          name
          startDate
          endDate
        }
        pricingSettings {
          pricingType
          pricingEnabled
          pricingTiers {
            items {
              id
              taxDeductibleType
              price
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`;
/**
 * __useTicketingOptionListQuery__
 *
 * To run a query within a React component, call `useTicketingOptionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketingOptionListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTicketingOptionListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TicketingOptionListDocument, options);
}
export function useTicketingOptionListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TicketingOptionListDocument, options);
}
export const TicketingVerifyUserDocument = gql `
  query TicketingVerifyUser($experienceId: String!, $email: String!) {
    ticketingVerifyUser(email: $email, experienceId: $experienceId) {
      success
      message
    }
  }
`;
/**
 * __useTicketingVerifyUserQuery__
 *
 * To run a query within a React component, call `useTicketingVerifyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketingVerifyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketingVerifyUserQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useTicketingVerifyUserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TicketingVerifyUserDocument, options);
}
export function useTicketingVerifyUserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TicketingVerifyUserDocument, options);
}
export const PublicClientUserDocument = gql `
  query PublicClientUser {
    user {
      id
      email
      firstName
      lastName
      gender
      birthDate
      avatar {
        id
        downloadUrl
      }
      roles {
        items {
          id
          name
        }
      }
      phone {
        code
        number
      }
      address {
        city
        state
        country
        zip
        street1
        street2
      }
      vizslaOrganizationStaff {
        items {
          id
          roles
          vizslaOrganization {
            id
          }
        }
      }
    }
  }
`;
/**
 * __usePublicClientUserQuery__
 *
 * To run a query within a React component, call `usePublicClientUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicClientUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicClientUserQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicClientUserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PublicClientUserDocument, options);
}
export function usePublicClientUserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PublicClientUserDocument, options);
}
export const UserGetDataDocument = gql `
  query UserGetData($filter: UserFilter) {
    usersList(filter: $filter) {
      count
      items {
        ...USER_DATA_FRAGMENT
        childrens {
          count
          items {
            ...USER_DATA_FRAGMENT
          }
        }
        parent {
          ...USER_DATA_FRAGMENT
        }
      }
    }
  }
  ${User_Data_FragmentFragmentDoc}
`;
/**
 * __useUserGetDataQuery__
 *
 * To run a query within a React component, call `useUserGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserGetDataQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserGetDataDocument, options);
}
export function useUserGetDataLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserGetDataDocument, options);
}
